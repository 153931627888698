import React, { useState, useEffect, useCallback } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { zonedTimeToUtc } from 'date-fns-tz';
import axios from 'axios';
import ConversionHelper from '../../helpers/conversionHelper';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DateSelect from '../UI/DateSelect/DateSelect';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Aux from '../../hoc/Auxilary/Auxilary';
import SectionTitle from '../UI/Typography/SectionTitle';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import ColorHelper from '../../helpers/colorHelper';

const ScheduleBarChart = (props) => {
    const [values, setValues] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        endDate: new Date(new Date().setDate(new Date().getDate() + 2)),
        user: '',
        users: [],
        process: '',
        userSel: null,
        processSel: null,
        depSel: null,
        department: ''
    });
    const [departments, setDepartements] = useState([]);
    const [users, setUsers] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [bars, setBars] = useState([]);

    const { t } = useTranslation('translation');

    const loadRecords = useCallback(async () => {
        const data = {
            tenant: props.auth.user.tenant,
            user: values.user,
            department: values.department,
            startDate: zonedTimeToUtc(ConversionHelper.minDate(values.startDate)),
            endDate: zonedTimeToUtc(ConversionHelper.maxDate(values.endDate)),
        };
        let result = await axios.get('/api/schedulecount', { params: data });
            let rows = result.data.rows;
            let types = result.data.types;
            let row1 = rows[0];
            let row2 = rows[1];
            let row3 = rows[2];
            let row4 = rows[3];
            let row5 = rows[4];
            let row6 = rows[5];
            for (let key of Object.keys(row1)) {
                if(row1[key] === 0 && row2[key] === 0 && row3[key] === 0 && row4[key] === 0 && row5[key] === 0 && row6[key] === 0){
                    let typeIndex = types.findIndex(x => x === key);
                    if(typeIndex > -1){
                        types.splice(typeIndex, 1);
                    }
                }
                if (row1[key] === 0) {
                    delete row1[key];
                }
                if (row1[key] === 0) {
                    delete row2[key];
                }
                if (row3[key] === 0) {
                    delete row3[key];
                }
                if (row4[key] === 0) {
                    delete row4[key];
                }
                if (row5[key] === 0) {
                    delete row5[key];
                }
                if (row6[key] === 0) {
                    delete row6[key];
                }
            }
            setChartData(rows);
            let bars = [];
            const colors = ColorHelper.colorList();
            let i = 0;
            for (let row of types) {
                bars.push({
                    dataKey: row,
                    stackId: 'a',
                    fill: colors[i]
                });
                i++;
            }
            setBars(bars);
    }, [props, values]);

    useEffect(() => {
        const getData = async () => {
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.userDepartment], props.auth.constants, null, props.auth.user.tenant);
            let data  = await axios.get('/api/schedulecountvalues');
            setUsers(data.data.users);
            setDepartements(cacheValues.userDepartments);
        }
        getData();
    }, [props, values]);

    useEffect(() => {
        loadRecords();
    }, [values, loadRecords]);

    const changeAuto = (name, data) => (e, newValue) => {
        setValues(
            {...values, 
                [name]: newValue, 
                [data]: newValue != null ? newValue.value : ''
            }, () => {loadRecords()});
    };

    const dateChange = (name) => (e) => {
        setValues({...values, [name]: e}, () => {loadRecords()});
    };

    const barClick = (e) => {
        let status = e.activePayload != null && e.activePayload.length > 0 && e.activePayload[0].payload.id != null ? e.activePayload[0].payload.id : null;
        if(status == null)
            props.history.push('/scheduledActivities?user=' + values.user);
        else 
            props.history.push('/scheduledActivities?status=' + status +
            '&startDate=' + values.startDate.toISOString() + '&endDate=' + values.endDate.toISOString() + '&user=' + values.user);
    };

    return (
        <Aux>
            <SectionTitle title={t('scheduledActs')}/>
            <Grid container spacing={3}>
                <Grid item xs={4} sm={2}>
                    <DateSelect
                        className="form-control"
                        name="startDate"
                        onChange={dateChange('startDate')}
                        value={values.startDate}
                        label={t('startDate')}
                        format={props.auth.user.dateFormat}
                    />
                </Grid>
                <Grid item xs={4} sm={2}>
                    <DateSelect
                        className="form-control"
                        id="endDate"
                        onChange={dateChange('endDate')}
                        value={values.endDate}
                        label={t('endDate')}
                        format={props.auth.user.dateFormat}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <AutoCompleteField
                        value={values.userSel}
                        options={users}
                        onChange={changeAuto('userSel', 'user')}
                        label={t('user')}
                    />
                </Grid>
                <Grid item xs={4} sm={3}>
                    <AutoCompleteField
                        value={values.depSel}
                        options={departments}
                        onChange={changeAuto('depSel', 'department')}
                        label={t('department')}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                            width={1000}
                            height={300}
                            data={chartData}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                            onClick={barClick}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="status" />
                            <YAxis />
                            <Tooltip />
                            {bars.map((row, i) =>
                                <Bar dataKey={row.dataKey} stackId={row.stackId} fill={row.fill} key={'barrow' + i}/>
                            )}
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </Aux>
    );
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(withRouter(ScheduleBarChart));