import React, { Component } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateTimeSelect from '../UI/DateTimeSelect/DateTimeSelect';
import TextField from '../UI/TextField/TextField';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { connect } from 'react-redux';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import isEmpty from '../../is-empty';
import ActivityWorkRecord from '../Cultivation/ActivityWorkRecord';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Link } from 'react-router-dom';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { approveSigSecurity, completeSigSecurity, 
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import Box from '@mui/material/Box';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import IconButton from '@mui/material/IconButton';
import LabelPrint from '../UI/Buttons/LabelPrint';
import ConversionHelper from '../../helpers/conversionHelper';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import LabelIcon from 'mdi-material-ui/Barcode';
import ItemLedgerComponent from '../Inventory/ItemLedgerComponent';
import { List, ListItem } from '@mui/material';
import PrintView from '../Printing/PrintView';

class ProductionOrderActivity extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            _id: '',
            number: '',
            status: '',
            statusSel: {
                value: '',
                label: '',
            },
            statuses: [],
            productionOrder: '',
            poLine: '',
            room: '',
            equipments: [],
            users: [],
            usersSel: [],
            userOptions: [],
            startTime: new Date(),
            completeTime: new Date(),
            effort: '',
            sops: [],
            sopList: [],
            sopIds: [],
            sopsSel: [],
            user: null,
            reviewer: null,
            signer: null,
            reviewers: [],
            signers: [],
            tabKey: 0,
            tabLabels: [{name: t('activity'), disabled: false}],
            documents: [],
            signed: false,
            renderNum: Math.random(),
            security: [],
            fieldAccess: false, 
            deviations: [],
            ncrs: [],
            optionsMenu: [],
            flagForSignature: 0,
            enableSave: true,
            labourCost: '',
            rooms: [],
            roomSel: null,
            notes: '',
            activeUsers: [],
            activeManagers: [],
            activeQas: [],
            form: '',
            hasForm: false,
            template: '',
            critical: false,
            outputs: [],
            item: '',
            itemSel: null,
            items: [],
            amount: '',
            uom: '',
            uomSel: null,
            uoms: [],
            customLot: '',
            totalWeight: '',
            totalWeightUOM: '',
            totalWeightUOMSel: null,
            bin: '',
            binSel: null,
            bins: [],
            containerType: '',
            containerTypeSel: null,
            containerTypes: [],
            container: '',
            containerSel: null,
            containers: [],
            lot: '',
            lotSel: null,
            lots: [],
            outputLines: [],
            outputLabels: [],
            enableUnlock: false,
            newLot: false,
            itemLots: [],
            machineTypes: [],
            allEquipment: [],
            finalDocument: null,
            printViewer: false,
            equipmentAll: [],
            hasLedgers: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.user, common.cacheValues.status, common.cacheValues.room, common.cacheValues.machineType,
            common.cacheValues.productionOrderActivityForm, common.cacheValues.productionOrderActivitySop, common.cacheValues.item, common.cacheValues.quantityUOM,
            common.cacheValues.containerType, common.cacheValues.bin, common.cacheValues.equipment, common.cacheValues.equipmentAll], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let allUsers = cacheValues.users;
        const userValues = await ValueHelper.screenUsers(common.screens.productionOrderActivity, this.props.auth.user.tenant);
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        });
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        let gSel = cacheValues.quantityUOMs.find(x => x.value === this.props.auth.constants.quantityUOMs.g);
        this.setState({
            items: cacheValues.items,
            uoms: cacheValues.quantityUOMs,
            statuses: cacheValues.statuses,
            rooms: cacheValues.rooms,
            sopList: cacheValues.sops,
            userOptions: userValues.create,
            reviewers: userValues.review,
            signers: userValues.approve,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            fieldAccess: fieldAccess,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            security: this.props.permission,
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            activeQas: activeQas,
            template: cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            allUsers: allUsers,
            bins: cacheValues.bins,
            containerTypes: cacheValues.containerTypes,
            gSel: gSel,
            allEquipment: cacheValues.equipments,
            equipmentAll: cacheValues.equipmentAll,
            machineTypes: cacheValues.machineTypes
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/eproductionorders', label: this.props.t('productionOrders'), screen: this.props.auth.screenDefs.ProductionOrder},
                    { path: '/#/eproductionorder/', label: this.props.t('order'), screen: this.props.auth.screenDefs.ProductionOrder},
                    { path: 'active', label: this.props.t('productionOrderActivity'), screen: this.props.auth.screenDefs.ProductionOrder}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        let record = await axios.get('/api/poactivity/' + this.state._id);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let usersSel = [];
        let userOptions = this.state.userOptions;
        record.data.users.forEach(row => {
            let val = this.state.userOptions.find(x => x.value === row);
            if(val != null){
                usersSel.push(val);
            } else {
                val = this.state.allUsers.find(x => x.value === row);
                if(val != null){
                    usersSel.push(val);
                    userOptions.push(val);
                }
            }
        });
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let sopIds = [];
        let sopsSel = [];
        let tabLabels = [{name: t('activity'), disabled: false}];
        let line = record.data.productionOrder.lines.find(x => x._id === record.data.poLine);
        let critical = line != null && line.critical === true ? true : false;
        if(record.data.sops != null && record.data.sops.length > 0){
            record.data.sops.forEach(sop => {
                let val = this.state.sopList.find(x => x.value === sop.sop);
                sopsSel.push(val);
                sopIds.push(sop.sop);
                tabLabels.push({name: val.label, disabled: false});
            });
        }
        if(record.data.hasLedgers){
            tabLabels.push({name: t('itemLedgers'), disabled: false});
        }
        let room = record.data.room != null ? record.data.room : '';
        let roomSel = record.data.room != null ? this.state.rooms.find(x => x.value === record.data.room) : null;
        let outputs = record.data.outputs;
        let outputLines = [];
        let outputLabels = [];
        let itemLots = record.data.itemLots;
        let equipments = record.data.equipments;
        if(equipments != null && equipments.length > 0){
            for(let row of equipments){
                if(row.machineType != null){
                    let machineTypeSel = this.state.machineTypes.find(x => x.value === row.machineType);
                    row.machineTypeSel = machineTypeSel;
                    let equips = await ValueHelper.getEquipmentByMachineType(row.machineType);
                    row.equipments = equips;
                }
                let equipmentsSel = [];
                if(row.equipment != null && row.equipment.length > 0){
                    for(let e of row.equipment){
                        let val = this.state.equipmentAll.find(x => x.value === e);
                        if(val != null){
                            equipmentsSel.push(val);
                        }
                    }
                }
                row.equipmentsSel = equipmentsSel;
            }
        }
        if(outputs != null && outputs.length > 0){
            outputs.forEach(row => {
                if(row.item != null){
                    let line = outputLines.find(x => x.item === row.item);
                    let itemSel = this.state.items.find(x => x.value === row.item);
                    let uomSel = this.state.uoms.find(x => x.value === row.uom);
                    let itemLotRow = itemLots.find(x => x.item === row.item);
                    let lots = itemLotRow != null ? itemLotRow.values : [];
                    let lotSel = lots != null && lots.length > 0 && row.lot != null ? lots.find(x => x.value === row.lot) : null;
                    let containers = [];
                    if(row.lot != null){
                        let lotRow = itemLotRow != null ? itemLotRow.lots.find(x => x.lot === row.lot) : null;
                        if(lotRow != null){
                            containers = lotRow.containers;
                        }
                    }
                    let newLot = row.newLot === true ? true : itemSel.customNumber !== true ? false : lots == null || lots.length === 0 ? true : false;
                    let containerSel = containers != null && containers.length > 0 && row.container != null ?
                        containers.find(x => x.value === row.container) : null;
                    let binSel = row.bin != null ? this.state.bins.find(x => x.value === row.bin) : null;
                    let containerTypeSel = row.containerType != null ? this.state.containerTypes.find(x => x.value === row.containerType) : null;
                    if(line == null){
                        let outputLength = 310;
                        if((row.count != null && row.count !== '' && row.count !== 0) || (row.itemSel != null && row.itemSel.hasCount === true)){
                            outputLength += 100;
                        }
                        if((row.totalWeight != null && row.totalWeight !== '' && row.totalWeight !== 0) || (row.itemSel != null && row.itenSel.discreteWeight === true)){
                            outputLength += 150
                            if(fieldAccess){
                                outputLength += 60
                            }
                        }
                        if((itemSel != null && itemSel.lotTracked) || (row.lot != null && row.lot !== '') || (row.customLet != null && row.customLot !== '')){
                            outputLength += 420;
                        }
                        if(itemSel != null && itemSel.tare === true){
                            outputLength += 120
                        }
                        if(itemSel != null && itemSel.lotTracked === true && !fieldAccess){
                            outputLength += 320;
                        }
                        if(row.discreteWeight != null && row.discreteWeight !== '' && !fieldAccess){
                            outputLength += 150;
                        }
                        outputLines.push({
                            item: row.item,
                            itemSel: itemSel,
                            amount: row.amount,
                            uom: row.uom,
                            uomSel: uomSel,
                            count: row.count,
                            totalWeight: row.totalWeight,
                            lots: lots,
                            outputLength: outputLength,
                            lines: [{
                                _id: row._id,
                                container: row.container,
                                containerSel: containerSel,
                                containers: containers,
                                containerTransaction: row.containerTransaction,
                                lot: row.lot,
                                lotSel: lotSel,
                                lotTransaction: row.lotTransaction,
                                bin: row.bin,
                                binSel: binSel,
                                containerType: row.containerType,
                                containerTypeSel: containerTypeSel,
                                amount: row.amount,
                                count: row.count,
                                itemLedger: row.itemLedger,
                                totalWeight: row.totalWeight,
                                discreteWeight: row.discreteWeight,
                                customLot: row.customLot,
                                tareWeight: row.tareWeight,
                                totalUOM: this.state.g,
                                totalUOMSel: this.state.gSel,
                                newLot: newLot
                            }]
                        })
                    } else {
                        line.amount = line.amount != null && row.amount != null ? parseFloat(line.amount) + parseFloat(row.amount) : row.amount != null ? row.amount : line.amount;
                        line.count = row.count != null && line.count != null ? parseInt(row.count) + parseInt(line.count) : row.count != null ? row.count : line.count;
                        line.totalWeight = line.totalWeight != null && row.totalWeight != null ? parseFloat(line.totalWeight) + parseFloat(row.totalWeight) :
                            row.totalWeight != null ? row.totalWeight : line.totalWeight;
                        line.lines.push({
                            _id: row._id,
                            container: row.container,
                            containerSel: containerSel,
                            containers: containers,
                            containerTransaction: row.containerTransaction,
                            lot: row.lot,
                            lotSel: lotSel,
                            lotTransaction: row.lotTransaction,
                            bin: row.bin,
                            binSel: binSel,
                            containerType: row.containerType,
                            containerTypeSel: containerTypeSel,
                            amount: row.amount,
                            count: row.count,
                            itemLedger: row.itemLedger,
                            totalWeight: row.totalWeight,
                            discreteWeight: row.discreteWeight,
                            customLot: row.customLot,
                            tareWeight: row.tareWeight,
                            totalUOM: this.state.g,
                            totalUOMSel: this.state.gSel,
                            newLot: newLot
                        });
                    }
                    if(row.containers != null && row.containers.length > 0 && row.container != null && containerSel != null && containerTypeSel != null) {
                        outputLabels.push({
                            id: row._id,
                            container: containerSel.number,
                            lot: row.lot.number,
                            item: itemSel.label,
                            quantity: ConversionHelper.round3(row.amount),
                            uom: uomSel.label,
                            category: containerTypeSel.label,
                            itemNumber: itemSel.number,
                            date: record.data.completeTime != null ? record.data.completeTime : record.data.startTime,
                            qrPath: `${window.location.origin}/#/container/${row._id}`,
                            strain: itemSel.strain != null && itemSel.strain !== '' ? itemSel.strain : '',
                            strainCode: itemSel.strainCode != null && itemSel.strainCode !== '' ? itemSel.strainCode : '',
                            dateFormat: this.props.auth.user.dateFormat
                        });
                    }
                }
            })
        };
        let signed = record.data.status === this.props.auth.constants.statuses.complete || 
                        record.data.status === this.props.auth.constants.statuses.reviewed ||
                        record.data.status === this.props.auth.constants.statuses.signed;
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.receiver, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let sigDeleteCallback = (record.data.status === this.props.auth.constants.statuses.new || record.data.status === this.props.auth.constants.statuses.inprogress) && 
                        record.data.user != null ? this.deleteSigCallback : null;   
        const optionsMenu = critical === true || (record.data.outputs != null && record.data.outputs.length > 0) ? [
            { 
                security: completeSigSec, user: this.props.auth.user.id, users: usersSel, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user, 
                objectType: this.props.auth.constants.objectIds.poActivity, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, deleteCallback: sigDeleteCallback
            },
            { 
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.reviewers, 
                name: t('review'), title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id, 
                objectType: this.props.auth.constants.objectIds.poActivity, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: !internal && this.state.signer == null ? this.state.activeQas : this.state.signers, 
                name: t('approve'), title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer, 
                objectType: this.props.auth.constants.objectIds.poActivity, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature, complete: true
            }
        ] : [
            { 
                security: completeSigSec, user: this.props.auth.user.id, users: usersSel, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user, 
                objectType: this.props.auth.constants.objectIds.poActivity, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, complete: true, deleteCallback: sigDeleteCallback
            }
        ];
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        let form = record.data.form != null && record.data.form !== '' ? JSON.parse(record.data.form) : this.state.template != null && this.state.template !== '' ? this.state.template : null;
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        let params = queryString.parse(this.props.location.search); 
        this.setState({
            status: record.data.status,
            statusSel: statusSel,
            number: record.data.number,
            productionOrder: record.data.productionOrder,
            poLine: record.data.poLine,
            type: record.data.type,
            room: room,
            equipments: equipments,
            users: record.data.users,
            usersSel: usersSel,
            completeTime: record.data.completeTime != null ? record.data.completeTime : new Date(),
            startTime: record.data.startTime,
            effort: record.data.effort,
            sops: record.data.sops,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            documents: record.data.documents,
            sopIds: sopIds,
            sopsSel: sopsSel,
            tabLabels: tabLabels,
            renderNum: Math.random(),
            signed: signed,
            fieldAccess: fieldAccess, 
            deviations: record.data.deviations,
            ncrs: record.data.ncrs,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            labourCost: record.data.labourCost,
            roomSel: roomSel,
            notes: record.data.notes,
            form: form,
            hasForm: hasForm,
            critical: critical,
            userOptions: userOptions,
            outputs: record.data.outputs,
            outputLines: outputLines,
            outputLabels: outputLabels,
            itemLots: record.data.itemLots,
            finalDocument: record.data.finalDocument,
            hasLedgers: record.data.hasLedgers
        }, () => {
            const crumbs = [
                { path: '/#/eproductionorders', label: this.props.t('productionOrders'), screen: this.props.auth.screenDefs.ProductionOrder},
                { path: '/#/eproductionorder/' + record.data.productionOrder._id, label: this.props.t('order') + ' - ' + record.data.productionOrder.number, screen: this.props.auth.screenDefs.ProductionOrder},
                { path: 'active', label: this.props.t('productionOrderActivity') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.ProductionOrder}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            this.determineUnlock();
            if(params.tab != null)
                this.openNotification(params);
        });
    }

    determineUnlock = () => {
        let enableUnlock = false;
        if(this.state.security.unlock === true){
            if(this.state.productionOrder.status === this.props.auth.constants.productionOrderStatuses.released || 
                this.state.productionOrder.status === this.props.auth.constants.productionOrderStatuses.inprogress){
                if(this.state.status === this.props.auth.constants.statuses.complete || 
                    this.state.status === this.props.auth.constants.statuses.reviewed || 
                    this.state.status === this.props.auth.constants.statuses.signed){
                        enableUnlock = true;
                }
            }
        }
        this.setState({enableUnlock: enableUnlock});
    }

    openNotification = (params) => {
        let tabKey = this.state.tabKey;
        if(params != null && params.tab != null)
            tabKey = Number(params.tab);
        this.setState({tabKey});
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]}, () => {
            if(name === 'newLot'){
                this.setState({container: '', containerSel: null, lot: '', lotSel: null})
            }
        });
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            if(data === 'item'){
                if(newValue != null){
                    let itemLots = this.state.itemLots;
                    let lots = [];
                    if(itemLots.length > 0){
                        let itemRow = itemLots.find(x => x.item === newValue.value);
                        if(itemRow != null){
                            lots = itemRow.values;
                        }
                    }
                    let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                    this.setState({
                        uom: newValue.uom,
                        uomSel: uomSel,
                        lots: lots,
                        containers: [],
                        newLot: newValue.customNumber === true  && lots.length === 0 ? true : false
                    });
                } else {
                    this.setState({
                        uomSel: null,
                        uom: '',
                        totalWeight: '',
                        totalUOM: '',
                        totalUOMSel: null,
                        totalWeightUOM: '',
                        totalWeightUOMSel: null,
                        discreteWeight: null,
                        discreteWeightUOM: '',
                        discreteWeightUOMSel: null,
                        lots: [],
                        containers: [],
                        newLot: false
                    });
                }
            } else if( data === 'lot'){
                if(newValue != null){
                    let itemLots = this.state.itemLots;
                    let itemRow = itemLots.find(x => x.item === this.state.item);
                    if(itemRow != null){
                        let lotRow = itemRow.lots.find(x => x.lot === newValue.value);
                        if(lotRow != null){
                            this.setState({containers: lotRow.containers, container: '', containerSel: null});
                        }
                    }
                } else {
                    this.setState({containers: [], container: '', containerSel: null});
                }
            }
        });
    };

    changeNumber = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeMulti = (name, data) => (e, newValue) => {
        var val = newValue;
        var ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    changeSop = (e, newValue) => {
        let sops = this.state.sops;
        let tabLabels = this.state.tabLabels;
        if(newValue != null){
            if(sops.length > newValue.length){
                for(let i = 0; i < sops.length; i++){
                    let sop = sops[i];
                    let rec = newValue.find(x => x.value === sop.sop);
                    if(!rec){
                        sops.splice(i, 1);
                        tabLabels.splice(i + 1, 1);
                        break;
                    }
                }
            } else {
                for(let i = 0; i < newValue.length; i++){
                    let val = newValue[i];
                    let rec = sops.find(x => x.sop === val.value)
                    if(!rec){
                        sops.push({
                            sop: val.value,
                            workRecord: null,
                            original: false
                        });
                        if(this.state._id != null && this.state._id !== '')
                            tabLabels.push({name: val.label, disabled: false});
                    }
                }
            }
            let sopIds = [];
            newValue.forEach(val => {
                sopIds.push(val.value);
            });
            this.setState({
                sopIds: sopIds,
                sopsSel: newValue,
                sops: sops
            });
        } else {
            for(let i = 0; i < sops.length; i++){
                let sop = sops[i];
                if(sop.required)
                    return;
            }
            this.setState({sops: [], sopIds: [], sopsSel: [], tabLabels: [{name: this.props.t('activity'), disabled: false}]});
        }
    };

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async (e) => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let outputLines = this.state.outputLines;
        let outputs = [];
        for(let output of outputLines){
            for(let row of output.lines){
                outputs.push({
                    item: output.item,
                    itemSel: output.itemSel,
                    amount: row.amount,
                    uom: output.uom,
                    uomSel: output.uomSel,
                    count: row.count,
                    lot: row.lot !== '' ? row.lot : null,
                    lotTransaction: row.lotTransaction,
                    container: row.container !== '' ? row.container : null,
                    containerTransaction: row.containerTransaction,
                    bin: row.bin,
                    itemLedger: row.itemLedger,
                    totalWeight: row.totalWeight,
                    totalUOM: row.totalWeight != null && row.totalWeight !== '' ? this.state.g : null,
                    discreteWeight: output.discreteWeight,
                    discreteWeightUOM: row.totalWeight != null && row.totalWeight !== '' ? this.state.g : null,
                    containerType: row.containerType,
                    customLot: row.customLot,
                    newLot: row.newLot
                });
            }
        }
        let data = {
            _id: this.state._id,
            completeTime: this.state.completeTime,
            startTime: this.state.startTime,
            status: this.state.status,
            effort: this.state.effort,
            sops: this.state.sops,
            users: this.state.users,
            room: this.state.room,
            notes: this.state.notes,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            outputs: outputs,
            equipments: this.state.equipments
        };
        try{
            let result = await axios.post('/api/poactivity', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/poactivity/' + result.data.id);
            this.setState({ enableSave: true }, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    };

    validate(full) {
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.startTime == null || state.startTime === ''){
            errors[t('startTime')] = t('required');
        } else {
            let validDate = ConversionHelper.validActivityDate(this.state.startTime, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
            if(!validDate){
                errors[t('startTime')] = t('dateBeforeLock');
            }
        }
        if(state.effort == null || state.effort === '')
            errors[t('effort')] = t('required');
        if(state.users == null || state.users.length === 0)
            errors[t('users')] = t('required');
        if(state.room == null || state.room === '')
            errors[t('room')] = t('required');
        if(full === true){
            for(let [i, row] of state.equipments.entries()){
                if(row.machineType != null && row.machineType !== ''){
                    if(row.equipment == null || row.equipment.length === 0)
                        errors[`${t('equipments')} (${t('line')} ${i})`] = t('required');
                }
            }
            if(state.completeTime == null || state.completeTime === ''){
                errors[t('completeTime')] = t('required');
            } else {
                let validEndDate = ConversionHelper.validActivityDate(this.state.completeTime, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
                if(!validEndDate){
                    errors[t('completeTime')] = t('dateBeforeLock');
                }
            }
            for(let row of state.outputLines){
                if(row.amount == null || row.amount === '')
                    errors[t('amount')] = t('required');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    addDeviation = (e) => {
        this.props.history.push('/deviation?productionOrder=' + this.state.productionOrder._id + '&poActivity=' + this.state._id);
    };

    addNcr = (e) => {
        this.props.history.push('/ncr?productionOrder=' + this.state.productionOrder._id + '&poActivity=' + this.state._id);
    };

    validateComplete = () => {
        const t = this.props.t;
        let state = this.state;
        let valid = this.validate(true);
        if(!valid)
            return t('notAllComplete');
        if(state.sops != null && state.sops.length > 0){
            for(let i = 0; i < state.sops.length; i++){
                let sop = state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        return null;
    }

    addOutputLine = (i) => {
        let lines = this.state.outputLines;
        let row = lines[i];
        if(row.lines == null){
            row.lines = [];
        }
        row.lines.push({
            container: '',
            containerSel: null,
            lot: '',
            lotSel: null,
            amount: '',
            count: '',
            discreteWeight: '',
            bin: '',
            binSel: null,
            totalWeight: '',
            customLot: '',
            containerType: '',
            containerTypeSel: null,
            newLot: false
        });
        this.setState({outputLines: lines});

    }

    addOutput = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.item == null || this.state.item === '')
            errors[t('item')] = t('required');
        if(this.state.amount == null || this.state.amount === '')
            errors[t('amount')] = t('required');
        if(this.state.uom == null || this.state.uom === '')
            errors[t('uom')] = t('required');
        if(this.state.itemSel != null && this.state.itemSel.category !== this.props.auth.constants.itemCategories.caExcise){
            if(this.state.bin == null || this.state.bin === '')
                errors[t('bin')] = t('required');
        }
        if(this.state.itemSel.discreteWeight ){
            if(this.state.totalWeight == null || this.state.totalWeight === '')
                errors[t('totalWeight')] = t('required');
        }
        if(this.state.itemSel != null && this.state.itemSel.customNumber === true){
            if((this.state.customLot == null || this.state.customLot === '') && this.state.newLot === true){
                errors[t('customLot')] = t('required');
            } else if(this.state.lots != null && this.state.lots.length > 0 && (this.state.lot == null || this.state.lot === '')){
                errors[t('lot')] = t('required');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let outputLines = this.state.outputLines;
        let existing = outputLines.find(x => x.item === this.state.item);
        if(existing != null){
            existing.amount = existing.amount != null && this.state.amount != null && this.state.amount !== '' ?
                parseFloat(existing.amount) + parseFloat(this.state.amount) : existing.amount == null ? this.state.amount : existing.amount;
            existing.totalWeight = existing.totalWeight != null && this.state.totalWeight != null && this.state.totalWeight !== '' ?
                parseFloat(existing.totalWeight) + parseFloat(this.state.totalWeight) : existing.totalWeight == null ? this.state.totalWeight : existing.totalWeight;
            existing.lines.push({
                amount: this.state.amount,
                bin: this.state.bin,
                binSel: this.state.binSel,
                totalWeight: this.state.totalWeight,
                containerType: this.state.containerType,
                containerTypeSel: this.state.containerTypeSel,
                customLot: this.state.customLot,
                totalUOM: this.state.totalUOM !== '' ? this.state.totalUOM : null,
                totalUOMSel: this.state.totalUOMSel,
                lot: this.state.lot,
                lotSel: this.state.lotSel,
                container: this.state.container,
                containerSel: this.state.containerSel,
                containers: this.state.containers,
                newLot: this.state.newLot
            })
        } else {
            outputLines.push({
                item: this.state.item,
                itemSel: this.state.itemSel,
                uom: this.state.uom,
                uomSel: this.state.uomSel,
                discreteWeightUOM: this.state.g,
                discreteWeightUOMSel: this.state.gSel,
                lots: this.state.lots,
                lines: [{
                    amount: this.state.amount,
                    bin: this.state.bin,
                    binSel: this.state.binSel,
                    totalWeight: this.state.totalWeight,
                    containerType: this.state.containerType,
                    containerTypeSel: this.state.containerTypeSel,
                    customLot: this.state.customLot,
                    totalUOM: this.state.g,
                    totalUOMSel: this.state.gSel,
                    lot: this.state.lot,
                    lotSel: this.state.lotSel,
                    container: this.state.container,
                    containerSel: this.state.containerSel,
                    newLot: this.state.newLot
                }]
            });
        }
        let quantity = this.state.quantity;
        if(outputLines.length > 0) {
            let total = 0;
            outputLines.forEach(output => {
                if(output.item === this.state.item) {
                    output.lines.forEach(row => {
                        total += Number(row.amount);
                    })
                }
            })
            quantity = total;
        }

        this.setState({
            outputLines: outputLines,
            item: '',
            itemSel: null,
            amount: 0,
            uom: '',
            uomSel: null,
            bin: '',
            binSel: null,
            quantity: quantity,
            discreteWeight: '',
            discreteWeightUOM: '',
            discreteWeightUOMSel: null,
            totalWeight: '',
            totalWeightUOM: '',
            totalWeightUOMSel: null,
            containerType: '',
            containerTypeSel: null,
            customLot: '',
            lot: '',
            lotSel: null,
            container: '',
            containerSel: null,
            lots: [],
            containers: [],
            newLot: false
        });
    };

    changeOutputItem = async (e, newValue) => {
        if(newValue != null){
            let itemLots = this.state.itemLots;
            let lots = [];
            if(itemLots.length > 0){
                let itemRow = itemLots.find(x => x.item === newValue.value);
                if(itemRow != null){
                    lots = itemRow.values;
                }
            }
            let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
            this.setState({
                item: newValue.value,
                itemSel: newValue,
                uom: newValue.uom,
                uomSel: uomSel,
                lots: lots,
                containers: [],
                newLot: newValue.customNumber === true  && lots.length === 0 ? true : false
            });
        } else {
            this.setState({
                item: '',
                itemSel: null,
                amount: '',
                uomSel: null,
                uom: '',
                discreteWeight: '',
                discreteWeightUOM: '',
                discreteWeightUOMSel: null,
                totalWeight: '',
                totalWeightUOM: '',
                totalWeightUOMSel: null,
                lots: [],
                containers: [],
                lot: '',
                lotSel: null,
                container: '',
                containerSel: null
            });
        }
    };

    deleteOutput = (index) => (e) => {
        let outputLines = this.state.outputLines;
        outputLines.splice(index, 1);
        let quantity = this.state.quantity;
        if(outputLines.length > 0) {
            let total = 0
            outputLines.forEach(output => {
                if(output.item === this.state.outputItem){
                    for(let line of output.lines){
                        total += Number(line.amount);
                    }
                }
            })
            quantity = total;
        }
        this.setState({outputLines: outputLines, quantity: quantity});
    };

    changeSubLineNumber = (name, i, j) => (e) => {
        let contents = this.state.inputLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        if(isNaN(value))
            return;
        row[name] = value;
        line = this.calculateLineTotals(line);
        this.setState({inputLines: contents});
    };

    changeSubLineValue = (name, i, j) => (e) => {
        let contents = this.state.inputLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        row[name] = value;
        this.setState({inputLines: contents});
    };

    changeSubLineAuto = (name, data, i, j) => async (e, newValue) => {
        let contents = this.state.inputLines;
        let line = contents[i];
        let row = line.lines[j];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : '';
        let hasCount = false;
        if(data === 'bin' && newValue != null){
            let binConts = await axios.post('/api/itemcontainers', {items: [line.item], bin: newValue.value});
            line.containers = binConts.data;
        }
        this.setState({inputLines: contents, inputHasCount: hasCount});
    };

    changeOutLineNumber = (name, i, j) => (e) => {
        let contents = this.state.outputLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        if(isNaN(value))
            return;
        row[name] = value;
        line = this.calculateLineTotals(line);
        this.setState({outputLines: contents});
    };

    changeOutLineValue = (name, i, j) => (e) => {
        let contents = this.state.outputLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        row[name] = value;
        this.setState({outputLines: contents});
    };

    changeOutLineBool = (name, i, j) => (e) => {
        let outputs = this.state.outputLines;
        let output = outputs[i];
        let row = output.lines[j]
        row[name] = !row[name];
        if(name === 'newLot' && row[name] === true){
            row.lot = '';
            row.lotSel = null;
            row.container = '';
            row.containerSel = null;
        }
        this.setState({outputLines: outputs});
    };

    changeOutLineAuto = (name, data, i, j) => (e, newValue) => {
        let contents = this.state.outputLines;
        let line = contents[i];
        let row = line.lines[j];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : '';
        if(data === 'lot' && newValue != null){
            let itemLots = this.state.itemLots;
            let itemRow = itemLots.find(x => x.item === line.item);
            if(itemRow != null){
                let lotRow = itemRow.lots.find(x => x.lot === newValue.value);
                if(lotRow != null){
                    row.containers = lotRow.containers;
                }
            }
        } else if (data === 'lot' && newValue == null){
            row.containers = [];
            row.containerSel = null;
            row.container = '';
        }
        this.setState({outputLines: contents});
    };

    deleteOutputRow = (i, j) => (e) => {
        let contentLines = this.state.outputLines;
        if(i > -1 && i < contentLines.length){
            let line = contentLines[i];
            if(j > -1 && j < line.lines.length){
                line.lines.splice(j, 1);
                line = this.calculateLineTotals(line);
                this.setState({outputLines: contentLines});
            }
        }
    };

    calculateLineTotals(line){
        let amount = 0.0;
        let count = 0;
        for(let row of line.lines){
            if(row.amount !== '')
                amount += parseFloat(row.amount);
            count += row.count != null && row.count !== '' ? parseInt(row.count) : 0;
        }
        line.amount = amount;
        line.count = count !== 0 ? count : null;
        return line;
    }

    changeEquipmentSel = (index) => (e, newValue) => {
        let equipments = this.state.equipments;
        let equipment = equipments[index];
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            })
        }
        equipment.equipment = ids;
        equipment.equipmentsSel = newValue;
        this.setState({equipments: equipments});
    }

    togglePrintViewer = () => {
        this.setState({printViewer: !this.state.printViewer});
    }

    deleteSigCallback = async (type) => {
        try{
            let result = await axios.post('/api/poactivitysigdelete', {type: type, id: this.state._id});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    render(){
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const hasDevice = this.props.devices.hasRegisteredDevices;
        const preInventoryLabel = this.props.auth.user.preInventoryLabel;
        const micro = this.props.auth.user.micro;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        labelData={this.state.outputLabels}
                        labelAmount='multiple'
                        printViewClick={this.togglePrintViewer}
                        printViewTitle={t('activityRecord')}
                        enablePrintView={this.state.status === this.props.auth.constants.statuses.signed}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.poActivity}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>                
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TabPanel value={this.state.tabKey} index={0}>
                            <Grid container spacing={3}>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                {this.state.equipment != null && this.state.equipment !== ''&&
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('equipment')}</FormLabel>
                                            <Link to={'/equipment/' + this.state.equipment._id}>
                                                {this.state.equipment.identityNo + '-' + this.state.equipment.name}
                                            </Link>
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.workCenter != null && this.state.workCenter !== '' &&
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('workCenter')}</FormLabel>
                                            <Link to={'/workcenter/' + this.state.workCenter._id}>
                                                {this.state.workCenter.number + '-' + this.state.workCenter.name}
                                            </Link>
                                        </FormControl>
                                    </Grid>
                                }
                                {!this.state.signed && 
                                    <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            disabled={!fieldAccess}
                                            value={this.state.roomSel}
                                            options={this.state.rooms}
                                            onChange={this.changeAuto('roomSel', 'room')}
                                            error={errors[t('room')] != null ? true : false}
                                            helperText={errors[t('room')]}
                                            label={t('room')}
                                        />
                                    </Grid>
                                }
                                {this.state.signed && this.state.room != null && this.state.room !== '' &&
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('room')}</FormLabel>
                                            {this.state.roomSel != null ? this.state.roomSel.label : ''}
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.productionOrder != null &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('productionOrder')}</FormLabel>
                                            <Link to={'/eproductionorder/' + this.state.productionOrder._id}>{this.state.productionOrder.number}</Link>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <DateTimeSelect
                                            onChange={this.dateChange('startTime')}
                                            value={this.state.startTime}
                                            helperText={errors[t('startTime')]}
                                            error={errors[t('startTime')] != null ? true : false}
                                            label={t('start')}
                                            disabled={!fieldAccess}
                                            required
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('start')}</FormLabel>
                                            <DateDisplay value={this.state.startTime} format={this.props.auth.user.dateFormat + " h:mm a"}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <DateTimeSelect
                                            onChange={this.dateChange('completeTime')}
                                            value={this.state.completeTime}
                                            helperText={errors[t('completeTime')]}
                                            error={errors[t('completeTime')] != null ? true : false}
                                            label={t('completed')}
                                            disabled={!fieldAccess}
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('completed')}</FormLabel>
                                            {this.state.completeTime != null && this.state.completeTime !== '' &&
                                                <DateDisplay value={this.state.completeTime} format={this.props.auth.user.dateFormat + " h:mm a"}/>
                                            }
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField type="number" value={this.state.effort} onChange={this.changeNumber} name="effort"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('effortM')} required
                                        error={errors[t('effort')] != null ? true : false} helperText={errors[t('effort')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('effortM')}</FormLabel>
                                            {this.state.effort}
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            
                            {this.state.hasForm === true &&
                                <Aux>
                                    <br/>
                                    {errors[t('form')] != null && errors[t('form')] !== '' &&
                                        <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                    }
                                    <Form renderer={renderer}
                                        defaultFields={this.state.form}
                                        onChange={this.formValueChanged}
                                        disabled={!fieldAccess}/>
                                </Aux>
                            }
                            {(this.state.status === this.props.auth.constants.statuses.complete || 
                                    this.state.status === this.props.auth.constants.statuses.reviewed ||
                                    this.state.status === this.props.auth.constants.statuses.signed) && !micro &&
                                    <Aux>
                                        { (this.state.labourCost != null && this.state.labourCost !== '' && this.state.labourCost !== 0) &&
                                            <Aux>
                                                <br/>
                                                <SectionTitle title={t('costs')}/>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={4} sm={2}>
                                                        <FormControl>
                                                            <FormLabel>{t('labour')}</FormLabel>
                                                            <NumericFormat displayType={'text'} value={this.state.labourCost} thousandSeparator={true} 
                                                            decimalScale={2} prefix="$"/>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <br/>
                                            </Aux>
                                        }
                                    </Aux>
                                }
                            <Grid container spacing={3}>
                                {this.state.sopList != null && this.state.sopList.length > 0 &&
                                    <Grid item sm={11}>
                                        <AutoCompleteField
                                            multiple={true} disabled={!fieldAccess}
                                            value={this.state.sopsSel}
                                            options={this.state.sopList}
                                            onChange={this.changeSop}
                                            error={errors[t('sopIds')] != null ? true : false}
                                            helperText={errors[t('sopIds')]}
                                            label={t('workRecs')}
                                        />
                                    </Grid>
                                }                                
                                <Grid item sm={11}>
                                    <AutoCompleteField
                                        multiple={true}
                                        value={this.state.usersSel}
                                        options={fieldAccess && !internal ? this.state.activeUsers : this.state.userOptions}
                                        onChange={this.changeMulti('usersSel', 'users')}
                                        error={errors[t('users')] != null ? true : false}
                                        helperText={errors[t('users')]}
                                        label={t('users')}
                                        disabled={!fieldAccess}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    {fieldAccess &&
                                        <TextField value={this.state.notes} onChange={this.changeValue} name="notes"
                                        multiline={true} maxRows="20"
                                        size="medium" fullWidth={true} label={t('notes')}
                                        error={errors[t('notes')] != null ? true : false} helperText={errors[t('notes')]}/>
                                    }
                                    {!fieldAccess && this.state.notes != null && this.state.notes !== '' &&
                                        <FormControl>
                                            <FormLabel>{t('notes')}</FormLabel>
                                            {this.state.notes}
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            {fieldAccess && !micro &&
                                <Aux>
                                    <br/>
                                    <Grid container spacing={3}>
                                        <Grid item sm={10}>
                                            <Button variant="outlined" color="primary" size="sm" onClick={this.addDeviation} sx={{ mx: 0.5 }}>{t('addDeviation')}</Button>
                                            <Button variant="outlined" color="primary" size="sm" onClick={this.addNcr} sx={{ mx: 0.5 }}>{t('addNcr')}</Button>
                                        </Grid>
                                    </Grid>
                                </Aux>
                            }
                            {this.state.equipments != null && this.state.equipments.length > 0 &&
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('equipment')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={2}>{t('machineType')}</Grid>
                                        <Grid item sm={10}>{t('equipment')}</Grid>
                                    </Grid>
                                    {this.state.equipments.map((row, i) =>
                                        <Aux key={row._id}>
                                            {row.machineType != null && row.machineType !== '' &&
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4} sm={2}>
                                                        <FormControl>
                                                            <FormLabel></FormLabel>
                                                            {row.machineTypeSel != null ? row.machineTypeSel.label : ''}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item sm={10}>
                                                        {fieldAccess &&
                                                            <AutoCompleteField
                                                                multiple={true}
                                                                value={row.equipmentsSel}
                                                                options={row.equipments}
                                                                onChange={this.changeEquipmentSel(i)}
                                                                error={errors[`${t('equipments')} (${t('line')} ${i})`] != null ? true : false}
                                                                helperText={errors[`${t('equipments')} (${t('line')} ${i})`]}
                                                                disabled={!fieldAccess}
                                                                required
                                                            />
                                                        }
                                                        {!fieldAccess &&
                                                            <FormControl data-cy={"equipments" + i}>
                                                                <FormLabel></FormLabel>
                                                                <List style={{ display: 'flex', flexDirection: 'row', padding: 2 }}>
                                                                    {row.equipmentsSel.map((equip, j) =>
                                                                        <ListItem disableGutters={true} disablePadding={true} key={'baitemtch' + j}><Link to={'/equipment/' + equip.value}>{equip.label}</Link>&nbsp;&nbsp;</ListItem>
                                                                    )}
                                                                </List>
                                                            </FormControl>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            }
                                            {(row.machineType == null || row.machineType === '') && row.equipmentsSel != null && row.equipmentsSel.length > 0 &&
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4} sm={2}></Grid>
                                                    <Grid item sm={10}>
                                                        <FormControl data-cy={"equipments" + i}>
                                                            <FormLabel></FormLabel>
                                                            <List style={{ display: 'flex', flexDirection: 'row', padding: 2 }}>
                                                                {row.equipmentsSel.map((equip, j) =>
                                                                    <ListItem disableGutters={true} disablePadding={true} key={'baitemtch' + j}><Link to={'/equipment/' + equip.value}>{equip.label}</Link>&nbsp;&nbsp;</ListItem>
                                                                )}
                                                            </List>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            }
                                            <br/>
                                        </Aux>
                                    )}
                                </Aux>
                            }
                            {this.state.deviations != null && this.state.deviations.length > 0 && !micro &&
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('deviations')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={2}>{t('number')}</Grid>
                                        <Grid item xs={6} sm={3}>{t('title')}</Grid>
                                        <Grid item xs={4} sm={2}>{t('date')}</Grid>
                                    </Grid>
                                    {this.state.deviations.map((row, i) =>
                                        <Grid container spacing={3} key={row._id}>
                                            <Grid item xs={4} sm={2}>
                                                <Link to={'/deviation/' + row._id}>{row.number}</Link>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>{row.title}</Grid>
                                            <Grid item xs={4} sm={2}><DateDisplay value={row.occurrenceDate} format={this.props.auth.user.dateFormat}/></Grid>
                                        </Grid>
                                    )}
                                    <br/>
                                </Aux>
                            }
                            {this.state.ncrs != null && this.state.ncrs.length > 0 && !micro &&
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('ncrs')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={2}>{t('number')}</Grid>
                                        <Grid item xs={6} sm={3}>{t('title')}</Grid>
                                        <Grid item xs={4} sm={2}>{t('date')}</Grid>
                                    </Grid>
                                    {this.state.ncrs.map((row, i) =>
                                        <Grid container spacing={3} key={row._id}>
                                            <Grid item xs={4} sm={2}>
                                                <Link to={'/deviation/' + row._id}>{row.number}</Link>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>{row.title}</Grid>
                                            <Grid item xs={4} sm={2}><DateDisplay value={row.occurrenceDate} format={this.props.auth.user.dateFormat}/></Grid>
                                        </Grid>
                                    )}
                                    <br/>
                                </Aux>
                            }
                            <br/>
                            {(fieldAccess || (this.state.outputLines != null && this.state.outputLines.length > 0)) &&
                                <Aux>
                                    <SectionTitle title={t('outputs')}/>
                                    {this.state.outputLines != null && this.state.outputLines.length > 0 && this.state.outputLines.map((row, i) =>
                                        <Box borderColor="primary.main" {...defaultProps} key={'outputLine' + i} component={'div'}>
                                            {row.lines.length > 1 &&
                                                <Aux>
                                                    <Grid container spacing={3}>
                                                        <Grid item sx={{width: 350}}>
                                                            <FormControl>
                                                                <FormLabel>{t('item')}</FormLabel>
                                                                <Link to={'/item/' + row.item}>{row.itemSel != null ? row.itemSel.label : ''}</Link>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sx={{width: 150}}>
                                                            <FormControl>
                                                                <FormLabel>{t('quantity')}</FormLabel>
                                                                 <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                    value={row.amount != null && row.amount !== '' ? row.amount : 0}
                                                                    suffix={row.uomSel != null ? row.uomSel.label : ''}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        {row.count != null && row.count !== 0 &&
                                                            <Grid item sx={{width: 100}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('count')}</FormLabel>
                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                        value={row.count != null && row.count !== '' ? row.count : 0}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {row.totalWeight != null && row.totalWeight !== 0 && row.totalWeight !== '' &&
                                                            <Grid item sx={{width: 150}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('totalWeight')}</FormLabel>
                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                        value={row.totalWeight != null && row.totalWeight !== '' ? row.totalWeight : 0}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {fieldAccess &&
                                                            <Grid item style={{width: 50}}>
                                                                <IconButton aria-label="delete line" onClick={this.deleteOutput(i)}>
                                                                    <RemoveCircleTwoToneIcon  />
                                                                </IconButton>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                    {row.lines != null && row.lines.length > 0 && row.outputLength < 1199 &&
                                                        <Grid container spacing={3} key={'ohead' + i}>
                                                            <Grid item sx={{width: 110}}>
                                                                <FormLabel>{t('quantity') + '(' + row.uomSel.label + ')'}</FormLabel>
                                                            </Grid>
                                                            {(row.itemSel.hasCount === true || row.count != null) &&
                                                                <Grid item sx={{width: 100}}>
                                                                    <FormLabel>{t('count')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {fieldAccess && row.itemSel != null && row.itemSel.lotTracked &&
                                                                <Grid item sx={{width: 60}}>
                                                                    <FormLabel>{t('newLot')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {row.itemSel.lotTracked && 
                                                                <Grid item sx={{width: 180, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                    <FormLabel>{t('lot')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {row.itemSel.lotTracked &&
                                                                <Aux>
                                                                    <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}>
                                                                        <FormLabel>{t('container')}</FormLabel>
                                                                    </Grid>
                                                                </Aux>
                                                            }
                                                            {row.itemSel.discreteWeight === true &&
                                                                <Grid item sx={{width: 110}}>
                                                                    <FormLabel>{t('totalWeightG')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                                <Grid item sx={{width: 200}}>
                                                                    <FormLabel>{t('bin')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {row.itemSel.lotTracked &&
                                                                <Grid item sx={{width: 180}}>
                                                                    <FormLabel>{t('containerType')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {fieldAccess &&
                                                                <Grid item sx={{width: 50}}>
                                                                    <IconButton aria-label="delete line" onClick={() => this.addOutputLine(i)}>
                                                                        <AddBoxTwoToneIcon  />
                                                                    </IconButton>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    }
                                                    {row.lines != null && row.lines.length > 0 && row.lines.map((line, k) =>
                                                        <Aux key={'ol' + i + 'a' + k}>
                                                            {!fieldAccess && row.outputLength < 1199 &&
                                                                <Grid container spacing={3} sx={{pb: 2}}>
                                                                    <Grid item sx={{width: 110, textAlign: 'center'}} >
                                                                        <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                            value={line.amount != null && line.amount !== '' ? line.amount : 0}
                                                                        />
                                                                    </Grid>
                                                                    {(row.itemSel.hasCount === true || row.count != null) &&
                                                                        <Grid item sx={{width: 100, textAlign: 'center'}}>
                                                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                                value={line.count != null && line.count !== '' ? line.count : 0}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked && !fieldAccess && line.lotSel != null &&
                                                                        <Aux>
                                                                            <Grid item sx={{width: 180, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                                {line.lotSel != null &&
                                                                                    <Link to={'/lot/' + line.lotSel.value}>{line.lotSel.label}</Link>
                                                                                }
                                                                            </Grid>
                                                                            <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}>
                                                                                {line.containerSel != null &&
                                                                                    <Grid container>
                                                                                        <Grid item>
                                                                                            {hasDevice &&
                                                                                                <LabelPrint 
                                                                                                    containerData={this.state.outputLabels != null && this.state.outputLabels.length > 0 ? 
                                                                                                                this.state.outputLabels.find(x => x.id === line.containerSel.value) : null}
                                                                                                    printAmount='single'
                                                                                                />
                                                                                            }
                                                                                        </Grid>
                                                                                        <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}>
                                                                                            {line.containerSel != null &&
                                                                                                <Link to={'/container/' + line.containerSel.value}>{line.containerSel.label}</Link>
                                                                                            }
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>
                                                                        </Aux>
                                                                    }
                                                                    {row.itemSel.lotTracked && line.lotSel == null &&
                                                                        <Aux>
                                                                            <Grid item sx={{width: 180, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                                {line.customLot != null &&
                                                                                    line.customLot
                                                                                }
                                                                            </Grid>
                                                                            <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}></Grid>
                                                                        </Aux>
                                                                    }
                                                                    {row.itemSel.discreteWeight &&
                                                                        <Grid item sx={{width: 110}}>
                                                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                                value={line.totalWeight != null && line.totalWeight !== '' ? line.totalWeight : 0}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                                        <Grid item sx={{width: 200}}>
                                                                            {line.binSel != null &&
                                                                                <Link to={'/bin/' + line.binSel.value}>{line.binSel.label}</Link>
                                                                            }
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked &&
                                                                        <Grid item sx={{width: 180}}>
                                                                            {line.containerTypeSel != null ? line.containerTypeSel.label : ''}
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            }
                                                            {!fieldAccess && row.outputLength >= 1199 &&
                                                                <Box borderColor="primary.main" {...defaultProps}  key={'outputLine' + i + 'o' + k} component={'div'}>
                                                                    <Grid container spacing={3} sx={{pb: 8}}>
                                                                        <Grid item sx={{width: 110}} align="center">
                                                                            <FormControl>
                                                                                <FormLabel>{t('quantity')}</FormLabel>
                                                                                <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                                value={line.amount != null && line.amount !== '' ? line.amount : 0}
                                                                            />
                                                                            </FormControl>
                                                                        </Grid>
                                                                        {(row.itemSel.hasCount === true || row.count != null) &&
                                                                            <Grid item sx={{width: 100}} align="center">
                                                                                <FormControl>
                                                                                    <FormLabel>{t('count')}</FormLabel>
                                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                                        value={line.count != null && line.count !== '' ? line.count : 0}
                                                                                    />
                                                                                </FormControl>
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.lotTracked && !fieldAccess &&
                                                                            <Aux>
                                                                                <Grid item sx={{width: 140, display: { xs: 'none', md: 'inline-grid'}}}>
                                                                                    <FormControl>
                                                                                        <FormLabel>{t('lot')}</FormLabel>
                                                                                        {line.lotSel != null &&
                                                                                            <Link to={'/lot/' + line.lotSel.value}>{line.lotSel.label}</Link>
                                                                                        }
                                                                                    </FormControl>
                                                                                </Grid>
                                                                                <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}>
                                                                                    <FormControl>
                                                                                        <FormLabel sx={{ ml: 2}}>{t('container')}</FormLabel>
                                                                                        <Grid container>
                                                                                            <Grid item>
                                                                                            {hasDevice &&
                                                                                                <LabelPrint 
                                                                                                    containerData={this.state.outputLabels != null && this.state.outputLabels.length > 0 ?
                                                                                                                   this.state.outputLabels.find(x => x.id === line.container) : null}
                                                                                                    printAmount='single'
                                                                                                />
                                                                                            }
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                {line.containerSel != null &&
                                                                                                    <Link to={'/container/' + line.containerSel.value}>{line.containerSel.label}</Link>
                                                                                                }
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </FormControl>                                                                                    
                                                                                </Grid>
                                                                            </Aux>
                                                                        }
                                                                        {row.itemSel.discreteWeight &&
                                                                            <Grid item sx={{width: 110}}>
                                                                                <FormControl>
                                                                                    <FormLabel>{t('totalWeightG')}</FormLabel>
                                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                                        value={line.totalWeight != null && line.totalWeight !== '' ? line.totalWeight : 0}
                                                                                    />
                                                                                </FormControl>
                                                                            </Grid>
                                                                        }
                                                                        {line.discreteWeight != null && line.discreteWeight !== '' &&
                                                                            <Grid item sx={{width: 150}}>
                                                                                <FormControl>
                                                                                    <FormLabel>{t('discreteG')}</FormLabel>
                                                                                        <NumericFormat displayType={'text'} value={line.discreteWeight} thousandSeparator={true} decimalScale={3} />
                                                                                </FormControl>
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                                            <Grid item sx={{width: 200}}>
                                                                                <FormControl>
                                                                                    <FormLabel>{t('bin')}</FormLabel>
                                                                                    {line.binSel != null &&
                                                                                        <Link to={'/bin/' + line.binSel.value}>{line.binSel.label}</Link>
                                                                                    }
                                                                                </FormControl>
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.lotTracked &&
                                                                            <Grid item sx={{width: 180}}>
                                                                                <FormControl>
                                                                                    <FormLabel>{t('containerType')}</FormLabel>
                                                                                    {line.containerTypeSel != null ? line.containerTypeSel.label : ''}
                                                                                </FormControl>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Box>
                                                            }
                                                            {fieldAccess && row.outputLength < 1199 &&
                                                                <Grid container spacing={3}>
                                                                    <Grid item sx={{width: 110}}>
                                                                        <TextField type="number" value={line.amount} onChange={this.changeOutLineNumber('amount', i, k)} name={"amount" + i + 'a' + k}
                                                                        size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"amount" + i + 'a' + k}
                                                                        error={errors[`${t('amount')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('amount')} (${t('line')} ${i}, ${k})`]}/>
                                                                    </Grid>
                                                                    {(row.itemSel.hasCount || ((row.count != null && row.count !== '' && row.count !== 0) || (line.count != null && line.count !== '' && line.count !== 0))) &&
                                                                        <Grid item sx={{width: 100}}>
                                                                            {line.count != null &&
                                                                                <NumericFormat displayType={'text'} value={line.count} thousandSeparator={true} decimalScale={0}/>
                                                                            }
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked &&
                                                                        <Grid item sx={{width: 60}}>
                                                                            <CheckboxField
                                                                            checked={line.newLot} onChange={this.changeOutLineBool('newLot', i, k)}
                                                                            name="newLot" disabled={!fieldAccess}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {line.newLot === true &&
                                                                        <Grid item sx={{width: 180}}>
                                                                            {row.itemSel != null && row.itemSel.customNumber === true &&
                                                                                <TextField type="text" value={line.customLot} onChange={this.changeOutLineValue('customLot', i, k)} name={"customLot" + i + 'a' + k}
                                                                                size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"outputCustomLot" + i + 'a' + k} 
                                                                                error={errors[`${t('customLot')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('customLot')} (${t('line')} ${i}, ${k})`]}/>
                                                                            }
                                                                        </Grid>
                                                                    }
                                                                    {line.newLot === true &&
                                                                        <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}>
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked && line.newLot !== true &&
                                                                        <Aux>
                                                                            <Grid item sx={{width: 180, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                                <AutoCompleteField
                                                                                    value={line.lotSel}
                                                                                    options={row.lots}
                                                                                    onChange={this.changeOutLineAuto('lotSel', 'lot', i, k)}
                                                                                    error={errors[`${t('lot')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                    helperText={errors[`${t('lot')} (${t('line')} ${i}, ${k})`]}
                                                                                    disabled={!fieldAccess }
                                                                                    data-cy={"lot" + i + 'a' + k}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}>
                                                                                {line.containers != null && line.containers.length > 0 &&
                                                                                    <AutoCompleteField
                                                                                        value={line.containerSel}
                                                                                        options={line.containers}
                                                                                        onChange={this.changeOutLineAuto('containerSel', 'container', i, k)}
                                                                                        error={errors[`${t('container')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                        helperText={errors[`${t('container')} (${t('line')} ${i}, ${k})`]}
                                                                                        disabled={!fieldAccess }
                                                                                        data-cy={"container" + i + 'a' + k}
                                                                                    />
                                                                                }
                                                                            </Grid>
                                                                        </Aux>
                                                                    }
                                                                    {row.itemSel.discreteWeight === true &&
                                                                        <Aux>
                                                                            <Grid item sx={{width: 100}}>
                                                                                <TextField type="number" value={line.totalWeight} onChange={this.changeOutLineNumber('totalWeight', i, k)} name={"totalWeight" + i + 'a' + k}
                                                                                size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"outputTotalWeight" + i + 'a' + k}
                                                                                error={errors[`${t('totalWeight')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('totalWeight')} (${t('line')} ${i}, ${k})`]}/>
                                                                            </Grid>
                                                                        </Aux>

                                                                    }
                                                                    {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                                        <Grid item sx={{width: 200}}>
                                                                            <AutoCompleteField
                                                                                value={line.binSel}
                                                                                options={this.state.bins}
                                                                                onChange={this.changeOutLineAuto('binSel', 'bin', i, k)}
                                                                                error={errors[`${t('bin')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                helperText={errors[`${t('bin')} (${t('line')} ${i}, ${k})`]}
                                                                                disabled={!fieldAccess }
                                                                                required
                                                                                data-cy={"outputBin" + i + 'a' + k}
                                                                                groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                                                noneMessage={t('noRoomSelected')}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked &&
                                                                        <Grid item sx={{width: 180}}>
                                                                            <AutoCompleteField
                                                                            value={line.containerTypeSel}
                                                                            options={this.state.containerTypes}
                                                                            onChange={this.changeOutLineAuto('containerTypeSel', 'containerType', i, k)}
                                                                            error={errors[`${t('containerType')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                            helperText={errors[`${t('containerType')} (${t('line')} ${i}, ${k})`]}
                                                                            disabled={!fieldAccess }
                                                                            required
                                                                            data-cy={"outputContainerType" + i + 'a' + k}
                                                                        />
                                                                        </Grid>
                                                                    }
                                                                    {fieldAccess &&
                                                                        <Grid item container sx={{width: 100}}>
                                                                            <Grid item>
                                                                                <IconButton aria-label="delete line" onClick={this.deleteOutputRow(i, k)} data-cy={"deleteOutput" + i + 'a' + k}>
                                                                                    <RemoveCircleTwoToneIcon  />
                                                                                </IconButton>
                                                                            </Grid>
                                                                            <Grid item sx={{ mt: 1 }}>
                                                                                {hasDevice && preInventoryLabel && line.amount != null && line.amount > 0 &&
                                                                                    <LabelPrint 
                                                                                        containerData={{
                                                                                            container: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                                        row.itemSel.customNumber && line.customLot != null && line.customLot !== '' ? line.customLot + '-' + (k + 1) : 
                                                                                                        line.previousLot != null && line.previousLot !== '' ? line.previousLot + '-' + (k + 1) : '',
                                                                                            lot: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                                    row.itemSel.customNumber && line.customLot != null && line.customLot !== '' ? line.customLot : 
                                                                                                    line.previousLot != null && line.previousLot !== '' ? line.previousLot : '',
                                                                                            item: row.itemSel != null && row.itemSel.label != null ? row.itemSel.label : '',
                                                                                            strain: row.itemSel != null && row.itemSel.strain != null ? row.itemSel.strain : '',
                                                                                            quantity: ConversionHelper.round3(line.amount),
                                                                                            uom: row.uomSel.label,
                                                                                            category: line.containerTypeSel != null && line.containerTypeSel !== '' ? line.containerTypeSel.label : t('regular'),
                                                                                            itemNumber: row.itemSel != null && row.itemSel.number != null ? row.itemSel.number : '',
                                                                                            date: this.state.startTime != null && this.state.startTime !== '' ? this.state.startTime : '',
                                                                                            preInventory: true
                                                                                        }}
                                                                                        printAmount='single'
                                                                                    />
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            }
                                                            {fieldAccess && row.outputLength >= 1199 &&
                                                                <Grid container spacing={3} >
                                                                    <Grid item sx={{width: 110}}>
                                                                        <TextField type="number" value={line.amount} onChange={this.changeOutLineNumber('amount', i, k)} name={"outputAmount" + i + 'a' + k}
                                                                        size="medium" fullWidth={true} disabled={!fieldAccess} required label={t('quantity')} data-cy={"outputAmount" + i + 'a' + k}
                                                                        error={errors[`${t('outputAmount')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('outputAmount')} (${t('line')} ${i}, ${k})`]}/>
                                                                    </Grid>
                                                                    {row.itemSel.hasCount &&
                                                                        <Grid item sx={{width: 100}}>
                                                                            {line.count != null &&
                                                                                <FormControl>
                                                                                    <FormLabel>{t('count')}</FormLabel>
                                                                                    <NumericFormat displayType={'text'} value={line.count} thousandSeparator={true} decimalScale={0}/>
                                                                                </FormControl>
                                                                            }
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked &&
                                                                        <Grid item sx={{width: 60}}>
                                                                            <CheckboxField
                                                                            checked={line.newLot} onChange={this.changeOutLineBool('newLot', i, k)}
                                                                            name="newLot" disabled={!fieldAccess} label={t('newLot')}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.customNumber === true && line.newLot === true &&
                                                                        <Grid item sx={{width: 10}}>
                                                                            <TextField type="text" value={line.customLot} onChange={this.changeOutLineValue('customLot', i, k)} name={"customLot" + i + 'a' + k}
                                                                            size="medium" fullWidth={true} disabled={!fieldAccess} required label={t('lot')} data-cy={"outputCustomLot" + i + 'a' + k}
                                                                            error={errors[`${t('customLot')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('customLot')} (${t('line')} ${i}, ${k})`]}/>
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked && line.newLot !== true && row.lots != null && row.lots.length > 0 &&
                                                                        <Grid item sx={{width: 200}}>
                                                                            <AutoCompleteField
                                                                                value={line.lotSel}
                                                                                options={row.lots}
                                                                                onChange={this.changeOutLineAuto('lotSel', 'lot', i, k)}
                                                                                error={errors[`${t('lot')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                helperText={errors[`${t('lot')} (${t('line')} ${i}, ${k})`]}
                                                                                disabled={!fieldAccess }
                                                                                label={t('lot')}
                                                                                data-cy={"lot" + i + 'a' + k}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked && line.newLot !== true && line.containers != null && line.containers.length > 0 &&
                                                                        <Grid item sx={{width: 210}}>
                                                                            <AutoCompleteField
                                                                                value={line.containerSel}
                                                                                options={line.containers}
                                                                                onChange={this.changeOutLineAuto('containerSel', 'container', i, k)}
                                                                                error={errors[`${t('container')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                helperText={errors[`${t('container')} (${t('line')} ${i}, ${k})`]}
                                                                                disabled={!fieldAccess }
                                                                                label={t('container')}
                                                                                data-cy={"container" + i + 'a' + k}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.discreteWeight === true &&
                                                                        <Aux>
                                                                            <Grid item sx={{width: 100}}>
                                                                                <TextField type="number" value={line.totalWeight} onChange={this.changeOutLineNumber('totalWeight', i, k)} name={"totalWeight" + i + 'a' + k}
                                                                                size="medium" fullWidth={true} disabled={!fieldAccess} required label={t('totalWeightG')} data-cy={"outputTotalWeight" + i + 'a' + k}
                                                                                error={errors[`${t('totalWeight')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('totalWeight')} (${t('line')} ${i}, ${k})`]}/>
                                                                            </Grid>
                                                                        </Aux>
                                                                    }
                                                                    {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                                        <Grid item sx={{width: 200}}>
                                                                            <AutoCompleteField
                                                                                value={line.binSel}
                                                                                options={this.state.bins}
                                                                                onChange={this.changeOutLineAuto('binSel', 'bin', i, k)}
                                                                                error={errors[`${t('bin')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                helperText={errors[`${t('bin')} (${t('line')} ${i}, ${k})`]}
                                                                                disabled={!fieldAccess }
                                                                                required
                                                                                label={t('bin')}
                                                                                data-cy={"outputBin" + i + 'a' + k}
                                                                                groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                                                noneMessage={t('noRoomSelected')}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked &&
                                                                        <Grid item sx={{width: 180}}>
                                                                            <AutoCompleteField
                                                                            value={line.containerTypeSel}
                                                                            options={this.state.containerTypes}
                                                                            onChange={this.changeOutLineAuto('containerTypeSel', 'containerType', i, k)}
                                                                            error={errors[`${t('containerType')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                            helperText={errors[`${t('containerType')} (${t('line')} ${i}, ${k})`]}
                                                                            disabled={!fieldAccess }
                                                                            required
                                                                            label={t('containerType')}
                                                                            data-cy={"outputContainerType" + i + 'a' + k}
                                                                        />
                                                                        </Grid>
                                                                    }
                                                                    {fieldAccess &&
                                                                        <Grid item container sx={{width: 100}}>
                                                                            <Grid item>
                                                                                <IconButton aria-label="delete line" onClick={this.deleteOutputRow(i, k)} data-cy={"deleteOutput" + i + 'a' + k}>
                                                                                    <RemoveCircleTwoToneIcon  />
                                                                                </IconButton>
                                                                            </Grid>
                                                                            <Grid item sx={{ mt: 1 }}>
                                                                                {hasDevice && preInventoryLabel && line.amount != null && line.amount > 0 &&
                                                                                    <LabelPrint 
                                                                                        containerData={{
                                                                                            container: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                                        row.itemSel.customNumber && line.customLot != null && line.customLot !== '' ? line.customLot + '-' + (k + 1) : 
                                                                                                        line.previousLot != null && line.previousLot !== '' ? line.previousLot + '-' + (k + 1) : '',
                                                                                            lot: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                                    row.itemSel.customNumber && line.customLot != null && line.customLot !== '' ? line.customLot : 
                                                                                                    line.previousLot != null && line.previousLot !== '' ? line.previousLot : '',
                                                                                            item: row.itemSel != null && row.itemSel.label != null ? row.itemSel.label : '',
                                                                                            strain: row.itemSel != null && row.itemSel.strain != null ? row.itemSel.strain : '',
                                                                                            quantity: ConversionHelper.round3(line.amount),
                                                                                            uom: row.uomSel.label,
                                                                                            category: line.containerTypeSel != null && line.containerTypeSel !== '' ? line.containerTypeSel.label : t('regular'),
                                                                                            itemNumber: row.itemSel != null && row.itemSel.number != null ? row.itemSel.number : '',
                                                                                            date: this.state.startTime != null && this.state.startTime !== '' ? this.state.startTime : '',
                                                                                            preInventory: true
                                                                                        }}
                                                                                        printAmount='single'
                                                                                    />
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            }
                                                        </Aux>
                                                    )}
                                                </Aux>
                                            }
                                            {row.lines.length < 2 &&
                                                <Grid container spacing={3}>
                                                    <Grid item sx={{width: 350}}>
                                                        <FormControl>
                                                            <FormLabel>{t('item')}</FormLabel>
                                                            <Link to={'/item/' + row.item}>{row.itemSel != null ? row.itemSel.label : ''}</Link>
                                                        </FormControl>
                                                    </Grid>
                                                    {row.itemSel.lotTracked &&
                                                        <Grid item sx={{width: 130}}>
                                                            <CheckboxField
                                                            checked={row.lines[0].newLot} onChange={this.changeOutLineBool('newLot', i, 0)}
                                                            name="newLot" disabled={!fieldAccess} label={t('newLot')}
                                                            />
                                                        </Grid>
                                                    }
                                                    {fieldAccess && row.itemSel != null && row.itemSel.lotTracked && row.itemSel.customNumber && row.lines != null && row.lines[0].newLot === true &&
                                                        <Grid item sx={{width: 150}}>
                                                            <TextField type="text" value={row.lines[0].customLot} onChange={this.changeOutLineValue('customLot', i, 0)} name={"customLot" + i + 'a' + 0}
                                                            size="medium" fullWidth={true} disabled={!fieldAccess}
                                                            label={t('lot')} data-cy={"outputCustomLot" + i}
                                                            error={errors[`${t('inputCount')} (${t('line')} 0)`] != null ? true : false} helperText={errors[`${t('inputCount')} (${t('line')} 0)`]}/>
                                                        </Grid>
                                                    }
                                                    {row.itemSel.lotTracked === true && row.lines[0].newLot !== true && row.lots != null && row.lots.length > 0 &&
                                                        <Grid item sx={{width: 210}}>
                                                            {fieldAccess &&
                                                                <AutoCompleteField
                                                                    value={row.lines[0].lotSel}
                                                                    onChange={this.changeOutLineAuto('lotSel', 'lot', i, 0)}
                                                                    options={row.lots}
                                                                    error={errors[`${t('lot')} (${t('line')} ${i}, 0)`] != null ? true : false}
                                                                    helperText={errors[`${t('lot')} (${t('line')} ${i}, 0)`]}
                                                                    disabled={!fieldAccess }
                                                                    label={t('lot')}
                                                                    data-cy={"outputLot" + i}
                                                                />
                                                            }
                                                            {!fieldAccess && row.lines[0].lotSel != null &&
                                                                <Aux>
                                                                    <FormControl>
                                                                        <FormLabel>{t('lot')}</FormLabel>
                                                                        <Link to={'/lot/' + row.lines[0].lotSel.value}>{row.lines[0].lotSel.label}</Link>
                                                                    </FormControl>
                                                                    {row.lines[0].label != null && row.lines[0].label !== '' &&
                                                                        <IconButton sx={{ padding: 0,
                                                                                        marginLeft: 15,
                                                                                        marginBottom: 2}}
                                                                                    aria-label="print lot label"
                                                                                    onClick={() => this.printDialog('lot', i, 0)}
                                                                                    color='primary'
                                                                                    disabled={!this.state.security.print} >
                                                                            <LabelIcon />
                                                                        </IconButton>
                                                                    }
                                                                </Aux>
                                                            }
                                                        </Grid>
                                                    }
                                                    {row.itemSel.lotTracked === true && row.lines[0].newLot !== true && row.lines[0].containers != null && row.lines[0].containers.length > 0 &&
                                                        <Grid item sx={{width: 210}}>
                                                            {fieldAccess &&
                                                                <AutoCompleteField
                                                                    value={row.lines[0].containerSel}
                                                                    options={row.lines[0].containers}
                                                                    onChange={this.changeOutLineAuto('containerSel', 'container', i, 0)}
                                                                    error={errors[`${t('container')} (${t('line')} ${i}, 0)`] != null ? true : false}
                                                                    helperText={errors[`${t('container')} (${t('line')} ${i}, 0)`]}
                                                                    disabled={!fieldAccess }
                                                                    label={t('container')}
                                                                    data-cy={"container" + i}
                                                                />
                                                            }
                                                            {!fieldAccess && row.lines[0].containerSel != null &&
                                                                <FormControl>
                                                                    <FormLabel>{t('container')}</FormLabel>
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            {hasDevice &&
                                                                                <LabelPrint
                                                                                    containerData={this.state.outputLabels != null && this.state.outputLabels.length > 0 ?
                                                                                                   this.state.outputLabels.find(x => x.id === row.lines[0].container) : null}
                                                                                    printAmount='single'
                                                                                />
                                                                            }
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Link to={'/container/' + row.lines[0].containerSel.value}>{row.lines[0].containerSel.label}</Link>
                                                                        </Grid>
                                                                    </Grid>
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                    }
                                                    <Grid item sx={{width: 125}}>
                                                        {fieldAccess &&
                                                            <TextField type="number" value={row.lines[0].amount} onChange={this.changeOutLineNumber('amount', i, 0)} name={"outputAmount" + i + 'a' + 0}
                                                            size="medium" fullWidth={true} disabled={!fieldAccess} data-cy={"outputAmount" + i}
                                                            label={t('amount')+(row.uomSel != null && row.uomSel !== '' ? ' ('+row.uomSel.label+')' : '')}
                                                            error={errors[`${t('outputAmount')} (${t('line')} 0)`] != null ? true : false} helperText={errors[`${t('outputAmount')} (${t('line')} 0)`]}/>
                                                        }
                                                        {!fieldAccess &&
                                                            <FormControl>
                                                                <FormLabel>{t('amount')+(row.uomSel != null && row.uomSel !== '' ? ' ('+row.uomSel.label+')' : '')}</FormLabel>
                                                                <NumericFormat displayType={'text'} value={row.lines[0].amount} thousandSeparator={true} decimalScale={3} />
                                                            </FormControl>
                                                        }
                                                    </Grid>
                                                    {this.state.outputCount &&
                                                        <Grid item sx={{width: 125}}>
                                                            {row.lines[0].count != null &&
                                                                <FormControl>
                                                                    <FormLabel>{t('count') + ' (ea)'}</FormLabel>
                                                                    <NumericFormat displayType={'text'} value={row.lines[0].count} thousandSeparator={true} decimalScale={0} />
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                    }
                                                    {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                        <Grid item sx={{width: 200}}>
                                                            {fieldAccess &&
                                                                <AutoCompleteField
                                                                    value={row.lines[0].binSel}
                                                                    options={this.state.bins}
                                                                    onChange={this.changeOutLineAuto('binSel', 'bin', i, 0)}
                                                                    error={errors[`${t('bin')} (${t('line')} ${i}, 0)`] != null ? true : false}
                                                                    helperText={errors[`${t('bin')} (${t('line')} ${i}, 0)`]}
                                                                    disabled={!fieldAccess }
                                                                    required
                                                                    label={t('bin')}
                                                                    data-cy={"outputBin" + i}
                                                                    groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                                    noneMessage={t('noRoomSelected')}
                                                                />
                                                            }
                                                            {!fieldAccess && row.lines[0].binSel != null &&
                                                                <FormControl>
                                                                    <FormLabel>{t('bin')}</FormLabel>
                                                                    <Link to={'/bin/' + row.lines[0].binSel.value}>{row.lines[0].binSel.label}</Link>
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                    }
                                                    {row.itemSel != null && row.itemSel.discreteWeight &&
                                                        <Aux>
                                                            <Grid item sx={{width: 140}}>
                                                                {fieldAccess &&
                                                                    <TextField type="number" value={row.lines[0].totalWeight}
                                                                    onChange={this.changeOutLineNumber('totalWeight', i, 0)}
                                                                    name={"totalWeight" + i + 'a' + 0}
                                                                    size="medium" fullWidth={true} disabled={!fieldAccess}
                                                                    label={t('totalWeightG')} required data-cy={"outputTotalWeight" + i}
                                                                    error={errors[`${t('totalWeight')} (${t('line')} 0)`] != null ? true : false} helperText={errors[`${t('totalWeight')} (${t('line')} 0)`]}/>
                                                                }
                                                                {!fieldAccess &&
                                                                    <FormControl>
                                                                        <FormLabel>{t('totalWeightG')}</FormLabel>
                                                                        <NumericFormat displayType={'text'} value={row.lines[0].totalWeight} thousandSeparator={true} decimalScale={3} />
                                                                    </FormControl>
                                                                }
                                                            </Grid>
                                                            {row.lines[0].discreteWeight != null && row.lines[0].discreteWeight !== '' &&
                                                                <Aux>
                                                                    <Grid item sx={{width: 150}}>
                                                                        <FormControl>
                                                                            <FormLabel>{t('discreteG')}</FormLabel>
                                                                                <NumericFormat displayType={'text'} value={row.lines[0].discreteWeight} thousandSeparator={true} decimalScale={3} />
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Aux>
                                                            }
                                                        </Aux>
                                                    }
                                                    {fieldAccess && row.itemSel != null && row.itemSel.lotTracked &&
                                                        <Grid item sx={{width: 150}}>
                                                            <AutoCompleteField
                                                                value={row.lines[0].containerTypeSel}
                                                                options={this.state.containerTypes}
                                                                onChange={this.changeOutLineAuto('containerTypeSel', 'containerType', i, 0)}
                                                                error={errors[`${t('containerType')} (${t('line')} ${i}, 0)`] != null ? true : false}
                                                                helperText={errors[`${t('containerType')} (${t('line')} ${i}, 0)`]}
                                                                disabled={!fieldAccess }
                                                                label={t('containerType')}
                                                                data-cy={"outputContainerType" + i}
                                                            />
                                                        </Grid>
                                                    }
                                                    {!fieldAccess && row.lines[0].containerTypeSel != null &&
                                                        <Grid item style={{width: 150}}>
                                                            <FormControl>
                                                                <FormLabel>{t('containerType')}</FormLabel>
                                                                {row.lines[0].containerTypeSel != null ? row.lines[0].containerTypeSel.label : ''}
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                    {fieldAccess &&
                                                        <Grid container item sx={{width: 100}}>
                                                            <Grid item>
                                                                <IconButton aria-label="delete output" onClick={this.deleteOutput(i)} disabled={!fieldAccess}>
                                                                    <RemoveCircleTwoToneIcon  />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item sx={{ mt: 1 }}>
                                                                {hasDevice && preInventoryLabel && row.lines[0].amount != null && row.lines[0].amount > 0 &&
                                                                    <LabelPrint 
                                                                        containerData={{
                                                                            container: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                        row.lines[0].customLot != null && row.lines[0].customLot !== '' ? row.lines[0].customLot + '- 1' : '',
                                                                            lot: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                    row.lines[0].customLot != null && row.lines[0].customLot !== '' ? row.lines[0].customLot : '',
                                                                            item: row.itemSel != null && row.itemSel.label != null ? row.itemSel.label : '',
                                                                            strain: row.itemSel != null && row.itemSel.strain != null ? row.itemSel.strain : '',
                                                                            quantity: ConversionHelper.round3(row.lines[0].amount),
                                                                            uom: row.uomSel.label,
                                                                            category: row.lines[0].containerTypeSel != null && row.lines[0].containerTypeSel !== '' ? row.lines[0].containerTypeSel.label : t('regular'),
                                                                            itemNumber: row.itemSel != null && row.itemSel.number != null ? row.itemSel.number : '',
                                                                            date: this.state.startTime != null && this.state.startTime !== '' ? this.state.startTime : '',
                                                                            preInventory: true
                                                                        }}
                                                                        printAmount='single'
                                                                    />
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            }
                                        </Box>
                                    )}
                                    {fieldAccess && this.state.status !== this.props.auth.constants.productionOrderStatuses.new &&
                                        <Box borderColor="primary.main" {...defaultProps} component={'div'} key="outNew">
                                        <Grid container spacing={3}>
                                            <Grid item sx={{width: 350}}>
                                                <AutoCompleteField
                                                    value={this.state.itemSel}
                                                    options={this.state.items}
                                                    onChange={this.changeOutputItem}
                                                    error={errors[t('item')] != null ? true : false}
                                                    helperText={errors[t('item')]}
                                                    disabled={!fieldAccess}
                                                    label={t('item')}
                                                    data-cy="outputItem"
                                                />
                                            </Grid>
                                            {this.state.itemSel != null && this.state.itemSel.lotTracked &&
                                                <Grid item sx={{width: 130}}>
                                                    <CheckboxField
                                                    checked={this.state.newLot} onChange={this.changeBool('newLot')}
                                                    name="newLot" disabled={!fieldAccess} label={t('newLot')}
                                                    />
                                                </Grid>
                                            }
                                            {this.state.itemSel != null && this.state.itemSel.lotTracked === true && this.state.itemSel.customNumber && this.state.newLot === true &&
                                                <Grid item sx={{width: 150}}>
                                                    <TextField type="text" value={this.state.customLot} onChange={this.changeValue} name={"customLot"}
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('lot')} data-cy="outputCustomLot"
                                                    error={errors[t('customLot')] != null ? true : false} helperText={errors[t('customLot')]}/>
                                                </Grid>
                                            }
                                            {this.state.itemSel != null && this.state.itemSel.lotTracked && this.state.newLot !== true && this.state.lots != null && this.state.lots.length > 0 &&
                                                <Grid item sx={{width: 210}}>
                                                    <AutoCompleteField
                                                        value={this.state.lotSel}
                                                        options={this.state.lots}
                                                        onChange={this.changeAuto('lotSel', 'lot')}
                                                        error={errors[t('lot')] != null ? true : false}
                                                        helperText={errors[t('lot')]}
                                                        disabled={!fieldAccess}
                                                        label={t('lot')}
                                                        data-cy="lot"
                                                    />
                                                </Grid>
                                            }
                                            {this.state.itemSel != null && this.state.itemSel.lotTracked && this.state.newLot !== true && this.state.containers != null && this.state.containers.length > 0 &&
                                                <Grid item sx={{width: 210}}>
                                                    <AutoCompleteField
                                                        value={this.state.containerSel}
                                                        options={this.state.containers}
                                                        onChange={this.changeAuto('containerSel', 'container')}
                                                        error={errors[t('container')] != null ? true : false}
                                                        helperText={errors[t('container')]}
                                                        disabled={!fieldAccess}
                                                        label={t('container')}
                                                        data-cy="container"
                                                    />
                                                </Grid>
                                            }
                                            <Grid item sx={{width: 125}}>
                                                <TextField type="number" value={this.state.amount} onChange={this.changeNumber} name={"amount"}
                                                size="medium" fullWidth={true} disabled={!fieldAccess} data-cy="amount"
                                                label={t('amount')+(this.state.uomSel != null && this.state.uomSel !== '' ? ' ('+this.state.uomSel.label+')' : '')}
                                                error={errors[t('amount')] != null ? true : false} helperText={errors[t('amount')]}/>
                                            </Grid>
                                            {this.state.itemSel != null && this.state.itemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                <Grid item sx={{width: 200}}>
                                                    <AutoCompleteField
                                                        value={this.state.binSel}
                                                        options={this.state.bins}
                                                        onChange={this.changeAuto('binSel', 'bin')}
                                                        error={errors[t('bin')] != null ? true : false}
                                                        helperText={errors[t('bin')]}
                                                        disabled={!fieldAccess}
                                                        required
                                                        label={t('bin')}
                                                        data-cy="bin"
                                                        groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                        noneMessage={t('noRoomSelected')}
                                                    />
                                                </Grid>
                                            }
                                            {this.state.itemSel != null && this.state.itemSel.discreteWeight &&
                                                <Aux>
                                                    <Grid item sx={{width: 125}}>
                                                        <TextField type="number" value={this.state.totalWeight} onChange={this.changeNumber} name={"totalWeight"}
                                                        size="medium" fullWidth={true} disabled={!fieldAccess}
                                                        label={t('totalWeightG')} data-cy="totalWeight"
                                                        error={errors[t('totalWeight')] != null ? true : false} helperText={errors[t('totalWeight')]}/>
                                                    </Grid>
                                                </Aux>
                                            }
                                            {this.state.itemSel != null && this.state.itemSel.lotTracked &&
                                                <Grid item sx={{width: 200}}>
                                                    <AutoCompleteField
                                                        value={this.state.containerTypeSel}
                                                        options={this.state.containerTypes}
                                                        onChange={this.changeAuto('containerTypeSel', 'containerType')}
                                                        error={errors[t('containerType')] != null ? true : false}
                                                        helperText={errors[t('containerType')]}
                                                        disabled={!fieldAccess}
                                                        label={t('containerType')}
                                                        data-cy="outputContainerType"
                                                    />
                                                </Grid>
                                            }
                                            <Grid item sx={{width: 50}}>
                                                <IconButton aria-label="Add Output" onClick={this.addOutput} disabled={!fieldAccess} data-cy="addOutput">
                                                    <AddBoxTwoToneIcon  />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        </Box>
                                    }
                                </Aux>
                            }
                            <FormButtonStack 
                                id={this.state._id}
                                objectType={this.props.auth.constants.objectIds.poActivity}
                                callback={this.loadRecord}
                                saveClick={this.save}
                                status={this.state.status}
                                permissions={this.props.permission}
                                common={this.props.auth.common}
                                constants={this.props.auth.constants}
                                saveEnabled={this.state.enableSave}
                                enableUnlockProp={this.state.enableUnlock}
                                activityDate={this.state.completeTime}
                                lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                                isSuper={this.props.auth.user.isSuper}
                            />
                            {this.state._id != null && this.state._id !== '' &&
                                <div>
                                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.poActivity}/>
                                    <ObjectLog id={this.state._id}/>
                                </div>
                            }
                        </TabPanel>
                        {this.state._id != null && this.state._id !== '' && this.state.sopsSel != null &&
                            this.state.sopsSel.length > 0 && this.state.sopsSel.map((sop, i) =>
                            <TabPanel value={this.state.tabKey} index={i + 1}>
                                <ActivityWorkRecord productionOrder={this.state.productionOrder._id} sop={sop.value} 
                                objectType={this.props.auth.constants.objectIds.poActivity} date={this.state.startTime} tab={this.state.tabKey}
                                locationId={this.state.productionOrder.location} recordId={this.state._id} id={this.state.sops[i].workRecord}
                                complete={this.loadRecord} renderNum={this.state.renderNum} users={this.state.users}/>
                            </TabPanel>
                        )}
                         {this.state.hasLedgers &&
                            <TabPanel value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                                <ItemLedgerComponent record={this.state._id} permission={this.props.permission} auth={this.props.auth} renderNum={this.state.renderNum}/>
                            </TabPanel>
                        }
                    </Grid>
                </Grid>
                {this.state.printViewer &&
                    <PrintView
                        id={this.state._id}
                        object={this.props.auth.constants.objectIds.poActivity}
                        open={this.state.printViewer}
                        onClose={this.togglePrintViewer}
                        callBack={this.loadRecord}
                        printAccess={this.state.security.print}
                    />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    devices: state.devices,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ProductionOrderActivity));
