import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import queryString from 'query-string';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import ActionBar from '../UI/Buttons/ActionBar';
import ActivityWorkRecord from '../Cultivation/ActivityWorkRecord';
import { EvoraTabs, TabCanvas, TabPanel } from '../UI/Tabs/Tabs';
import { approveSigSecurity, completeSigSecurity,
    fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ItemLedgerComponent from './ItemLedgerComponent';
import Box from '@mui/material/Box';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ConversionHelper from '../../helpers/conversionHelper';
import { NumericFormat } from 'react-number-format';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import LabelPrint from '../UI/Buttons/LabelPrint';
import ConfirmDelete from '../General/ConfirmDelete';
import Switch from '../UI/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

class Receipt extends Component {
    constructor(props){
        super(props);
        this.state = {
            number: '',
            _id: '',
            status: '',
            statusSel: {
                label: '',
                value: ''
            },
            statuses: [],
            type: '',
            typeSel: null,
            types: [],
            location: '',
            locationSel: null,
            locations: [],
            date: new Date(),
            customer: '',
            customerSel: null,
            customers: [],
            vendor: '',
            vendorSel: null,
            vendors: [],
            employees: [],
            receivedFrom: '',
            receivedFromSel: null,
            purchaseOrder: '',
            purchaseOrderSel: null,
            purchaseOrders: [],
            purchaseOrderObject: null,
            courier: '',
            shippingNumber: '',
            documents: null,
            user: null,
            reviewer: null,
            signer: null,
            sopIds: [],
            sopsSel: [],
            sopList: [],
            users: [],
            userList: [],
            usersSel: [],
            managers: [],
            uoms: [],
            items: [],
            item: '',
            itemSel: null,
            quantity: '',
            uom: '',
            uomSel: null,
            bin: '',
            binSel: null,
            bins: [],
            qas: [],
            vendorLot: '',
            lotExpiration: '',
            security: [],
            locked: false,
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            tabKey: 0,
            tabLabels: [{name: 'Receipt', disabled: false}],
            optionsMenu: [],
            flagForSignature: 0,
            hasCount: false,
            count: '',
            enableSave: true,
            enableUnlock: false,
            customLot: '',
            sigData: null,
            activeUsers: [],
            activeManagers: [],
            activeQas: [],
            newLot: false,
            form: '',
            hasForm: false,
            totalWeight: '',
            totalUOM: '',
            totalUOMSel: null,
            contentLines: [],
            containerType: '',
            containerTypeSel: null,
            containerTypes: [],
	        outputLabels: [],
            lot: '',
            container: '',
            itemLots: [],
            lotSel: null,
            containerSel: null,
            deleteOpen: false,
            rtv: false,
            hasLedgers: false,
            allItems: [],
            shippedLots: [],
            shippedLot: '',
            shippedLotSel: null
        }
    };

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.item, common.cacheValues.bin, common.cacheValues.user, common.cacheValues.allItems,
                                        common.cacheValues.activeLocation, common.cacheValues.status, common.cacheValues.quantityUOM,
                                        common.cacheValues.receiptType, common.cacheValues.receiptForm, common.cacheValues.receiptSop,
                                        common.cacheValues.containerType], this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.items;
        let bins = cacheValues.bins;
        let allUsers = cacheValues.users;
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.receipt, this.props.auth.user.tenant);
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
                this.props.auth.user.defaultLocation : '';
        let locationSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locationSel == null && locations.length === 1){
            locationSel = locations[0];
            location = locationSel.value;
        }
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        let workRecords = [];
        let sopIds = [];
        let sopsSel = [];
        cacheValues.sops.forEach(sop => {
            if(sop.required === true){
                workRecords.push({
                    sop: sop.value,
                    original: true
                });
                sopIds.push(sop.value);
                sopsSel.push(sop);
            }
        });
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        })
        let fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        let gSel = cacheValues.quantityUOMs.find(x => x.value === this.props.auth.constants.quantityUOMs.g);
        this.setState({
            statuses: cacheValues.statuses,
            statusSel: statusSel,
            status: this.props.auth.constants.statuses.new,
            types: cacheValues.receiptTypes,
            uoms: cacheValues.quantityUOMs,
            allItems: cacheValues.allItems,
            items: items,
            locations: locations,
            location: location,
            locationSel: locationSel,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            security: this.props.permission,
            fieldAccess: fieldAccess,
            sops: workRecords,
            sopList: cacheValues.sops,
            sopIds: sopIds,
            sopsSel: sopsSel,
            userList: userValues.create,
            activeUsers: activeUsers,
            managers: userValues.review,
            activeManagers: activeManagers,
            qas: userValues.approve,
            activeQas: activeQas,
            bins: bins,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            allUsers: allUsers,
            containerTypes: cacheValues.containerTypes,
            g: this.props.auth.constants.quantityUOMs.g,
            gSel: gSel
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/receipts', label: this.props.t('receipts'), screen: this.props.auth.screenDefs.Receipts},
                    { path: 'active', label: this.props.t('receipt'), screen: this.props.auth.screenDefs.Receipts}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async (e) => {
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.vendor, common.cacheValues.customer], this.props.auth.constants, null, this.props.auth.user.tenant);
        let vendors = cacheValues.vendors;
        let customers = cacheValues.customers;
        let record = await axios.get('/api/receipt/' + this.state._id);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let customerSel = record.data.customer != null ? customers.find(x => x.value === record.data.customer) : null;
        let typeSel = this.state.types.find(x => x.value === record.data.type);
        let vendorSel = record.data.vendor != null ? vendors.find(x => x.value === record.data.vendor) : null;
        let receivedFromSel = record.data.receivedFrom != null ? record.data.employees.find(x => x.value === record.data.receivedFrom) : null;
        let purchaseOrderSel = record.data.purchaseOrder != null ? record.data.purchaseOrders.find(x => x.value === record.data.purchaseOrder) : null;
        let locationSel = this.state.locations.find(x => x.value === record.data.location);
        let fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let hasCount = false;
        let contents = record.data.contents;
        let contentLines = [];
	    let outputLabels = [];
        let itemLots = record.data.itemLots;
        if(contents != null && contents.length > 0){
            contents.forEach(row => {
                let itemLine = contentLines.find(x => x.item != null && x.item === row.item);
                let itemSel = this.state.allItems.find(x => x.value === row.item);
                row.itemSel = itemSel;
                if(itemSel != null && itemSel.hasCount)
                    hasCount = true;
                let uomSel = this.state.uoms.find(x => x.value === row.uom);
                row.uomSel = uomSel;
                let binSel = this.state.bins.find(x => x.value === row.bin);
                row.binSel = binSel;
                let itemLotRow = itemLots.find(x => x.item === row.item);
                let lots = itemLotRow != null ? itemLotRow.values : [];
                let containers = [];
                if(row.lot != null){
                    let lotRow = itemLotRow.lots.find(x => x.lot === row.lot);
                    if(lotRow != null){
                        containers = lotRow.containers;
                    }
                }
                row.shippedLotSel = row.shippedLot != null ? row.shippedLots.find(x => x.value === row.shippedLot) : null;
                let lotSel = row.lot != null ? lots.find(x => x.value === row.lot) : null;
                let containerSel = row.container != null ? containers.find(x => x.value === row.container) : null;
                if(row.totalUOM != null && row.totalUOM !== ''){
                    row.totalUOMSel = this.state.gSel;
                }
                if(row.containerType != null && row.containerType !== '')
                    row.containerTypeSel = this.state.containerTypes.find(x => x.value === row.containerType);
                let newLot = row.newLot === true ? true : itemSel.customNumber !== true ? false : lots == null || lots.length === 0 ? true : false;
                if(itemLine == null){
                    let lines = [];
                    if(row.quantity != null){
                        lines = [{
                            _id: row._id,
                            quantity: row.quantity,
                            uom: row.uom,
                            bin: row.bin,
                            binSel: binSel,
                            count: row.count,
                            container: row.container,
                            containerTransaction: row.containerTransaction,
                            itemLedger: row.itemLedger,
                            lot: row.lot,
                            lotTransaction: row.lotTransaction,
                            vendorLot: row.vendorLot,
                            lotExpiration: row.lotExpiration,
                            customLot: row.customLot,
                            newLot: newLot,
                            totalWeight: row.totalWeight,
                            totalUOM: this.state.g,
                            totalUOMSel: this.state.gSel,
                            discreteWeight: row.discreteWeight,
                            discreteWeightUOM: this.state.g,
                            containerType: row.containerType,
                            containerTypeSel: row.containerTypeSel,
                            add: false,
                            lotSel: lotSel,
                            containerSel: containerSel,
                            containers: containers,
                            shippedLot: row.shippedLot,
                            shippedLotSel: row.shippedLotSel
                        }];
                    }
                    contentLines.push({item: row.item,
                        itemSel: itemSel,
                        uom: row.uom,
                        uomSel: uomSel,
                        quantity: row.quantity,
                        count: row.count,
                        totalWeight: row.totalWeight,
                        totalUOM: this.state.g,
                        totalUOMSel: this.state.gSel,
                        lots: lots,
                        expectedQuantity: row.expectedQuantity,
                        lines: lines,
                        shippedLots: row.shippedLots,
                    })
                } else {
                    itemLine.quantity = parseFloat(itemLine.quantity) + parseFloat(row.quantity);
                    itemLine.count = row.count != null && itemLine.count != null ? parseInt(row.count) + parseInt(itemLine.count) : row.count != null ?
                                    row.count : itemLine.count;
                    itemLine.totalWeight = row.totalWeight != null && itemLine.totalWeight != null ? parseFloat(row.totalWeight) + parseFloat(itemLine.totalWeight) :
                                    row.totalWeight != null ? row.totalWeight : itemLine.totalWeight;
                    itemLine.lines.push({
                        _id: row._id,
                        quantity: row.quantity,
                        uom: row.uom,
                        bin: row.bin,
                        binSel: binSel,
                        count: row.count,
                        container: row.container,
                        containerTransaction: row.containerTransaction,
                        itemLedger: row.itemLedger,
                        lot: row.lot,
                        lotTransaction: row.lotTransaction,
                        vendorLot: row.vendorLot,
                        lotExpiration: row.lotExpiration,
                        customLot: row.customLot,
                        newLot: newLot,
                        totalWeight: row.totalWeight,
                        totalUOM: this.state.g,
                        totalUOMSel: this.state.gSel,
                        discreteWeight: row.discreteWeight,
                        discreteWeightUOM: this.state.g,
                        containerType: row.containerType,
                        containerTypeSel: row.containerTypeSel,
                        add: false,
                        lotSel: lotSel,
                        containerSel: containerSel,
                        containers: containers,
                        shippedLot: row.shippedLot,
                        shippedLotSel: row.shippedLotSel
                    })
                }
                if(row.container != null && itemSel != null &&
                   uomSel != null && row.containerTypeSel != null) {
                    outputLabels.push({
                        id: row.container,
                        container: containerSel != null ? containerSel.number : '',
                        lot: row.customLot != null ? row.customLot :
                             lotSel != null && lotSel.label != null ? lotSel.label : '',
                        item: itemSel != null && itemSel.label != null ? itemSel.label : '',
                        quantity: ConversionHelper.round3(row.quantity),
                        uom: uomSel.label,
                        category: row.containerTypeSel != null && row.containerTypeSel.label != null ?
                                  row.containerTypeSel.label : '',
                        itemNumber: itemSel != null && itemSel.number != null ? itemSel.number : '',
                        date: record.data.date,
                        qrPath: `${window.location.origin}/#/container/${row.container}`,
                        dateFormat: this.props.auth.user.dateFormat
                    });
                }
            });
            if(fieldAccess){
                for(let row of contentLines){
                    row.lines.push({
                        quantity: '',
                        uom: row.uom,
                        bin: '',
                        binSel: null,
                        count: '',
                        vendorLot: '',
                        customLot: '',
                        newLot: false,
                        totalWeight: '',
                        totalUOM: this.state.g,
                        totalUOMSel: this.state.gSel,
                        discreteWeight: '',
                        containerType: '',
                        containerTypeSel: null,
                        add: true
                    });
                }
            }
        }
        let locked = record.data.status !== this.props.auth.constants.statuses.new && record.data.status !== this.props.auth.constants.statuses.inprogress;
        let sopIds = [];
        let sopsSel = [];
        let tabLabels = [{name: 'Receipt', disabled: false}];
        if(record.data.sops != null && record.data.sops.length > 0){
            record.data.sops.forEach(sop => {
                sopIds.push(sop.sop);
                let val = this.state.sopList.find(x => x.value === sop.sop);
                sopsSel.push(val);
                tabLabels.push({name: val.label, disabled: false});
            });
        }
        if(record.data.hasLedgers){
            tabLabels.push({name: t('itemLedgers'), disabled: false});
        }
        let userList = this.state.userList;
        if(record.data.userValues != null && record.data.userValues.length > 0){
            record.data.userValues.forEach(row => {
                let found = userList.find(x => x.value === row.value);
                if(!found)
                    userList.push(row)
            });
        }
        let usersSel = [];
        if(record.data.users != null && record.data.users.length > 0){
            record.data.users.forEach(row => {
                let val = userList.find(x => x.value === row);
                if(val != null){
                    usersSel.push(val);
                } else {
                    val = this.state.allUsers.find(x => x.value === row);
                    if(val != null){
                        usersSel.push(val);
                        userList.push(val);
                    }
                }
            });
        }
        let sigDeleteCallback = (record.data.status === this.props.auth.constants.statuses.new || record.data.status === this.props.auth.constants.statuses.inprogress) && 
                        record.data.user != null ? this.deleteSigCallback : null;
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: usersSel, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user,
                objectType: this.props.auth.constants.objectIds.receipt, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, saveCallback: this.saveCallback, deleteCallback: sigDeleteCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.managers,
                name: t('review'), title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.receipt, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: !internal && this.state.signer == null ? this.state.activeQas : this.state.qas,
                name: t('approve'), title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.receipt, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        let params = queryString.parse(this.props.location.search);
        this.setState({
            number: record.data.number,
            date: record.data.date,
            status: record.data.status,
            statusSel: statusSel,
            type: record.data.type,
            typeSel: typeSel,
            courier: record.data.courier,
            customer: record.data.customer,
            customerSel: customerSel,
            vendor: record.data.vendor,
            vendorSel: vendorSel,
            vendors: vendors,
            employees: record.data.employees,
            receivedFromSel: receivedFromSel,
            receivedFrom: record.data.receivedFrom,
            purchaseOrder: record.data.purchaseOrder,
            purchaseOrderSel: purchaseOrderSel,
            purchaseOrders: record.data.purchaseOrders,
            purchaseOrderObject: record.data.purchaseOrderObject,
            customers: customers,
            shippingNumber: record.data.shippingNumber,
            location: record.data.location,
            locationSel: locationSel,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            sopIds: sopIds,
            sopsSel: sopsSel,
            sops: record.data.sops,
            renderNum: Math.random(),
            tabLabels: tabLabels,
            users: record.data.users,
            usersSel: usersSel,
            locked: locked,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            fieldAccess: fieldAccess,
            documents: record.data.documents,
            userList: userList,
            hasCount: hasCount,
            sigData: null,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            contentLines: contentLines,
	        outputLabels: outputLabels,
            itemLots: itemLots,
            deleteOpen: false,
            rtv: record.data.rtv === true ? true : false,
            hasLedgers: record.data.hasLedgers
        }, () => {
            const crumbs = [
                { path: '/#/receipts', label: this.props.t('receipts'), screen: this.props.auth.screenDefs.Receipts},
                { path: 'active', label: this.props.t('receipt') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.Receipts}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.determineUnlock();
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            if(params.tab != null)
                this.openNotification(params);
        });
    }

    openNotification = (params) => {
        let tabKey = this.state.tabKey;
        if(params != null && params.tab != null)
            tabKey = Number(params.tab);
        this.setState({tabKey});
    }
    determineUnlock() {
        let enableUnlock = false;
        let state = this.state;
        let constants = this.props.auth.constants;
        if(state.status === constants.statuses.complete && this.props.permission.unlock){
            enableUnlock = true;
        } else if (state.status === constants.statuses.reviewed || state.status === constants.statuses.signed){
            if(this.props.auth.user.tenant !== common.tenants.eic){
                if((state.contents == null || state.contents.length === 0)){
                    enableUnlock = true;
                } else {
                    let outputsLock = false;
                    if(state.contents != null && state.contents.length > 0){
                        for(let row of state.contents){
                            if(row.container != null && row.itemLedger != null){
                                if(ConversionHelper.round3(parseFloat(row.itemLedger.quantity)) > ConversionHelper.round3(parseFloat(row.container.quantity))){
                                    outputsLock = true;
                                    break;
                                }
                            }
                        }
                    }
                    if(!outputsLock)
                        enableUnlock = true;
                }
            }
        }
        this.setState({enableUnlock: enableUnlock})
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
        });
        if(newValue != null){
            if(data === 'type'){
                if(newValue.value === this.props.auth.constants.receiptTypes.purchase){
                    let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.vendor], this.props.auth.constants, null, this.props.auth.user.tenant);
                    let vendors = cacheValues.vendors
                    let contentLines = this.state.contentLines;
                    for(let row of contentLines){
                        row.shippedLots = [];
                        for(let line of row.lines){
                            line.shippedLot = '';
                            line.shippedLotSel = null;
                        }
                    }
                    this.setState({vendors: vendors, rtv: false, shippedLots: [], shippedLot: '', shippedLotSel: null, contentLines: contentLines});
                } else if(newValue.value === this.props.auth.constants.receiptTypes.return){
                    let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.customer], this.props.auth.constants, null, this.props.auth.user.tenant);
                    let customers = cacheValues.customers
                    this.setState({customers: customers});
                    let contentLines = this.state.contentLines;
                    for(let row of contentLines){
                        let lotsResp = await axios.get('/api/itemlots/' + row.item, {params: {includeEmpty: true}});
                        row.shippedLots = lotsResp.data;
                    }
                    if(this.state.item != null && this.state.item !== '' ){
                        let lotsResp = await axios.get('/api/itemlots/' + this.state.item, {params: {includeEmpty: true}});
                        this.setState({shippedLots: lotsResp.data});
                    }
                }
            } else if(data === 'item'){
                let shippedLots = this.state.shippedLots;
                let hasCount = this.state.hasCount;
                if(this.state.contentLines.length === 0)
                    hasCount = false;
                if(newValue.hasCount)
                    hasCount = true;
                let uom = newValue.purchaseUOM != null ? newValue.purchaseUOM : newValue.uom;
                let uomSel = this.state.uoms.find(x => x.value === uom);
                let itemLots = this.state.itemLots;
                let lots = [];
                if(itemLots.length > 0){
                    let itemRow = itemLots.find(x => x.item === newValue.value);
                    if(itemRow != null){
                        lots = itemRow.values;
                    }
                }
                if(this.state.type != null && this.state.type === this.props.auth.constants.receiptTypes.return && newValue != null){
                    let lotsResp = await axios.get('/api/itemlots/' + newValue.value, {params: {includeEmpty: true}});
                    shippedLots = lotsResp.data;
                } else {
                    shippedLots = [];
                }
                this.setState({
                    uom: uom,
                    uomSel: uomSel,
                    hasCount: hasCount,
                    lots: lots,
                    newLot: newValue.customNumber === true  && lots.length === 0 ? true : false,
                    shippedLots: shippedLots
                });
            } else if(data === 'vendor'){
                let purchaseOrders = await axios.get('/api/purchaseorderselect', {params: {vendor: newValue.value, status: this.props.auth.constants.purchaseOrderStatuses.approved}});
                let employees = await axios.get('/api/vendoremployeeselect/' + newValue.value);
                this.setState({purchaseOrders: purchaseOrders.data, employees: employees.data})
            } else if (data === 'lot'){
                let itemLots = this.state.itemLots;
                let itemRow = itemLots.find(x => x.item === this.state.item);
                if(itemRow != null){
                    let lotRow = itemRow.lots.find(x => x.lot === newValue.value);
                    if(lotRow != null){
                        this.setState({containers: lotRow.containers});
                    }
                }
            } else if (data === 'purchaseOrder'){
                let poRes = await axios.get('/api/purchaseorder/' + newValue.value);
                let poObject = poRes.data;
                let items = [];
                let contentLines = [];
                poObject.contents.forEach(row => {
                    let item = this.state.allItems.find(x => x.value === row.item);
                    let uomSel = this.state.uoms.find(x => x.value === item.uom);
                    items.push(item);
                    let itemLine = contentLines.find(x => x.item === row.item);
                    if(itemLine == null){
                        contentLines.push({
                            item: row.item,
                            itemSel: item,
                            uom: item.uom,
                            uomSel: uomSel,
                            expectedQuantity: row.quantity,
                            lines: []
                        });
                    } else {
                        itemLine.lines.push({
                            quantity: row.quantity
                        })
                    }
                })
                for(let row of contentLines){
                    row.lines.push({
                        quantity: '',
                        count: '',
                        add: true
                    })
                }
                this.setState({
                    purchaseOrderObject: poObject,
                    contentLines: contentLines,
                });
            }
        } else {
            if(data === 'item'){
                this.setState({uomSel: null, uom: '', lot: '', lotSel: null, lots: [], container: '', containerSel: null, containers: []});
            } else if (data  === 'type'){
                this.setState({rtv: false, customers: [], vendors: []});
            }
        }
    };

    changeMulti = (name, data) => (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            [name]: newValue,
            [data]: ids
        })
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]}, () => {
            if(name === 'newLot' && this.state.newLot === true){
                this.setState({
                    lot: '',
                    lotSel: null,
                    container: '',
                    containerSel: null
                });
            }
        });
    }

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    };

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.save()})
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let contentLines = this.state.contentLines;
        let contents = [];
        for(let row of contentLines){
            if(row.lines != null && row.lines.length > 0){
                for(let line of row.lines){
                    if(line.add == null || line.add === false || row.lines.length === 1){
                        contents.push({
                            _id: line._id,
                            item: row.item,
                            itemSel: row.itemSel,
                            quantity: line.quantity,
                            uom: row.uom,
                            count: line.count !== '' && line.count !== 0 ? line.count : null,
                            container: line.container,
                            containerTransaction: line.containerTransaction,
                            itemLedger: line.itemLedger,
                            lot: line.lot,
                            lotTransaction: line.lotTransaction,
                            bin: line.bin !== '' ? line.bin : null,
                            vendorLot: line.vendorLot !== '' ? line.vendorLot : null,
                            lotExpiration: line.lotExpiration !== '' ? line.lotExpiration : null,
                            customLot: line.customLot !== '' ? line.customLot : null,
                            newLot: line.newLot === true ? true : false,
                            totalWeight: line.totalWeight !== '' && line.totalWeight !== 0 ? line.totalWeight : null,
                            totalUOM: line.totalUOM !== '' ? line.totalUOM : null,
                            containerType: line.containerType,
                            expectedQuantity: row.expectedQuantity,
                            shippedLot: line.shippedLot,
                        });
                    }
                }
            }
        }
        let data = {
            _id: this.state._id,
            number: this.state.number,
            date: this.state.date,
            type: this.state.type,
            status: this.state.status,
            location: this.state.location,
            customer: this.state.customer,
            vendor: this.state.vendor,
            receivedFrom: this.state.receivedFrom,
            purchaseOrder: this.state.purchaseOrder,
            courier: this.state.courier,
            shippingNumber: this.state.shippingNumber,
            contents: contents,
            sops: this.state.sops,
            users: this.state.users,
            sigData: this.state.sigData,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            rtv: this.state.rtv === true ? true : false
        }
        try {
            let result = await axios.post('/api/receipt', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/receipt/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true, sigData: null}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
            this.loadRecord();
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    validate(final){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.date == null || state.date === ''){
            errors[t('date')] = t('required');
        } else {
            let validEndDate = ConversionHelper.validActivityDate(this.state.date, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
            if(!validEndDate){
                errors[t('date')] = t('dateBeforeLock');
            }
        }
        if(state.status == null || state.status === '')
            errors[t('status')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.users == null || state.users.length === 0)
            errors[t('users')] = t('required');
        if(state.type == null || state.type === ''){
            errors[t('type')] = t('required');
        } else {
            if(state.type === this.props.auth.constants.receiptTypes.return){
                if(state.customer == null || state.customer === '')
                    errors[t('customer')] = t('required');
            } else if(state.type === this.props.auth.constants.receiptTypes.purchase){
                if(state.vendor == null || state.vendor === '')
                    errors[t('vendor')] = t('required');
            }
        }
        if((state.item != null && state.item !== '') || (state.quantity != null && state.quantity !== '') ||
        (state.vendorLot != null && state.vendorLot !== '') || (state.bin != null && state.bin !== '') ||
        (state.customLot != null && state.customLot !== ''))
            errors[t('item')] = t('rowNotAdded');
        for(let i = 0; i < state.contentLines.length; i++){
            let row = state.contentLines[i];
            if(row.item == null || row.item === '')
                errors[`${t('item')} (${t('line')} {i})`] = t('required');
            let k = 0;
            for(let line of row.lines){
                if(line.add == null || line.add !== true){
                    if(line.quantity == null || line.quantity === '' || line.quantity === 0)
                        errors[`${t('quantity')} (${t('line')} ${i}, ${k})`] = t('required');
                    if(row.itemSel != null && row.itemSel.lotTracked && (line.bin == null || line.bin === ''))
                        errors[`${t('bin')} (${t('line')} ${i}, ${k})`] = t('required');
                    if(row.itemSel != null && row.itemSel.lotTracked && row.itemSel.customNumber){
                        if(line.customLot == null || line.customLot === '')
                            errors[`${t('customLot')} (${t('line')} ${i}, ${k})`] = t('required');
                        else {
                            if(line.customLot != null && line.customLot !== '' && row.item != null && row.item !== ''){
                                let customOuts = state.contentLines.filter(x => x.item !== row.item && x.itemSel != null && x.itemSel.customNumber === true);
                                for(let out of customOuts){
                                    let matchLots = out.lines.filter(x => x.customLot === line.customLot);
                                    if(matchLots != null && matchLots.length > 0){
                                        errors[`${t('customLot')} (${t('line')} ${i}, ${k})`] = t('lotNumberUsed');
                                    }
                                }
                            }
                        }
                    }
                    if(line.itemSel != null && line.itemSel.discreteWeight){
                        if(line.totalWeight == null || line.totalWeight === '')
                            errors[`${t('totalWeight')} (${t('line')} ${i}, ${k})`] = t('required');
                    }
                } else {
                    if((line.quantity != null && line.quantity !== '') || (line.bin != null && line.bin !== '') ||
                        (line.count != null && line.count !== '') || (line.totalWeight != null && line.totalWeight !== '')){
                        errors[`${t('quantity')} (${t('line')} ${i}, ${k})`] = t('rowNotAdded');
                    }
                }
                k++;
            }
            if(final === true){
                if(row.lines.length === 1 && row.lines[0].add === true){
                    errors[`${t('quantity')} (${t('line')} ${i}, 0)`] = t('rowNotAdded');
                }
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    addLine = (e) => {
        let valid = this.validateLine();
        if(!valid)
            return;
        let contentLines = this.state.contentLines;
        let existingLine = contentLines.find(x => x.item === this.state.item);
        if(existingLine != null){
            existingLine.quantity = parseFloat(existingLine.quantity) + parseFloat(this.state.quantity);
            existingLine.count = existingLine.count != null && this.state.count != null && this.state.count !== '' ?
                                parseInt(existingLine.count) + parseInt(this.state.count) : this.state.count != null && this.state.count !== '' ?
                                this.state.count : existingLine.count;
            existingLine.totalWeight = existingLine.totalWeight != null && this.state.totalWeight != null && this.state.totalWeight !== '' ?
                                parseFloat(existingLine.totalWeight) + parseFloat(this.state.totalWeight) : this.state.totalWeight != null && this.state.totalWeight !== '' ?
                                this.state.totalWeight : existingLine.totalWeight;
            existingLine.shippedLots = this.state.shippedLots;
            let line = {
                quantity: this.state.quantity,
                count: this.state.count !== '' ? this.state.count : null,
                bin: this.state.bin,
                binSel: this.state.binSel,
                uom: this.state.uom,
                vendorLot: this.state.vendorLot,
                lotExpiration: this.state.lotExpiration,
                customLot: this.state.customLot !== '' ? this.state.customLot : null,
                newLot: this.state.newLot,
                totalWeight: this.state.totalWeight !== '' ? this.state.totalWeight : null,
                totalUOM: this.state.totalWeight !== '' ? this.state.g : null,
                totalUOMSel: this.state.totalWeight !== '' ? this.state.gSel : null,
                containerType: this.state.containerType,
                containerTypeSel: this.state.containerTypeSel,
                add: false,
                lot: this.state.lot !== '' ? this.state.lot : null,
                container: this.state.container !== '' ? this.state.container : null,
                shippedLot: this.state.shippedLot !== '' ? this.state.shippedLot : null,
                shippedLotSel: this.state.shippedLotSel,
            };
            existingLine.lines.splice(existingLine.lines.length - 1, 0, line);
        } else {
            contentLines.push({
                itemSel: this.state.itemSel,
                item: this.state.item,
                uom: this.state.uom,
                uomSel: this.state.uomSel,
                quantity: this.state.quantity,
                count: this.state.count !== '' ? this.state.count : null,
                totalWeight: this.state.totalWeight !== '' ? this.state.totalWeight : null,
                totalUOM: this.state.totalWeight !== '' ? this.state.g : null,
                totalUOMSel: this.state.totalWeight !== '' ? this.state.gSel: null,
                shippedLots: this.state.shippedLots,
                lines: [{
                    quantity: this.state.quantity,
                    count: this.state.count !== '' ? this.state.count : null,
                    bin: this.state.bin,
                    binSel: this.state.binSel,
                    uom: this.state.uom,
                    vendorLot: this.state.vendorLot,
                    lotExpiration: this.state.lotExpiration,
                    customLot: this.state.customLot !== '' ? this.state.customLot : null,
                    newLot: this.state.newLot,
                    totalWeight: this.state.totalWeight !== '' ? this.state.totalWeight : null,
                    totalUOM: this.state.totalWeight !== '' ? this.state.g : null,
                    totalUOMSel: this.state.totalWeight !== '' ? this.state.gSel : null,
                    containerType: this.state.containerType,
                    containerTypeSel: this.state.containerTypeSel,
                    add: false,
                    shippedLot: this.state.shippedLot !== '' ? this.state.shippedLot : null,
                    shippedLotSel: this.state.shippedLotSel,
                },
                {
                    quantity: '',
                    uom: this.state.uom,
                    bin: '',
                    binSel: null,
                    count: '',
                    vendorLot: '',
                    customLot: '',
                    newLot: false,
                    totalWeight: '',
                    totalUOM: '',
                    totalUOMSel: null,
                    discreteWeight: '',
                    containerType: '',
                    containerTypeSel: null,
                    add: true
                }]
            })
        }
        this.setState({
            contentLines: contentLines,
            item: '',
            itemSel: null,
            quantity: '',
            uom: '',
            uomSel: null,
            bin: '',
            binSel: null,
            vendorLot: '',
            count: '',
            lotExpiration: '',
            customLot: '',
            newLot: false,
            totalWeight: '',
            totalUOM: '',
            totalUOMSel : null,
            containerType: '',
            containerTypeSel: null,
            shippedLot: '',
            shippedLotSel: null,
            shippedLots: []
        })
    };

    validateLine(){
        const t = this.props.t;
        let errors = {};
        if(this.state.item == null || this.state.item === '')
            errors[t('item')] = t('required');
        if(this.state.quantity == null || this.state.quantity === '')
            errors[t('quantity')] = t('required');
        if(this.state.itemSel != null && this.state.itemSel.lotTracked === true && (this.state.bin == null || this.state.bin === ''))
            errors[t('bin')] = t('required');
        if(this.state.itemSel != null && this.state.itemSel.lotTracked && this.state.itemSel.customNumber){
            if(this.state.customLot == null || this.state.customLot === '')
                errors[t('customLot')] = t('required');
        }
        if(this.state.itemSel != null && this.state.itemSel.discreteWeight){
            if(this.state.totalWeight == null || this.state.totalWeight === ''){
                errors[t('totalWeight')] = t('required');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    validateComplete = () => {
        const t = this.props.t;
        let valid = this.validate(true);
        if(!valid)
            return t('notAllComplete');
        if(this.state.sops != null && this.state.sops.length > 0){
            for(let i = 0; i < this.state.sops.length; i++){
                let sop = this.state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        return null;
    };

    changeSop = (e, newValue) => {
        const t = this.props.t;
        let sops = this.state.sops;
        let tabLabels = this.state.tabLabels;
        if(newValue != null){
            if(sops.length > newValue.length){
                for(let i = 0; i < sops.length; i++){
                    let sop = sops[i];
                    let rec = newValue.find(x => x.value === sop.sop);
                    if(!rec){
                        sops.splice(i, 1);
                        tabLabels.splice(i + 1, 1);
                        break;
                    }
                }
            } else {
                for(let i = 0; i < newValue.length; i++){
                    let val = newValue[i];
                    let rec = sops.find(x => x.sop === val.value)
                    if(!rec){
                        sops.push({
                            sop: val.value,
                            workRecord: null,
                            original: false
                        });
                        if(this.state._id != null && this.state._id !== '')
                            tabLabels.push({name: val.label, disabled: false});
                    }
                }
            }
            let sopIds = [];
            newValue.forEach(val => {
                sopIds.push(val.value);
            });
            this.setState({
                sopIds: sopIds,
                sopsSel: newValue,
                sops: sops
            });
        } else {
            for(let i = 0; i < sops.length; i++){
                let sop = sops[i];
                if(sop.required)
                    return;
            }
            this.setState({sops: [], sopIds: [], sopsSel: [], tabLabels: [{name: t('receipt'), disabled: false}]});
        }
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    }

    _deleteClick = async (e) => {
        const t = this.props.t;
        if(!this.state.locked && this.state.fieldAccess && this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/receipt/' + this.state._id);
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true, deleteOpen: false});
        }
    };

    deleteItem = (i) => (e) => {
        let contentLines = this.state.contentLines;
        if(i > -1 && i < contentLines.length){
            contentLines.splice(i, 1);
        }
        this.setState({contentLines: contentLines});
    }

    deleteContentRow = (i, j) => (e) => {
        let contentLines = this.state.contentLines;
        if(i > -1 && i < contentLines.length){
            let line = contentLines[i];
            if(j > -1 && j < line.lines.length){
                line.lines.splice(j, 1);
                line = this.calculateLineTotals(line);
                this.setState({contentLines: contentLines});
            }
        }
    }

    addContentRow = (i, j) => (e) => {
        const t = this.props.t;
        let contentLines = this.state.contentLines;
        if(i > -1 && i < contentLines.length){
            let line = contentLines[i];
            let row = line.lines[j];
            let errors = {};
            if(row.quantity == null || row.quantity === '')
                errors[`${t('quantity')} (${t('line')} ${i}, ${j})`] = t('required');
            if(row.bin == null || row.bin === '')
                errors[`${t('bin')} (${t('line')} ${i}, ${j})`] = t('required');
            if(line.itemSel.customNumber){
                if(row.customLot == null || row.customLot === '')
                    errors[`${t('customLot')} (${t('line')} ${i}, ${j})`] = t('required');
            }
            if(line.itemSel.discreteWeight){
                if(row.totalWeight == null || row.totalWeight === '')
                    errors[`${t('totalWeight')} (${t('line')} ${i}, ${j})`] = t('required');
            }
            if(line.itemSel.hasCount){
                if(row.count == null || row.count === '')
                    errors[`${t('count')} (${t('line')} ${i}, ${j})`] = t('required');
            }
            if(!isEmpty(errors)){
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                return;
            }
            row.add = false;
            line.lines.push({
                quantity: '',
                uom: row.uom,
                bin: '',
                binSel: null,
                count: '',
                vendorLot: '',
                customLot: '',
                newLot: false,
                totalWeight: '',
                // totalUOM: row.totalUOM,
                // totalUOMSel: row.totalUOMSel,
                totalUOM: '',
                totalUOMSel: null,
                discreteWeight: '',
                containerType: '',
                containerTypeSel: null,
                add: true
            });
            this.setState({contentLines: contentLines, errors: {}});
        }
    }

    changeSubLineNumber = (name, i, j) => (e) => {
        let contents = this.state.contentLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        if(isNaN(value))
            return;
        row[name] = value;
        line = this.calculateLineTotals(line);
        this.setState({contentLines: contents});
    };

    changeSubLineValue = (name, i, j) => (e) => {
        let contents = this.state.contentLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        row[name] = value;
        this.setState({contentLines: contents});
    };

    changeSubLineDate = (name, i, j) => (e) => {
        let contents = this.state.contentLines;
        let line = contents[i];
        let row = line.lines[j];
        row[name] = e;
        this.setState({contentLines: contents});
    };

    changeSubLineAuto = (name, data, i, j) => (e, newValue) => {
        let contents = this.state.contentLines;
        let line = contents[i];
        let row = line.lines[j];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : '';
        let hasCount = false;
        if(data === 'lot' && newValue != null){
            let itemLots = this.state.itemLots;
            let itemRow = itemLots.find(x => x.item = line.item);
            if(itemRow != null){
                let lotRow = itemRow.lots.find(x => x.lot === newValue.value);
                row.containers = lotRow != null ? lotRow.containers : [];
            }
        }
        this.setState({contentLines: contents, hasCount: hasCount});
    };

    changeSubLineBool = (name, i, j) => (e) => {
        let contents = this.state.contentLines;
        let line = contents[i];
        let row = line.lines[j]
        row[name] = !row[name];
        if(row[name] === true){
            row.lot = '';
            row.lotSel = null;
            row.container = '';
            row.containerSel = null;
        }
        this.setState({contentLines: contents});
    };

    calculateLineTotals(line){
        let quantity = 0.0;
        let count = 0;
        let totalWeight = 0.0;
        for(let row of line.lines){
            if(row.quantity !== '')
                quantity += parseFloat(row.quantity);
            count += row.count != null && row.count !== '' ? parseInt(row.count) : 0;
            totalWeight += row.totalWeight != null && row.totalWeight !== '' ? parseFloat(row.totalWeight) : 0;
        }
        line.quantity = quantity;
        line.count = count !== 0 ? count : null;
        line.totalWeight = totalWeight !== 0 ? totalWeight : null;
        return line;
    }

    deleteSigCallback = async (type) => {
        try{
            let result = await axios.post('/api/receiptsigdelete', {type: type, id: this.state._id});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    render(){
        const locked = this.state.locked;
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const t = this.props.t;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const internal = this.props.auth.user.internal;
        const hasDevices = this.props.devices.hasRegisteredDevices;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        labelData={this.state.outputLabels}
                        labelAmount='multiple'
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.receipt}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabPanel value={this.state.tabKey} index={0}>
                    <Grid container spacing={3}>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={6} sm={3}>
                                <FormControl data-cy='number'>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            <FormControl data-cy='status'>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null ? this.state.statusSel.label : ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <DateSelect
                                    onChange={this.changeDate('date')}
                                    value={this.state.date}
                                    helperText={errors[t('date')]}
                                    error={errors[t('date')] != null ? true : false}
                                    label={t('date')}
                                    disabled={!fieldAccess}
                                    format={this.props.auth.user.dateFormat}
                                    required
                                    data-cy='date'
                                />
                            }
                            {locked && this.state.date != null && this.state.date !== '' &&
                                <FormControl>
                                    <FormLabel>{t('date')}</FormLabel>
                                    <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.locationSel}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locationSel', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    label={t('location')}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy='location'
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.typeSel}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSel', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]}
                                    label={t('type')}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy='type'
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('type')}</FormLabel>
                                    {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        {this.state.type != null && this.state.type === this.props.auth.constants.receiptTypes.purchase &&
                            <Grid item xs={6} sm={3}>
                                {!locked &&
                                    <AutoCompleteField
                                        value={this.state.vendorSel}
                                        options={this.state.vendors}
                                        onChange={this.changeAuto('vendorSel', 'vendor')}
                                        error={errors[t('vendor')] != null ? true : false}
                                        helperText={errors[t('vendor')]}
                                        label={t('vendor')}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy='vendor'
                                    />
                                }
                                {locked && this.state.vendorSel != null &&
                                    <FormControl>
                                        <FormLabel>{t('vendor')}</FormLabel>
                                        <Link to={'/vendor/' + this.state.vendorSel.value}>{this.state.vendorSel != null ? this.state.vendorSel.label : ''}</Link>
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {this.state.type != null && this.state.type === this.props.auth.constants.receiptTypes.return && !locked &&
                            <Grid item xs={4} sm={2}>
                                <FormControlLabel
                                    disabled={false}
                                    control={<Switch checked={this.state.rtv} onChange={this.changeBool('rtv')} name="rtv" />}
                                    label={t('rtv')} 
                                />
                            </Grid>
                        }
                        {this.state.type != null && this.state.type === this.props.auth.constants.receiptTypes.return && locked && this.state.rtv &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('rtv')}</FormLabel>
                                    {t('yes')}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.employees != null && this.state.employees.length > 0 &&
                            <Grid item xs={6} sm={3}>
                                {!locked &&
                                    <AutoCompleteField
                                        value={this.state.receivedFromSel}
                                        options={this.state.employees}
                                        onChange={this.changeAuto('receivedFromSel', 'receivedFrom')}
                                        error={errors[t('receivedFrom')] != null ? true : false}
                                        helperText={errors[t('receivedFrom')]}
                                        label={t('receivedFrom')}
                                        disabled={!fieldAccess}
                                        data-cy='receivedFrom'
                                    />
                                }
                                {locked && this.state.receivedFromSel != null &&
                                    <FormControl>
                                        <FormLabel>{t('receivedFrom')}</FormLabel>
                                        {this.state.receivedFromSel != null ? this.state.receivedFromSel.label : ''}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {this.state.purchaseOrders != null && this.state.purchaseOrders.length > 0 && !locked &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.purchaseOrderSel}
                                    options={this.state.purchaseOrders}
                                    onChange={this.changeAuto('purchaseOrderSel', 'purchaseOrder')}
                                    error={errors[t('purchaseOrder')] != null ? true : false}
                                    helperText={errors[t('purchaseOrder')]}
                                    label={t('purchaseOrder')}
                                    disabled={!fieldAccess}
                                    data-cy='purchaseOrder'
                                />
                            </Grid>
                        }
                        {locked && this.state.purchaseOrderObject != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('purchaseOrder')}</FormLabel>
                                    <Link to={'/purchaseorder/' + this.state.purchaseOrderObject._id}>
                                        {this.state.purchaseOrderObject.number}
                                    </Link>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.type != null && this.state.type === this.props.auth.constants.receiptTypes.return &&
                            <Aux>
                                <Grid item xs={8} sm={4}>
                                    {!locked &&
                                        <AutoCompleteField
                                            value={this.state.customerSel}
                                            options={this.state.customers}
                                            onChange={this.changeAuto('customerSel', 'customer')}
                                            error={errors[t('customer')] != null ? true : false}
                                            helperText={errors[t('customer')]}
                                            label={t('customer')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy='customer'
                                        />
                                    }
                                    {locked && this.state.customerSel != null &&
                                        <FormControl>
                                            <FormLabel>{t('customer')}</FormLabel>
                                            <Link to={'/customer/' + this.state.customerSel.value}>
                                                {this.state.customerSel != null ? this.state.customerSel.label : ''}
                                            </Link>
                                        </FormControl>
                                    }
                                </Grid>
                            </Aux>
                        }
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <TextField value={this.state.courier} onChange={this.changeValue} name="courier"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('courier')} data-cy='courier'
                                error={errors[t('courier')] != null ? true : false} helperText={errors[t('courier')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('courier')}</FormLabel>
                                    {this.state.courier}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <TextField value={this.state.shippingNumber} onChange={this.changeValue} name="shippingNumber"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('shippingNumber')} data-cy='shippingNumber'
                                error={errors[t('shippingNumber')] != null ? true : false} helperText={errors[t('shippingNumber')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('shippingNumber')}</FormLabel>
                                    {this.state.shippingNumber}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    {this.state.hasForm === true &&
                        <Aux>
                            <br/>
                            {errors[t('form')] != null && errors[t('form')] !== '' &&
                                <div style={{color: 'red'}}>{errors[t('form')]}</div>
                            }
                            <Form renderer={renderer}
                                defaultFields={this.state.form}
                                onChange={this.formValueChanged}
                                disabled={!fieldAccess}/>
                        </Aux>
                    }
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item xs={11}>
                            <AutoCompleteField
                                multiple={true}
                                value={this.state.usersSel}
                                options={fieldAccess && !internal ? this.state.activeUsers : this.state.userList}
                                onChange={this.changeMulti('usersSel', 'users')}
                                error={errors[t('users')] != null ? true : false}
                                helperText={errors[t('users')]}
                                label={t('users')}
                                disabled={!fieldAccess}
                                data-cy='users'
                            />
                        </Grid>
                        {this.state.sopList != null && this.state.sopList.length > 0 &&
                            <Grid item sm={11}>
                                <AutoCompleteField
                                    multiple={true} disabled={!fieldAccess}
                                    value={this.state.sopsSel}
                                    options={this.state.sopList}
                                    onChange={this.changeSop}
                                    error={errors[t('sopIds')] != null ? true : false}
                                    helperText={errors[t('sopIds')]}
                                    label={t('workRecs')}
                                    data-cy='sops'
                                />
                            </Grid>
                        }
                    </Grid>
                    <br/>
                    <SectionTitle title={t('contents')}/>
                    {this.state.contentLines.map((row, i) =>
                        <Box borderColor="primary.main" {...defaultProps} component={'div'} key={'cl' + i}>
                            <Aux>
                                <Grid container spacing={3}>
                                    <Grid item sx={{width: 350}}>
                                        <FormControl>
                                            <FormLabel>{t('item')}</FormLabel>
                                            {row.itemSel != null &&
                                                <Link to={'/item/' + row.itemSel.value}>{row.itemSel != null ? row.itemSel.label : ''}</Link>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item sx={{width: 75}}>
                                        <FormControl>
                                            <FormLabel>{t('lines')}</FormLabel>
                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                value={row.lines != null && this.state.status === this.props.auth.constants.statuses.inprogress ? row.lines.length - 1 : row.lines.length}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sx={{width: 150}}>
                                        <FormControl>
                                            <FormLabel>{t('quantity')}</FormLabel>
                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                value={row.quantity != null && row.quantity !== '' ? row.quantity : 0}
                                                suffix={row.uomSel != null ? row.uomSel.label : ''}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {row.count != null && row.count !== 0 &&
                                        <Grid item sx={{width: 100}}>
                                            <FormControl>
                                                <FormLabel>{t('count')}</FormLabel>
                                                <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                    value={row.count != null && row.count !== '' ? row.count : 0}
                                                />
                                            </FormControl>
                                        </Grid>
                                    }
                                    {row.totalWeight != null && row.totalWeight !== 0 &&
                                        <Grid item sx={{width: 150}}>
                                            <FormControl>
                                                <FormLabel>{t('totalWeight')}</FormLabel>
                                                <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                    value={row.totalWeight != null && row.totalWeight !== '' ? row.totalWeight : 0}
                                                    suffix={row.totalUOMSel != null ? row.totalUOMSel.label : ''}
                                                />
                                            </FormControl>
                                        </Grid>
                                    }
                                    {row.expectedQuantity != null && row.expectedQuantity !== '' && row.expectedQuantity !== 0 &&
                                        <Grid item sx={{width: 150}}>
                                            <FormControl>
                                                <FormLabel>{t('expected')}</FormLabel>
                                                <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={2} value={row.expectedQuantity} 
                                                suffix={row.uomSel != null ? row.uomSel.label : ''}/>
                                            </FormControl>
                                        </Grid>
                                    }
                                    {!locked && fieldAccess &&
                                        <Grid item sx={{width: 50}}>
                                            <IconButton aria-label="delete line" onClick={this.deleteItem(i)}>
                                                <RemoveCircleTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    }
                                </Grid>
                                <br/>
                                {row.lines != null && row.lines.length > 0 &&
                                    <Grid container spacing={3} key={'row' + i + 'linehead'}>
                                        {!fieldAccess && row.itemSel.lotTracked && (this.state.status === this.props.auth.constants.statuses.reviewed ||
                                        this.state.status === this.props.auth.constants.statuses.signed) &&
                                            <Grid item sx={{width: 290}}>
                                                <FormLabel>{t('lotContainer')}</FormLabel>
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 110}}>
                                            <FormLabel>{t('quantity') + (row.uomSel != null ? '(' + row.uomSel.label + ')' : '')}</FormLabel>
                                        </Grid>
                                        {this.state.status !== this.props.auth.constants.statuses.reviewed && this.state.status !== this.props.auth.constants.statuses.signed &&
                                            <Grid item sx={{width: 60}}>
                                                <FormLabel>{t('newLot')}</FormLabel>
                                            </Grid>
                                        }
                                        {((fieldAccess && row.lots != null && row.lots.length > 0) || (row.itemSel != null && row.itemSel.customNumber === true)) && 
                                        this.state.status !== this.props.auth.constants.statuses.reviewed && this.state.status !== this.props.auth.constants.statuses.signed &&
                                            <Grid item sx={{width: 170}}>
                                                <FormLabel>{t('lot')}</FormLabel>
                                            </Grid>
                                        }
                                        {fieldAccess && row.lots != null && row.lots.length > 0 && this.state.status !== this.props.auth.constants.statuses.reviewed && 
                                        this.state.status !== this.props.auth.constants.statuses.signed &&
                                            <Grid item sx={{width: 170}}>
                                                <FormLabel>{t('container')}</FormLabel>
                                            </Grid>
                                        }
                                        {row.itemSel != null && row.itemSel.hasCount === true &&
                                            <Grid item sx={{width: 100}}>
                                                <FormLabel>{t('count')}</FormLabel>
                                            </Grid>
                                        }
                                        {row.itemSel != null && row.itemSel.discreteWeight === true &&
                                            <Grid item sx={{width: 110}}>
                                                <FormLabel>{t('fullDiscreteG')}</FormLabel>
                                            </Grid>
                                        }
                                        {this.state.type !== this.props.auth.constants.receiptTypes.return &&
                                            <Grid item sx={{width: 110}}>
                                                <FormLabel>{t('vendorLot')}</FormLabel>
                                            </Grid>
                                        }
                                        <Grid item sx={{width: fieldAccess ? 140 : 120}}>
                                            <FormLabel>{t('expires')}</FormLabel>
                                        </Grid>
                                        <Grid item sx={{width: 170}}>
                                            <FormLabel>{t('bin')}</FormLabel>
                                        </Grid>
                                        {row.itemSel != null && row.itemSel.lotTracked &&
                                            <Grid item sx={{width: fieldAccess ? 130 : 110}}>
                                                <FormLabel>{t('containerType')}</FormLabel>
                                            </Grid>
                                        }
                                        {this.state.type === this.props.auth.constants.receiptTypes.return && 
                                            <Grid item sx={{width: 210}}>
                                                <FormLabel>{t('shippedLot')}</FormLabel>
                                            </Grid>
                                        }
                                    </Grid>
                                }
                                {row.lines != null && row.lines.length > 0 && row.lines.map((line, k) =>
                                    <Aux key={'il' + i + 'a' + k}>
                                        {!fieldAccess &&
                                            <Grid container spacing={3} key={'il' + i + k} sx={{pb: 2}}>
                                                {!fieldAccess && (this.state.status === this.props.auth.constants.statuses.reviewed ||
                                                this.state.status === this.props.auth.constants.statuses.signed) && row.itemSel.lotTracked &&
                                                    <Aux>
                                                        {line.lotSel != null && line.containerSel != null &&
                                                            <Grid item sx={{width: 290}}>
                                                                <Grid container>
                                                                    <Grid item>
                                                                        <Link to={'/lot/' + line.lotSel.value}>{line.lotSel.label}</Link>&nbsp;/&nbsp;
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {hasDevices &&
                                                                            <LabelPrint
                                                                                containerData={this.state.outputLabels != null && this.state.outputLabels.length > 0 ?
                                                                                               this.state.outputLabels.find(x => x.id === line.container) : null}
                                                                                printAmount='single'
                                                                            />
                                                                        }
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Link to={'/container/' + line.containerSel.value}>{line.containerSel.label}</Link>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        {line.lotSel != null && line.containerSel == null &&
                                                            <Grid item sx={{width: 290}}>
                                                                <Link to={'/lot/' + line.lotSel.value}>{line.lotSel.label}</Link>&nbsp;/
                                                            </Grid>
                                                        }
                                                        {line.lotSel == null && line.containerSel != null &&
                                                            <Grid item sx={{width: 290}}>
                                                                &nbsp;/&nbsp;<Link to={'/container/' + line.containerSel.value}>{line.containerSel.label}</Link>
                                                            </Grid>
                                                        }
                                                        {line.lotSel == null && line.containerSel == null && (line.customLot == null || line.customLot === '') &&
                                                            <Grid item sx={{width: 290}}/>
                                                        }
                                                        {line.lot == null && line.customLot != null && line.customLot !== '' &&
                                                            <Grid item sx={{width: 290}}>
                                                                {line.customLot}
                                                            </Grid>
                                                        }
                                                    </Aux>
                                                }
                                                <Grid item sx={{width: 110}} align="center">
                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                        value={line.quantity != null && line.quantity !== '' ? line.quantity : 0}
                                                        suffix={row.uomSel != null ? row.uomSel.label : ''}
                                                    />
                                                </Grid>
                                                {row.itemSel != null && row.itemSel.hasCount === true &&
                                                    <Grid item sx={{width: 100}} align="center">
                                                        <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                            value={line.count != null && line.count !== '' ? line.count : 0}
                                                        />
                                                    </Grid>
                                                }
                                                {this.state.status === this.props.auth.constants.statuses.complete && row.itemSel.lotTracked &&
                                                    <Aux>
                                                        <Grid item sx={{width: 60}}>
                                                            <CheckboxField
                                                            checked={line.newLot} onChange={this.changeSubLineBool('newLot', i, k)}
                                                            name="newLot" disabled={!fieldAccess}
                                                            />
                                                        </Grid>
                                                        {row.itemSel.customNumber === true && line.newLot === true &&
                                                            <Grid item sx={{width: 170}}>
                                                                {line.customLot}
                                                            </Grid>
                                                        }
                                                        {line.newLot !== true && line.lotSel != null &&
                                                            <Grid item sx={{width: 170}}>
                                                                <Link to={'/lot/' + line.lotSel.value}>{line.lotSel.label}</Link>
                                                            </Grid>
                                                        }
                                                        {line.newLot !== true && line.containerSel != null &&
                                                            <Grid item sx={{width: 290}}>
                                                                <Link to={'/container/' + line.containerSel.value}>{line.containerSel.label}</Link>
                                                            </Grid>
                                                        }
                                                    </Aux>
                                                }
                                                {row.itemSel != null && row.itemSel.discreteWeight &&
                                                    <Grid item sx={{width: 110}}>
                                                        <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                            value={line.totalWeight != null && line.totalWeight !== '' ? line.totalWeight : 0}
                                                        />
                                                    </Grid>
                                                }
                                                {this.state.type !== this.props.auth.constants.receiptTypes.return &&
                                                    <Grid item sx={{width: 110}}>
                                                        {line.vendorLot != null ? line.vendorLot : ''}
                                                    </Grid>
                                                }
                                                <Grid item sx={{width: fieldAccess ? 140 : 120}}>
                                                    {line.lotExpiration != null && line.lotExpiration !== '' &&
                                                        <DateDisplay value={line.lotExpiration} format={this.props.auth.user.dateFormat}/>
                                                    }
                                                </Grid>
                                                {line.binSel != null &&
                                                    <Grid item sx={{width: 170}}>
                                                        {line.binSel != null &&
                                                            <Link to={'/bin/' + line.binSel.value}>{line.binSel.label}</Link>
                                                        }
                                                    </Grid>
                                                }
                                                {row.itemSel != null && row.itemSel.lotTracked &&
                                                    <Grid item sx={{width: fieldAccess ? 150 : 110}}>
                                                        {line.containerTypeSel != null ? line.containerTypeSel.label : ''}
                                                    </Grid>
                                                }
                                                {line.shippedLotSel != null && 
                                                    <Grid item sx={{width: 210}}>
                                                        <Link to={'/lot/' + line.shippedLotSel.value}>{line.shippedLotSel.label}</Link>
                                                    </Grid>
                                                }
                                            </Grid>
                                        }
                                        {fieldAccess &&
                                            <Aux key={'illa' + i + k}>
                                                <Grid container spacing={3} key={'ill' + i + k}>
                                                    <Grid item sx={{width: 110}}>
                                                        <TextField type="number" value={line.quantity} onChange={this.changeSubLineNumber('quantity', i, k)} name={"quantity" + i + 'a' + k}
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} required
                                                        error={errors[`${t('quantity')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('quantity')} (${t('line')} ${i}, ${k})`]}/>
                                                    </Grid>
                                                    <Grid item sx={{width: 60}}>
                                                        <CheckboxField
                                                        checked={line.newLot} onChange={this.changeSubLineBool('newLot', i, k)}
                                                        name="newLot" disabled={!fieldAccess}
                                                        />
                                                    </Grid>
                                                    {row.itemSel != null && row.itemSel.lotTracked &&
                                                    ((row.itemSel.customNumber && (line.newLot === true || (row.lots == null || row.lots.length === 0))) ||
                                                     (row.lots != null && row.lots.length > 0 && line.newLot !== true)) &&
                                                        <Grid item sx={{width: 170}}>
                                                            {row.itemSel.customNumber && (line.newLot === true || (row.lots == null || row.lots.length === 0)) &&
                                                                <TextField type="text" value={line.customLot} onChange={this.changeSubLineValue('customLot', i, k)} name={"customLot" + i + 'a' + k}
                                                                size="medium" fullWidth={true} disabled={!fieldAccess} required
                                                                error={errors[`${t('customLot')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('customLot')} (${t('line')} ${i}, ${k})`]}/>
                                                            }
                                                            {row.lots != null && row.lots.length > 0 && line.newLot !== true &&
                                                                <AutoCompleteField
                                                                    value={line.lotSel}
                                                                    options={row.lots}
                                                                    onChange={this.changeSubLineAuto('lotSel', 'lot', i, k)}
                                                                    error={errors[`${t('lot')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                    helperText={errors[`${t('lot')} (${t('line')} ${i}, ${k})`]}
                                                                    disabled={!fieldAccess}
                                                                />
                                                            }
                                                        </Grid>
                                                    }
                                                    {row.itemSel != null && row.lots != null && line.containers != null && row.lots.length > 0 && line.newLot !== true &&
                                                        <Grid item sx={{width: 170}}>
                                                            <AutoCompleteField
                                                                value={line.containerSel}
                                                                options={line.containers}
                                                                onChange={this.changeSubLineAuto('containerSel', 'container', i, k)}
                                                                error={errors[`${t('container')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                helperText={errors[`${t('container')} (${t('line')} ${i}, ${k})`]}
                                                                disabled={!fieldAccess}
                                                            />
                                                        </Grid>
                                                    }
                                                    {row.itemSel != null && row.itemSel.hasCount &&
                                                        <Grid item sx={{width: 100}}>
                                                            <TextField type="number" value={line.count} onChange={this.changeSubLineNumber('count', i, k)} name={"count" + i + 'a' + k}
                                                            size="medium" fullWidth={true} disabled={!fieldAccess} required
                                                            error={errors[`${t('lot')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('lot')} (${t('line')} ${i}, ${k})`]}/>
                                                        </Grid>
                                                    }
                                                    {row.itemSel != null && row.itemSel.discreteWeight === true &&
                                                        <Grid item sx={{width: 100}}>
                                                            <TextField type="number" value={line.totalWeight} onChange={this.changeSubLineNumber('totalWeight', i, k)} name={"totalWeight" + i + 'a' + k}
                                                            size="medium" fullWidth={true} disabled={!fieldAccess} required
                                                            error={errors[`${t('totalWeight')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('totalWeight')} (${t('line')} ${i}, ${k})`]}/>
                                                        </Grid>
                                                    }
                                                    {this.state.type !== this.props.auth.constants.receiptTypes.return &&
                                                        <Grid item sx={{width: 110}}>
                                                            <TextField value={line.vendorLot} onChange={this.changeSubLineValue('vendorLot', i, k)} name={"vendorLot" + i + 'a' + k}
                                                            size="medium" fullWidth={true} disabled={!fieldAccess}
                                                            error={errors[`${t('vendorLot')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('vendorLot')} (${t('line')} ${i}, ${k})`]}/>
                                                        </Grid>
                                                    }
                                                    <Grid item sx={{width: 140}}>
                                                        <DateSelect
                                                            onChange={this.changeSubLineDate('lotExpiration', i, k)}
                                                            value={line.lotExpiration}
                                                            helperText={errors[`${t('lotExpiration')} (${t('line')} ${i}, ${k})`]}
                                                            error={errors[`${t('lotExpiration')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                            disabled={!fieldAccess}
                                                            format={this.props.auth.user.dateFormat}
                                                        />
                                                    </Grid>
                                                        <Grid item sx={{width: 170}}>
                                                            <AutoCompleteField
                                                                value={line.binSel}
                                                                options={this.state.bins}
                                                                onChange={this.changeSubLineAuto('binSel', 'bin', i, k)}
                                                                error={errors[`${t('bin')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                helperText={errors[`${t('bin')} (${t('line')} ${i}, ${k})`]}
                                                                disabled={!fieldAccess}
                                                                required
                                                                groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                                noneMessage={t('noRoomSelected')}
                                                            />
                                                        </Grid>
                                                    {row.itemSel != null && row.itemSel.lotTracked &&
                                                        <Grid item sx={{width: 130}}>
                                                            <AutoCompleteField
                                                                value={line.containerTypeSel}
                                                                options={this.state.containerTypes}
                                                                onChange={this.changeSubLineAuto('containerTypeSel', 'containerType', i, k)}
                                                                error={errors[`${t('containerType')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                helperText={errors[`${t('containerType')} (${t('line')} ${i}, ${k})`]}
                                                                disabled={!fieldAccess}
                                                            />
                                                        </Grid>
                                                    }
                                                    {this.state.type === this.props.auth.constants.receiptTypes.return &&
                                                        <Grid item sx={{width: 210}}>
                                                            <AutoCompleteField
                                                                value={line.shippedLotSel}
                                                                options={row.shippedLots}
                                                                onChange={this.changeSubLineAuto('shippedLotSel', 'shippedLot', i, k)}
                                                                error={errors[`${t('shippedLot')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                helperText={errors[`${t('shippedLot')} (${t('line')} ${i}, ${k})`]}
                                                                disabled={!fieldAccess}
                                                            />
                                                        </Grid>
                                                    }
                                                    <Grid item sx={{width: 50}}>
                                                        {line.add === true &&
                                                            <IconButton aria-label="add line" onClick={this.addContentRow(i, k)}>
                                                                <AddBoxTwoToneIcon  />
                                                            </IconButton>
                                                        }
                                                        {line.add !== true &&
                                                            <IconButton aria-label="delete line" onClick={this.deleteContentRow(i, k)}>
                                                                <RemoveCircleTwoToneIcon  />
                                                            </IconButton>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Aux>
                                        }
                                    </Aux>
                                )}
                            </Aux>
                        </Box>
                    )}
                    {!locked && fieldAccess &&
                        <Box borderColor="primary.main" {...defaultProps} component={'div'}>
                            <Grid container spacing={3}>
                                <Grid item sx={{width: 350}}>
                                    <AutoCompleteField
                                        value={this.state.itemSel}
                                        options={this.state.items}
                                        onChange={this.changeAuto('itemSel', 'item')}
                                        error={errors[t('item')] != null ? true : false}
                                        helperText={errors[t('item')]}
                                        disabled={!fieldAccess}
                                        required
                                        label={t('item')}
                                        data-cy='item'
                                    />
                                </Grid>
                                {this.state.itemSel != null && this.state.itemSel.lotTracked === true && this.state.itemSel.customNumber &&
                                    <Grid item sx={{width: 150}}>
                                        <TextField type="text" value={this.state.customLot} onChange={this.changeValue} name={"customLot"}
                                        size="medium" fullWidth={true} disabled={!fieldAccess}
                                        label={t('customLot')} data-cy='customLot'
                                        error={errors[t('customLot')] != null ? true : false} helperText={errors[t('customLot')]}/>
                                    </Grid>
                                }
                                {this.state.lots != null && this.state.lots.length > 0 && this.state.newLot !== true &&
                                    <Grid item sx={{width: 150}}>
                                        <AutoCompleteField
                                            value={this.state.lotSel}
                                            options={this.state.lots}
                                            onChange={this.changeAuto('lotSel', 'lot')}
                                            error={errors[t('lot')] != null ? true : false}
                                            helperText={errors[t('lot')]}
                                            disabled={!fieldAccess}
                                            required
                                            label={t('lot')}
                                            data-cy='lot'
                                        />
                                    </Grid>
                                }
                                
                                {this.state.containers != null && this.state.containers.length > 0 &&
                                    <Grid item sx={{width: 150}}>
                                        <AutoCompleteField
                                            value={this.state.containerSel}
                                            options={this.state.containers}
                                            onChange={this.changeAuto('containerSel', 'container')}
                                            error={errors[t('container')] != null ? true : false}
                                            helperText={errors[t('container')]}
                                            disabled={!fieldAccess}
                                            required
                                            label={t('container')}
                                            data-cy='container'
                                        />
                                    </Grid>
                                }
                                <Grid item sx={{width: 150}}>
                                    <TextField type="number" value={this.state.quantity} onChange={this.changeNumber} name={"quantity"}
                                    size="medium" fullWidth={true} disabled={!fieldAccess} required label={t('quantity')} data-cy='quantity'
                                    error={errors[t('quantity')] != null ? true : false} helperText={errors[t('quantity')]}/>
                                </Grid>
                                <Grid item sx={{width: 50}}>
                                    <FormControl>
                                        <FormLabel>{t('uom')}</FormLabel>
                                        {this.state.uomSel != null ? this.state.uomSel.label : ''}
                                    </FormControl>
                                </Grid>
                                {this.state.hasCount &&
                                    <Grid item sx={{width: 100}}>
                                        <TextField type="number" value={this.state.count} onChange={this.changeNumber} name={"count"} label={t('count')}
                                        size="medium" fullWidth={true} disabled={!fieldAccess || this.state.itemSel == null || !this.state.itemSel.hasCount}
                                        required data-cy='count'
                                        error={errors[t('count')] != null ? true : false} helperText={errors[t('count')]}/>
                                    </Grid>
                                }
                                {this.state.type !== this.props.auth.constants.receiptTypes.return &&
                                    <Grid item sx={{width: 150}}>
                                        <TextField value={this.state.vendorLot} onChange={this.changeValue} name={"vendorLot"}
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('vendorLot')} data-cy='vendorLot'
                                        error={errors[t('vendorLot')] != null ? true : false} helperText={errors[t('vendorLot')]}/>
                                    </Grid>
                                }
                                <Grid item sx={{width: 140}}>
                                    <DateSelect
                                        onChange={this.changeDate('lotExpiration')}
                                        value={this.state.lotExpiration}
                                        helperText={errors[t('lotExpiration')]}
                                        error={errors[t('lotExpiration')] != null ? true : false}
                                        disabled={!fieldAccess}
                                        format={this.props.auth.user.dateFormat}
                                        label={t('expires')}
                                        data-cy='lotExpiration'
                                    />
                                </Grid>
                                <Grid item sx={{width: 250}}>
                                    <AutoCompleteField
                                        value={this.state.binSel}
                                        options={this.state.bins}
                                        onChange={this.changeAuto('binSel', 'bin')}
                                        error={errors[t('bin')] != null ? true : false}
                                        helperText={errors[t('bin')]}
                                        disabled={!fieldAccess}
                                        required
                                        label={t('bin')}
                                        data-cy='bin'
                                        groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                        noneMessage={t('noRoomSelected')}
                                    />
                                </Grid>
                                {this.state.itemSel != null && this.state.itemSel.lotTracked === true &&
                                    <Grid item sx={{width: 150}}>
                                        <AutoCompleteField
                                            value={this.state.containerTypeSel}
                                            options={this.state.containerTypes}
                                            onChange={this.changeAuto('containerTypeSel', 'containerType')}
                                            error={errors[t('containerType')] != null ? true : false}
                                            helperText={errors[t('containerType')]}
                                            disabled={!fieldAccess}
                                            required
                                            label={t('containerType')}
                                            data-cy='containerType'
                                        />
                                    </Grid>
                                }
                                <Grid item sx={{width: 140}}>
                                    <CheckboxField
                                    checked={this.state.newLot} onChange={this.changeBool('newLot')}
                                    label={t('newLot')} name="newLot" disabled={!fieldAccess} data-cy='newLot'
                                    />
                                </Grid>
                                {this.state.itemSel != null && this.state.itemSel.discreteWeight &&
                                    <Grid item sx={{width: 125}}>
                                        <TextField type="number" value={this.state.totalWeight} onChange={this.changeNumber} name={"totalWeight"}
                                        size="medium" fullWidth={true} disabled={!fieldAccess}
                                        label={t('fullDiscreteG')} data-cy='totalWeight'
                                        error={errors[t('totalWeight')] != null ? true : false} helperText={errors[t('totalWeight')]}/>
                                    </Grid>
                                }
                                {this.state.type === this.props.auth.constants.receiptTypes.return && this.state.itemSel != null && this.state.shippedLots != null && this.state.shippedLots.length > 0 &&
                                     <Grid item sx={{width: 200}}>
                                        <AutoCompleteField
                                            value={this.state.shippedLotSel}
                                            options={this.state.shippedLots}
                                            onChange={this.changeAuto('shippedLotSel', 'shippedLot')}
                                            error={errors[t('shippedLot')] != null ? true : false}
                                            helperText={errors[t('shippedLot')]}
                                            disabled={!fieldAccess}             
                                            label={t('shippedLot')}
                                            data-cy='shippedLot'
                                        />
                                    </Grid>
                                }
                                <Grid item sx={{width: 50}}>
                                    <IconButton aria-label="add line" onClick={this.addLine} data-cy='addLine'>
                                        <AddBoxTwoToneIcon  />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    <br/>
                    <FormButtonStack
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.receipt}
                        callback={this.loadRecord}
                        saveClick={() => this.save()}
                        status={this.state.status}
                        permissions={this.props.permission}
                        common={this.props.auth.common}
                        constants={this.props.auth.constants}
                        saveEnabled={this.state.enableSave}
                        deleteClick={this.deleteToggle}
                        enableUnlockProp={this.state.enableUnlock}
                        activityDate={this.state.date}
                        lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                        isSuper={this.props.auth.user.isSuper}
                    />
                </TabPanel>
                {this.state._id != null && this.state._id !== '' && this.state.sopsSel != null &&
                    this.state.sopsSel.length > 0 && this.state.sopsSel.map((sop, i) =>
                    <TabPanel value={this.state.tabKey} index={i + 1}>
                        <br/>
                        <ActivityWorkRecord users={this.state.users} sop={sop.value} id={this.state.sops[i].workRecord} tab={this.state.tabKey}
                        objectType={this.props.auth.constants.objectIds.receipt} recordId={this.state._id} date={this.state.date}
                        locationId={this.state.location} renderNum={this.state.renderNum} complete={this.loadRecord}/>
                    </TabPanel>
                )}
                {this.state.hasLedgers &&
                    <TabCanvas value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                        <ItemLedgerComponent record={this.state._id} permission={this.props.permission} auth={this.props.auth} renderNum={this.state.renderNum}/>
                    </TabCanvas>
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.receipt}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    devices: state.devices,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Receipt));
