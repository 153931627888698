import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
// import isEmpty from '../../is-empty';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { NumericFormat } from 'react-number-format';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import common from '../../jsons/common.json';
import Paper from '@mui/material/Paper';
import Aux from '../../hoc/Auxilary/Auxilary';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import Panel from '../UI/Panel/Panel';
import Link from '../UI/Link/Link';
import ActionBar from '../UI/Buttons/ActionBar';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ValueHelper from '../../helpers/valueHelper';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { CSVDownload } from "react-csv";
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ConversionHelper from '../../helpers/conversionHelper';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import SectionTitle from '../UI/Typography/SectionTitle';

class PastInventory extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            record: {},
            items: [],
            totalCount: 0,
            listSize: 10,
            orderBy: 'item',
            ordDir: 1,
            offset: 0,
            rowsPerPage: 10,
            page: 0,
            category: '',
            categorySel: null,
            categories: [],
            hcReportCategory: '',
            hcReportCategorySel: null,
            hcCategories: [],
            csvSeparator: ',',
            csvData: null,
            csvHeaders: null,
            tabKey: 0,
            tabLabels: [],
            hcListSize: 10,
            catListSize: 10,
            hcOffset: 0,
            catOffset: 0,
            hcCats: [],
            cats: [],
            hcPage: 0,
            catPage: 0
        };
    }
    
    componentDidMount = async () => {
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.itemCategory, common.cacheValues.hcReportCategory],
            this.props.auth.constants, null, this.props.auth.user.tenant
        );
        const crumbs = [
            { path: '/#/pastinvetories', label: this.props.t('pastInventoryList'), screen: this.props.auth.screenDefs.PastInventoryList},
            { path: 'active', label: this.props.t('pastInventory'), screen: this.props.auth.screenDefs.PastInventory}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        this.setState({
            security: this.props.permission,
            categories: cacheValues.itemCategories,
            hcCategories: cacheValues.hcReportCategories,
            csvSeparator: sessionStorage.getItem('csvSeparator'),
            tabLabels: [{name: this.props.t('categories'), disabled: false}, {name: this.props.t('items'), disabled: false}]
        }, () => {            
            this.loadRecord();
        });
    }

    loadRecord = async () => {
        let result = await axios.get('/api/tenanttotal/' + this.props.match.params.id);
        this.setState({
            _id: this.props.match.params.id,
            record: result.data.record,
        }, () => {
            const crumbs = [
                { path: '/#/pastinventories', label: this.props.t('pastInventoryList'), screen: this.props.auth.screenDefs.PastInventoryList},
                { path: 'active', label: this.props.t('pastInventory') + ' - ' + result.data.record.number, screen: this.props.auth.screenDefs.PastInventory}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadItems();
            this.setHcList();
            this.setCatList();
        });
    }

    loadItems = async () => {
        let columnFilters = [];
        if(this.state.category != null && this.state.category !== ''){
            columnFilters.push({id: 'category', value: this.state.category});
        }
        if(this.state.hcReportCategory != null && this.state.hcReportCategory !== ''){
            columnFilters.push({id: 'hcReportCategory', value: this.state.hcReportCategory});
        }
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        }
        let result = await axios.get('/api/tenanttotalitems/' + this.state._id, {params: params});
        this.setState({items: result.data.list, totalCount: result.data.totalCount});
    }

    setHcList = () => {
        let hcCats = this.state.record.reportCategories.slice(this.state.hcOffset, this.state.hcOffset + this.state.hcListSize);
        this.setState({hcCats: hcCats});
    }

    setCatList = () => {
        let cats = this.state.record.categories.slice(this.state.catOffset, this.state.catOffset + this.state.catListSize);
        this.setState({cats: cats});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({[name]: newValue, [data]: newValue != null ? newValue.value : null, page: 0, offset: 0}, () => {
            this.loadItems();
        });
    }

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadItems();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), offset: 0}, () => {
            this.loadItems();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: this.state.listSize * newPage}, () => {
            this.loadItems();
        });
    };

    handleHCRowsPerPage = e => {
        this.setState({hcListSize: parseInt(e.target.value), offset: 0}, () => {
            this.setHcList();
        });
    };

    handleHCChangePage = (event, newPage) => {
        this.setState({hcPage: newPage, hcOffset: this.state.hcListSize * newPage}, () => {
            this.setHcList();
        });
    };

    handleCatRowsPerPage = e => {
        this.setState({catListSize: parseInt(e.target.value), offset: 0}, () => {
            this.setCatList();
        });
    };

    handleCatChangePage = (event, newPage) => {
        this.setState({catPage: newPage, catOffset: this.state.catListSize * newPage}, () => {
            this.setCatList();
        });
    };

    csvClick = async () => {
        let columnFilters = [];
        if(this.state.category != null && this.state.category !== ''){
            columnFilters.push({id: 'category', value: this.state.category});
        }
        if(this.state.hcReportCategory != null && this.state.hcReportCategory !== ''){
            columnFilters.push({id: 'hcReportCategory', value: this.state.hcReportCategory});
        }
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting)
        }
        let result = await axios.get('/api/tenanttotalitemscsv/' + this.state._id, {params: params});
        this.setState({csvData: result.data.data, headers: result.data.headers});
    }

    lotCsv = async () => {
        this.setState({csvData: null, csvHeaders: null}, async () => {
            let result = await axios.get('/api/tenanttotallots/' + this.state._id);
            this.setState({csvData: result.data.data, csvHeaders: result.data.headers});
        });
    }

    hcCsvClick = () => {
        const t = this.props.t;
        this.setState({csvData: null, csvHeaders: null}, () => {
            let hcCats = this.state.record.reportCategories;
            let csvData = [];
            for(let row of hcCats){
                csvData.push({
                    category: row.hcReportCategory.label,
                    quantity: ConversionHelper.round2(row.quantity),
                    count: Math.round(row.count),
                    thc: ConversionHelper.round2(row.thc),
                    cannabis: ConversionHelper.round2(row.cannabis),
                    packages: Math.round(row.packages)
                });
            }
            let headers = [
                {label: t('category'), key: 'category'},
                {label: t('quantity'), key: 'quantity'},
                {label: t('count'), key: 'count'},
                {label: t('thc'), key: 'thc'},
                {label: t('cannabis'), key: 'cannabis'},
                {label: t('packages'), key: 'packages'}
            ];
            this.setState({csvData: csvData, csvHeaders: headers});
        });
    }

    catCsvClick = () => {
        const t = this.props.t;
        this.setState({csvData: null, csvHeaders: null}, () => {
            let cats = this.state.record.categories;
            let csvData = [];
            for(let row of cats){
                csvData.push({
                    category: row.category.description,
                    hcReportCategory: row.hcReportCategory != null ? row.hcReportCategory.label : '',
                    quantity: ConversionHelper.round2(row.quantity),
                    count: Math.round(row.count),
                    thc: ConversionHelper.round2(row.thc),
                    cannabis: ConversionHelper.round2(row.cannabis),
                    packages: Math.round(row.packages)
                });
            }
            let headers = [
                {label: t('category'), key: 'category'},
                {label: t('hcReportCategory'), key: 'hcReportCategory'},
                {label: t('quantity'), key: 'quantity'},
                {label: t('count'), key: 'count'},
                {label: t('thc'), key: 'thc'},
                {label: t('cannabis'), key: 'cannabis'},
                {label: t('packages'), key: 'packages'}
            ];
            this.setState({csvData: csvData, csvHeaders: headers});
        });
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    render() {
        const { t } = this.props;
        return (
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl data-cy='number'>
                                <FormLabel>{t('number')}</FormLabel>
                                {this.state.record.number}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl data-cy='date'>
                                <FormLabel>{t('date')}</FormLabel>
                                    <DateDisplay value={this.state.record.date} format={this.props.auth.user.dateFormat} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl data-cy='created'>
                                <FormLabel>{t('created')}</FormLabel>
                                    <DateDisplay value={this.state.record.created} format={this.props.auth.user.dateFormat} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl data-cy='type'>
                                <FormLabel>{t('type')}</FormLabel>
                                    {this.state.record.usePostingDate ? t('postingDate') : t('lastUpdated')}
                            </FormControl>
                        </Grid>
                    </Grid> 
                    <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                    <TabPanel value={this.state.tabKey} index={0}>
                        <SectionTitle title={t('hcReportCategories')} />
                        <TableContainer component={Paper}>
                            <Table aria-label="HC Report Categories">
                                <TableHead>
                                    <TableRow key="hcCatHead">
                                        <TableCell>{t('category')}</TableCell>
                                        <TableCell align="right">{t('quantity')}</TableCell>
                                        <TableCell align='right'>{t('count')}</TableCell>
                                        <TableCell align="right">{t('thc')}</TableCell>
                                        <TableCell align="right">{t('cannabis')}</TableCell>
                                        <TableCell align="right">{t('packages')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.hcCats != null && this.state.hcCats.map((row, index) =>
                                        <TableRow key={'hcCat' + index}>
                                            <TableCell>{row.hcReportCategory.label}</TableCell>
                                            <TableCell align="right"><NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                                decimalScale={2} fixedDecimalScale={true}/>
                                            </TableCell>
                                            <TableCell align="right"><NumericFormat displayType={'text'} value={row.count} thousandSeparator={true}
                                                decimalScale={0} fixedDecimalScale={true}/>
                                            </TableCell>
                                            <TableCell align="right"><NumericFormat displayType={'text'} value={row.thc} thousandSeparator={true}
                                                decimalScale={2} fixedDecimalScale={true}/>
                                            </TableCell>
                                            <TableCell align="right"><NumericFormat displayType={'text'} value={row.cannabis} thousandSeparator={true}
                                                decimalScale={2} fixedDecimalScale={true}/>
                                            </TableCell>
                                            <TableCell align="right"><NumericFormat displayType={'text'} value={row.packages} thousandSeparator={true}
                                                decimalScale={0} fixedDecimalScale={true}/>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow key="hcFoot">
                                        {this.state.record.reportCategories != null && this.state.record.reportCategories.length > 0 &&
                                            <Aux>
                                                <Tooltip title={t('exportToCsv')} >
                                                    <IconButton
                                                        disabled={false}
                                                        component={'div'}
                                                        onClick={this.hcCsvClick}
                                                        name='actionCsv'
                                                        color="primary"
                                                    >
                                                        <ExcelIcon fontSize='medium'/>
                                                        </IconButton>
                                                </Tooltip>
                                                <TablePagination count={this.state.record.reportCategories.length}
                                                rowsPerPage={this.state.hcListSize}
                                                onPageChange={this.handleHCChangePage}
                                                page = {this.state.hcPage}
                                                rowsPerPageOptions={common.rowOptions}
                                                onRowsPerPageChange={this.handleHCRowsPerPage}/>
                                            </Aux>
                                        }
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        <br/>
                        <SectionTitle title={t('itemCategories')} />
                        <TableContainer component={Paper}>
                            <Table aria-label="Item Categories">
                                <TableHead>
                                    <TableRow key="catHead">
                                        <TableCell>{t('category')}</TableCell>
                                        <TableCell>{t('hcReportCategory')}</TableCell>
                                        <TableCell align="right">{t('quantity')}</TableCell>
                                        <TableCell align='right'>{t('count')}</TableCell>
                                        <TableCell align="right">{t('thc')}</TableCell>
                                        <TableCell align="right">{t('cannabis')}</TableCell>
                                        <TableCell align="right">{t('packages')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.cats != null && this.state.cats.map((row, index) =>
                                        <TableRow key={'category' + index}>
                                            <TableCell>{row.category.description}</TableCell>
                                            <TableCell>{row.hcReportCategory != null ? row.hcReportCategory.label : ''}</TableCell>
                                            <TableCell align="right">
                                                <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                            </TableCell>
                                            <TableCell align="right"><NumericFormat displayType={'text'} value={row.count} thousandSeparator={true}
                                                decimalScale={0} fixedDecimalScale={true}/>
                                            </TableCell>
                                            <TableCell align="right"><NumericFormat displayType={'text'} value={row.thc} thousandSeparator={true}
                                                decimalScale={2} fixedDecimalScale={true}/>
                                            </TableCell>
                                            <TableCell align="right"><NumericFormat displayType={'text'} value={row.cannabis} thousandSeparator={true}
                                                decimalScale={2} fixedDecimalScale={true}/>
                                            </TableCell>
                                            <TableCell align="right"><NumericFormat displayType={'text'} value={row.packages} thousandSeparator={true}
                                                decimalScale={0} fixedDecimalScale={true}/>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow key="hcFoot">
                                        {this.state.record.categories != null && this.state.record.categories.length > 0 &&
                                            <Aux>
                                                <Tooltip title={t('exportToCsv')} >
                                                    <IconButton
                                                        disabled={false}
                                                        component={'div'}
                                                        onClick={this.catCsvClick}
                                                        name='actionCsv'
                                                        color="primary"
                                                    >
                                                        <ExcelIcon fontSize='medium'/>
                                                        </IconButton>
                                                </Tooltip>
                                                <TablePagination count={this.state.record.categories.length}
                                                rowsPerPage={this.state.catListSize}
                                                onPageChange={this.handleCatChangePage}
                                                page = {this.state.catPage}
                                                rowsPerPageOptions={common.rowOptions}
                                                onRowsPerPageChange={this.handleCatRowsPerPage}/>
                                            </Aux>
                                        }
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={this.state.tabKey} index={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                value={this.state.categorySel}
                                options={this.state.categories}
                                onChange={this.changeAuto('categorySel', 'category')}
                                label={t('category')}
                            />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                    value={this.state.hcReportCategorySel}
                                    options={this.state.hcCategories}
                                    onChange={this.changeAuto('hcReportCategorySel', 'hcReportCategory')}
                                    label={t('reportCategory')}
                                />
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table aria-label="Past Inventory">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('item')} active={this.state.orderBy === 'item'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>
                                                {t('item')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('quantity')} active={this.state.orderBy === 'quantity'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>
                                                {t('quantity')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>{t('uom')}</TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('category')} active={this.state.orderBy === 'category'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>
                                                {t('category')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('hcReportCategory')} active={this.state.orderBy === 'hcReportCategory'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>
                                                {t('reportCategory')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableSortLabel onClick={() => this.sortClick('count')}
                                            active={this.state.orderBy === 'count'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('count')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableSortLabel onClick={() => this.sortClick('thc')}
                                            active={this.state.orderBy === 'thc'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('thc')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableSortLabel onClick={() => this.sortClick('cannabis')}
                                            active={this.state.orderBy === 'cannabis'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('cannabis')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableSortLabel onClick={() => this.sortClick('packages')}
                                            active={this.state.orderBy === 'packages'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('packages')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>{t('lots')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.items.map((item, index) => 
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Link to={'/item/' + item.item._id}>{item.item.number + '-' + item.item.name}</Link>
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumericFormat displayType={'text'} value={item.quantity} thousandSeparator={true}
                                                decimalScale={2} fixedDecimalScale={true}/>
                                            </TableCell>
                                            <TableCell>
                                                {item.uom != null ? item.uom : ''}
                                            </TableCell>
                                            <TableCell>
                                                {item.category != null ? item.category.description : ''}
                                            </TableCell>
                                            <TableCell>
                                                {item.hcReportCategory != null ? item.hcReportCategory.label : ''}
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumericFormat displayType={'text'} value={item.count} thousandSeparator={true}
                                                decimalScale={0}/>
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumericFormat displayType={'text'} value={item.thc} thousandSeparator={true}
                                                decimalScale={2}/>
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumericFormat displayType={'text'} value={item.cannabis} thousandSeparator={true}
                                                decimalScale={2}/>
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumericFormat displayType={'text'} value={item.packages} thousandSeparator={true}
                                                decimalScale={0}/>
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumericFormat displayType={'text'} value={item.lots} thousandSeparator={true}
                                                decimalScale={0}/>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow key="transFoot">
                                        {this.state.items != null && this.state.items.length > 0 &&
                                            <Aux>
                                                <Tooltip title={t('exportToCsv')} >
                                                    <IconButton
                                                        disabled={false}
                                                        component={'div'}
                                                        onClick={this.csvClick}
                                                        name='actionCsv'
                                                        color="primary"
                                                    >
                                                        <ExcelIcon fontSize='medium'/>
                                                        </IconButton>
                                                </Tooltip>
                                                <Tooltip title={t('exportToCsv')} >
                                                    <Button
                                                        disabled={false}
                                                        component={'div'}
                                                        onClick={this.lotCsv}
                                                        name='lotCsv'
                                                        color="primary"
                                                    >
                                                        {t('lots')}
                                                    </Button>
                                                </Tooltip>
                                                <TablePagination count={this.state.totalCount}
                                                rowsPerPage={this.state.listSize}
                                                onPageChange={this.handleChangePage}
                                                page = {this.state.page}
                                                rowsPerPageOptions={common.rowOptions}
                                                onRowsPerPageChange={this.handleRowsPerPage}/>
                                            </Aux>
                                        }
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    {this.state.csvData != null &&
                        <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.csvHeaders} separator={this.state.csvSeparator}/>
                    }
                </Panel>
            </Aux>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile
});

export default withTranslation() (connect(mapStateToProps)(PastInventory));