import React, { useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material/';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ProcessingIcon from '@mui/icons-material/PrecisionManufacturing';
import FacilityIcon from 'mdi-material-ui/Factory';
import InventoryIcon from 'mdi-material-ui/BallotRecount';
import QAIcon from 'mdi-material-ui/CheckboxMultipleMarkedOutline';
import CRMIcon from 'mdi-material-ui/AccountGroupOutline';
import SecurityIcon from 'mdi-material-ui/Security';
import HrManagementIcon from 'mdi-material-ui/AccountCheckOutline';
import ProductionIcon from '@mui/icons-material/AccessTimeTwoTone';
import ReportIcon from '@mui/icons-material/AssessmentTwoTone';
import DashMenuIcon from 'mdi-material-ui/MonitorDashboard';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DocumentsIcon from '@mui/icons-material/FileCopyTwoTone';
import ConfigMenuIcon from '@mui/icons-material/Tune';
import TimelapseTwoToneIcon from '@mui/icons-material/TimelapseTwoTone';
import InvestigationsIcon from '@mui/icons-material/FindInPageTwoTone';
import CultivationIcon from 'mdi-material-ui/Grass';
import SettingsIcon from '@mui/icons-material/SettingsTwoTone';
import ReportingIcon from '@mui/icons-material/BarChartTwoTone';
import Icon from '../../UI/Icons/Icons';
import Aux from '../../../hoc/Auxilary/Auxilary';

function NavMenuCategory(props) {
  return <ListItemText disableTypography primary={<Typography variant="body1" >{props.title}</Typography>} />;
};

function NavNestedCategory(props) {
    return <ListItemText disableTypography primary={<Typography variant="body2" >{props.title}</Typography>} />;
};

function NavDrawerLink(props) {
  return <ListItemText disableTypography primary={<Typography variant="body2" >{props.title}</Typography>} />;
};

function NestedDrawerLink(props) {
  return <ListItemText disableTypography primary={<Typography variant="subtitle2">{props.title}</Typography>} />;
}

export const NavList = (props) => {
  const drawerOpen = {
    backgroundColor: '#eeeeee',
    paddingLeft: '28px',
    "&.Mui-hover": {
      color: 'secondary.main',
      backgroundColor: '#d4d4d4'
    },
    "&.Mui-selected": {
      backgroundColor: '#d4d4d4'
    }
  };
  const drawerClosed = {
    backgroundColor: '#eeeeee',
    "&.Mui-hover": {
      color: 'secondary.main',
      backgroundColor: '#d4d4d4'
    },
    "&.Mui-selected": {
      backgroundColor: '#d4d4d4'
    }
  };
  const nestedCategoryOpen = {
    backgroundColor: '#eeeeee',
    paddingLeft: '28px',
    "&.Mui-hover": {
      color: 'secondary.main',
      backgroundColor: '#d4d4d4'
    },
    "&.Mui-selected": {
      backgroundColor: '#d4d4d4'
    }
  };
  const nestedCategoryClosed = { backgroundColor: '#eeeeee' };
  const nestedDrawerClosed = {
    backgroundColor: '#d4d4d4',
    "&.Mui-hover": {
      color: 'secondary.main',
      backgroundColor: '#d4d4d4'
    },
    "&.Mui-selected": {
      backgroundColor: '#d4d4d4'
    }
  };
  const nestedDrawerOpen = {
    backgroundColor: '#d4d4d4',
    paddingLeft: '35px',
    "&.Mui-hover": {
      color: 'secondary.main',
      backgroundColor: '#d4d4d4'
    },
    "&.Mui-selected": {
      backgroundColor: '#d4d4d4'
    }
  };
  const subMenu = { backgroundColor: '#d4d4d4'};
  const activeMenuIcon = { color: 'secondary.main' };
  const nonActiveMenuIcon = { color: 'primary.main' };

  const { isMobile, open, user } = props;
  const menuState = useSelector(state => state.navMenu);
  const menuOptions = useSelector(state => state.security.menuOptions);
  const auth = useSelector(state => state.auth);
  const screenDefs = useSelector(state => state.auth.screenDefs);
  const dispatch = useDispatch();
  const [ availableMenu, setAvailableMenu ] = useState({
    options: [],
    categories: []
  });
  useLayoutEffect(() => {
    if(menuOptions.length !== 0){
      let categories = [];
      let groups = [];
      menuOptions.forEach(option => {
        if(!groups.includes(option.name)) {
          groups.push(option.name);
          if(!categories.includes(option.category)){
            categories.push(option.category);
          }
        }
      });

      setAvailableMenu({
        options: groups,
        categories: categories
      });
    }
  }, [menuOptions]);

  const handleMenuClick = (name) => {
    if (menuState.openMenu !== name) {
      dispatch({type: "OPEN_NEW_NAVMENU", payload: name});
    }else {
      dispatch({type: "TOGGLE_NAVMENU"});
    }
  };

  const handleSubMenuClick = (name) => {
    if (menuState.openSubMenu !== name) {
      dispatch({type: "OPEN_NEW_NAVSUBMENU", payload: name});
    }else {
      dispatch({type: "TOGGLE_NAVSUBMENU"});
    }
  };

  const handleHomeClick = (e, path) => {
    dispatch({type: "RESET_NAVMENU"});
    buttonClick(e, path);
  }

  const buttonClick = (e, path) => {
    e.preventDefault();
    window.location.replace(path);
  }

  const { t } = useTranslation();
  return (
    <List
      component="nav"
      sx={{ width: '100%', maxWidth: '360px' }}
    >
      {availableMenu.categories.includes('launchPad') && !auth.user.hideLaunchPad &&
        <ListItemButton
          name="launchPad"
          dense={!isMobile}
          onClick={(e) => buttonClick(e, "/#/launchPad")}
          selected={menuState.index === 'launchPad'}
          >
          <ListItemIcon>
            <Icon screenDefs={screenDefs} sx={{ color: 'error.main'}}
                  screenDef={screenDefs.LaunchPad} title={t('launchPad')} placement="right"/>
          </ListItemIcon>
          <NavMenuCategory title={t('launchPad')} />
        </ListItemButton>
      }
      {availableMenu.categories.includes('home') &&
        <ListItemButton
          name="home"
          dense={!isMobile}
          onClick={(e) => handleHomeClick(e, "/#/home")}
          selected={menuState.index === 'home'}
          >
          <ListItemIcon>
            <Icon sx={menuState.index === 'home' && menuState.category === 'home' ?
                             activeMenuIcon : nonActiveMenuIcon}
                  screenDefs={screenDefs} screenDef={screenDefs.Home} title={t('home')} placement="right"/>
          </ListItemIcon>
          <NavMenuCategory title={t('home')} />
        </ListItemButton>
      }
      {availableMenu.categories.includes('dashboard') &&
        <ListItemButton name="dashboard" onClick={() => handleMenuClick('dashboard')}>
          <ListItemIcon>
            <Tooltip title={t('dashboards')} placement="right">
              <DashMenuIcon color={ menuState.category === 'dashboard' ? "secondary" : "primary" } />
            </Tooltip>
          </ListItemIcon>
          <NavMenuCategory title={t('dashboards')} />
          { menuState.openMenu === 'dashboard' && menuState.isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
        </ListItemButton>
      }
      <Collapse in={menuState.openMenu === 'dashboard' && menuState.isOpen ? true : false} timeout="auto" unmountOnExit>
        <List>
          <Aux>
            {availableMenu.options.includes('QADashboard') &&
              <ListItemButton
                dense={!isMobile}
                name="qaDashboard"
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/qadashboard")}
                selected={menuState.index === 'qadashboard'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'qadashboard' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.QADashboard} title={t('qualityAssurance')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('qualityAssurance')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('CultivationDashboard') && auth.user.cultivator &&
              <ListItemButton
                dense={!isMobile}
                name="cultivationDashboard"
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/cultivationdashboard")}
                selected={menuState.index === 'cultivationdashboard'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'cultivationdashboard' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.CultivationDashboard} title={t('cultivation')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('cultivation')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('ProcessingDashboard') &&
              <ListItemButton
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                name="processingDashboard"
                onClick={(e) => buttonClick(e, "/#/processingdashboard")}
                selected={menuState.index === 'processingdashboard'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'processingdashboard' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ProcessingDashboard} title={t('processing')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('processing')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('InventoryDashBoard') &&
                <ListItemButton
                    dense={!isMobile}
                    sx={open ? drawerOpen : drawerClosed}
                    name="inventoryDashboard"
                    onClick={(e) => buttonClick(e, "/#/inventorydashboard")}
                    selected={menuState.index === 'inventoryDashboard'} >
                    <ListItemIcon>
                    <Icon sx={menuState.index === 'inventoryDashboard' ? activeMenuIcon : nonActiveMenuIcon}
                            screenDefs={screenDefs} screenDef={screenDefs.InventoryDashboard} title={t('inventory')} placement="right"/>
                    </ListItemIcon>
                    <NavDrawerLink title={t('inventory')}/>
                </ListItemButton>
            }
          </Aux>
        </List>
      </Collapse>
      {availableMenu.categories.includes('cultivation') && auth.user.cultivator &&
        <ListItemButton name={'cultivationMenu'} onClick={() => handleMenuClick('cultivation')}>
          <ListItemIcon>
            <Tooltip title={t('cultivation')} placement="right">
              <CultivationIcon color={ menuState.category === 'cultivation' ? "secondary" : "primary" } />
            </Tooltip>
          </ListItemIcon>
          <NavMenuCategory title={t('cultivation')}/>
          { menuState.openMenu === 'cultivation' && menuState.isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
        </ListItemButton>
      }
      <Collapse in={menuState.openMenu === 'cultivation' && menuState.isOpen ? true : false} timeout="auto" unmountOnExit>
        <List>
          <Aux>
            {availableMenu.options.includes('CultivationBatchList') &&
              <ListItemButton
                name="cultivationBatches"
                data-cy="cultivationBatches"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/cultivationbatches")}
                selected={menuState.index === 'cultivationbatch'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'cultivationbatch' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.CultivationBatchList} title={t('batches')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('batches')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('BatchActivities') &&
            <ListItemButton
                name="batchActivities"
                data-cy="batchActivities"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/batchactivities")}
                selected={menuState.index === 'batchActivities'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'batchActivities' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.BatchActivities} title={t('batchActivities')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('batchActivities')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('IPMTreatmentList') && !props.user.isMushrooms &&
              <ListItemButton
                  name="ipmTreatments"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/croptreatments")}
                  selected={menuState.index === 'ipmTreatments'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'ipmTreatments' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.IPMTreatmentList} title={t('cropTreatments')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('cropTreatments')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('RoomFeedings') && !props.user.isMushrooms &&
              <ListItemButton
                  name="roomFeedings"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  // component="a"
                  // href="/#/feedinglots"
                  onClick={(e) => buttonClick(e, "/#/roomfeedings")}
                  selected={menuState.index === 'roomFeedings'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'roomFeedings' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.RoomFeeding} title={t('roomFeedings')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('roomFeedings')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('FeedingLotList') && !props.user.isMushrooms &&
              <ListItemButton
                  name="feedingLots"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/feedinglots")}
                  selected={menuState.index === 'feedinglots'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'feedinglots' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.FeedingLotList} title={t('feedingLots')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('feedingLots')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('FeedingRecipes') && !props.user.isMushrooms &&
              <ListItemButton
                  name="feedingRecipes"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  // component="a"
                  // href="/#/feedinglots"
                  onClick={(e) => buttonClick(e, "/#/feedingrecipes")}
                  selected={menuState.index === 'feedingRecipes'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'feedRecipes' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.FeedingRecipe} title={t('feedingRecipes')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('feedingRecipes')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('Strains') &&
              <ListItemButton
                name="strains"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/strains")}
                selected={menuState.index === 'strains'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'strains' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.Strains} title={t('strains')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('strains')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('IPMSolutionList') && !props.user.isMushrooms &&
              <ListItemButton
                  name="IPMSolutions"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/pesticidepreps")}
                  selected={menuState.index === 'ipmSolutions'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'ipmSolutions' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.IPMSolutionList} title={t('pesticideSolution')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('pesticideSolution')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('PesticideRecipes') && !props.user.isMushrooms &&
              <ListItemButton
                  name="pesticideRecipes"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/pesticiderecipes")}
                  selected={menuState.index === 'pesticideRecipes'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'pesticideRecipes' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.PesticideRecipes} title={t('pesticideRecipes')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('pesticideRecipes')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('BatchTemplates') &&
                <ListItemButton
                    name="batchTemplates"
                    dense={!isMobile}
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/batchtemplates")}
                    selected={menuState.index === 'batchtemplates'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'batchtemplates' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.BatchTemplates} title={t('templates')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('templates')} />
                </ListItemButton>
              }
              {availableMenu.options.includes('BatchActivityDefinitions') &&
                <ListItemButton
                    name="activityDefinitions"
                    dense={!isMobile}
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/batchactivitydefinitions")}
                    selected={menuState.index === 'batchactivitydefinitions'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'batchactivitydefinitions' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.BatchActivityDefinitions} title={t('activityDefinitions')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('activityDefinitions')} />
                </ListItemButton>
              }
          </Aux>
        </List>
      </Collapse>
      {availableMenu.categories.includes('processing') &&
        <ListItemButton dense={!isMobile} name="processing" onClick={() => handleMenuClick('processing')}>
          <ListItemIcon>
            <Tooltip title={t('processing')} placement="right">
              <ProcessingIcon color={ menuState.category === 'processing' ? "secondary" : "primary" } />
            </Tooltip>
          </ListItemIcon>
          <NavMenuCategory title={t('processing')} />
          { menuState.openMenu === 'processing' && menuState.isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
        </ListItemButton>
      }
      <Collapse in={menuState.openMenu === 'processing' && menuState.isOpen ? true : false} timeout="auto" unmountOnExit>
        <List>
          <Aux>
            {availableMenu.options.includes('TrimmingOrderList') &&
              <ListItemButton
                name="trimmings"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/trimmingorders")}
                selected={menuState.index === 'trimmings'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'trimmings' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.TrimmingOrderList} title={t('trimmings')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('trimmings')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('DryingOrderList') && !props.user.isMushrooms &&
              <ListItemButton
                name="dryings"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/dryingorders")}
                selected={menuState.index === 'drying'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'drying' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.DryingOrderList} title={t('dryings')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('dryings')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('CuringList') && !props.user.isMushrooms &&
              <ListItemButton
                name="curings"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/curings")}
                selected={menuState.index === 'curings'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'curings' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.CuringList} title={t('curings')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('curings')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('ProductionBatches') &&
              <ListItemButton
                name="productionBatches"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/productionbatches")}
                selected={menuState.index === 'productionBatches'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'productionBatches' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ProductionBatches} title={t('batches')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('batches')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('ProductionOrderList') &&
              <ListItemButton
                name="productionOrders"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/eproductionorders")}
                selected={menuState.index === 'productionorders'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'productionorders' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ProductionOrderList} title={t('productionOrders')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('productionOrders')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('MasterCaseList') &&
              <ListItemButton
                name="masterCases"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/mastercases")}
                selected={menuState.index === 'masterCasing'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'masterCasing' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.MasterCaseList} title={t('masterCases')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('masterCases')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('Routings') &&
              <ListItemButton
                name="routings"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/routings")}
                selected={menuState.index === 'routings'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'routings' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.Routings} title={t('routings')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('routings')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('ProductionBoms') &&
              <ListItemButton
                name="productionBoms"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/boms")}
                selected={menuState.index === 'boms'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'boms' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ProductionBoms} title={t('productionBoms')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('productionBoms')}/>
              </ListItemButton>
            }
          </Aux>
        </List>
      </Collapse>
      {availableMenu.categories.includes("facility") &&
        <ListItemButton dense={!isMobile} name="facility" onClick={() => handleMenuClick('facility')}>
          <ListItemIcon>
            <Tooltip title={t('facility')} placement="right">
              <FacilityIcon color={ menuState.category === 'facility' || menuState.category === 'security' ? "secondary" : "primary" } />
            </Tooltip>
          </ListItemIcon>
          <NavMenuCategory title={t('facility')} />
          { menuState.openMenu === 'facility' && menuState.isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" /> }
        </ListItemButton>
      }
      <Collapse in={menuState.openMenu === 'facility' && menuState.isOpen ? true : false} timeout="auto" unmountOnExit>
        <List>
          <Aux>
          {availableMenu.options.includes('DestructionList') &&
              <ListItemButton
                  name="destructions"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/destructions")}
                  selected={menuState.index === 'destructions'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'destructions' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.DestructionList} title={t('destructions')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('destructions')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('Rooms') &&
              <ListItemButton
                name="rooms"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/rooms")}
                selected={menuState.index === 'rooms'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'rooms' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.Rooms} title={t('rooms')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('rooms')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('RoomCheckList') &&
              <ListItemButton
                  name="roomChecks"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/roomchecklist")}
                  selected={menuState.index === 'roomchecklist'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'roomchecklist' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.RoomCheckList} title={t('roomChecks')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('roomChecks')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('RoomCleaningList') &&
              <ListItemButton
                  name="roomCleanings"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/roomcleanings")}
                  selected={menuState.index === 'roomcleanings'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'roomcleanings' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.RoomCleaningList} title={t('roomCleanings')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('roomCleanings')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('EquipmentList') &&
              <ListItemButton
                name="equipmentList"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/equipmentlist")}
                selected={menuState.index === 'equipmentlist'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'equipmentlist' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.EquipmentList} title={t('equipment')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('equipment')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('EquipmentCalibrationChecks') &&
              <ListItemButton
                name="equipmentCalibrationChecks"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/equipmentcalibrationchecks")}
                selected={menuState.index === 'equipmentCalibrationChecks'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'equipmentCalibrationChecks' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.EquipmentCalibrationChecks} title={t('calibrationChecks')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('calibrationChecks')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('EquipmentCalibrationTypes') &&
              <ListItemButton
                name="equipmentCalibrationTypes"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/equipmentcalibrationtypes")}
                selected={menuState.index === 'equipmentCalibrationTypes'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'equipmentCalibrationTypes' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.EquipmentCalibrationTypes} title={t('calibrationTypes')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('calibrationTypes')}/>
              </ListItemButton>
            }
            {/*availableMenu.options.includes('WorkCenterList') &&
              <ListItemButton
                name="workCenters"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/workcenters")}
                selected={menuState.index === 'workcenters'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'workcenters' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.WorkCenterList} title={t('workCenters')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('workCenters')}/>
              </ListItemButton>
            */}
            {availableMenu.options.includes('EquipmentLogs') &&
              <ListItemButton
                  name="equipmentLogs"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/equipmentlogs")}
                  selected={menuState.index === 'equipmentlogs'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'equipmentlogs' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.EquipmentLogs} title={t('maintenanceLogs')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('maintenanceLogs')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('BinList') &&
              <ListItemButton
                name="bins"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/bins")}
                selected={menuState.index === 'bins'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'bins' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.BinList} title={t('bins')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('bins')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('LocationList') &&
              <ListItemButton
                  name="locations"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/locations")}
                  selected={menuState.index === 'locations'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'locations' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.LocationList} title={t('locations')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('locations')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('MaintenanceRequestList') &&
              <ListItemButton
                  name="maintenanceRequests"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/maintenancerequests")}
                  selected={menuState.index === 'maintenancerequests'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'maintenancerequests' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.MaintenanceRequestList} title={t('serviceRequests')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('serviceRequests')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('ToteList') && auth.user.cultivator &&
              <ListItemButton
                  name="totes"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/totes")}
                  selected={menuState.index === 'totes'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'totes' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ToteList} title={t('totes')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('totes')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('CleaningSolutionList') &&
              <ListItemButton
                  name="cleaningSolutions"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/cleaningsolutions")}
                  selected={menuState.index === 'cleaningsolutions'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'cleaningsolutions' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.CleaningSolutionList} title={t('cleaningSolutions')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('cleaningSolutions')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('CleaningSolutionRecipes') &&
              <ListItemButton
                  name="cleaningSolutionRecipes"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/cleaningsolutionrecipes")}
                  selected={menuState.index === 'cleaningSolutionRecipes'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'cleaningSolutionRecipes' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.CleaningSolutionRecipes} title={t('cleaningSolutionRecipes')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('cleaningSolutionRecipes')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('PestScoutingList') &&
                <ListItemButton
                    name="pestScoutingList"
                    dense={!isMobile}
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/pestscoutings")}
                    selected={menuState.index === 'pestscoutngs'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'pestScoutings' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.PestScoutingList} title={t('pestScoutings')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('pestScoutings')} />
                </ListItemButton>
              }
            {/*availableMenu.options.includes('TaskRiskAssessmentList') &&
              <ListItemButton
                  name="riskAssessments"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  // component="a"
                  // href="/#/taskrisks"
                  onClick={(e) => buttonClick(e, "/#/taskrisks")}
                  selected={menuState.index === 'taskrisks'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'taskrisks' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.TaskRiskAssessmentList} title={t('taskRiskAssessment')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title="Risk Assessment" />
              </ListItemButton>
        */}
            {availableMenu.categories.includes('security') &&
                <ListItemButton dense={!isMobile} name="security" sx={open ? nestedCategoryOpen : nestedCategoryClosed} onClick={() => handleSubMenuClick('security')}>
                  <ListItemIcon>
                    <Tooltip title={t('security')} placement="right">
                      {open ?
                        <SecurityIcon color={ menuState.category === 'security' ? "secondary" : "primary" } /> :
                        menuState.openSubMenu === 'security' && menuState.subIsOpen ? <ExpandMore color={ menuState.category === 'security' ? "secondary" : "primary" } /> :
                        <SecurityIcon color={ menuState.category === 'security' ? "secondary" : "primary" } />
                      }

                    </Tooltip>
                  </ListItemIcon>
                  <NavNestedCategory title={t('security')} />
                  { menuState.openSubMenu === 'security' && menuState.subIsOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                </ListItemButton>
              }
              <Collapse in={menuState.openSubMenu === 'security' && menuState.subIsOpen ? true : false} timeout="auto" unmountOnExit>
                <List sx={subMenu}>
                  <Aux>
                    {availableMenu.options.includes('SigninList') &&
                      <ListItemButton
                          name="signins"
                          dense={!isMobile}
                          sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                          onClick={(e) => buttonClick(e, "/#/signins")}
                          selected={menuState.index === 'signins'} >
                        <ListItemIcon>
                          <Icon sx={menuState.index === 'signins' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.SigninList} title={t('signins')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('signins')} />
                      </ListItemButton>
                    }
                    {availableMenu.options.includes('VisitorList') &&
                      <ListItemButton
                          name="visitors"
                          dense={!isMobile}
                          sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                          onClick={(e) => buttonClick(e, "/#/visitors")}
                          selected={menuState.index === 'visitors'} >
                        <ListItemIcon>
                          <Icon sx={menuState.index === 'visitors' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.VisitorList} title={t('visitors')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('visitors')} />
                      </ListItemButton>
                    }
                  </Aux>
                </List>
              </Collapse>
          </Aux>
        </List>
      </Collapse>
      {availableMenu.categories.includes("inventory") &&
        <ListItemButton dense={!isMobile} name="inventory" onClick={() => handleMenuClick('inventory')}>
          <ListItemIcon>
            <Tooltip title={t('inventory')} placement="right">
              <InventoryIcon color={ menuState.category === 'inventory' ? "secondary" : "primary" } />
            </Tooltip>
          </ListItemIcon>
          <NavMenuCategory title={t('inventory')} />
          { menuState.openMenu === 'inventory' && menuState.isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" /> }
        </ListItemButton>
      }
      <Collapse in={menuState.openMenu === 'inventory' && menuState.isOpen ? true : false} timeout="auto" unmountOnExit>
        <List>
          <Aux>
            {availableMenu.options.includes('ItemList') &&
              <ListItemButton
                name="items"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/itemlist")}
                selected={menuState.index === 'itemlist'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'itemlist' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ItemList} title={t('items')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('items')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('LotList') &&
              <ListItemButton
                name="lots"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/lots")}
                selected={menuState.index === 'lots'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'lots' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.LotList} title={t('lots')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('lots')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('ContainerList') &&
              <ListItemButton
                name="containers"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/containers")}
                selected={menuState.index === 'containers'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'containers' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ContainerList} title={t('containers')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('containers')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('Strains') && !auth.user.cultivator &&
              <ListItemButton
                name="strains"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/strains")}
                selected={menuState.index === 'strains'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'strains' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.Strains} title={t('strains')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('strains')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('ItemLedgers') &&
              <ListItemButton
                name="itemLedgers"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/itemledgers")}
                selected={menuState.index === 'itemledgers'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'itemledgers' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ItemLedgers} title={t('itemLedgers')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('itemLedgers')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('ExciseAdjustmentList') &&
              <ListItemButton
                name="exciseAdjustments"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/exciseadjustments")}
                selected={menuState.index === 'ExciseAdjustments'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'ExciseAdjustments' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ExciseAdjustmentList} title={t('exciseAdjustments')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('exciseAdjustments')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('DestructionReport') &&
              <ListItemButton
                name="destructionReport"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/destructionreport")}
                selected={menuState.index === 'destructionReport'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'destructionReport' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.DestructionReport} title={t('destructionReport')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('destructionReport')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('VendorList') &&
              <ListItemButton
                name="vendors"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/vendors")}
                selected={menuState.index === 'vendors'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'vendors' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.VendorList} title={t('vendors')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('vendors')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('ShipmentList') &&
              <ListItemButton
                name="shipments"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/shipments")}
                selected={menuState.index === 'shipments'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'shipments' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ShipmentList} title={t('shipments')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('shipments')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('ReceiptList') &&
              <ListItemButton
                name="Receipts"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/receipts")}
                selected={menuState.index === 'receipts'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'receipts' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ReceiptList} title={t('receipts')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('receipts')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('PurchaseOrderList') &&
              <ListItemButton
                name="PurchaseOrders"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/purchaseorders")}
                selected={menuState.index === 'purchaseOrders'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'purchaseOrders' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.PurchaseOrderList} title={t('purchaseOrders')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('purchaseOrders')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('ItemCategories') &&
              <ListItemButton
                  name="itemCats"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/itemcategories")}
                  selected={menuState.index === 'itemcategories'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'itemcategories' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ItemCategories} title={t('itemCategories')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('itemCategories')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('PastInventoryList') &&
              <ListItemButton
                name="pastInventoryList"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/pastinventories")}
                selected={menuState.index === 'PastInventoryList'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'PastInventoryList' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.PastInventoryList} title={t('pastInventoryList')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('pastInventoryList')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('BinSummary') &&
              <ListItemButton
                name="binSummary"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/binsummary")}
                selected={menuState.index === 'binSummary'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'binSummary' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.BinSummary} title={t('binSummary')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('binSummary')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('RoomSummary') && auth.user.cultivator &&
              <ListItemButton
                name="roomSummary"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/roomsummary")}
                selected={menuState.index === 'roomSummary'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'roomSummary' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.RoomSummary} title={t('roomSummary')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('roomSummary')}/>
              </ListItemButton>
            }
          </Aux>
        </List>
      </Collapse>
      {availableMenu.categories.includes('quality') &&
        <ListItemButton dense={!isMobile} name="quality" onClick={() => handleMenuClick('quality')}>
          <ListItemIcon>
            <Tooltip title={t('quality')} placement="right">
              <QAIcon color={(menuState.category === 'quality' || menuState.category === 'tests' ||
                              menuState.category === 'investigations' || menuState.category === 'reports' ) ? "secondary" : "primary" } />
            </Tooltip>
          </ListItemIcon>
          <NavMenuCategory title={t('quality')} />
          { menuState.openMenu === 'quality' && menuState.isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
        </ListItemButton>
      }
      <Collapse in={menuState.openMenu === 'quality' && menuState.isOpen ? true : false} timeout="auto" unmountOnExit>
        <List>
          <Aux>
            {availableMenu.categories.includes('tests') &&
              <ListItemButton dense={!isMobile} name="tests" sx={open ? nestedCategoryOpen : nestedCategoryClosed} onClick={() => handleSubMenuClick('tests')}>
                <ListItemIcon>
                  <Tooltip title={t('testing')} placement="right">
                    {open ?
                      <TimelapseTwoToneIcon color={ menuState.category === 'tests' ? "secondary" : "primary" } /> :
                      menuState.openSubMenu === 'tests' && menuState.subIsOpen ? <ExpandMore color={ menuState.category === 'tests' ? "secondary" : "primary" } /> :
                      <TimelapseTwoToneIcon color={ menuState.category === 'tests' ? "secondary" : "primary" } />
                    }

                  </Tooltip>
                </ListItemIcon>
                <NavNestedCategory title={t('testing')} />
                { menuState.openSubMenu === 'tests' && menuState.subIsOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
              </ListItemButton>
            }
            <Collapse in={menuState.openSubMenu === 'tests' && menuState.subIsOpen ? true : false} timeout="auto" unmountOnExit>
              <List sx={subMenu}>
                <Aux>
                  {availableMenu.options.includes('TestResultList') &&
                    <ListItemButton
                      name="testResults"
                      dense={!isMobile}
                      sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                      onClick={(e) => buttonClick(e, "/#/testresultlist")}
                      selected={menuState.index === 'testresultlist'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'testresultlist' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.TestResultList} title={t('testResults')} placement="right"/>
                      </ListItemIcon>
                      <NestedDrawerLink title={t('testResults')}/>
                    </ListItemButton>
                  }
                  {availableMenu.options.includes('TestDefinitionList') &&
                    <ListItemButton
                        name="testDefinitions"
                        dense={!isMobile}
                        sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                        onClick={(e) => buttonClick(e, "/#/testdefinitions")}
                        selected={menuState.index === 'testdefinitions'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'testdefinitions' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.TestDefinitionList} title={t('testDefinitions')} placement="right"/>
                      </ListItemIcon>
                      <NestedDrawerLink title={t('testDefinitions')} />
                    </ListItemButton>
                  }
                  {availableMenu.options.includes('TestItemList') &&
                    <ListItemButton
                        name="testItems"
                        dense={!isMobile}
                        sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                        onClick={(e) => buttonClick(e, "/#/testItems")}
                        selected={menuState.index === 'testItems'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'testItems' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.TestItemList} title={t('testItems')} placement="right"/>
                      </ListItemIcon>
                      <NestedDrawerLink title={t('testItems')} />
                    </ListItemButton>
                  }
                  {availableMenu.options.includes('TestItemCategories') &&
                    <ListItemButton
                        name="testCategories"
                        dense={!isMobile}
                        sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                        onClick={(e) => buttonClick(e, "/#/testItemCategories")}
                        selected={menuState.index === 'testItemCategories'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'testItemCategories' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.TestItemCategories} title={t('testCategories')} placement="right"/>
                      </ListItemIcon>
                      <NestedDrawerLink title={t('testCategories')} />
                    </ListItemButton>
                  }
                </Aux>
              </List>
            </Collapse>
            {availableMenu.categories.includes('investigations') &&
              <ListItemButton dense={!isMobile} name="investigations" sx={open ? nestedCategoryOpen : nestedCategoryClosed} onClick={() => handleSubMenuClick('investigations')}>
                <ListItemIcon>
                  <Tooltip title={t('investigations')} placement="right">
                    {open ?
                      <InvestigationsIcon color={ menuState.category === 'investigations' ? "secondary" : "primary" } /> :
                      menuState.openSubMenu === 'investigations' && menuState.subIsOpen ? <ExpandMore color={ menuState.category === 'investigations' ? "secondary" : "primary" } /> :
                      <InvestigationsIcon color={ menuState.category === 'investigations' ? "secondary" : "primary" } />
                    }

                  </Tooltip>
                </ListItemIcon>
                <NavNestedCategory title={t('investigations')} />
                { menuState.openSubMenu === 'investigations' && menuState.subIsOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
              </ListItemButton>
            }
            <Collapse in={menuState.openSubMenu === 'investigations' && menuState.subIsOpen ? true : false} timeout="auto" unmountOnExit>
              <List sx={subMenu}>
                <Aux>
                    {availableMenu.options.includes('CapaList') &&
                        <ListItemButton
                            name="capas"
                            dense={!isMobile}
                            sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                            onClick={(e) => buttonClick(e, "/#/capas")}
                            selected={menuState.index === 'capas'} >
                        <ListItemIcon>
                            <Icon sx={menuState.index === 'capas' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.CapaList} title={t('capas')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('capas')} />
                        </ListItemButton>
                    }
                  {availableMenu.options.includes('DeviationList') &&
                    <ListItemButton
                        name="deviations"
                        dense={!isMobile}
                        sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                        onClick={(e) => buttonClick(e, "/#/deviations")}
                        selected={menuState.index === 'deviations'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'deviations' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.DeviationList} title={t('deviations')} placement="right"/>
                      </ListItemIcon>
                      <NavDrawerLink title={t('deviations')} />
                    </ListItemButton>
                  }
                  {availableMenu.options.includes('NcrList') &&
                    <ListItemButton
                        name="ncrs"
                        dense={!isMobile}
                        sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                        onClick={(e) => buttonClick(e, "/#/ncrs")}
                        selected={menuState.index === 'ncrs'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'ncrs' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.NcrList} title={t('ncrs')} placement="right"/>
                      </ListItemIcon>
                      <NavDrawerLink title={t('ncrs')} />
                    </ListItemButton>
                  }
                  {availableMenu.options.includes('LossCannabisList') &&
                    <ListItemButton
                        name="losses"
                        dense={!isMobile}
                        sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                        onClick={(e) => buttonClick(e, "/#/losses")}
                        selected={menuState.index === 'losses'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'losses' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.LossCannabisList} title={t('lossOrTheft')} placement="right"/>
                      </ListItemIcon>
                      <NavDrawerLink title={t('lossOrTheft')} />
                    </ListItemButton>
                  }
                  {availableMenu.options.includes('CorrectiveActionRequests') &&
                    <ListItemButton
                        name="cars"
                        dense={!isMobile}
                        sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                        onClick={(e) => buttonClick(e, "/#/cars")}
                        selected={menuState.index === 'correctiveActionRequests'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'correctiveActionRequests' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.CorrectiveActionRequests} title={t('correctiveActionRequests')} placement="right"/>
                      </ListItemIcon>
                      <NavDrawerLink title={t('cars')} />
                    </ListItemButton>
                  }
                  {availableMenu.options.includes('EmployeeIllnessList') &&
                    <ListItemButton
                        name='employeeIllnesses'
                        dense={!isMobile}
                        sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                        onClick={(e) => buttonClick(e, "/#/illnesses")}
                        selected={menuState.index === 'illnesses'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'illnesses' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.EmployeeIllnessList} title={t('employeeIllnesses')} placement="right"/>
                      </ListItemIcon>
                      <NavDrawerLink title={t('employeeIllnesses')} />
                    </ListItemButton>
                  }
                  {availableMenu.options.includes('IncidentList') &&
                    <ListItemButton
                        name="incidents"
                        dense={!isMobile}
                        sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                        onClick={(e) => buttonClick(e, "/#/majors")}
                        selected={menuState.index === 'majors'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'majors' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.IncidentList} title={t('incidents')} placement="right"/>
                      </ListItemIcon>
                      <NavDrawerLink title={t('incidents')} />
                    </ListItemButton>
                  }
                  {availableMenu.options.includes('RecallList') &&
                    <ListItemButton
                        name="recalls"
                        dense={!isMobile}
                        sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                        onClick={(e) => buttonClick(e, "/#/recalls")}
                        selected={menuState.index === 'recalls'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'recalls' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.RecallList} title={t('recalls')} placement="right"/>
                      </ListItemIcon>
                      <NavDrawerLink title={t('recalls')} />
                    </ListItemButton>
                  }
                  {//availableMenu.options.includes('InjuryHazardousList') &&
                    //<ListItemButton
                    //    name="injuries"
                    //    dense={!isMobile}
                    //    sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                        // component="a"
                        // href="/#/hazardouslist"
                    //    onClick={(e) => buttonClick(e, "/#/hazardouslist")}
                    //    selected={menuState.index === 'hazardouslist'} >
                    //  <ListItemIcon>
                          // <Icon sx={menuState.index === 'hazardouslist' ? activeMenuIcon : nonActiveMenuIcon}
                          //       screenDefs={screenDefs} screenDef={screenDefs.InjuryHazardousList} title={t('hazardousSpills')} placement="right"/>
                    //  </ListItemIcon>
                    //</Aux>  <NavDrawerLink title={t('hazardousSpills')} />
                    //</ListItemButton>
                  }
                  {availableMenu.options.includes('VendorAuditList') &&
                    <ListItemButton
                        name="vendorAudits"
                        dense={!isMobile}
                        sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                        onClick={(e) => buttonClick(e, "/#/vendoraudits")}
                        selected={menuState.index === 'vendoraudits'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'vendoraudits' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.VendorAuditList} title={t('vendorAudits')} placement="right"/>
                      </ListItemIcon>
                      <NavDrawerLink title={t('vendorAudits')} />
                    </ListItemButton>
                  }
                </Aux>
              </List>
            </Collapse>
            {availableMenu.options.includes('ExternalTreatmentList') &&
              <Aux>
              <ListItemButton
                  name="externalTreatments"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/exttreats")}
                  selected={menuState.index === 'externaltreatments'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'externaltreatments' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ExternalTreatmentList} title={t('externalTreatments')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('externalTreatments')} />
              </ListItemButton>
              </Aux>
            }
            {availableMenu.options.includes('RiskAssessments') &&
              <Aux>
              <ListItemButton
                  name="riskAssessments"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/riskassessments")}
                  selected={menuState.index === 'riskAssessments'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'riskAssessments' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.RiskAssessments} title={t('riskAssessments')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('riskAssessments')} />
              </ListItemButton>
              </Aux>
            }
          </Aux>
        </List>
      </Collapse>
      {availableMenu.categories.includes("crm") &&
        <ListItemButton dense={!isMobile} name="crm" onClick={() => handleMenuClick('crm')}>
          <ListItemIcon>
            <Tooltip title={t('crm')} placement="right">
              <CRMIcon color={ menuState.category === 'crm' ? "secondary" : "primary" } />
            </Tooltip>
          </ListItemIcon>
          <NavMenuCategory title={t('crm')} />
          { menuState.openMenu === 'crm' && menuState.isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
        </ListItemButton>
      }
      <Collapse in={menuState.openMenu === 'crm' && menuState.isOpen ? true : false} timeout="auto" unmountOnExit>
        <List>
          <Aux>
            {availableMenu.options.includes('CustomerList') &&
              <ListItemButton
                name="customers"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/customers")}
                selected={menuState.index === 'customers'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'customers' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.CustomerList} title={t('customers')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('customers')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('SalesList') &&
              <ListItemButton
                name="sales"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/sales")}
                selected={menuState.index === 'sales'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'sales' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.SalesList} title={t('sales')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('sales')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('CCXPostings') && props.user.ccx === true &&
              <ListItemButton
                name="CcxPostings"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/ccxpostings")}
                selected={menuState.index === 'CcxPostings'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'CcxPostings' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.CCXPostings} title={t('ccxPostings')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('ccxPostings')}/>
              </ListItemButton>
            }
            {availableMenu.options.includes('InvoiceList') &&
              <ListItemButton
                name="invoices"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/invoices")}
                selected={menuState.index === 'invoices'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'invoices' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.InvoiceList} title={t('invoices')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('invoices')}/>
              </ListItemButton>
            }
            {/*availableMenu.options.includes('PractitionerList') && props.user.isCannabis &&
              <ListItemButton
                name="practitioners"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/practitioners")}
                selected={menuState.index === 'practitioners'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'practitioners' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.PractitionerList} title={t('practitioners')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('practitioners')}/>
              </ListItemButton>
            */}
            {availableMenu.options.includes('CustomerComplaints') &&
              <ListItemButton
                name="customerComplaints"
                dense={!isMobile}
                sx={open ? drawerOpen : drawerClosed}
                onClick={(e) => buttonClick(e, "/#/customercomplaints")}
                selected={menuState.index === 'customercomplaints'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'customerComplaints' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.CustomerComplaints} title={t('complaints')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('complaints')}/>
              </ListItemButton>
            }
            </Aux>
        </List>
      </Collapse>
      {availableMenu.categories.includes("reporting") &&
        <ListItemButton dense={!isMobile} name="reporting" onClick={() => handleMenuClick('reporting')}>
          <ListItemIcon>
            <Tooltip title={t('reporting')} placement="right">
              <ReportingIcon color={ menuState.category === 'reporting' ? "secondary" : "primary" } />
            </Tooltip>
          </ListItemIcon>
          <NavMenuCategory title={t('reporting')} />
          { menuState.openMenu === 'reporting' && menuState.isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
        </ListItemButton>
      }
      <Collapse in={menuState.openMenu === 'reporting' && menuState.isOpen ? true : false} timeout="auto" unmountOnExit>
        <List>
            <Aux>
                {availableMenu.options.includes('HCReportList') &&
                    <ListItemButton
                        name="hcReports"
                        dense={!isMobile}
                        sx={open ? drawerOpen : drawerClosed}
                        onClick={(e) => buttonClick(e, "/#/hcreports")}
                        selected={menuState.index === 'hcreports'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'hcreports' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.HCReportList} title={t('hcReports')} placement="right"/>
                      </ListItemIcon>
                      <NavDrawerLink title={t('hcReports')} />
                    </ListItemButton>
                }
                {/*availableMenu.options.includes('InventoryBreakdown') &&
                    <ListItemButton
                        name="InventoryBreakdown"
                        dense={!isMobile}
                        sx={open ? drawerOpen : drawerClosed}
                        onClick={(e) => buttonClick(e, "/#/inventorybreakdown")}
                        selected={menuState.index === 'inventorybreakdown'} >
                        <ListItemIcon>
                        <Icon sx={menuState.index === 'InventoryBreakdown' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.InventoryBreakdown} title={t('inventoryBreakdown')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('inventoryBreakdown')}/>
                    </ListItemButton>
                */}
                {availableMenu.options.includes('AvailableInventoryList') &&
                    <ListItemButton
                        name="availableInventoryList"
                        dense={!isMobile}
                        sx={open ? drawerOpen : drawerClosed}
                        onClick={(e) => buttonClick(e, "/#/availableinventories")}
                        selected={menuState.index === 'availableInventory'} >
                        <ListItemIcon>
                        <Icon sx={menuState.index === 'availableInventory' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.AvailableInventoryList} title={t('availableInventory')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('availableInventory')}/>
                    </ListItemButton>
                }
                {availableMenu.options.includes('ExciseStampAudit') &&
                    <ListItemButton
                        name="exciseStampAudit"
                        dense={!isMobile}
                        sx={open ? drawerOpen : drawerClosed}
                        onClick={(e) => buttonClick(e, "/#/excisestampaudit")}
                        selected={menuState.index === 'exciseStampAudit'} >
                        <ListItemIcon>
                        <Icon sx={menuState.index === 'exciseStampAudit' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.ExciseStampAudit} title={t('exciseStampAudit')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('exciseStampAudit')}/>
                    </ListItemButton>
                }
            </Aux>
        </List>
      </Collapse>
      {availableMenu.categories.includes("production") &&
        <ListItemButton dense={!isMobile} name="production" onClick={() => handleMenuClick('production')}>
          <ListItemIcon>
            <Tooltip title={t('production')} placement="right">
              <ProductionIcon color={ menuState.category === 'production' ? "secondary" : "primary" } />
            </Tooltip>
          </ListItemIcon>
          <NavMenuCategory title={t('production')}/>
          { menuState.openMenu === 'production' && menuState.isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
        </ListItemButton>
      }
      <Collapse in={menuState.openMenu === 'production' && menuState.isOpen ? true : false} timeout="auto" unmountOnExit>
        <List>
          <Aux>
            {availableMenu.options.includes('ScheduledActivities') &&
              <ListItemButton
                name="scheduledActivities"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/scheduledActivities")}
                  selected={menuState.index === 'scheduledActivities'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'scheduledActivities' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ScheduledActivities} title={t('scheduledActs')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('scheduledActs')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('ProductionPlanList') &&
              <ListItemButton
                  name='productionPlans'
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/productionplans")}
                  selected={menuState.index === 'productionplans'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'productionplans' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ProductionPlanList} title={t('planning')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('planning')} />
              </ListItemButton>
            }
            {availableMenu.options.includes('ProductionScheduleList') &&
              <ListItemButton
                  name="productionSchedules"
                  dense={!isMobile}
                  sx={open ? drawerOpen : drawerClosed}
                  onClick={(e) => buttonClick(e, "/#/productionschedules")}
                  selected={menuState.index === 'productionschedules'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'productionschedules' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ProductionScheduleList} title={t('schedules')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('schedules')} />
              </ListItemButton>
            }
          </Aux>
        </List>
      </Collapse>
      {availableMenu.categories.includes("documents") &&
        <ListItemButton dense={!isMobile} name="documents" onClick={() => handleMenuClick('documents')}>
          <ListItemIcon>
            <Tooltip title={t('documents')} placement="right">
              <DocumentsIcon color={ menuState.category === 'documents' ? "secondary": "primary" } />
            </Tooltip>
          </ListItemIcon>
          <NavMenuCategory title={t('documents')} />
          { menuState.openMenu === 'documents' && menuState.isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
        </ListItemButton>
      }
      <Collapse in={ menuState.openMenu === 'documents' && menuState.isOpen ? true : false } timeout="auto" unmountOnExit>
        <List>
            <Aux>
              {availableMenu.options.includes('ManagedDocumentList') &&
                <ListItemButton
                    dense={!isMobile}
                    name="managedDocuments"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/manageddocs")}
                    selected={menuState.index === 'manageddocs'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'manageddocs' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.ManagedDocumentList} title={t('documents')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('documents')} />
                </ListItemButton>
              }
              {availableMenu.options.includes('DocumentReviewList') &&
                <ListItemButton
                    dense={!isMobile}
                    name="documentReviews"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/manageddocreviews")}
                    selected={menuState.index === 'manageddocreviews'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'manageddocreviews' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.DocumentReviewList} title={t('reviews')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('reviews')} />
                </ListItemButton>
              }
              {availableMenu.options.includes('ChangeRequestList') &&
                <ListItemButton
                    dense={!isMobile}
                    name="changeRequests"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/changerequests")}
                    selected={menuState.index === 'changerequests'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'changerequests' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.ChangeRequestList} title={t('changeRequests')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('changeRequests')} />
                </ListItemButton>
              }
              {availableMenu.options.includes('SopList') &&
                <ListItemButton
                    dense={!isMobile}
                    name="sops"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/sops")}
                    selected={menuState.index === 'sops'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'sops' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.SopList} title={t('workRecAdmin')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('workRecAdmin')} />
                </ListItemButton>
              }
              {availableMenu.options.includes('DocumentCertificationList') &&
                <ListItemButton
                    dense={!isMobile}
                    name="documentCertificationList"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/certs")}
                    selected={menuState.index === 'documentCertifications'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'documentCertifications' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.DocumentCertificationList} title={t('documentCertifications')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('documentCertifications')} />
                </ListItemButton>
              }
              {availableMenu.options.includes('ManagedDocumentTemplates') &&
                <ListItemButton
                    dense={!isMobile}
                    name="ManagedDocumentTemplates"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/manageddocumenttemplates")}
                    selected={menuState.index === 'managedDocumentTemplates'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'managedDocumentTemplates' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.ManagedDocumentTemplates} title={t('documentTemplates')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('documentTemplates')} />
                </ListItemButton>
              }
            </Aux>
        </List>
      </Collapse>
      {availableMenu.categories.includes("hrmanagement") &&
        <ListItemButton dense={!isMobile} name="hrmanagement" onClick={() => handleMenuClick('hrmanagement')}>
          <ListItemIcon>
            <Tooltip title={t('management')} placement="right">
              <HrManagementIcon color={ menuState.category === 'hrmanagement' ? "secondary": "primary" } />
            </Tooltip>
          </ListItemIcon>
          <NavMenuCategory title={t('management')} />
          { menuState.openMenu === 'hrmanagement' && menuState.isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
        </ListItemButton>
      }
      <Collapse in={ menuState.openMenu === 'hrmanagement' && menuState.isOpen ? true : false } timeout="auto" unmountOnExit>
        <List>
            <Aux>
              {availableMenu.options.includes('ManageUsers') &&
                <ListItemButton
                    dense={!isMobile}
                    name="manageusers"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/manageusers")}
                    selected={menuState.index === 'manageusers'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'manageusers' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.ManageUsers} title={t('users')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('users')} />
                </ListItemButton>
              }
            </Aux>
        </List>
      </Collapse>
      {availableMenu.categories.includes("admin") &&
        <ListItemButton dense={!isMobile} name="admin" onClick={() => handleMenuClick('admin')}>
          <ListItemIcon>
            <Tooltip title={t('settings')} placement="right">
              <SettingsIcon color={ menuState.category === 'admin' || menuState.category === 'config' ? "secondary" : "primary" } />
            </Tooltip>
          </ListItemIcon>
          <NavMenuCategory title={t('settings')} />
          { menuState.openMenu === 'admin' && menuState.isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
        </ListItemButton>
      }
        <Collapse in={ menuState.openMenu === 'admin' && menuState.isOpen ? true : false } timeout="auto" unmountOnExit>
          <Aux>
            <List>
              {availableMenu.options.includes('TenantUsers') &&
                <ListItemButton
                    dense={!isMobile}
                    name="tenantUsers"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/tenantusers")}
                    selected={menuState.index === 'users'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'tenantUsers' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.TenantUsers} title={t('users')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('users')} />
                </ListItemButton>
              }
              {availableMenu.options.includes('LogList') &&
                <ListItemButton
                    dense={!isMobile}
                    name="logs"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/loglist")}
                    selected={menuState.index === 'loglist'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'loglist' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.LogList} title={t('logs')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('logs')} />
                </ListItemButton>
              }
              {availableMenu.options.includes('ApiLogs') &&
                <ListItemButton
                    dense={!isMobile}
                    name="apiLogs"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/apilogs")}
                    selected={menuState.index === 'apiLogs'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'apiLogs' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.ApiLogs} title={t('apiLogs')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('apiLogs')} />
                </ListItemButton>
              }
              {availableMenu.options.includes('ValueTypes') &&
                <ListItemButton
                    dense={!isMobile}
                    name="valueTypes"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/valueTypes")}
                    selected={menuState.index === 'valueTypes'} >
                <ListItemIcon>
                  <Icon sx={menuState.index === 'valueTypes' ? activeMenuIcon : nonActiveMenuIcon}
                        screenDefs={screenDefs} screenDef={screenDefs.ValueTypes} title={t('valueAdmin')} placement="right"/>
                </ListItemIcon>
                <NavDrawerLink title={t('valueAdmin')} />
                </ListItemButton>
            }
              {availableMenu.options.includes('AdminSettings') &&
                <ListItemButton
                    dense={!isMobile}
                    name="adminSettings"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/settings")}
                    selected={menuState.index === 'settings'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'settings' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.AdminSettings} title={t('settings')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('settings')} />
                </ListItemButton>
              }
              {availableMenu.options.includes('SettingPrefix') &&
                <ListItemButton
                    dense={!isMobile}
                    name="settingPrefix"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/settingprefix")}
                    selected={menuState.index === 'settingprefix'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'settingprefix' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.SettingPrefix} title={t('prefixes')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('prefixes')} />
                </ListItemButton>
              }
              {availableMenu.options.includes('TenantReportList') &&
                <ListItemButton
                    dense={!isMobile}
                    name="tenantReports"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/tenantreports")}
                    selected={menuState.index === 'tenantReports'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'tenantReports' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.TenantReportList} title={t('reportSettings')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('reportSettings')} />
                </ListItemButton>
              }
              {availableMenu.options.includes('HeadersFooters') &&
                <ListItemButton
                    dense={!isMobile}
                    name="headersFooters"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/headersfooterslist")}
                    selected={menuState.index === 'headersFooters'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'headersfooters' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.HeadersFootersList} title={t('headersAndFooters')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('headersAndFooters')} />
                </ListItemButton>
              }
              {availableMenu.options.includes('Screens') &&
                <ListItemButton
                    dense={!isMobile}
                    name="screens"
                    sx={open ? drawerOpen : drawerClosed}
                    onClick={(e) => buttonClick(e, "/#/screens")}
                    selected={menuState.index === 'screens'} >
                  <ListItemIcon>
                    <Icon sx={menuState.index === 'screens' ? activeMenuIcon : nonActiveMenuIcon}
                          screenDefs={screenDefs} screenDef={screenDefs.Screens} title={t('screens')} placement="right"/>
                  </ListItemIcon>
                  <NavDrawerLink title={t('screens')} />
                </ListItemButton>
              }
              {availableMenu.categories.includes('adminReports') &&
              <ListItemButton dense={!isMobile} name="adminReports" sx={open ? nestedCategoryOpen : nestedCategoryClosed} onClick={() => handleSubMenuClick('adminReports')}>
                <ListItemIcon>
                  <Tooltip title={t('reports')} placement="right">
                    {open ?
                      <ReportIcon color={ menuState.category === 'adminReports' ? "secondary" : "primary" } /> :
                      menuState.openSubMenu === 'adminReports' && menuState.subIsOpen ? <ExpandMore color={ menuState.category === 'adminReports' ? "secondary" : "primary" } /> :
                      <ReportIcon color={ menuState.category === 'adminReports' ? "secondary" : "primary" } />
                    }

                  </Tooltip>
                </ListItemIcon>
                <NavNestedCategory title={t('reports')} />
                { menuState.openSubMenu === 'adminReports' && menuState.subIsOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
              </ListItemButton>
            }
            <Collapse in={menuState.openSubMenu === 'adminReports' && menuState.subIsOpen ? true : false} timeout="auto" unmountOnExit>
              <List sx={subMenu}>
                <Aux>
                  {availableMenu.options.includes('uacReportList') &&
                    <ListItemButton
                        name="uacReportList"
                        dense={!isMobile}
                        sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                        onClick={(e) => buttonClick(e, "/#/uacreports")}
                        selected={menuState.index === 'uacreports'} >
                      <ListItemIcon>
                        <Icon sx={menuState.index === 'uacreports' ? activeMenuIcon : nonActiveMenuIcon}
                              screenDefs={screenDefs} screenDef={screenDefs.UACReportList} title={t('uacreports')} placement="right"/>
                      </ListItemIcon>
                      <NavDrawerLink title={t('uacreports')} />
                    </ListItemButton>
                  }
                </Aux>
              </List>
            </Collapse>
              {availableMenu.categories.includes('config') && user.internal &&
                <ListItemButton dense={!isMobile} name="config" sx={open ? nestedCategoryOpen : nestedCategoryClosed} onClick={() => handleSubMenuClick('config')}>
                  <ListItemIcon>
                    <Tooltip title={t('configuration')}placement="right">
                      {open ?
                        <ConfigMenuIcon color={ menuState.category === 'config' ? "secondary" : "primary" } /> :
                        menuState.openSubMenu === 'config' && menuState.subIsOpen ? <ExpandMore color={ menuState.category === 'config' ? "secondary" : "primary" } /> :
                        <ConfigMenuIcon color={ menuState.category === 'config' ? "secondary" : "primary" } />
                      }

                    </Tooltip>
                  </ListItemIcon>
                  <NavNestedCategory title={t('configuration')} />
                  { menuState.openSubMenu === 'config' && menuState.subIsOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                </ListItemButton>
              }
              <Collapse in={menuState.openSubMenu === 'config' && menuState.subIsOpen ? true : false} timeout="auto" unmountOnExit>
                <List sx={subMenu}>
                  <Aux>
                    {availableMenu.options.includes('UserList') &&
                      <ListItemButton
                          dense={!isMobile}
                          name="users"
                          sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                          onClick={(e) => buttonClick(e, "/#/users")}
                          selected={menuState.index === 'users'} >
                        <ListItemIcon>
                          <Icon sx={menuState.index === 'users' ? activeMenuIcon : nonActiveMenuIcon}
                                    screenDefs={screenDefs} screenDef={screenDefs.UserList} title={t('allUsers')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('allUsers')} />
                      </ListItemButton>
                    }
                    {availableMenu.options.includes('Announcements') &&
                        <ListItemButton
                            dense={!isMobile}
                            name="announcements"
                            sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                            // component="a"
                            // href="/#/loglist"
                            onClick={(e) => buttonClick(e, "/#/announcements")}
                            selected={menuState.index === 'announcements'} >
                        <ListItemIcon>
                            <Icon sx={menuState.index === 'announcements' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.Announcements} title={t('announcements')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('announcements')} />
                        </ListItemButton>
                    }
                    {availableMenu.options.includes('EmailTemplateList') &&
                      <ListItemButton
                          dense={!isMobile}
                          name="emailTemplates"
                          sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                          onClick={(e) => buttonClick(e, "/#/emailtemplates")}
                          selected={menuState.index === 'emailtemplates'} >
                        <ListItemIcon>
                          <Icon sx={menuState.index === 'emailtemplates' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.EmailTemplateList} title={t('emailTemplates')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('emailTemplates')} />
                      </ListItemButton>
                    }
                    {availableMenu.options.includes('EmailSentList') &&
                      <ListItemButton
                          dense={!isMobile}
                          name="emailssent"
                          sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                          onClick={(e) => buttonClick(e, "/#/emailssent")}
                          selected={menuState.index === 'emailssent'} >
                        <ListItemIcon>
                          <Icon sx={menuState.index === 'emailssent' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.EmailSentList} title={t('sentEmails')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('sentEmails')} />
                      </ListItemButton>
                    }
                    {availableMenu.options.includes('DataUpload') &&
                      <ListItemButton
                          dense={!isMobile}
                          name="dataUpload"
                          sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                          onClick={(e) => buttonClick(e, "/#/datauploads")}
                          selected={menuState.index === 'dataUpload'} >
                        <ListItemIcon>
                          <Icon sx={menuState.index === 'dataUpload' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.DataUpload} title={t('dataUpload')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={'Data Upload'} />
                      </ListItemButton>
                    }
                    {availableMenu.options.includes('Management') &&
                      <ListItemButton
                          dense={!isMobile}
                          name="managmeent"
                          sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                          onClick={(e) => buttonClick(e, "/#/management")}
                          selected={menuState.index === 'management'} >
                        <ListItemIcon>
                          <Icon sx={menuState.index === 'management' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.Management} title={t('management')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('management')} />
                      </ListItemButton>
                    }
                    {availableMenu.options.includes('Provinces') &&
                      <ListItemButton
                          dense={!isMobile}
                          name="provinces"
                          sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                          onClick={(e) => buttonClick(e, "/#/provinces")}
                          selected={menuState.index === 'provinces'} >
                        <ListItemIcon>
                          <Icon sx={menuState.index === 'provinces' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.Provinces} title={t('provinces')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('provinces')} />
                      </ListItemButton>
                    }
                    {availableMenu.options.includes('Countries') &&
                      <ListItemButton
                          dense={!isMobile}
                          name="countries"
                          sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                          onClick={(e) => buttonClick(e, "/#/countries")}
                          selected={menuState.index === 'countries'} >
                        <ListItemIcon>
                          <Icon sx={menuState.index === 'countries' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.Countries} title={t('countries')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('countries')} />
                      </ListItemButton>
                    }
                    {availableMenu.options.includes('ScreenDefinitions') &&
                      <ListItemButton
                          dense={!isMobile}
                          name="screenDefinitions"
                          sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                          onClick={(e) => buttonClick(e, "/#/screendefinitions")}
                          selected={menuState.index === 'screendefinitions'} >
                        <ListItemIcon>
                          <Icon sx={menuState.index === 'screendefinitions' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.ScreenDefinitions} title={t('screenDefinitions')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('screenDefinitions')} />
                      </ListItemButton>
                    }
                    {availableMenu.options.includes('TenantList') &&
                      <ListItemButton
                          dense={!isMobile}
                          name="tenants"
                          sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                          onClick={(e) => buttonClick(e, "/#/tenants")}
                          selected={menuState.index === 'tenants'} >
                        <ListItemIcon>
                          <Icon sx={menuState.index === 'tenants' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.TenantList} title={t('tenants')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('tenants')} />
                      </ListItemButton>
                    }
                    {availableMenu.options.includes('Objects') &&
                      <ListItemButton
                          dense={!isMobile}
                          name="objects"
                          sx={open ? nestedDrawerOpen : nestedDrawerClosed}
                          onClick={(e) => buttonClick(e, "/#/objects")}
                          selected={menuState.index === 'objects'} >
                        <ListItemIcon>
                          <Icon sx={menuState.index === 'objects' ? activeMenuIcon : nonActiveMenuIcon}
                                screenDefs={screenDefs} screenDef={screenDefs.Objects} title={t('objects')} placement="right"/>
                        </ListItemIcon>
                        <NavDrawerLink title={t('objects')} />
                      </ListItemButton>
                    }
                  </Aux>
                </List>
              </Collapse>
            </List>
          </Aux>
        </Collapse>
    </List>
  );
}
