import React, { Component } from 'react';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import ItemReorderList from '../Inventory/ItemReorderList';
import LotsExpiring from '../Inventory/LotsExpiring';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import InventoryComparisonChart from '../General/InventoryComparisonChart';

class InventoryDashboard extends Component {
    componentDidMount = () => {
        const crumbs = [
            { path: 'active', label: this.props.t('inventoryDashboard'), screen: this.props.auth.screenDefs.inventoryDashboard }
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
    };
    render(){
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        helpPath={this.props.auth.user.helpUrl + '/'+ this.props.security.currentPath.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <InventoryComparisonChart/>
                    <ItemReorderList/>
                    <LotsExpiring/>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    security: state.security
  });
  
export default withTranslation() (connect(mapStateToProps)(InventoryDashboard));