import React, { useState, useEffect, useCallback } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { zonedTimeToUtc } from 'date-fns-tz';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DateSelect from '../UI/DateSelect/DateSelect';
import Grid from '@mui/material/Grid';
import Aux from '../../hoc/Auxilary/Auxilary';
import SectionTitle from '../UI/Typography/SectionTitle';
import ConversionHelper from '../../helpers/conversionHelper';
import { format } from 'date-fns';
import ColorHelper from '../../helpers/colorHelper';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';

const InventoryComparisonChart = (props) => { 
    const { t } = useTranslation('translation');
    const [values, setValues] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 180)),
        endDate: new Date(),
        usePostingDate: true,
        useReportCategories: true,
        type: '1',
        typeSel: {value: '1', label: t('reportCategory')},
        types: [{value: '1', label: t('reportCategory')}, {value: '2', label: t('category')}]
    });
    const [reportCategories, setReportCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [catOptions, setCatOptions] = useState([]);
    const [reportCatOptions, setReportCatOptions] = useState([]);
    const [reportCatSels, setReportCatSels] = useState([]);
    const [catSels, setCatSels] = useState([]);
    const [lines, setLines] = useState([]);
    const [allLines, setAllLines] = useState([]);
    const [repLines, setRepLines] = useState([]);
    const [allRepLines, setAllRepLines] = useState([]);
    
    const loadRecords = useCallback(async () => {
        let result = await axios.get('/api/tenanttotalschart', {
            params: {
                startDate: zonedTimeToUtc(ConversionHelper.minDate(values.startDate)),
                endDate: zonedTimeToUtc(ConversionHelper.maxDate(values.endDate)),
                usePostingDate: values.usePostingDate
            }
        });
        let reportCategories = [];
        let categories = [];
        let reportLines = [];
        let catLines = [];
        let repCatOpts = [];
        let catOpts = [];
        const colors = ColorHelper.colorList();
        if(result.data.data != null && result.data.data.length > 0){
            for(let tot of result.data.data) {
                let dateString = format(new Date(tot.date), props.auth.user.dateFormat);
                let i = 0;
                for(let row of tot.reportCategories){
                    let cat = reportCategories.find(x => x.date === dateString);
                    if(cat === undefined || cat == null){
                        cat = {
                            date: dateString,
                            [row.hcReportCategory]: row.quantity
                        };
                        reportCategories.push(cat);
                    } else {
                        cat[row.hcReportCategory] = row.quantity;
                    }
                    let found = reportLines.find(x => x.key === row.hcReportCategory);
                    if(!found){
                        reportLines.push({key: row.hcReportCategory, color: colors[i]});
                        repCatOpts.push({value: row.hcReportCategory, label: row.hcReportCategory});
                    }
                    i++;
                }
                i = 0;
                for(let row of tot.categories){
                    let cat = categories.find(x => x.date === dateString);
                    if(cat === undefined || cat == null){
                        cat = {
                            date: dateString,
                            [row.category.description]: row.quantity
                        };
                        categories.push(cat);
                    } else {
                        cat[row.category.description] = row.quantity;
                    }
                    let found = catLines.find(x => x.key === row.category.description);
                    if(!found){
                        catLines.push({key: row.category.description, color: colors[i]});
                        catOpts.push({value: row.category.description, label: row.category.description});
                    }
                    i++
                }
            }
        }
        setReportCategories(reportCategories);
        setCategories(categories);
        setLines(catLines);
        setAllLines(catLines)
        setRepLines(reportLines);
        setAllRepLines(reportLines);
        setCatOptions(catOpts);
        setReportCatOptions(repCatOpts);
        
    }, [values, props]);

    useEffect(() => {
        loadRecords();
    }, [values, loadRecords]);

    const dateChange = (name) => (e) => {
        setValues({...values, [name]: e}, () => {loadRecords()});
    };

    const changeAuto = (name, data) => (e, newValue) => {
        setValues(
            {...values,
                [name]: newValue,
                [data]: newValue != null ? newValue.value : '',
                useReportCategories: newValue == null || newValue.value === '1'
            });
    };

    const changeMultiAuto = (name) => (e, newValue) => {
        if(newValue != null && newValue.length > 0){
            if(name === 'reportCats'){
                let tempLines = JSON.parse(JSON.stringify(allRepLines));
                for(let i = tempLines.length - 1; i >= 0; i--){
                    if(!newValue.find(x => x.value === tempLines[i].key)){
                        tempLines.splice(i, 1);
                    }
                }
                setRepLines(tempLines);
                setReportCatSels(newValue);
            } else if (name === 'cats'){
                let tempLines = JSON.parse(JSON.stringify(allLines));
                for(let i = tempLines.length - 1; i >= 0; i--){
                    if(!newValue.find(x => x.value === tempLines[i].key)){
                        tempLines.splice(i, 1);
                    }
                }
                setLines(tempLines);
                setCatSels(newValue);
            }
        } else {
            setLines(allLines);
            setRepLines(allRepLines);
            setCatSels([]);
            setReportCatSels([]);
        }
    };

    return (
        <Aux>
            <SectionTitle title={t('inventoryComparison')}/>
            <Grid container spacing={3}>
                <Grid item xs={4} sm={2}>
                    <DateSelect
                        className="form-control"
                        name="startDate"
                        onChange={dateChange('startDate')}
                        value={values.startDate}
                        label={t('startDate')}
                        format={props.auth.user.dateFormat}
                    />
                </Grid>
                <Grid item xs={4} sm={2}>
                    <DateSelect
                        className="form-control"
                        id="endDate"
                        onChange={dateChange('endDate')}
                        value={values.endDate}
                        label={t('endDate')}
                        format={props.auth.user.dateFormat}
                    />
                </Grid>
                <Grid item xs={2}>
                    <AutoCompleteField
                        value={values.typeSel}
                        options={values.types}
                        onChange={changeAuto('typeSel', 'type')}
                        label={t('type')}
                    />
                </Grid>
                {values.type === '1' &&
                    <Grid item sm={6}>
                        <AutoCompleteField
                            value={reportCatSels}
                            options={reportCatOptions}
                            onChange={changeMultiAuto('reportCats')}
                            label={t('hcReportCategories')}
                            multiple={true}
                        />
                    </Grid>
                } 
                {values.type === '2' &&
                    <Grid item sm={6}>
                        <AutoCompleteField
                            value={catSels}
                            options={catOptions}
                            onChange={changeMultiAuto('cats')}
                            label={t('categories')}
                            multiple={true}
                        />
                    </Grid>
                }
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                            width={1000}
                            height={300}
                            data={values.useReportCategories ? reportCategories : categories}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            {values.useReportCategories && repLines.map((line) =>
                                <Line type="monotone" dataKey={line.key} stroke={line.color}/>
                            )}
                            {!values.useReportCategories && lines.map((line) =>
                                <Line type="monotone" dataKey={line.key} stroke={line.color}/>
                            )}
                        </LineChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </Aux>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(InventoryComparisonChart);