import React, { Component } from 'react';
import axios from 'axios';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import SubSectionHeading from '../UI/Typography/SubSectionHeading'
import { connect } from 'react-redux';
import FormLabel from '@mui/material/FormLabel';
import isEmpty from '../../is-empty';
import ActivityWorkRecord from './ActivityWorkRecord';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import DateTimeSelect from '../UI/DateTimeSelect/DateTimeSelect';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { EvoraTabs, TabCanvas, TabPanel } from '../UI/Tabs/Tabs';
import FormControl from '@mui/material/FormControl';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons'
import { approveSigSecurity, completeSigSecurity, printButtonSecurity,
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import { format } from 'date-fns'
import BatchObservation from './Forms/BatchObservation';
import LabelIcon from 'mdi-material-ui/Barcode';
import LabelDialog from '../UI/Buttons/Dialogs/Labels';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import ItemLedgerComponent from '../Inventory/ItemLedgerComponent';
import { NumericFormat } from 'react-number-format';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ConversionHelper from '../../helpers/conversionHelper';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BatchLedgers from './BatchLedgers'
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import BatchPlantHelper from '../../helpers/batchPlantHelper';
import LabelPrint from '../UI/Buttons/LabelPrint';
import PrintView from'../Printing/PrintView';
import ModalDialog from '../UI/Dialog/ModalDialog';

class BatchActivity extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            batch: null,
            batchObj: null,
            activityId: null,
            definition: null,
            _id: '',
            status: '',
            statusSel: null,
            number: '',
            inputs: [],
            outputs: [],
            roomList: [],
            rooms: [],
            sops: [],
            workRecords: [],
            statuses: [],
            schedule: null,
            uoms: [],
            items: [],
            users: [],
            userList: [],
            usersSel: [],
            completedDate: new Date(),
            quantityCompleted: 0,
            effort: '',
            showClones: false,
            cloneBatch: '',
            batchSel: null,
            batches: [],
            sopList: [],
            sopsSel: [],
            sopIds: [],
            roomTo: '',
            roomToSel: null,
            startSel: null,
            startRoom: '',
            advanceStage: false,
            count: 0,
            batchActivity: {},
            bin: '',
            binSel: [],
            showInputs: false,
            showOutputs: false,
            user: null,
            signer: null,
            reviewer: null,
            documents: [],
            managers: [],
            qas: [],
            culls: [],
            cullRoom: '',
            cullRoomSel: null,
            cullCount: 0,
            batchRoomList: [],
            flowerRoomList: [],
            deviations: [],
            batchRoomSelect: [],
            renderNum: Math.random(),
            harvest: false,
            harvestedTotes: [],
            tote: '',
            toteSel: null,
            weight: '',
            weightUOM: '',
            weighUOMSel: null,
            dateTime: '',
            toteIndex: -1,
            toteUser: '',
            toteUserSel: null,
            editTote: false,
            totes: [],
            toteRoom: '',
            toteRoomSel: null,
            harvestedRooms: [],
            harvestedRoom: '',
            harvestedRoomSel: null,
            harvestedRoomCount: '',
            ncrs: [],
            tabKey: 0,
            tabLabels: [{name: t('activity'), disabled: false}],
            bins: [],
            security: [],
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            isDestruction: false,
            cullReasons: [],
            cullReason: '',
            cullReasonSel: null,
            batchQuantity: '',
            plant: false,
            sourceRoom: '',
            sourceRoomSel: null,
            harvestedSaved: false,
            flagForSignature: 0,
            showObservation: false,
            observation: '',
            optionsMenu: [],
            labelData: {},
            itemCounts: [],
            currentItem: {},
            containerPrintOpen: false,
            containerLabel: {},
            labelPrintPermission: false,
            dialogOpen: false,
            observationAccess: false,
            enableUnlock: false,
            gotValues: false,
            inputCost: '',
            labourCost: '',
            enableSave: true,
            sigData: null,
            activeUsers: [],
            activeManagers: [],
            activeQas: [],
            form: '',
            template: '',
            hasForm: false,
            latestDate: null,
            sequence: null,
            currStage: null,
            hasItemLedgers: false,
            hasBatchLedgers: false,
            inputLines: [],
            outputLines: [],
            inputItem: '',
            inputSel: null,
            outputItem: '',
            outputSel: null,
            inputUOM: '',
            inputUOMSel: '',
            inputContainer: '',
            inputContainerSel: null,
            inputContainers: [],
            outputUOM: '',
            outputUOMSel: null,
            outputBin: '',
            outputBinSel: null,
            inputCount: '',
            outputCount: '',
            customLot:'',
            tareWeight: '',
            containerType: '',
            containerTypeSel: null,
            outputTotalWeight: '',
            outputTotalWeightUOM: '',
            outputTotalWeightUOMSel: null,
            containerTypes: [],
            stagePlantType: null,
            stagePlant: null,
            changeRoom: false,
            prevPlant: null,
            harvestedLots: [],
            outputLot: '',
            outputLotSel: null,
            outputLabels: [],
            plantLabel: {},
            goBack: -1,
            itemLots: [],
            outputLots: [],
            outputContainers: [],
            outputContainer: '',
            outputContainerSel: null,
            newLot: false,
            allItems: [],
            tissueExport: false,
            templateObj: null,
            isMultiplcation: false,
            addToBatch: false,
            isAddToBatch: false,
            tissueClone: false,
            plantLot: false,
            printViewer: false,
            finalDocument: null,
            cullTotal: null,
            cullPlants: '',
            fullItemList: [],
            completeAct: false,
            showSequence: false,
            newSequence: '',
            lastSequence: null
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems, common.cacheValues.user, common.cacheValues.status, common.cacheValues.quantityUOM,
                                common.cacheValues.defaultGrowingRoom, common.cacheValues.cullReason, common.cacheValues.defaultBin, common.cacheValues.batchActivitySop,
                                common.cacheValues.batchActivityForm, common.cacheValues.containerType], this.props.auth.constants, null, this.props.auth.user.tenant);
        const constants = this.props.auth.constants;
        let items = cacheValues.allItems;
        let allItems = cacheValues.allItems;
        let dryIndex = allItems.findIndex(x => x.value === constants.items.dryPlantWaste);
        if(dryIndex > -1){
            allItems.splice(dryIndex, 1);
        }
        let exciseIndex = allItems.findIndex(x => x.value === constants.items.exciseWaste);
        if(exciseIndex > -1){
            allItems.splice(exciseIndex, 1);
        }
        let hempIndex = allItems.findIndex(x => x.value === constants.items.hempWaste);
        if(hempIndex > -1){
            allItems.splice(hempIndex, 1);
        }
        
        let allUsers = cacheValues.users;
        let statuses = cacheValues.statuses;
        const userValues = await ValueHelper.screenUsers(common.screens.batchActivity, this.props.auth.user.tenant);
        let params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        var schedule = null;
        let goBack = -1;
        if(scheduleId != null){
            goBack = -2;
            schedule = await axios.get('/api/schedulebase/' + scheduleId);
        }
        let harvest = false;
        if(schedule != null && schedule.data.activityDefinition != null && schedule.data.activityDefinition.definitionId === this.props.auth.constants.batchActivityDefs.harvest){
            harvest = true;
        }
        let plant = false;
        if(schedule != null && schedule.data.activityDefinition != null && schedule.data.activityDefinition.definitionId === this.props.auth.constants.batchActivityDefs.plant){
            plant = true;
        }
        let statusSel = statuses.find(x => x.value === this.props.auth.constants.statuses.inprogress);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        })
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        let gSel = cacheValues.quantityUOMs.find(x => x.value === this.props.auth.constants.quantityUOMs.g);
        this.setState({
            batch: schedule != null ? schedule.data.ebatch : null,
            activityId: schedule != null ? schedule.data.batchActivityId : null,
            definition : schedule != null ? schedule.data.activityDefinition : null,
            harvest: harvest,
            plant: plant,
            statuses: cacheValues.statuses,
            uoms: cacheValues.quantityUOMs,
            items: items,
            allItems: allItems,
            fullItemList: cacheValues.allItems,
            roomList: cacheValues.rooms,
            sopList: cacheValues.sops,
            userList: userValues.create,
            managers: userValues.review,
            qas: userValues.approve,
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            activeQas: activeQas,
            schedule: scheduleId,
            status: this.props.auth.constants.statuses.inprogress,
            statusSel: statusSel,
            bins: cacheValues.defaultBins,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            cullReasons: cacheValues.cullReasons,
            containerTypes: cacheValues.containerTypes,
            template: cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            allUsers: allUsers,
            g: this.props.auth.constants.quantityUOMs.g,
            gSel: gSel,
            goBack: goBack
        }, async () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== '')
                this.loadRecord();
            else if(schedule != null){
                let actBase = await axios.get('/api/batchactbase/' + schedule.data.ebatch + '/' + schedule.data.batchActivityId);
                let activity = actBase.data.activity;
                let batchRoomList = [];
                let flowerRoomList = [];
                if(actBase.data.batch.currentRooms != null && actBase.data.batch.currentRooms.length > 0){
                    actBase.data.batch.currentRooms.forEach(row => {
                        row.items.forEach(item => {
                            batchRoomList.push({
                                value: row.room._id + item.item._id,
                                label: row.room.name + '-' + item.item.name + ' (' + item.count + ')',
                                room: row.room._id,
                                count: item.count,
                                item: item.item._id
                            });
                            if(item.item.category === this.props.auth.constants.itemCategories.flowerPlants){
                                flowerRoomList.push({
                                    value: row.room._id + item.item._id,
                                    label: row.room.name + '-' + item.item.name + ' (' + item.count + ')',
                                    count: item.count,
                                    room: row.room._id,
                                    item: item.item._id
                                });
                            }
                        })
                    });
                }
                let inputs = [];
                if(activity.inputs != null && activity.inputs.length > 0){
                    activity.inputs.forEach(input => {
                        let itemSel = cacheValues.items.find(x => x.value === input.item);
                        let uomSel = cacheValues.quantityUOMs.find(x => x.value === input.uom);
                        inputs.push({
                            showInput: true,
                            item: input.item,
                            itemSel: itemSel,
                            amount: '',
                            uom: input.uom,
                            uomSel: uomSel,
                            lot: null,
                            containers: input.containers
                        });
                    })
                }
                let outputs = [];
                if(activity.outputs != null && activity.outputs.length > 0){
                    activity.outputs.forEach(output => {
                        let itemSel = cacheValues.items.find(x => x.value === output.item);
                        let uomSel = cacheValues.quantityUOMs.find(x => x.value === output.uom);
                        outputs.push({
                            showOutputs: true,
                            item: output.item,
                            itemSel: itemSel,
                            uom: output.uom,
                            uomSel: uomSel,
                            lot: null,
                            lots: output.lots,
                            open: true
                        });
                    })
                }
                let sopsSel = [];
                let sops = [];
                if(activity.sops != null && activity.sops.length > 0){
                    activity.sops.forEach(sop => {
                        let val = this.state.sopList.find(x => x.value === sop);
                        sopsSel.push(val);
                        sops.push({
                            sop: sop,
                            workRecord: null,
                            original: false
                        })
                    });
                }
                let data = {
                    status: this.state.status,
                    completedDate: this.state.completedDate,
                    quantityCompleted: actBase.data.batch.currentQuantity,
                    batch: this.state.batch,
                    activityId: this.state.activityId,
                    schedule: this.state.schedule,
                    sops: sops,
                    inputs: inputs,
                    outputs: outputs
                };
                let _id = null;
                let number = null;
                try {
                    let actIdResult = await axios.post('/api/batchactivityid', data);
                    _id = actIdResult.data.id;
                    number = actIdResult.data.number;
                    this.setState({_id: _id, number: number}, () => {
                        this.loadRecord();
                    });
                } catch(err){
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                }
            }else {
                const crumbs = [
                    { path: '/#/cultivationbatches', label: this.props.t('batches'), screen: this.props.auth.screenDefs.CultivationBatch},
                    { path: '/#/cultivationbatch', label: this.props.t('batches'), screen: this.props.auth.screenDefs.CultivationBatch},
                    { path: 'active', label: this.props.t('batchActivity'), screen: this.props.auth.screenDefs.BatchActivity}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const constants = this.props.auth.constants;
        let record = await axios.get('/api/batchactivity/' + this.state._id);
        let items = this.state.items;
        let allItems = this.state.allItems;
        const fullItemList = this.state.fullItemList;
        if(!this.state.gotValues){
            let itemResult = await axios.get('/api/batchactitems/' + record.data.batch.strain._id);
            items = itemResult.data;
            if(record.data.definition === constants.batchActivityDefs.tissueClone){
                items = [];
                let cloneItem = allItems.find(x => x.value === record.data.batch.strain.cloneItem);
                items.push(cloneItem);
                let wasteItems = allItems.filter(x => x.category === constants.itemCategories.growWaste);
                items = items.concat(wasteItems);
            }
        }
        let plantletItemFull = allItems.find(x => x.category === constants.itemCategories.plantlet);
        let batchRoomList = [];
        let flowerRoomList = [];
        let roomList = record.data.roomList;
        let vegPlants = false;
        let wholePlants = false;
        let seeds = false;
        let plantlets = false;
        let rootlets = false;
        let weanlets = false;
        let showItems = record.data.status === constants.statuses.new || record.data.status === constants.statuses.inprogress;
        let roomsCompleted = record.data.roomsCompleted;
        let setRooms = false;
        if(roomsCompleted == null || roomsCompleted.length === 0){
            roomsCompleted = [];
            if(record.data.completed == null && record.data.effort == null){
                setRooms = true;
            }
        }
        let def = null;
        let batchActivity;
        let harvest = false;
        let plantLot = false;
        let isMultiplcation = false;
        let tissueExport = false;
        let tissueClone = false;
        let plant = false;
        let importing = false;
        let isDestruction = false;
        let latestDate = null;
        let currStage = null;
        let stagePlantType = null;
        let stagePlant = null;
        let changeRoom = false;
        let prevPlant = null;
        let stageIndex = 0;
        let harvestedLots = [];
        let isAddToBatch = false;
        let rootletItem = fullItemList.find(x => x.category === constants.itemCategories.rootlet);
        let weanletItem = fullItemList.find(x => x.category === constants.itemCategories.weanlet);
        if(record.data.batch.outputLots != null && record.data.batch.outputLots.length > 0){
            for(let row of record.data.batch.outputLots){
                harvestedLots.push({value: row._id, label: row.number});
            }
        }
        let completeAct = false;
        for(let stage of record.data.batch.stages){
            let act = stage.activities.find(x => x._id === record.data.batchActivityId);
            if(act){
                currStage = stage.stage;
                stagePlantType = BatchPlantHelper.determineStagePlantType(stage, constants);
                stagePlant = BatchPlantHelper.determineStagePlant(record.data.batch.strain, stagePlantType, constants, fullItemList);
                def = act.activityDefinition;
                if(def.definitionId === constants.batchActivityDefs.harvest){
                    harvest = true;
                } else if(def.definitionId === constants.batchActivityDefs.plant){
                    plant = true;
                } else if(def.definitionId === constants.batchActivityDefs.import){
                    importing = true;
                } else if (def.definitionId === constants.batchActivityDefs.cropDest){
                    isDestruction = true;
                } else if(def.definitionId === constants.batchActivityDefs.tissueExport){
                    tissueExport = true;
                } else if (def.definitionId === constants.batchActivityDefs.multiplyPlantlet){
                    isMultiplcation = true;
                } else if (def.definitionId === constants.batchActivityDefs.tissueClone){
                    tissueClone = true;
                } else if (def.definitionId === constants.batchActivityDefs.plantLot){
                    let strainItems = allItems.filter(x => x.strain != null);
                    for(let row of strainItems){
                        if(row.value !== stagePlant){
                            let rowIndex = allItems.findIndex(x => x.value === row.value);
                            allItems.splice(rowIndex, 1);
                        }
                    }
                    plantLot = true;
                }
                if(def.changeRoom === true && plant === false && importing === false){
                    changeRoom = true;
                    if(stageIndex > 0){
                        prevPlant = BatchPlantHelper.determineStagePlantFull(record.data.batch.strain, record.data.batch.stages[stageIndex - 1], constants, fullItemList);
                    }
                }
                batchActivity = act;
            }
            for(let act of stage.activities){
                if(act.activity != null && act.activity.status != null && (act.activity.status.toString() === constants.statuses.reviewed || 
                act.activity.status.toString() === constants.statuses.complete || act.activity.status.toString() === constants.statuses.signed)){
                    if(act.activity.status.toString() === constants.statuses.complete){
                        if(act.activity._id !== this.state._id){
                            completeAct = true;
                        }
                    }
                    if(act.actualDate != null && act.actualDate !== ''){
                        if(latestDate == null || Date.parse(act.actualDate) > Date.parse(latestDate)){
                            latestDate = act.actualDate;
                        }
                    }
                }
            }
            stageIndex++;
        }
        if(record.data.batch.currentRooms != null && record.data.batch.currentRooms.length > 0){
            record.data.batch.currentRooms.forEach(row => {
                row.items.forEach(item => {
                    if(item.item._id === record.data.batch.strain.cloneItem || item.item._id === record.data.batch.strain.vegItem || 
                        item.item._id === record.data.batch.strain.motherItem){
                        vegPlants = true;
                    } else if(item.item._id === record.data.batch.strain.flowerItem){
                        wholePlants = true;
                    } else if(item.item._id === record.data.batch.strain.seedItem){
                        seeds = true;
                    } else if(plantletItemFull != null && item.item._id === plantletItemFull.value){
                        plantlets = true;
                    } else if(rootletItem != null && item.item._id === rootletItem.value){
                        rootlets = true;
                    } else if (weanletItem != null && item.item._id === weanletItem.value){
                        weanlets = true;
                    }
                    if(item.item._id === stagePlant || (prevPlant != null && item.item._id === prevPlant)){
                        batchRoomList.push({
                            value: row.room._id + item.item._id,
                            label: row.room.name + '-' + item.item.name + ' (' + item.count + ')',
                            count: item.count,
                            room: row.room._id,
                            item: item.item._id
                        });
                    }
                    if(item.item.category === constants.itemCategories.flowerPlants){
                        flowerRoomList.push({
                            value: row.room._id + item.item._id,
                            label: row.room.name + '-' + item.item.name + ' (' + item.count + ')',
                            count: item.count,
                            room: row.room._id,
                            item: item.item._id
                        });
                    } 
                })
                if((record.data.status === constants.statuses.new || record.data.status === constants.statuses.inprogress) && setRooms){
                    let roomSel = roomList.find(x => x.value === row.room._id);
                    let roomStageItem = row.items.find(x => x.item._id === stagePlant);
                    if(roomStageItem != null){
                        roomsCompleted.push({
                            room: row.room._id,
                            roomSel: {value: roomSel.value, label: roomSel.label + '-' + roomStageItem.item.name},
                            effort: '',
                            quantityCompleted: '',
                            item: roomStageItem.item._id,
                            count: roomStageItem.count,
                            itemSel: fullItemList.find(x => x.value === roomStageItem.item._id)
                        })
                    }
                    if(prevPlant != null){
                        let roomPrev = row.items.find(x => x.item._id === prevPlant);
                        if(roomPrev != null){
                            roomsCompleted.push({
                                room: row.room._id,
                                roomSel: {value: roomSel.value, label: roomSel.label + '-' + roomPrev.item.name},
                                effort: '',
                                quantityCompleted: '',
                                item: roomPrev.item._id,
                                count: roomPrev.count,
                                itemSel: fullItemList.find(x => x.value === roomPrev.item._id)
                            })
                        }
                    }
                } 
            });
        }
        let batchRoomSelect = record.data.batchRoomSelect;
        for(let i = batchRoomSelect.length -1; i >= 0; i--){
            let row = batchRoomSelect[i];
            if(row.item !== stagePlant){
                if(prevPlant != null && prevPlant === row.item){
                    continue;
                }
               batchRoomSelect.splice(i, 1); 
            }
        }
        if(record.data.status !== constants.statuses.new && record.data.statuses !== constants.statuses.inprogress && roomsCompleted.length > 0){
            for(let row of roomsCompleted){
                let roomSel = roomList.find(x => x.value === row.room);
                row.roomSel = roomSel;
            }
        }
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let usersSel = [];
        let showInputs = false;
        let showOutputs = false;
        let fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, constants);
        if(batchRoomList == null || batchRoomList.length === 0){
            if(!plant && !harvest && !isDestruction && currStage !== constants.batchStages.harvest && fieldAccess){
                fieldAccess = false;
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('noPlantsStage'), title: t('warning'), severity: 'warning'}});
            }
        }
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, constants)
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, constants);
        const printAccess = printButtonSecurity(this.props.permission, record.data.status, this.props.auth.common, constants, this.props.auth.common.statusTypes.batch);
        const observationAccess = record.data.batch.currentRooms != null && record.data.batch.currentRooms.length > 0 ? true : false;
        let userList = this.state.userList;
        record.data.users.forEach(user => {
            let val = userList.find(x => x.value === user);
            if(val != null){
                usersSel.push(val);
            } else {
                val = this.state.allUsers.find(x => x.value === user);
                if(val != null){
                    usersSel.push(val);
                    userList.push(val);
                }
            }
        });
        let sopsSel = [];
        let sopIds = [];
        let tabLabels = [{name: t('activity'), disabled: false}];
        if(record.data.sops != null && record.data.sops.length > 0){
            record.data.sops.forEach(sop => {
                let val = this.state.sopList.find(x => x.value === sop.sop);
                sopsSel.push(val);
                sopIds.push(sop.sop);
                tabLabels.push({name: val.label, disabled: false});
            });
        }
        if(record.data._id != null && record.data._id !== ''){
            if(record.data.hasLedgers){
                tabLabels.push({name: t('itemLedgers'), disabled: false});
            }
            if(record.data.hasBatchLedgers){
                tabLabels.push({name: t('batchLedgers'), disabled: false})
            }
        }
        if(record.data.rooms != null && record.data.rooms.length > 0){
            record.data.rooms.forEach(room => {
                let roomSel = batchRoomList.find(x => x.value === room.room + room.endItem);
                if(!roomSel)
                    roomSel = this.state.roomList.find(x => x.value === room.room);
                room.roomSel = roomSel;
                let startSel =  null;
                if(room.startRoom != null){
                    startSel = batchRoomList.find(x => x.value === room.startRoom + room.startItem)
                    if(!startSel){
                        startSel = this.state.roomList.find(x => x.value === room.startRoom);
                    }
                }
                room.startSel = startSel;
            });
        }
        let cullTotal = 0;
        if(record.data.culls != null && record.data.culls.length > 0){
            record.data.culls.forEach(cull => {
                let roomSel = batchRoomList.find(x => x.value === cull.room + cull.item);
                if(!roomSel)
                    roomSel = this.state.roomList.find(x => x.value === cull.room);
                cull.roomSel = roomSel;
                let reasonSel = cull.reason != null ? this.state.cullReasons.find(x => x.value === cull.reason) : null;
                cull.reasonSel = reasonSel;
                cullTotal += parseInt(cull.count);
            });
        }
        let inputHasCount = false;
        let inputLines = [];
        if(record.data.inputs != null && record.data.inputs.length > 0){
            record.data.inputs.forEach(input => {
                let line = inputLines.find(x => x.item === input.item);
                showInputs = true;
                let itemSel = fullItemList.find(x => x.value === input.item);
                if(itemSel != null && itemSel.hasCount)
                    inputHasCount = true;
                let uomSel = this.state.uoms.find(x => x.value === input.uom);
                let containerSel = input.container != null ? input.containers.find(x => x.value === input.container) : null;
                if(line == null){
                    inputLines.push({
                        itemSel: itemSel,
                        item: input.item,
                        uom: input.uom,
                        uomSel: uomSel,
                        count: input.count,
                        amount: input.amount,
                        containers: input.containers,
                        lines: [{
                            _id: input._id,
                            amount: input.amount,
                            count: input.count,
                            lot: input.lot,
                            lotTransaction: input.lotTransaction,
                            container: input.container,
                            containerSel: containerSel,
                            containerTransaction: input.containerTransaction,
                            itemLedger: input.itemLedger,
                            cloneItemLedger: input.cloneItemLedger,
                            reservation: input.reservation,
                            reservedAmount: input.reservedAmount
                        }]
                    });
                } else {
                    line.amount = input.amount != null && line.amount != null ? parseFloat(input.amount) + parseFloat(line.amount) : input.amount != null ? input.amount : line.amount;
                    line.count = input.count != null && line.count != null ? parseInt(input.count) + parseInt(line.count) : input.count != null ? input.count : line.count;
                    line.lines.push({
                        _id: input._id,
                        amount: input.amount,
                        count: input.count,
                        lot: input.lot,
                        lotTransaction: input.lotTransaction,
                        container: input.container,
                        containerSel: containerSel,
                        containerTransaction: input.containerTransaction,
                        itemLedger: input.itemLedger,
                        cloneItemLedger: input.cloneItemLedger,
                        reservation: input.reservation,
                        reservedAmount: input.reservedAmount
                    });
                }
            })
        } else {
            if(batchActivity.activityDefinition.definitionId === constants.batchActivityDefs.pollenate){
                if(record.data.batch.strain.pollenItem != null){
                    let contRes = await axios.post('/api/itemcontainers', {items:[record.data.batch.strain.pollenItem], date: record.data.date, available: true, consume: true});
                    record.data.inputs = [];
                    let itemSel = fullItemList.find(x => x.value === record.data.batch.strain.pollenItem);
                    let uomSel = this.state.uoms.find(x => x.value === itemSel.uom);
                    record.data.inputs.push({
                        item: record.data.batch.strain.pollenItem,
                        itemSel: itemSel,
                        amount: '0',
                        uom: itemSel.uom,
                        uomSel: uomSel,
                        containers: contRes.data,
                        open: showItems
                    });
                }
            }
        }
        if(record.data.template.batchType !== constants.batchTypes.plantlet && batchActivity != null && batchActivity.activityDefinition != null && 
            batchActivity.activityDefinition.definitionId !== constants.batchActivityDefs.tissueExport){
            let ind = items.findIndex(x => x.category === constants.itemCategories.plantlet);
            if(ind > -1){
                items.splice(ind, 1);
            }
        }
        let outputHasCount = false;
        let outputs = record.data.outputs;
        let outputLines = [];
        let outputLabels = [];
        let itemLots = record.data.itemLots;
        if(outputs != null && outputs.length > 0){
            outputs.forEach(output => {
                let line = outputLines.find(x => x.item === output.item);
                if(output.addToBatch === true){
                    isAddToBatch = true;
                }
                showOutputs = true;
                let itemSel = fullItemList.find(x => x.value === output.item);
                if(itemSel != null && itemSel.hasCount)
                    outputHasCount = true;
                output.itemSel = itemSel;
                let uomSel = this.state.uoms.find(x => x.value === output.uom);
                output.uomSel = uomSel;
                let itemLotRow = itemLots.find(x => x.item === output.item);
                let lots = itemLotRow != null ? itemLotRow.values : [];
                let containers = [];
                if(output.lot != null && itemLotRow != null && itemLotRow.lots != null && itemLotRow.lots.length > 0){
                    let lotRow = itemLotRow.lots.find(x => x.lot === output.lot);
                    if(lotRow != null){
                        containers = lotRow.containers;
                    }
                }
                let lotSel = output.lot != null ? lots.find(x => x.value === output.lot) : null;
                output.lotSel = lotSel;
                let newLot = output.newLot === true ? true : itemSel?.customNumber !== true ? false : lots == null || lots.length === 0 ? true : false;
                let binSel = output.bin != null ? record.data.bins.find(x => x.value === output.bin) : null;
                output.binSel = binSel;
                let containerSel = null;
                let tareWeight = output.tareWeight;
                let totalWeight = parseFloat(output.amount ?? 0) + parseFloat(tareWeight ?? 0);
                if(output.container != null) {
                    containerSel = containers.find(x => x.value === output.container);
                }
                output.containerSel = containerSel;
                output.tareWeight = tareWeight;
                output.totalWeight = totalWeight.toString();
                let sourceRoomSel = null;
                if(!tissueClone || output.item !== record.data.batch.strain.cloneItem){
                    sourceRoomSel = output.sourceRoom != null ? batchRoomList.find(x => x.value === output.sourceRoom + output.item) : null;
                } else {
                    sourceRoomSel = output.sourceRoom != null ? batchRoomList.find(x => x.value === output.sourceRoom + weanletItem.value) : null;
                }
                if(sourceRoomSel == null && output.sourceRoom != null){
                    sourceRoomSel = this.state.roomList.find(x => x.value === output.sourceRoom);
                }
                output.sourceRoomSel = sourceRoomSel;
                output.newLot = output.newLot ? true : false;
                output.tareRequired = tareWeight > 0 ? true : false;
                output.open = showItems && (output.amount == null || output.amount === '');
                let containerTypeSel = containerSel != null && containerSel !== '' ? 
                                       this.state.containerTypes.find(x => x.value === containerSel.type) : this.state.containerTypes.find(x => x.value === output.containerType);
                if(line == null){
                    outputLines.push({
                        item: output.item,
                        itemSel: itemSel,
                        amount: output.amount,
                        uom: output.uom,
                        uomSel: uomSel,
                        count: output.count,
                        totalWeight: totalWeight.toString(),
                        lots,
                        lines: [{
                            _id: output._id,
                            amount: output.amount,
                            count: output.count,
                            lot: output.lot,
                            lotSel: lotSel,
                            lotTransaction: output.lotTransaction,
                            container: output.container,
                            containerSel: containerSel,
                            containerTransaction: output.containerTransaction,
                            bin: output.bin,
                            binSel: binSel,
                            itemLedger: output.itemLedger,
                            sourceRoom: output.sourceRoom,
                            sourceRoomSel: sourceRoomSel,
                            ledger: output.ledger,
                            tareWeight: output.tareWeight,
                            totalWeight: output.totalWeight,
                            newLot: newLot,
                            customLot: output.customLot,
                            containerType: output.containerType,
                            containerTypeSel: containerTypeSel,
                            lots: output.lots,
                            containers: containers,
                            addToBatch: output.addToBatch,
                            cullCount: output.cullCount
                        }]
                    });
                } else {
                    line.amount = line.amount != null && output.amount != null ? parseFloat(line.amount) + parseFloat(output.amount) : output.amount != null ? output.amount : line.amount;
                    line.count = output.count != null && line.count != null ? parseInt(output.count) + parseInt(line.count) : output.count != null ? output.count : line.count;
                    line.totalWeight = line.totalWeight != null && output.totalWeight != null ? parseFloat(line.totalWeight) + parseFloat(output.totalWeight) :
                        output.totalWeight != null ? outputHasCount.totalWeight : line.totalWeight;
                    line.lines.push({
                        _id: output._id,
                        amount: output.amount,
                        count: output.count,
                        lot: output.lot,
                        lotSel: lotSel,
                        lotTransaction: output.lotTransaction,
                        container: output.container,
                        containerSel: containerSel,
                        containers: containers,
                        containerTransaction: output.containerTransaction,
                        bin: output.bin,
                        binSel: binSel,
                        itemLedger: output.itemLedger,
                        sourceRoom: output.sourceRoom,
                        sourceRoomSel: sourceRoomSel,
                        ledger: output.ledger,
                        tareWeight: output.tareWeight,
                        totalWeight: output.totalWeight,
                        newLot: newLot,
                        customLot: output.customLot,
                        containerType: output.containerType,
                        containerTypeSel: containerTypeSel,
                        lots: output.lots,
                        addToBatch: output.addToBatch,
                        cullCount: output.cullCount
                    });
                }
                if(containerSel != null && uomSel != null && containerTypeSel != null && itemSel != null && lotSel != null) {
                    outputLabels.push({
                        id: output.container,
                        container: containerSel.number,
                        lot: output.customLot != null && output.customLot !== '' ? output.customLot : 
                             lotSel != null && lotSel.label != null ? lotSel.label : '',
                        item: itemSel != null && itemSel.label != null ? itemSel.label : '',
                        quantity: ConversionHelper.round3(output.amount),
                        uom: uomSel.label,
                        category: containerTypeSel.label,
                        itemNumber: itemSel != null && itemSel.number != null ? itemSel.number : '',
                        date: record.data.completedDate,
                        qrPath: `${window.location.origin}/#/container/${output.container}`,
                        strain: itemSel.strain != null && itemSel.strain !== '' ? itemSel.strain : '',
                        strainCode: itemSel.strainCode != null && itemSel.strainCode !== '' ? itemSel.strainCode : '',
                        dateFormat: this.props.auth.user.dateFormat
                    });
                }
            });
            if(harvest && record.data.status === constants.statuses.new){
                let outLine = outputLines.find( x => x.item === record.data.batch.outputItem._id);
                let itemLotRow = itemLots.find(x => x.item === record.data.batch.outputItem._id);
                let lots = itemLotRow != null ? itemLotRow.values : [];
                if(outLine == null){
                    let itemSel = items.find(x => x.value === record.data.batch.outputItem._id);
                    let uomSel = this.state.uoms.find(x => x.value === record.data.batch.outputItem.uom);
                    outputLines.unshift({
                        item: record.data.batch.outputItem._id,
                        itemSel: itemSel,
                        amount: 0,
                        uom: record.data.batch.outputItem.uom,
                        uomSel: uomSel,
                        lots: lots,
                        lines: [{}]
                    });
                }
            }
            if(tissueExport && record.data.status === constants.statuses.new){
                if(plantletItemFull != null){
                    outputLines.push({
                        item: plantletItemFull.value,
                        itemSel: plantletItemFull,
                        amount: 0,
                        uom: plantletItemFull.uom,
                        uomSel: this.state.uoms.find(x => x.value === plantletItemFull.uom),
                        lines: [{}]
                    })
                }
            }
        } else {
            if(harvest && record.data.status === constants.statuses.new){
                let itemSel = items.find(x => x.value === record.data.batch.outputItem._id);
                let uomSel = this.state.uoms.find(x => x.value === record.data.batch.outputItem.uom);
                let itemLotRow = itemLots.find(x => x.item === record.data.batch.outputItem._id);
                let lots = itemLotRow != null ? itemLotRow.values : [];
                outputLines.push({
                    item: record.data.batch.outputItem._id,
                    itemSel: itemSel,
                    amount: 0,
                    uom: record.data.batch.outputItem.uom,
                    uomSel: uomSel,
                    lots: lots,
                    lines: [{lots: harvestedLots}]
                });
            } else if(tissueExport && record.data.status === constants.statuses.new){
                if(plantletItemFull != null){
                    outputLines.push({
                        item: plantletItemFull.value,
                        itemSel: plantletItemFull,
                        amount: 0,
                        uom: plantletItemFull.uom,
                        uomSel: this.state.uoms.find(x => x.value === plantletItemFull.uom),
                        lines: [{}]
                    })
                }
            } else if(batchActivity != null && batchActivity.activityDefinition != null && batchActivity.activityDefinition.definitionId === constants.batchActivityDefs.harvestPollen){
                if(record.data.batch.strain.pollenItem != null){
                    let itemSel = items.find(x => x.value === record.data.batch.strain.pollenItem);
                    let uomSel = this.state.uoms.find(x => x.value === itemSel.uom);
                    outputLines.push({
                        item: record.data.batch.strain.pollenItem,
                        itemSel: itemSel,
                        amount: 0,
                        uom: itemSel.uom,
                        uomSel: uomSel,
                        lines: [{}]
                    });
                }
            } else if(batchActivity != null && batchActivity.activityDefinition != null && batchActivity.activityDefinition.definitionId === constants.batchActivityDefs.harvestSeeds){
                if(record.data.batch.strain.seedItem != null){
                    let itemSel = items.find(x => x.value === record.data.batch.strain.seedItem);
                    let uomSel = this.state.uoms.find(x => x.value === itemSel.uom);
                    outputLines.push({
                        item: record.data.batch.strain.seedItem,
                        itemSel: itemSel,
                        amount: 0,
                        uom: itemSel.uom,
                        uomSel: uomSel,
                        lines: [{}]
                    });
                }
            } else if(batchActivity != null && batchActivity.activityDefinition != null && batchActivity.activityDefinition.definitionId === constants.batchActivityDefs.plantLot){
                let item = record.data.batch.stage === constants.batchStages.prop ? record.data.batch.strain.cloneItem :
                            record.data.batch.stage === constants.batchStages.veg ? record.data.batch.strain.vegItem :
                            record.data.stage === constants.batchStages.mother ? record.data.batch.strain.motherItem :
                            record.data.batch.stage === constants.batchStages.flower ? record.data.batch.strain.flowerItem : null ;
                if(item != null){
                    let itemSel = items.find(x => x.value === item);
                    if(itemSel != null){
                        let uomSel = this.state.uoms.find(x => x.value === itemSel.uom);
                        outputLines.push({
                            item: item,
                            itemSel: itemSel,
                            amount: 0,
                            uom: itemSel.uom,
                            uomSel: uomSel,
                            lines: [{}]
                        });
                    }
                }
            }
        }
        if(this.props.auth.user.cropType === this.props.auth.common.cropTypes.cannabis){
            if(batchActivity != null && batchActivity.defId != null && batchActivity.defId !== constants.batchActivityDefs.harvest && fieldAccess &&
                batchActivity.defId !== constants.batchActivityDefs.harvestWaste){
                let wetWasteIndex = items.findIndex(x => x.value === constants.items.wetPlantWaste);
                if(wetWasteIndex > -1){
                    items.splice(wetWasteIndex, 1);
                }
                wetWasteIndex = allItems.findIndex(x => x.value === constants.items.wetPlantWaste);
                if(wetWasteIndex > -1){
                    allItems.splice(wetWasteIndex, 1);
                }
                if(!seeds){
                    let seedIndex = items.findIndex(x => x.value === constants.items.seedWaste);
                    if(seedIndex > -1)
                        items.splice(seedIndex, 1);
                    seedIndex = allItems.findIndex(x => x.value === constants.items.seedWaste);
                        if(seedIndex > -1)
                            allItems.splice(seedIndex, 1);
                }
                if(!vegPlants && !plantlets && !rootlets && !weanlets){
                    let vegWasteIndex = items.findIndex(x => x.value === constants.items.vegPlantWaste);
                    if(vegWasteIndex > -1)
                        items.splice(vegWasteIndex, 1);
                    vegWasteIndex = allItems.findIndex(x => x.value === constants.items.vegPlantWaste);
                        if(vegWasteIndex > -1)
                            allItems.splice(vegWasteIndex, 1);
                }
                if (!wholePlants){
                    let wpWasteIndex = items.findIndex(x => x.value === constants.items.wholePlantWaste);
                    if(wpWasteIndex > -1)
                        items.splice(wpWasteIndex, 1);
                    wpWasteIndex = allItems.findIndex(x => x.value === constants.items.wholePlantWaste);
                        if(wpWasteIndex > -1)
                            allItems.splice(wpWasteIndex, 1);
                }
            } else if(batchActivity != null && batchActivity.defId != null && fieldAccess){
                let vegWasteIndex = items.findIndex(x => x.value === constants.items.vegPlantWaste);
                if(vegWasteIndex > -1)
                    items.splice(vegWasteIndex, 1);
                vegWasteIndex = allItems.findIndex(x => x.value === constants.items.vegPlantWaste);
                    if(vegWasteIndex > -1)
                        allItems.splice(vegWasteIndex, 1)
                let wpWasteIndex = items.findIndex(x => x.value === constants.items.wholePlantWaste);
                if(wpWasteIndex > -1)
                    items.splice(wpWasteIndex, 1);
                 wpWasteIndex = allItems.findIndex(x => x.value === constants.items.wholePlantWaste);
                    if(wpWasteIndex > -1)
                        allItems.splice(wpWasteIndex, 1);
                let seedIndex = items.findIndex(x => x.value === constants.items.seedWaste);
                if(seedIndex > -1)
                    items.splice(seedIndex, 1);
                seedIndex = allItems.findIndex(x => x.value === constants.items.seedWaste);
                    if(seedIndex > -1)
                        allItems.splice(seedIndex, 1);
            }
        }
        let harvestedSaved = false;
        if(record.data.harvestedRooms != null && record.data.harvestedRooms.length > 0){
            if(record.data.harvestedRooms[0]._id != null && record.data.harvestedRooms[0]._id !== '')
                //harvestedSaved = true;
            record.data.harvestedRooms.forEach(row => {
                let roomSel = batchRoomList.find(x => x.value === row.room + record.data.batch.strain.flowerItem);
                if(roomSel == null){
                    roomSel = this.state.roomList.find(x => x.value === row.room);
                }
                row.roomSel = roomSel;
            });
        }
        let culls = record.data.culls;
        if(isDestruction && (culls == null || culls.length === 0)){
            culls = [];
            record.data.batch.currentRooms.forEach(row => {
                let cullRoomSel = this.state.roomList.find(x => x.value === row.room._id);
                culls.push({room: row.room._id, count: row.count, roomSel: cullRoomSel});
            });
        }
        const internal = this.props.auth.user.internal;
        let sigDeleteCallback = (record.data.status === this.props.auth.constants.statuses.new || record.data.status === this.props.auth.constants.statuses.inprogress) && 
                        record.data.user != null ? this.deleteSigCallback : null;
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: usersSel, name: t('complete'),
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user,
                objectType: this.props.auth.constants.objectIds.batchActivity, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, object: this.state._id, saveCallback: this.saveCallback, deleteCallback: sigDeleteCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.managers, 
                name: t('review'), title: t('reviewedBy'), setId: () => this.loadRecord(), object: this.state._id, signature: record.data.reviewer,
                objectType: this.props.auth.constants.objectIds.batchActivity, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature, validate: () => this.validateComplete()
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: !internal && this.state.signer == null ? this.state.activeQas : this.state.qas, 
                name: t('approve'), title: t('approvedBy'), setId: () => this.loadRecord(), object: this.state._id, signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.batchActivity, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;

        const labelPrintPermission = (record.data.batch.status === this.props.auth.constants.batchStatuses.growing ||
                                     record.data.batch.status === this.props.auth.constants.batchStatuses.harvested) &&
                                     this.props.permission.print ? true : false;
        let form = record.data.form != null && record.data.form !== '' ? JSON.parse(record.data.form) : fieldAccess && this.state.template != null && this.state.template !== '' ? this.state.template : '';
        let params = queryString.parse(this.props.location.search);
        let batchQuantity = record.data.status === constants.statuses.new || record.data.status === constants.statuses.inprogress ? record.data.batch.currentQuantity : record.data.batchQuantity;
        
        let plantLabel = {};
        if( batchActivity != null && batchActivity.activityDefinition != null && batchActivity.activityDefinition.definitionId != null &&
            batchActivity.activityDefinition.definitionId === constants.batchActivityDefs.label ) {
            plantLabel = {
                objectType: constants.objectIds.batchActivity,
                object: record.data._id,
                qrPath: `${window.location.origin}/#/cultivationbatch/${record.data.batch._id}`,
                batch: record.data.batch.number,
                startQuantity: record.data.batch.startQuantity,
                strain: record.data.batch.strain.name,
                date: record.data.batch.actualStart,
                dateFormat: this.props.auth.user.dateFormat
            }
        }
	    this.setState({
            _id: record.data._id,
            status: record.data.status,
            statusSel: statusSel,
            number: record.data.number,
            users: record.data.users,
            usersSel: usersSel,
            finalDocument: record.data.finalDocument,
            completedDate: record.data.completedDate != null ? record.data.completedDate : batchActivity != null && batchActivity.plannedDate != null && Date.parse(batchActivity.plannedDate) < new Date() ? batchActivity.plannedDate : new Date(),
            quantityCompleted: record.data.quantityCompleted != null ? record.data.quantityCompleted : "0",
            effort: record.data.effort != null ? record.data.effort : '',
            sopsSel: sopsSel,
            inputs: record.data.inputs != null ? record.data.inputs : [],
            outputs: outputs != null ? outputs : [],
            sops: record.data.sops,
            sopIds: sopIds,
            batchObj: record.data.batch,
            lastSequence: record.data.batch.lastSequence,
            batch: record.data.batch._id,
            activityId: record.data.batchActivityId,
            definition: def,
            harvest: harvest,
            plant: plant,
            rooms: record.data.rooms,
            batchActivity: batchActivity,
            showInputs: showInputs,
            showOutputs: showOutputs,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            documents: record.data.documents,
            batchRoomList: batchRoomList,
            flowerRoomList: flowerRoomList,
            culls: culls,
            deviations: record.data.deviations,
            ncrs: record.data.ncrs,
            batchRoomSelect: record.data.batchRoomSelect,
            renderNum: Math.random(),
            harvestedTotes: record.data.harvestedTotes,
            harvestedRooms: record.data.harvestedRooms != null ? record.data.harvestedRooms : [],
            tabLabels: tabLabels,
            fieldAccess: fieldAccess,
            isDestruction: isDestruction,
            batchQuantity: batchQuantity,
            harvestedSaved: harvestedSaved,
            flagForSignature: flagForSignature,
            observation: record.data.observation,
            optionsMenu: optionsMenu,
            inputHasCount: inputHasCount,
            outputHasCount: outputHasCount,
            itemCounts: record.data.itemCounts,
            currentItem: record.data.currentItem,
            roomList: roomList,
            labelData: record.data.labelData,
            labelPrintPermission: labelPrintPermission,
            observationAccess: observationAccess,
            items: items,
            allItems: allItems,
            gotValues: true,
            inputCost: record.data.inputCost,
            labourCost: record.data.labourCost,
            schedule: record.data.schedule,
            roomsCompleted: roomsCompleted,
            form: form,
            latestDate: latestDate,
            userList: userList,
            sequence: record.data.sequence,
            bins: record.data.bins,
            currStage: currStage,
            hasItemLedgers: record.data.hasLedgers,
            hasBatchLedgers: record.data.hasBatchLedgers,
            outputLines: outputLines,
            inputLines: inputLines,
            stagePlantType: stagePlantType,
            stagePlant: stagePlant,
            changeRoom: changeRoom,
            importing: importing,
            prevPlant: prevPlant,
            harvestedLots: harvestedLots,
            outputLabels: outputLabels,
            plantLabel: plantLabel,
            itemLots: itemLots,
            tissueExport: tissueExport,
            templateObject: record.data.template,
            isMultiplcation: isMultiplcation,
            isAddToBatch: isAddToBatch,
            tissueClone: tissueClone,
            plantLot: plantLot,
            printAccess: printAccess,
            cullTotal: cullTotal !== 0 ? cullTotal : null,
            completeAct: completeAct
        }, () => {
            this.determineUnlock();
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            const crumbs = [
                { path: '/#/cultivationbatches', label: this.props.t('batches'), screen: this.props.auth.screenDefs.CultivationBatch},
                { path: '/#/cultivationbatch/' + record.data.batch._id, label: (this.props.t('batch') + ' - ' + record.data.batch.number), screen: this.props.auth.screenDefs.CultivationBatch},
                { path: 'active', label: (this.state.definition != null ? this.state.definition.description + ' - ' + record.data.number : record.data.number), screen: this.props.auth.screenDefs.BatchActivity}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            if(params != null)
                this.openNotification(params);
        });
    };

    determineUnlock() {
        let enableUnlock = false;
        let state = this.state;
        let constants = this.props.auth.constants;
        if(state.batchObj.status !== constants.batchStatuses.review && state.batchObj.status !== constants.batchStatuses.signedOff){
            if(state.status === constants.statuses.complete && this.props.permission.unlock){
                enableUnlock = true;
            } else if (state.status === constants.statuses.reviewed || state.status === constants.statuses.signed){
                if(state.sequence === state.batchObj.lastSequence){
                    if((state.outputLines == null || state.outputLines.length === 0) && (state.culls == null  || state.culls.length === 0) &&
                    (state.rooms == null || state.rooms.length === 0)){
                        enableUnlock = true;
                    } else {
                        let outputsLock = false;
                        if(state.outputLines != null && state.outputLines.length > 0){
                            for(let output of state.outputLines){
                                for(let row of output.lines){
                                    if(row.containerSel != null){
                                        if(ConversionHelper.round3(parseFloat(row.amount)) > ConversionHelper.round3(parseFloat(row.containerSel.quantity))){
                                            outputsLock = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        if(!outputsLock)
                            enableUnlock = true;
                    }
                }
            }
        }
        this.setState({enableUnlock: enableUnlock})
    }

    openNotification = (params) => {
        let showObservation = this.state.showObservation;
        let tabKey = this.state.tabKey;
        if(params != null && params.observation != null)
            showObservation = params.observation;
        if(params != null && params.tab != null)
            tabKey = Number(params.tab);
        this.setState({showObservation, tabKey});
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        if(isNaN(value))
            return;
        let errors = {};
        if(name === 'inputAmount'){
            if(this.state.inputContainerSel != null){
                if(parseFloat(value) > parseFloat(this.state.inputContainerSel.quantity)){
                    errors[this.props.t('inputAmount')] = this.props.t('moreThanContainer');
                }
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
        } else {
            this.setState({[name]: value});
        }
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, async () => {
            if(data === 'inputItem'){
                if(newValue != null){
                    let containers = [];
                    if(newValue.lotTracked){
                        let contRes = await axios.post('/api/itemcontainers', {items:[newValue.value], date: this.state.date, available: true, consume: true});
                        containers = contRes.data;
                    }
                    let inputUOMSel = this.state.uoms.find(x => x.value === newValue.uom);
                    this.setState({inputContainers: containers, inputUOM: newValue.uom, inputUOMSel: inputUOMSel});
                } else {
                    this.setState({inputContainers: [], inputUOM: '', inputUOMSel: null});
                }
            } else if(data === 'outputItem'){
                if(newValue != null){
                    let itemLots = this.state.itemLots;
                    let lots = [];
                    if(itemLots.length > 0){
                        let itemRow = itemLots.find(x => x.item === newValue.value);
                        if(itemRow != null){
                            lots = itemRow.values;
                        }
                    }
                    let outputUOMSel = this.state.uoms.find(x => x.value === newValue.uom);
                    this.setState({
                        outputUOM: newValue.uom, 
                        outputUOMSel: outputUOMSel, 
                        outputLots: lots, 
                        outputLot: '', 
                        outputLotSel: null, 
                        outputContainer: '', 
                        outputContainerSel: null,
                        newLot: newValue.customNumber === true  && lots.length === 0 ? true : false
                    });
                } else {
                    this.setState({
                        outputUOM: '', 
                        outputUOMSel: null,
                        outputLots: [],
                        outputLotSel: null,
                        outputLot: '',
                        outputContainer: '',
                        outputContainerSel: null
                    });
                }
            } else if (data === 'outputLot'){
                if(newValue != null){
                    let itemLots = this.state.itemLots;
                    let itemRow = itemLots.find(x => x.item === this.state.outputItem);
                    if(itemRow != null){
                        let lotRow = itemRow.lots.find(x => x.lot === newValue.value);
                        if(lotRow != null){
                            this.setState({outputContainers: lotRow.containers, outputContainer: '', outputContainerSel: null});
                        }
                    }
                } else {
                    this.setState({outputContainers: [], outputContainer: '', outputContainerSel: null});
                }
            }
        });
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
        if(name === 'newLot'){
            if(!this.state.newLot){
                this.setState({outputLot: '', outputLotSel: null, outputContainer: '', outputContainerSel: null});
            }
        }
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    deleteInputRow = (i, j) => (e) => {
        let contentLines = this.state.inputLines;
        if(i > -1 && i < contentLines.length){
            let line = contentLines[i];
            if(j > -1 && j < line.lines.length){
                line.lines.splice(j, 1);
                line = this.calculateLineTotals(line);
                this.setState({inputLines: contentLines});
            }
        }
    };

    changeSubLineNumber = (name, i, j) => (e) => {
        let contents = this.state.inputLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        if(isNaN(value))
            return;
        row[name] = value;
        line = this.calculateLineTotals(line);
        this.setState({inputLines: contents});
    };

    changeSubLineValue = (name, i, j) => (e) => {
        let contents = this.state.inputLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        row[name] = value;
        this.setState({inputLines: contents});
    };

    changeSubLineAuto = (name, data, i, j) => async (e, newValue) => {
        let contents = this.state.inputLines;
        let line = contents[i];
        let row = line.lines[j];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : '';
        let hasCount = false;
        if(data === 'bin' && newValue != null){
            let binConts = await axios.post('/api/itemcontainers', {items: [line.item], bin: newValue.value, consume: true});
            line.containers = binConts.data;

        }
        this.setState({inputLines: contents, inputHasCount: hasCount});
    };

    changeOutLineNumber = (name, i, j) => (e) => {
        let contents = this.state.outputLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        if(isNaN(value))
            return;
        row[name] = value;
        line = this.calculateLineTotals(line);
        this.setState({outputLines: contents});
    };

    changeOutLineValue = (name, i, j) => (e) => {
        let contents = this.state.outputLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        row[name] = value;
        this.setState({outputLines: contents});
    };

    changeOutLineAuto = (name, data, i, j) => (e, newValue) => {
        let contents = this.state.outputLines;
        let line = contents[i];
        let row = line.lines[j];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : '';
        if(data === 'lot' && newValue != null){
            let itemLots = this.state.itemLots;
            let itemRow = itemLots.find(x => x.item === line.item);
            if(itemRow != null){
                let lotRow = itemRow.lots.find(x => x.lot === newValue.value);
                if(lotRow != null){
                    row.containers = lotRow.containers;
                }
            }
        } else if(data === 'lot' && newValue == null){
            row.containers = [];
        }
        this.setState({outputLines: contents});
    };

    changeOutLineBool = (name, i, j) => (e) => {
        let isAddToBatch = this.state.isAddToBatch;
        let outputs = this.state.outputLines;
        let output = outputs[i];
        let row = output.lines[j]
        row[name] = !row[name];
        if(name === 'newLot' && row[name] === true){
            row.lot = '';
            row.lotSel = null;
            row.container = '';
            row.containerSel = null;
        }
        if(name === 'addToBatch'){
            isAddToBatch = false;
            for(let row of outputs){
                for(let line of row.lines){
                    if(line.addToBatch === true){
                        isAddToBatch = true;
                        break;
                    }
                }
            }
        }
        this.setState({outputLines: outputs, isAddToBatch: isAddToBatch});
    };

    changeRoomNumber = (name, index) => (e) => {
        let rooms = this.state.rooms;
        let room = rooms[index];
        let value = e.target.value;
        if(isNaN(value))
            return;
        if(room.startSel != null && room.startSel.count < parseInt(value)){
            return;
        }
        room[name] = value;
        this.setState({rooms: rooms});
    };

    changeRoomAuto = (name, data, index) => (e, newValue) => {
        let rooms = this.state.rooms;
        let room = rooms[index];
        room[name] = newValue;
        room[data] = newValue != null ? newValue.value : null;
        this.setState({
            rooms: rooms
        });
    };

    changeRoomBool = (name, index) => (e) => {
        let rooms = this.state.rooms;
        let room = rooms[index];
        room[name] = room[name] != null ? !room[name] : true;
        this.setState({rooms: rooms});
    };

    changeCullNumber = (name, index) => (e) => {
        const t = this.props.t;
        let culls = this.state.culls;
        let cull = culls[index];
        let value = e.target.value;
        let errors = {};
        if(isNaN(value))
            return;
        if(cull.roomSel != null && cull.roomSel !== undefined){
            if(parseInt(value) > cull.roomSel.count){
                errors[`${t('cullCount')} (${t('line')} ${index})`] = this.props.t("moreThanRoom")
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                return;
            }
        }
        let cullTotal = 0;
        culls.forEach(row => {
            cullTotal += row.count;
        });
        cull[name] = value;
        this.setState({culls: culls, cullTotal: cullTotal});
    };

    changeHarvestNumber = (name, index) => (e) => {
        let harvestedRooms = this.state.harvestedRooms;
        let harvestedRoom = harvestedRooms[index];
        let value = e.target.value;
        if(isNaN(value))
            return;
        harvestedRoom[name] = value;
        this.setState({harvestedRooms: harvestedRooms});
    };

    changeCullAuto = (name, data, index) => (e, newValue) => {
        let culls = this.state.culls;
        let cull = culls[index];
        cull[name] = newValue;
        cull[data] = newValue != null ? newValue.value : null;
        this.setState({
            culls: culls
        });
    };

    changeHarvestAuto = (name, data, index) => (e, newValue) => {
        let harvestedRooms = this.state.harvestedRooms;
        let harvestedRoom = harvestedRooms[index];
        harvestedRoom[name] = newValue;
        harvestedRoom[data] = newValue != null ? newValue.value : null;
        this.setState({
            harvestedRoom: harvestedRoom
        });
    };

    changeMulti = (name, data) => (e, newValue) => {
        var val = newValue;
        var ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    changeSop = (e, newValue) => {
        let sops = this.state.sops;
        let tabLabels = this.state.tabLabels;
        if(newValue != null){
            if(sops.length > newValue.length){
                for(let i = 0; i < sops.length; i++){
                    let sop = sops[i];
                    let rec = newValue.find(x => x.value === sop.sop);
                    if(!rec){
                        sops.splice(i, 1);
                        tabLabels.splice(i + 1, 1);
                        break;
                    }
                }
            } else {
                for(let i = 0; i < newValue.length; i++){
                    let val = newValue[i];
                    let rec = sops.find(x => x.sop === val.value)
                    if(!rec){
                        sops.push({
                            sop: val.value,
                            workRecord: null,
                            original: false
                        });
                        if(this.state._id != null && this.state._id !== '')
                            tabLabels.push({name: val.label, disabled: false});
                    }
                }
            }
            let sopIds = [];
            newValue.forEach(val => {
                sopIds.push(val.value);
            });
            this.setState({
                sopIds: sopIds,
                sopsSel: newValue,
                sops: sops
            });
        } else {
            for(let i = 0; i < sops.length; i++){
                let sop = sops[i];
                if(sop.required)
                    return;
            }
            this.setState({sops: [], sopIds: [], sopsSel: [], tabLabels: [{name: this.props.t('activity'), disabled: false}]});
        }
    };

    addCull = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.cullRoom == null || this.state.cullRoom === '')
            errors[t('cullRoom')] = t('required');
        if(this.state.cullCount == null || this.state.cullCount === '' || this.state.cullCount === 0)
            errors[t('cullCount')] = t('required');
        if(this.state.cullReason == null || this.state.cullReason === '')
            errors[t('cullReason')] = t('required');
        let total = this.calculatePlantCounts();
        if(this.state.quantityCompleted != null && this.state.quantityCompleted !== ''){
            if(total > parseInt(this.state.quantityCompleted))
                errors[t('cullCount')] = t('moreThanCompleted');
            if(this.state.cullRoomSel != null && this.state.cullRoomSel.count < parseInt(this.state.cullCount))
                errors[t('cullCount')] = t('moreThanRoom');
        } else {
            errors[t('quantityCompleted')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let culls = this.state.culls;
        culls.push({
            room: this.state.cullRoom,
            roomSel: this.state.cullRoomSel,
            count: this.state.cullCount,
            reason: this.state.cullReason,
            reasonSel: this.state.cullReasonSel
        });
        let cullTotal = 0;
        culls.forEach(row => {
            cullTotal += parseInt(row.count);
        })
        this.setState({
            culls: culls,
            cullRoom: '',
            cullRoomSel: null,
            cullCount: 0,
            cullReason: '',
            cullReasonSel: null,
            cullTotal: cullTotal
        });
    };

    calculatePlantCounts() {
        let state = this.state;
        let total = 0;
        if(state.cullCount != null && state.cullCount !== '')
            total = total + parseInt(state.cullCount);
        if(state.count != null && state.count !== '')
            total = total + parseInt(state.count);
        if(state.culls != null && state.culls.length > 0){
            state.culls.forEach(row => {
                total = total + parseInt(row.count);
            });
        }
        if(state.rooms != null && state.rooms.length > 0){
            state.rooms.forEach(row => {
                total = total + parseInt(row.count);
            });
        }
        return total;
    }

    addHarvestedRoom = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.harvestedRoom == null || this.state.harvestedRoom === '')
            errors[t('harvestedRoom')] = t('required');
        if(this.state.harvestedRoomCount == null || this.state.harvestedRoomCount === '')
            errors[t('harvestedRoomCount')] = t('required');
        else if(isNaN(this.state.harvestedRoomCount))
            errors[t('harvestedRoomCount')] = (t('notInt'));
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let harvestedRooms = this.state.harvestedRooms;
        harvestedRooms.push({
            room: this.state.harvestedRoom,
            roomSel: this.state.harvestedRoomSel,
            count: this.state.harvestedRoomCount
        });
        this.setState({
            harvestedRooms: harvestedRooms,
            harvestedRoom: '',
            harvestedRoomSel: null,
            harvestedRoomCount: ''
        })
    }

    addRoom = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.roomTo == null || this.state.roomTo === '')
            errors[t('room')] = t('required');
        if(this.state.count == null || this.state.count === '' || this.state.count === 0)
            errors[t('count')] = t('required');
        else if(isNaN(this.state.count))
            errors[t('count')] = (t('notInt'));
        let startSel = this.state.startSel;
        if(startSel != null){
            if(this.state.count > startSel.count){
                errors[t('count')] = t('moreThanRoom');
            }
        }
        if(this.state.batchObj.status !== this.props.auth.constants.batchStatuses.released && this.state.currentItem.item != null &&
            this.state.batchActivity.defId !== this.props.auth.constants.batchActivityDefs.plant && this.state.total > this.state.currentItem.count){
            errors[t('count')] = t('moreThanItem') + " " + this.state.currentItem.count;
        }
        let advance = false;
        if(this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.plant && 
            this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.import && !this.state.isAddToBatch){
            if(this.state.startSel == null){
                errors[t('startRoom')] = t('required');
            } else {
                if(this.state.startSel.item !== this.state.stagePlant && !this.state.isAddToBatch){
                    advance = true;
                }
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let rooms = this.state.rooms;
        rooms.push({
            room: this.state.roomTo,
            roomSel: this.state.roomToSel,
            count: this.state.count,
            startSel: this.state.startSel,
            startRoom: this.state.startRoom !== '' ? this.state.startRoom : null,
            advanceStage: advance
        });
        this.setState({
            startRoom: '',
            startSel: null,
            rooms: rooms,
            roomTo: '',
            roomToSel: null,
            count: 0,
            advanceStage: false
        });
    };

    addInput = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.inputItem == null || this.state.inputItem === '')
            errors[t('inputItem')] = t('required');
        if(this.state.inputAmount == null || this.state.inputAmount === ''){
            errors[t('inputAmount')] = t('required');
        } else {
            if(this.state.inputContainerSel != null){
                if(parseFloat(this.state.inputAmount) > parseFloat(this.state.inputContainerSel.quantity)){
                    errors[t('inputAmount')] = t('moreThanContainer');
                }
            } else if(this.state.inputSel != null){
                if(parseInt(this.state.inputAmount) > this.state.inputSel.inventory)
                    errors[t('inputAmount')] = t('moreThanInventory');
            }
        }
        if(this.state.inputUOM == null || this.state.inputUOM === '')
            errors[t('inputUOM')] = t('required');
        if(this.state.inputSel != null && this.state.inputSel.lotTracked === true){
            if(this.state.inputContainer == null || this.state.inputContainer === '')
                errors[t('inputContainer')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let inputLines = this.state.inputLines;
        let existing = inputLines.find(x => x.item === this.state.inputItem);
        if(existing != null){
            let line = {
                amount: this.state.inputAmount,
                count: this.state.inputCount !== '' ? this.state.inputCount : null,
                container: this.state.inputContainer,
                containerSel: this.state.inputContainerSel,
                bin: this.state.inputBin !== '' ? this.state.inputBin : null,
                binSel: this.state.inputBinSel
            }
            existing.lines.push(line);
            let amount = 0;
            let count = 0;
            for(let row of existing.lines){
                amount += parseFloat(row.amount);
                if(row.count != null && row.count !== ''){
                    count += parseInt(row.count);
                }
            }
            existing.amount = amount;
            existing.count = count !== 0 ? count : null;
        } else {
            inputLines.push({
                item: this.state.inputItem,
                itemSel: this.state.inputSel,
                amount: this.state.inputAmount,
                count: this.state.inputCount !== '' ? this.state.inputCount : null,
                uom: this.state.inputUOM,
                uomSel: this.state.inputUOMSel,
                containers: this.state.inputContainers,
                lines: [{
                    amount: this.state.inputAmount,
                    count: this.state.inputCount !== '' ? this.state.inputCount : null,
                    bin: this.state.inputBin !== '' ? this.state.inputBin : null,
                    binSel: this.state.inputBinSel,
                    container: this.state.inputContainer,
                    containerSel: this.state.inputContainerSel
                }]
            })
        }
        this.setState({
            inputLines: inputLines,
            inputItem: '',
            inputSel: null,
            inputAmount: '',
            inputUOM: '',
            inputUOMSel: null,
            inputLot: '',
            inputLotSel: null,
            inputLots: [],
            inputCount: '',
            inputContainer: '',
            inputContainerSel: null,
            inputContainers: [],
            inputBin: '',
            inputBinSel: null,
            errors: {}
        })
    };

    addInputLine = (i) => {
        let inputLines = this.state.inputLines;
        let input = inputLines[i];
        if(input.lines == null){
            input.lines = [];
        }
        input.lines.push({
            container: '',
            containerSel: null,
            lot: '',
            lotSel: null,
            amount: '',
            count: ''
        });
        this.setState({inputLines: inputLines});

    };

    deleteCull = (index) => (e) => {
        let culls = this.state.culls;
        culls.splice(index, 1);
        this.setState({culls: culls});
    };

    deleteHarvestedRoom = (index) => (e) => {
        let harvestedRooms = this.state.harvestedRooms;
        harvestedRooms.splice(index, 1);
        this.setState({harvestedRoom: harvestedRooms});
    };

    deleteRoom = (index) => (e) => {
        let rooms = this.state.rooms;
        rooms.splice(index, 1);
        this.setState({rooms: rooms});
    };

    deleteInput = (index) => (e) => {
        let inputLines = this.state.inputLines;
        inputLines.splice(index, 1);
        let inputHasCount = false;
        if(this.state.inputLines != null && this.state.inputLines.length > 0){
            this.state.inputLines.forEach(row => {
                if(row.itemSel.hasCount)
                    inputHasCount = true;
            });
        }
        this.setState({inputLines: inputLines, inputHasCount: inputHasCount});
    };

    deleteOutput = (index) => (e) => {
        let outputLines = this.state.outputLines;
        outputLines.splice(index, 1);
        let quantity = this.state.quantity;
        if(outputLines.length > 0) {
            let total = 0
            outputLines.forEach(output => {
                if(output.item === this.state.outputItem){
                    for(let line of output.lines){
                        total += Number(line.amount);
                    }
                }
            })
            quantity = total;
        }
        let isAddToBatch = false;
        for(let row of outputLines){
            for(let line of row.lines){
                if(line.addToBatch === true){
                    isAddToBatch = true;
                    break;
                }
            }
        }
        this.setState({outputLines: outputLines, quantity: quantity, isAddToBatch: isAddToBatch});
    };

    valueChanged = (e) => {
        this.setState({value: e});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.save()})
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        let enableSave = this.state.enableSave;
        enableSave = false;
        this.setState({ enableSave: enableSave }, () => this._save());
    }

    _save = async () => {
        const valid = this.validate();
        const t = this.props.t;
        const constants = this.props.auth.constants;
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
                return;
            }
        }
        let inputLines = this.state.inputLines;
        let outputLines = this.state.outputLines;
        let inputs = [];
        let outputs = [];
        for(let i = 0; i < inputLines.length; i++){
            let input = inputLines[i];
            let valid = this.validateInput(i);
            if(!valid) {
                this.setState({ enableSave: true });
                return;
            }
            for(let row of input.lines){
                inputs.push({
                    _id: row._id,
                    amount: row.amount,
                    uom: input.uom,
                    uomSel: input.uomSel,
                    count: row.count,
                    lot: row.lot,
                    lotTransaction: row.lotTransaction,
                    container: row.container,
                    containerTransaction: row.containerTransaction,
                    itemLedger: row.itemLedger,
                    item: input.item,
                    itemSel: input.itemSel,
                    cloneItemLedger: row.cloneItemLedger,
                    reservation: row.reservation
                });
            }
        }
        let cullPlantTotal = 0;
        for(let i = 0; i < outputLines.length; i++){
            let output = outputLines[i]
            let valid = this.validateOutput(i);
            if(!valid) {
                this.setState({ enableSave: true });
                return;
            }
            if(output.item === constants.items.seedWaste || output.item === constants.items.vegPlantWaste || output.item === constants.items.wholePlantWaste){
                for(let row of output.lines){
                    if(row.cullCount != null && row.cullCount !== '' && !isNaN(row.cullCount)){
                        cullPlantTotal += parseInt(row.cullCount);
                    }
                }
            }
            for(let row of output.lines){
                outputs.push({
                    _id: row._id,
                    item: output.item,
                    itemSel: output.itemSel,
                    uom: output.uom,
                    uomSel: output.uomSel,
                    amount: row.amount,
                    count: row.count,
                    lot: row.lot,
                    lotTransaction: row.lotTransaction,
                    container: row.container,
                    containerTransaction: row.containerTransaction,
                    bin: row.bin,
                    binSel: row.binSel,
                    itemLedger: row.itemLedger,
                    sourceRoom: row.sourceRoomSel != null ? row.sourceRoomSel.room : null,
                    ledger: row.ledger,
                    tareWeight: row.tareWeight,
                    totalWeight: row.totalWeight,
                    newLot: row.newLot,
                    customLot: row.customLot,
                    containerType: row.containerType,
                    addToBatch: row.addToBatch,
                    cullCount: row.cullCount
                })
            }
        }
        if(this.state.cullTotal != null && cullPlantTotal !== this.state.cullTotal){
            let errors = {outputItem: t('culledPlantMatch')};
            this.setState({errors: errors, enableSave: true});
            return;
        }
        let harvestedRooms = [];
        if(this.state.harvestedRooms != null && this.state.harvestedRooms.length > 0){
            this.state.harvestedRooms.forEach(row => {
                harvestedRooms.push({
                    _id: row._id,
                    count: row.count,
                    ledger: row.ledger != null ? row.ledger._id : null,
                    room: row.roomSel.room != null ? row.roomSel.room : row.room,
                    itemLedger: row.itemLedger
                });
            });
        }
        let culls = [];
        if(this.state.culls != null && this.state.culls.length > 0){
            this.state.culls.forEach(row => {
                culls.push({
                    _id: row._id,
                    count: row.count,
                    ledger: row.ledger != null ? row.ledger._id : null,
                    itemLedger: row.itemLedger,
                    reason: row.reason,
                    item: row.roomSel.item,
                    room: row.roomSel.room != null ? row.roomSel.room : row.room
                });
            });
        }
        let rooms = [];
        let batchObj = this.state.batchObj;
        if(this.state.rooms != null && this.state.rooms.length > 0){
            this.state.rooms.forEach(row => {
                let startItem = row.startSel != null && row.startSel.item != null ? row.startSel.item : batchObj.startItem;
                let endItem = this.state.stagePlant;
                if(!row.advanceStage){
                    if(this.state.definition.definitionId === this.props.auth.constants.batchActivityDefs.import){
                        for(let input of inputs){
                            if(input.item === this.state.batchObj.strain.vegItem || input.item === this.state.batchObj.strain.cloneItem || 
                            input.item === this.state.batchObj.strain.flowerItem || input.item === this.state.batchObj.strain.motherItem){
                                startItem = input.item;
                                endItem = input.item;
                                break;
                            }
                        }
                    } else {
                        if(startItem === batchObj.strain.seedItem && this.state.currStage === this.props.auth.constants.batchStages.prop){
                            endItem = batchObj.strain.cloneItem;
                        } else if (startItem === batchObj.strain.seedItem && this.state.currStage === this.props.auth.constants.batchStages.germination){
                            endItem = batchObj.strain.seedItem;
                        } else if (startItem === batchObj.strain.motherItem && this.state.batchActivity.defId === this.props.auth.constants.batchActivityDefs.plant){
                            endItem = batchObj.strain.motherItem;
                        } else {
                            if(startItem === batchObj.startItem && batchObj.stages[0].stage === this.props.auth.constants.batchStages.mother){
                                endItem = batchObj.strain.motherItem;
                                startItem = batchObj.strain.motherItem;
                            } else {
                                endItem = startItem;
                            }
                        }
                    }
                } 
                rooms.push({
                    startRoom: row.startSel != null && row.startSel.room != null ? row.startSel.room : row.startRoom,
                    room: row.roomSel.room != null ? row.roomSel.room : row.room,
                    startItem: startItem,
                    endItem: endItem,
                    count: row.count,
                    ledger: row.ledger,
                    startLedger: row.ledger,
                    startItemLedger: row.startItemLedger,
                    itemLedger: row.ItemLedger,
                    advanceStage: row.advanceStage
                })
            })
        }
        let data = {
            _id: this.state._id,
            completedDate: this.state.completedDate,
            quantityCompleted: this.state.quantityCompleted,
            effort: this.state.effort,
            users: this.state.users,
            status: this.state.status,
            schedule: this.state.schedule,
            batch: this.state.batch,
            activityId: this.state.activityId,
            sops: this.state.sops,
            inputs: inputs,
            outputs: outputs,
            rooms: rooms,
            culls: culls,
            harvestedRooms: harvestedRooms,
            batchQuantity: this.state.batchQuantity,
            sigData: this.state.sigData,
            roomsCompleted: this.state.roomsCompleted,
            form: form != null && form !== '' ? JSON.stringify(form) : null
        };
        try {
            let result = await axios.post('/api/batchactivity', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/batchactivity/' + result.data.id);
            this.setState({_id: this.state._id != null && this.state._id !== '' ? this.state._id : result.data.id, number: result.data.number, enableSave: true, sigData: null}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    validate(final){
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        const defId = this.state.batchActivity.defId;
        const batchActivityDefs = this.props.auth.constants.batchActivityDefs;
        if(this.state.roomsCompleted == null || this.state.roomsCompleted.length === 0){
            if(state.effort == null || state.effort === '')
                errors[t('effort')] = t('required');
            if(parseInt(state.batchQuantity) > 0 && (state.quantityCompleted == null || state.quantityCompleted === '' || state.quantityCompleted === '0'))
                errors[t('quantityCompleted')] = t('required');
        } 
        
        if(state.completedDate == null || state.completedDate === ''){
            errors[t('completedDate')] = t('required');
        } else {
            let latest = null;
            if(state.latestDate != null && state.latestDate !== ''){
                latest = new Date(Date.parse(state.latestDate));
            }
            if(latest != null){
                latest.setHours(0, 0, 0, 0)
            }
            if(latest != null && Date.parse(state.completedDate) < latest){
                errors[t('completedDate')] = t('activityTooEarly');
            } else {
                let validEndDate = ConversionHelper.validActivityDate(this.state.completedDate, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
                if(!validEndDate){
                    errors[t('completedDate')] = t('dateBeforeLock');
                }
            }
        }
        if((state.inputAmount != null && state.inputAmount !== '' && state.inputAmount !== 0) || (state.inputItem != null && state.inputItem !== ''))
                errors[t('inputItem')] = t('notAdded');
        if((state.outputAmount != null && state.outputAmount !== '' && state.outputAmount !== 0) || (state.outputItem != null && state.outputItem !== ''))
            errors[t('outputItem')] = t('notAdded');
        if((state.room != null && state.room !== '') || (state.count != null && state.count !== '' && state.count !== 0))
            errors[t('room')] = t('notAdded');
        if((state.cullRoom != null && state.cullRoom !== '') || (state.cullCount != null && state.cullCount !== '' && state.cullCount !== 0))
            errors[t('cullRoom')] = t('notAdded');
        if(state.users == null || state.users.length === 0)
            errors[t('users')] = t('required');
        let batchCount = defId !== batchActivityDefs.plant ? parseInt(state.batchQuantity) :
            parseInt(this.state.quantityCompleted);
        let completed = state.quantityCompleted != null && state.quantityComplete !== '' ? parseInt(state.quantityCompleted) : null;
        if(state.batchObj.status !== this.props.auth.constants.batchStatuses.released && completed > batchCount &&
        defId !== batchActivityDefs.plant && !this.state.isAddToBatch)
            errors[t('quantityCompleted')] = t('moreThanBatch');
        let roomTotals = [];
        let total = 0;
        let addToBatchCount = 0;
        if(state.isAddToBatch){
            for(let row of state.outputLines){
                for(let line of row.lines){
                    if(line.addToBatch === true){
                        addToBatchCount += parseInt(line.amount);
                    }
                }
            }
            if(state.rooms == null || state.rooms.length === 0){
                errors[t('room')] = t('required');
            }
        }
        let roomAddToBatchCount = 0;
        if(state.rooms != null && state.rooms.length > 0){
            for(let i = 0; i < state.rooms.length; i++){
                let room = state.rooms[i];
                if(room.count != null && room.count !== ''){
                    total += parseInt(room.count);
                }
                let roomFound = roomTotals.find(x => x.room === room.room);
                if(!roomFound){
                    roomFound = {room: room.room, count: 0};
                    roomTotals.push(roomFound);
                }
                roomFound.count += parseInt(room.count);
                if(room.room == null || room.room === ''){
                    errors[`${t('room')} (Line ${i})`] = t('required');
                }
                if(room.count == null || room.count === '' || room.count === 0)
                    errors[`${t('count')} (Line ${i})`] = t('required');
                if(defId !== batchActivityDefs.plant && defId !== batchActivityDefs.import ){
                    if(room.startSel != null && parseInt(room.count) > room.startSel.count)
                    errors[`${t('count')} (Line ${i})`] = t('moreThanRoom');
                }
                if(room.startRoom != null && room.room != null && room.startRoom === room.room && !room.advanceStage){
                    errors[`${t('room')} (Line ${i})`] = t('noChange');
                }
                if(defId !== batchActivityDefs.plant && defId !== batchActivityDefs.import && !this.state.isAddToBatch){
                    if(room.startSel == null){
                        errors[`${t('startRoom')} (Line ${i})`] = t('required');
                    }
                }
                if(state.isAddToBatch){
                    roomAddToBatchCount += parseInt(room.count);
                }
            }
            if( defId !== batchActivityDefs.import && total > completed && !this.state.isAddToBatch){
                errors[`${t('room')} (${t('line')} 0)`] = t('moreThanCompleted');
            } else {
                if(defId === batchActivityDefs.plant){
                    let inputTotal = 0;
                    for(let row of this.state.inputLines){
                        if(row.item === this.state.batchObj.startItem){
                            for(let line of row.lines){
                                if(line.count == null || line.count === '')
                                    inputTotal += parseInt(line.amount);
                                else
                                    inputTotal += parseInt(line.count);
                            }
                        }
                    }
                    if(parseInt(inputTotal) !== parseInt(total)){
                        errors[`${t('room')} (${t('line')} 0)`] = t('mustMatchPlantCount');
                    }
                } else if (state.isAddToBatch){
                    if(roomAddToBatchCount !== addToBatchCount){
                        errors[`${t('room')} (${t('line')} 0)`] = t('mustMatchAddedCount');
                    }
                }
            }
        }
        if(state.culls != null && state.culls.length > 0){
            let cullTotals = [];
            for(let i = 0; i < state.culls.length; i++){
                let row = state.culls[i];
                let roomFound = cullTotals.find(x => x.room === row.room);
                if(!roomFound){
                    roomFound = {room: row.room, count: 0};
                    cullTotals.push(roomFound);
                }
                let totalRoom = roomTotals.find(x => x.room === row.room);
                if(!totalRoom){
                    totalRoom = {room: row.room, count: 0};
                    roomTotals.push(totalRoom);
                }
                totalRoom.count += parseInt(row.count);
                roomFound.count += parseInt(row.count);
                if(defId !== batchActivityDefs.plant && defId !== batchActivityDefs.import){
                    if(row.roomSel != null && parseInt(row.count) > row.roomSel.count)
                        errors[`${t('cullcount')} (Line ${i})`] = t('moreThanRoom');
                }
            }
        }
        if(this.state.definition != null && this.state.definition.changeRoom){
            if(this.state.rooms == null || this.state.rooms.length === 0){
                errors[t('room')] = t('required');
            }
        }
        let plantTotal = this.calculatePlantCounts();
        if(this.state.batchObj.status !== this.props.auth.constants.batchStatuses.released && 
            defId !== batchActivityDefs.plant && defId !== batchActivityDefs.import && !this.state.isAddToBatch){
            if(plantTotal > batchCount)
                errors[t('count')] = t('moreThanBatch');
            if(plantTotal !== parseInt(this.state.quantityCompleted) && this.state.rooms.length > 0 && !this.state.isAddToBatch)
                errors[t('count')] = t('countNotMatch');
        }
        if(this.state.roomsCompleted != null && this.state.roomsCompleted.length > 0){
            for(let i = 0; i < this.state.roomsCompleted.length; i++){
                let row = this.state.roomsCompleted[i];
                if(defId !== batchActivityDefs.plant){
                    if(row.quantityCompleted > row.count){
                        errors[`${t('quantityCompleted')} (Line ${i})`] = t('moreThanRoom');
                    }
                }
            }
        }
        if(defId === batchActivityDefs.import){
            const strain = this.state.batchObj.strain;
            let importCount = 0;
            for(let row of this.state.inputLines){
                if(row.item === strain.cloneItem || row.item === strain.vegItem || row.item === strain.flowerItem || row.item === strain.motherItem){
                    importCount += parseInt(row.amount);
                }
            }
            let roomCount = 0;
            for(let row of this.state.rooms){
                roomCount += parseInt(row.count);
            }
            if(roomCount !== importCount){
                errors[t('room')] = 'Room counts must match input plant count'
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    addDeviation = (e) => {
        this.props.history.push('/deviation?ebatch=' + this.state.batch + '&batchActivity=' + this.state._id);
    };

    addNcr = (e) => {
        this.props.history.push('/ncr?ebatch=' + this.state.batch + '&batchActivity=' + this.state._id);
    };

    validateComplete = () => {
        const t = this.props.t;
        let valid = this.validate(true);
        if(!valid)
            return t('notAllComplete');
        if(this.state.sops != null && this.state.sops.length > 0){
            for(let i = 0; i < this.state.sops.length; i++){
                let sop = this.state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        return null;
    };

    addTote = (e) => {
        let toteRoomSel = null;
        if(this.state.batchRoomList.length === 1){
            toteRoomSel = this.state.batchRoomList[0];
        }
        let uomSel = this.state.uoms.find(x => x.value === this.state.batchObj.outputItem.uom);
        this.setState({
            editTote: true,
            dateTime: this.state.completedDate,
            toteRoomSel: toteRoomSel,
            toteRoom: toteRoomSel != null ? toteRoomSel.value : '',
            weightUOMSel: uomSel,
            weightUOM: this.state.batchObj.outputItem.uom
        });
    };

    editTote(index){
        let tote = this.state.harvestedTotes[index];
        let toteSel = this.state.totes.find(x => x.value === tote.tote._id);
        let toteUOMSel = this.state.uoms.find(x => x.value === tote.weightUOM._id);
        let toteUserSel = this.state.userList.find(x => x.value === tote.user._id);
        let toteRoomSel = this.state.batchRoomList.find(x => x.value === tote.room._id);
        this.setState({
            toteIndex: index,
            tote: tote.tote._id,
            toteSel: toteSel,
            weight: tote.weight,
            weightUOMSel: toteUOMSel,
            weightUOM: tote.weightUOM._id,
            dateTime: tote.dateTime,
            toteUser: tote.user._id,
            toteUserSel: toteUserSel,
            toteRoom: tote.room._id,
            toteRoomSel: toteRoomSel,
            editTote: true
        });
    }

    clearTote = (e) => {
        this.setState({
            toteIndex: -1,
            tote: '',
            toteSel: null,
            weight: '',
            weighUOM: '',
            weightUOMSel: null,
            dateTime: '',
            toteUser: '',
            toteUserSel: null,
            toteRoom: '',
            toteRoomSel: null,
            editTote: false
        });
    };

    deleteTote = async (e) => {
        const t = this.props.t;
        if(this.state.toteIndex < 0){
            return;
        }
        let tote = this.state.harvestedTotes[this.state.toteIndex];
        let params = {
            id: this.state._id,
            toteId: tote._id
        };
        try {
            let result = await axios.delete('/api/batchactivitydeletetote', {params: params});
            this.clearTote();
            let totes = await axios.get('/api/batchactivitytotes/' + this.state._id);
            let outputs = totes.data.outputs;
            outputs.forEach(output => {
                let itemSel = this.state.items.find(x => x.value === output.item);
                output.itemSel = itemSel;
                let uomSel = this.state.uoms.find(x => x.value === output.uom);
                output.uomSel = uomSel;
                let lotSel = output.lot != null ? output.lots.find(x => x.value === output.lot) : null;
                output.lotSel = lotSel;
            });
            this.setState({
                harvestedTotes: totes.data.harvestedTotes,
                outputs: outputs,
                harvestedTotal: totes.data.harvestedTotal
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    saveTote = async (e) => {
        const t = this.props.t;
        let valid = this.validateTote();
        if(!valid)
            return;
        let tote = null;
        let prevTote = null;
        if(this.state.toteIndex > -1)
            tote = this.state.harvestedTotes[this.state.toteIndex];
        else {
            if(this.state.harvestedTotes.length > 0){
                prevTote = this.state.harvestedTotes[0];
            }
        }
        let data = {
            _id: this.state._id,
            toteId: tote != null ? tote._id : null,
            tote: this.state.tote,
            weight: this.state.weight,
            weightUOM: this.state.weightUOM,
            item: this.state.outputs[0].item,
            room: this.state.toteRoomSel.room != null ? this.state.toteRoomSel.room : this.state.toteRoom,
            dateTime: this.state.dateTime,
            user: this.state.toteUser,
            lot: tote != null ? tote.lot : prevTote != null ? prevTote.lot : this.state.outputs[0].lot != null ?
                this.state.outputs[0].lot : null,
            lotTransaction: tote != null ? tote.lotTransaction : null
        };
        try {
            let result = await axios.post('/api/batchactivitytote', data);
            this.clearTote();
            let totes = await axios.get('/api/batchactivitytotes/' + this.state._id);
            let outputs = totes.data.outputs;
            outputs.forEach(output => {
                let itemSel = this.state.items.find(x => x.value === output.item);
                output.itemSel = itemSel;
                let uomSel = this.state.uoms.find(x => x.value === output.uom);
                output.uomSel = uomSel;
                let lotSel = output.lot != null ? output.lots.find(x => x.value === output.lot) : null;
                output.lotSel = lotSel;
                let binSel = output.bin != null ? this.state.bins.find(x => x.value === output.bin) : null;
                output.binSel = binSel;
            });
            this.setState({
                harvestedTotes: totes.data.harvestedTotes,
                outputs: outputs,
                harvestedTotal: totes.data.harvestedTotal
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    validateTote(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.tote == null || state.tote === '')
            errors[t('tote')] = t('required');
        if(state.weight == null || state.weight === '' || state.weight === "0")
            errors[t('weight')] = t('required');
        if(state.weightUOM == null || state.weightUOM === '')
            errors[t('weightUOM')]  = t('required');
        if(state.dateTime == null || state.dateTime === '')
            errors[t('dateTime')]  = t('required');
        if(state.toteUser == null || state.toteUser === '')
            errors[t('toteUser')]  = t('required');
        if(state.toteRoom == null || state.toteRoom === '')
            errors[t('toteRoom')]  = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    dateTimeChange = (e) => {
        this.setState({dateTime: e});
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    closeLotSelect = () => {
        this.setState({showLotSelect : false});
    };

    changeNewLot = index => {
        let outputs = this.state.outputs;
        if(!outputs[index].newLot) {
            outputs[index].lot = '';
            outputs[index].lotSel = {};
            outputs[index].newLot = true;
        }else {
            outputs[index].newLot = false;
        }
        this.setState({outputs});
    }

    editObservation = (id) => (e) => {
        let observation = this.state.observation;
        if(id != null && id !== '')
            observation = id;
        this.setState({showObservation: !this.state.showObservation, observation: observation});
    };

    closeContainerPrintDialog = () => {
        this.setState({ containerPrintOpen: !this.state.containerPrintOpen, containerLabel: {} });
    }

    printContainer = i => {
        const container = this.state.outputs[i];
        if(!container) return;
        const labelData = {
            objectType: this.props.auth.constants.objectIds.batchActivity,
            object: this.state._id,
            container: container.containerSel.label,
            lot: container.lotSel.label,
            item: container.itemSel.label,
            type: this.props.auth.constants.labelPrintTypes.container,
            quantity: container.amount,
            uom: container.uomSel.label,
            category: container.category,
            date: this.state.completedDate,
            qrPath: window.location.origin + '#/container/' + this.state._id
        };

        this.setState({ containerPrintOpen: true, containerLabel: labelData });
    }

    toggleInputs = i => {
        let inputs = this.state.inputs;
        let open = inputs[i].open
        if(open) {
            if(!this.validateInput(i)) return;
        }
        inputs[i].open = !open;
        this.setState({ inputs: inputs});
    }

    saveInput = (i) => async (e) => {
        const t = this.props.t;
        if(!this.validateInput(i)) return;
        let inputs = this.state.inputs;
        let input = inputs[i];
        let data = {
            activity: this.state._id,
            index: i,
            record: {
                item: input.item,
                amount: input.amount,
                uom: input.uom ?? null,
                count: input.count ?? null,
                lot: input.lot ?? null,
                lotTransaction: input.lotTransaction ?? null,
                container: input.container ?? null,
                containerTransaction: input.containerTransaction ?? null,
                itemLedger: input.itemLedger ?? null,
                cloneItemLedger : input.cloneItemLedger ?? null
            },
        };
        try {
            const result = await axios.post('/api/batchactsaveinputs', data)
            input._id = result.data.id;
            input.reservation = result.data.reservation;
            input.open = false;
            this.setState({ inputs: inputs }); //Save or Close drawer
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        }catch (err) {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    validateInput = index => {
        const t = this.props.t;
        const state = this.state;
        let errors = {};
        const input = state.inputLines[index];
        for(let [i, line] of input.lines.entries()){
            if(input.item == null || input.item === '')
                errors[`${t('inputItem')} ($t{'line'} ${i})`] = t('required');
            if(line.amount == null || line.amount === '')
                errors[`${t('inputAmount')} ($t{'line'} ${i})`] = t('required');
            else if (isNaN(line.amount))
                errors[`${t('inputAmount')} ($t{'line'} ${i})`] = t('notNumeric');
            if(line.amount <= 0)
                errors[`${t('inputAmount')} ($t{'line'} ${i})`] = t('moreThan0');
            if(input.uom == null || input.uom === '')
                errors[`${t('inputUOM')} ($t{'line'} ${i})`] = t('required');
            if(input.itemSel != null && input.itemSel.lotTracked === true && line.containerSel == null)
                errors[`${t('inputContainer')} ($t{'line'} ${i})`] = t('required');
            if(line.containerSel != null && line.reservation == null && parseFloat(line.amount) > parseFloat(line.containerSel.quantity)){
                errors[`${t('inputAmount')} ($t{'line'} ${i})`] = t('moreThanContainer');
            } else if (line.containerSel != null && line.reservation != null && parseFloat(line.amount) > parseFloat(line.reservedAmount) + parseFloat(line.containerSel.quantity)){
                errors[`${t('inputAmount')} ($t{'line'} ${i})`] = t('moreThanContainer');
            } else {
                if(input.itemSel.lotTracked === false && input.itemSel != null && input.itemSel.infinite !== true && parseFloat(input.itemSel.inventory) < parseFloat(line.amount))
                    errors[`${t('inputAmount')} ($t{'line'} ${i})`] = t('moreThanInventory') + ", " + input.itemSel.inventory + ' left';
            }
            i++
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    toggleOutputs = async (i) => {
        let outputs = this.state.outputs;
        let open = outputs[i].open
        if(open) {
            if(!this.validateOutput(i)) return;
        }
        outputs[i].open = !open;
        this.setState({ outputs: outputs});
    }

    addOutputLine = (i) => {
        let lines = this.state.outputLines;
        let row = lines[i];
        if(row.lines == null){
            row.lines = [];
        }
        let previousLot = '';
        if(row.lines.length > 0){
            previousLot = row.lines[row.lines.length - 1].customLot;
        }
        row.lines.push({
            amount: '',
            count: '',
            discreteWeight: '',
            bin: '',
            binSel: null,
            totalWeight: '',
            tareWeight: '',
            customLot: '',
            containerType: '',
            containerTypeSel: null,
            previousLot: previousLot,
            addToBatch: false,
            cullCount: '',
            sourceRoom: '',
            sourceRoomSel: null
        });
        this.setState({outputLines: lines});
    }

    validateOutput = index => {
        const t = this.props.t;
        const state = this.state;
        let errors = {};
        const output = state.outputLines[index];
        if(output.item == null || output.item === '')
            errors[`${t('outputItem')} (${t('line')} ${index})`] = t('required');
        if(output.uom == null || output.uom === '')
            errors[`${t('outputUOM')} (${t('line')} ${index})`] = t('required');
        for(let [i, row] of output.lines.entries()){
            if(row.amount == null || row.amount === '')
                errors[`${t('outputAmount')} (${t('line')} ${index}, ${i})`] = t('required');
            else if (isNaN(row.amount))
                errors[`${t('outputAmount')} (${t('line')} ${index}, ${i})`] = t('notNumeric');
            if(row.amount <= 0)
                errors[`${t('outputAmount')} (${t('line')} ${index}, ${i})`] = t('moreThan0');
            if((row.bin == null || row.bin === '') && row.addToBatch !== true)
                errors[`${t('outputBin')} (${t('line')} ${index}, ${i})`] = t('required');
            if(output.itemSel != null && output.itemSel.customNumber && (row.customLot == null || row.customLot === '') && row.addToBatch !== true)
                errors[`${t('customLot')} (${t('line')} ${index}, ${i})`] = t('required');
            else if(output.itemSel != null && output.itemSel.customNumber && row.customLot != null && row.customLot !== '' ){
                let customOuts = state.outputLines.filter(x => x.item !== output.item && x.itemSel != null && x.itemSel.customNumber === true);
                for(let out of customOuts){
                    let matchLots = out.lines.filter(x => x.customLot === row.customLot);
                    if(matchLots != null && matchLots.length > 0){
                        errors[`${t('customLot')} (${t('line')} ${index}, ${i})`] = t('lotNumberUsed');
                    }
                }
            }
            if(state.plantLot){
                if(row.sourceRoom == null || row.sourceRoom === ''){
                    errors[`${t('sourceRoom')} (${t('line')} ${index}, ${i})`] = t('required');
                }
                if(output.item != null && output.item !== ''){
                    if(output.item !== state.stagePlant){
                        errors[`${t('outputItem')} (${t('line')} ${index})`] = t('invalidExportPlant');
                    }
                    if(state.currStage === this.props.auth.constants.batchStages.germination){
                        errors[`${t('outputItem')} (${t('line')} ${index})`] = t('cannotExportSeeds');
                    }
                }
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    addOutput = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.outputItem == null || this.state.outputItem === ''){
            errors[t('outputItem')] = t('required');
        } else {
            if(this.state.plantLot){
                if(this.state.outputItem !== this.state.stagePlant){
                    errors[t('outputItem')] = t('invalidExportPlant');
                }
                if(this.state.currStage === this.props.auth.constants.batchStages.germination){
                    errors[t('outputItem')] = t('cannotExportSeeds');
                }
            }
        }
        if(this.state.outputAmount == null || this.state.outputAmount === '')
            errors[t('outputAmount')] = t('required');
        if(this.state.outputUOM == null || this.state.outputUOM === '')
            errors[t('outputUOM')] = t('required');
        if(this.state.outputSel != null && this.state.outputSel.category !== this.props.auth.constants.itemCategories.caExcise){
            if((this.state.outputBin == null || this.state.outputBin === '') && this.state.addToBatch === false)
                errors[t('outputBin')] = t('required');
        }
        if(this.state.outputItem.discreteWeight && this.state.outputItem.uom === this.props.auth.constants.quantityUOMs.each){
            if(this.state.outputTotalWeightUOM == null || this.state.outputTotalWeightUOM === '')
                errors[t('outputTotalWeightUOM')] = t('required');
            if(this.state.outputTotalWeight == null || this.state.outputTotalWeight === '')
                errors[t('outputTotalWeight')] = t('required');
        }
        if(this.state.outputSel != null && this.state.outputSel.customNumber === true){
            if((this.state.customLot == null || this.state.customLot === '') && (this.state.outputLot == null || this.state.outputLot === '')){
                errors[t('customLot')] = t('required');
            }
        }
        if(this.state.plantLot){
            if(this.state.sourceRoom == null || this.state.sourceRoom === ''){
                errors[t('sourceRoom')] = t('required');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return;
        }
        let outputLines = this.state.outputLines;
        let existing = outputLines.find(x => x.item === this.state.outputItem);
        if(existing != null){
            existing.lines.push({
                amount: this.state.outputAmount,
                bin: this.state.outputBin,
                binSel: this.state.outputBinSel,
                totalWeight: this.state.outputTotalWeight,
                customLot: this.state.customLot,
                tareWeight: this.state.tareWeight,
                totalUOM: this.state.g,
                totalUOMSel: this.state.gSel,
                containerType: this.state.containerType,
                containerTypeSel: this.state.containerTypeSel,
                lot: this.state.outputLot,
                lotSel: this.state.outputLotSel,
                container: this.state.outputContainer,
                containerSel: this.state.outputContainerSel,
                newLot: this.state.newLot,
                containers: this.state.outputContainers,
                addToBatch: this.state.addToBatch,
                cullCount: this.state.cullPlants,
                sourceRoom: this.state.sourceRoom,
                sourceRoomSel: this.state.sourceRoomSel
            });
            let amount = 0;
            for(let row of existing.lines){
                amount += parseFloat(row.amount);
            }
            existing.amount = amount;
        } else {
            outputLines.push({
                item: this.state.outputItem,
                itemSel: this.state.outputSel,
                uom: this.state.outputUOM,
                uomSel: this.state.outputUOMSel,
                discreteWeightUOM: this.state.g,
                discreteWeightUOMSel: this.state.gSel,
                amount: this.state.outputAmount,
                lots: this.state.outputLots,
                lines: [{
                    amount: this.state.outputAmount,
                    bin: this.state.outputBin,
                    binSel: this.state.outputBinSel,
                    totalWeight: this.state.outputTotalWeight,
                    customLot: this.state.customLot,
                    tareWeight: this.state.tareWeight,
                    totalUOM: this.state.g,
                    totalUOMSel: this.state.gSel,
                    containerType: this.state.containerType,
                    lotSel: this.state.outputLotSel,
                    lot: this.state.outputLot,
                    container: this.state.outputContainer,
                    containerSel: this.state.containerSel,
                    containers: this.state.outputContainers,
                    containerTypeSel: this.state.containerTypeSel,
                    newLot: this.state.newLot,
                    addToBatch: this.state.addToBatch,
                    cullCount: this.state.cullPlants,
                    sourceRoom: this.state.sourceRoom,
                    sourceRoomSel: this.state.sourceRoomSel
                }]
            });
        }
        let isAddToBatch = false;
        for(let row of outputLines){
            for(let line of row.lines){
                if(line.addToBatch === true){
                    isAddToBatch = true;
                    break;
                }
            }
        }
        this.setState({
            outputLines: outputLines,
            outputItem: '',
            outputSel: null,
            outputAmount: 0,
            outputUOM: '',
            outputUOMSel: null,
            outputBin: '',
            outputBinSel: null,
            errors: {},
            outputDiscrete: '',
            outputDiscreteUOM: '',
            outputDiscreteUOMSel: null,
            outputTotalWeight: '',
            outputTotalWeightUOM: '',
            outputTotalWeightUOMSel: null,
            customLot: '',
            tareWeight: '',
            containerType: '',
            containerTypeSel: null,
            outputLot: '',
            outputLotSel: null,
            outputContainer: '',
            outputContainerSel: null,
            newLot: false,
            outputLots: [],
            outputContainers: [],
            addToBatch: false,
            isAddToBatch: isAddToBatch,
            cullPlants: '',
            sourceRoom: '',
            sourceRoomSel: null
        });
    };

    toggleOutputTare = index => {
        let outputs = this.state.outputs;
        outputs[index].tareRequired = !outputs[index].tareRequired;
        this.setState({ outputs });
    }

    toggleDialog = () => {
        let dialogOpen = this.state.dialogOpen;
        this.setState({ dialogOpen: !dialogOpen });
    };

    changeCompRoomNumber = (name, i) => (e) => {
        let list = this.state.roomsCompleted;
        let quantityCompleted = this.state.quantityCompleted;
        let row = list[i];
        let value = e.target.value;
        if(isNaN(value))
            return;
        row[name] = value;
        if(name === 'quantityCompleted'){
            quantityCompleted = 0;
            for(let row of list){
                if(row.quantityCompleted != null && row.quantityCompleted !== ''){
                    quantityCompleted += parseInt(row.quantityCompleted);
                }
            }
        }
        this.setState({roomsCompleted: list, quantityCompleted: quantityCompleted});
    };

    deleteOutputRow = (i, j) => (e) => {
        let contentLines = this.state.outputLines;
        if(i > -1 && i < contentLines.length){
            let line = contentLines[i];
            if(j > -1 && j < line.lines.length){
                line.lines.splice(j, 1);
                let isAddToBatch = false;
                for(let row of contentLines){
                    for(let line of row.lines){
                        if(line.addToBatch === true){
                            isAddToBatch = true;
                            break;
                        }
                    }
                }
                line = this.calculateLineTotals(line);
                this.setState({outputLines: contentLines, isAddToBatch: isAddToBatch});
            }
        }
    };

    calculateLineTotals(line){
        let amount = 0.0;
        let count = 0;
        for(let row of line.lines){
            if(row.amount !== '')
                amount += parseFloat(row.amount);
            count += row.count != null && row.count !== '' ? parseInt(row.count) : 0;
        }
        line.amount = amount;
        line.count = count !== 0 ? count : null;
        return line;
    }

    togglePrintViewer = () => {
        this.setState({printViewer: !this.state.printViewer});
    }

    deleteSigCallback = async (type) => {
        try{
            let result = await axios.post('/api/batchactivitysigdelete', {type: type, id: this.state._id});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    toggleSequence = (e) => {
        this.setState({showSequence: !this.state.showSequence});
    }

    editLastSequence = async (e) => {
        if(this.state.newSequence === '' || this.state.newSequence == null || isNaN(this.state.newSequence)){
            let error = {};
            error[this.props.t('newSequence')] = this.props.t('required');
            this.props.dispatch({ type: 'CREATE_ERROR', payload: error });
            return;
        }
        try {
            let result = await axios.post('/api/batchactivitysequence', {id: this.state._id, sequence: this.state.newSequence});
            this.setState({showSequence: false, newSequence: ''});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    render(){
        const { harvest, plant, fieldAccess, printAccess, printViewer } = this.state;
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const hasDevices = this.props.devices.hasRegisteredDevices;
        const preInventoryLabel = this.props.auth.user.preInventoryLabel;
        const micro = this.props.auth.user.micro;
        const constants = this.props.auth.constants;
        const errors = this.props.errors;

        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={fieldAccess}
                        observationClick={this.editObservation(null)}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        observationAccess={this.state.observationAccess}
                        labelData={this.state.outputLabels}
                        plantLabel={this.state.plantLabel}
                        labelAmount='multiple'
                        printViewClick={this.togglePrintViewer}
                        printViewTitle={t('batchActivity')}
                        enablePrintView={this.state.status === this.props.auth.constants.statuses.signed}
                        objectType={this.props.auth.constants.objectIds.batchActivity}
                        id={this.state._id}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        editSequenceClick={this.props.auth.user.internal ? this.toggleSequence : null}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TabPanel value={this.state.tabKey} index={0}>
                                <br/>
                                <Grid container spacing={3}>
                                    {this.state._id != null && this.state._id !== '' &&
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('number')}</FormLabel>
                                                {this.state.number}
                                            </FormControl>
                                        </Grid>
                                    }
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('status')}</FormLabel>
                                            {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                        </FormControl>
                                    </Grid>
                                    {this.state.batchObj != null &&
                                        <Grid item xs={6} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('batch')}</FormLabel>
                                                <Link to={'/evorabatch/' + this.state.batchObj._id}>{this.state.batchObj.number}</Link>
                                            </FormControl>
                                        </Grid>
                                    }
                                    <Grid item xs={4} sm={2}>
                                        {fieldAccess &&
                                            <DateSelect
                                                onChange={this.dateChange('completedDate')}
                                                value={this.state.completedDate}
                                                helperText={errors[t('completedDate')]}
                                                error={errors[t('completedDate')] != null ? true : false}
                                                label={t('completedOn')}
                                                disabled={!fieldAccess}
                                                format={this.props.auth.user.dateFormat}
                                                required
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('completedOn')}</FormLabel>
                                                <DateDisplay value={this.state.completedDate} format={this.props.auth.user.dateFormat}/>
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('batchCount')}</FormLabel>
                                            {this.state.batchObj != null ? this.state.batchQuantity : ''}
                                        </FormControl>
                                    </Grid>
                                    {(this.state.roomsCompleted == null || this.state.roomsCompleted.length === 0) &&
                                        <Grid item xs={4} sm={2}>
                                            {fieldAccess &&
                                                <TextField type="number" value={this.state.quantityCompleted} onChange={this.changeNumber} name="quantityCompleted"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('completed')} required
                                                error={errors[t('quantityCompleted')] != null ? true : false} helperText={errors[t('quantityCompleted')]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('completed')}</FormLabel>
                                                    {this.state.quantityCompleted}
                                                </FormControl>
                                            }
                                        </Grid>
                                    }
                                    {(this.state.roomsCompleted == null || this.state.roomsCompleted.length === 0) &&
                                        <Grid item xs={4} sm={2}>
                                            {fieldAccess &&
                                                <TextField type="number" value={this.state.effort} onChange={this.changeNumber} name="effort"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('effortM')} required
                                                error={errors[t('effort')] != null ? true : false} helperText={errors[t('effort')]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('effortM')}</FormLabel>
                                                    {this.state.effort}
                                                </FormControl>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                                {this.state.roomsCompleted != null && this.state.roomsCompleted.length > 0 &&
                                    <Aux>
                                        <br/>
                                        <SubSectionHeading title={t('roomsCompleted')}/>
                                        <Grid container spacing={3} key="compHeader">
                                            <Grid item xs={6} sm={3}>{t('room')}</Grid>
                                            <Grid item xs={4} sm={2}>{t('completed')}</Grid>
                                            <Grid item xs={4} sm={2}>{t('effortM')}</Grid>
                                            <Grid item xs={2} sm={2}>{t('plantCount')}</Grid>
                                        </Grid>
                                        {this.state.roomsCompleted.map((row, i) => 
                                            <Grid container spacing={3} key={'roomrow' + i}>
                                                <Grid item xs={6} sm={3}>{row.roomSel != null ? row.roomSel.label : ''}</Grid>
                                                <Grid item xs={4} sm={2}>
                                                    {fieldAccess &&
                                                        <TextField type="number" value={row.quantityCompleted} 
                                                        onChange={this.changeCompRoomNumber('quantityCompleted', i)} 
                                                        name={'quantityCompleted'}
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} 
                                                        error={errors[`${t('quantityCompleted')} (Line ${i})`] != null ? true : false} helperText={errors[`${t('quantityCompleted')} (Line ${i})`]}/>
                                                    }
                                                    {!fieldAccess &&
                                                        row.quantityCompleted
                                                    }
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    {fieldAccess &&
                                                        <TextField type="number" value={row.effort} 
                                                        onChange={this.changeCompRoomNumber('effort', i)}
                                                        name={'effort' + i}
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} 
                                                        error={errors[`${t('effort')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('effort')} (${t('line')} ${i})`]}/>
                                                    }
                                                    {!fieldAccess &&
                                                        row.effort
                                                    }
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    {row.count != null ? row.count : ''}
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Aux>
                                }
                                {this.state.hasForm === true &&
                                    <Aux>
                                        <br/>
                                        {errors[t('form')] != null && errors[t('form')] !== '' &&
                                            <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                        }
                                        <Form renderer={renderer}
                                            defaultFields={this.state.form}
                                            onChange={this.valueChanged}
                                            disabled={!fieldAccess}/>
                                    </Aux>
                                }
                                {(this.state.status === this.props.auth.constants.statuses.complete ||
                                    this.state.status === this.props.auth.constants.statuses.reviewed ||
                                    this.state.status === this.props.auth.constants.statuses.signed) &&
                                    <Aux>
                                         {((this.state.inputCost != null && this.state.inputCost !== '' && this.state.inputCost !== 0) ||
                                        (this.state.labourCost != null && this.state.labourCost !== '' && this.state.labourCost !== 0)) &&
                                            <Aux>
                                                <br/>
                                                <SectionTitle title={t('costs')}/>
                                                <Grid container spacing={3}>
                                                    {this.state.inputCost != null && this.state.inputCost !== '' &&
                                                        <Grid item xs={4} sm={2}>
                                                            <FormControl>
                                                                <FormLabel>{t('costItems')}</FormLabel>
                                                                <NumericFormat displayType={'text'} value={this.state.inputCost} thousandSeparator={true}
                                                                decimalScale={2} prefix="$"/>
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                    {this.state.labourCost != null && this.state.labourCost !== '' &&
                                                        <Grid item xs={4} sm={2}>
                                                            <FormControl>
                                                                <FormLabel>{t('labour')}</FormLabel>
                                                                <NumericFormat displayType={'text'} value={this.state.labourCost} thousandSeparator={true}
                                                                decimalScale={2} prefix="$"/>
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                </Grid>
                                                <br/>
                                            </Aux>
                                        }
                                    </Aux>
                                }
                                <Grid container spacing={3}>
                                    {this.state.sopList != null && this.state.sopList.length > 0 &&
                                        <Grid item sm={11}>
                                            <AutoCompleteField
                                                multiple={true} disabled={!fieldAccess}
                                                value={this.state.sopsSel}
                                                options={this.state.sopList}
                                                onChange={this.changeSop}
                                                error={errors[t('sopIds')] != null ? true : false}
                                                helperText={errors[t('sopIds')]} 
                                                label={t('workRecs')}
                                            />
                                        </Grid>
                                    }                                    
                                    <Grid item sm={11}>
                                        <AutoCompleteField
                                            multiple={true} disabled={!fieldAccess}
                                            value={this.state.usersSel}
                                            options={fieldAccess && !internal ? this.state.activeUsers : this.state.userList}
                                            onChange={this.changeMulti('usersSel', 'users')}
                                            error={errors[t('users')] != null ? true : false}
                                            helperText={errors[t('users')]} 
                                            label={t('users')}
                                            required
                                        />
                                    </Grid>
                                </Grid>

                                {fieldAccess && !micro &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={10}>
                                            <Button variant="outlined" color="primary" size="small" onClick={this.addDeviation}>{t('addDeviation')}</Button>
                                            <Button variant="outlined" color="primary" size="small" onClick={this.addNcr}>{t('addNcr')}</Button>
                                        </Grid>
                                    </Grid>
                                }
                                {this.state.deviations != null && this.state.deviations.length > 0 &&
                                    <Aux>
                                        <br/>
                                        <SectionTitle title={t('deviations')}/>
                                        <Grid container spacing={3} key="devhead">
                                            <Grid item xs={4} sm={2}>{t('number')}</Grid>
                                            <Grid item xs={6} sm={3}>{t('title')}</Grid>
                                            <Grid item xs={4} sm={2}>{t('date')}</Grid>
                                        </Grid>
                                        {this.state.deviations.map((row, i) =>
                                            <Grid container spacing={3} key={'dev' + i}>
                                                <Grid item xs={4} sm={2}>
                                                    <Link to={'/deviation/' + row._id}>{row.number}</Link>
                                                </Grid>
                                                <Grid item xs={6} sm={3}>{row.title}</Grid>
                                                <Grid item xs={4} sm={2}><DateDisplay value={row.occurrenceDate} format={this.props.auth.user.dateFormat}/></Grid>
                                            </Grid>
                                        )}
                                        <br/>
                                    </Aux>
                                }
                                {this.state.ncrs != null && this.state.ncrs.length > 0 &&
                                    <Aux>
                                        <br/>
                                        <SectionTitle title={t('ncrs')}/>
                                        <Grid container spacing={3} key='ncrhead'>
                                            <Grid item xs={4} sm={2}>{t('number')}</Grid>
                                            <Grid item xs={6} sm={3}>{t('title')}</Grid>
                                            <Grid item xs={4} sm={2}>{t('date')}</Grid>
                                        </Grid>
                                        {this.state.ncrs.map((row, i) =>
                                            <Grid container spacing={3} key={'ncr' + i}>
                                                <Grid item xs={4} sm={2}>
                                                    <Link to={'/deviation/' + row._id}>{row.number}</Link>
                                                </Grid>
                                                <Grid item xs={6} sm={3}>{row.title}</Grid>
                                                <Grid item xs={4} sm={2}><DateDisplay value={row.occurrenceDate} format={this.props.auth.user.dateFormat}/></Grid>
                                            </Grid>
                                        )}
                                        <br/>
                                    </Aux>
                                }
                                {!harvest && !this.state.isDestruction && ((!fieldAccess && this.state.inputLines != null && this.state.inputLines.length > 0) || fieldAccess) &&
                                    <Aux>
                                        <br/>
                                        <SectionTitle title={t('inputs')}/>
                                        {this.state.inputLines != null && this.state.inputLines.length > 0 && this.state.inputLines.map((row, i) => 
                                            <Box borderColor="primary.main" {...defaultProps} key={'inputLine' + i} component={'div'}>
                                                {row.lines.length > 1 &&
                                                    <Aux key={'inputLineHead' + i}>
                                                        <Grid container spacing={3} key={'inputLine' + i + 'head'}>
                                                            <Grid item sx={{width: 350}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('item')}</FormLabel>
                                                                    <Link to={'/item/' + row.item}>{row.itemSel != null ? row.itemSel.label : ''}</Link>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item sx={{width: 150}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('quantity')}</FormLabel>
                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                        value={row.amount != null && row.amount !== '' ? row.amount : 0}
                                                                        suffix={row.uomSel != null ? row.uomSel.label : ''}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            {row.count != null && row.count !== 0 &&
                                                                <Grid item sx={{width: 100}}>
                                                                    <FormControl>
                                                                        <FormLabel>{t('count')}</FormLabel>
                                                                        <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                            value={row.count != null && row.count !== '' ? row.count : 0}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            }
                                                            {fieldAccess &&
                                                                <Grid item sx={{width: 50}}>
                                                                    <IconButton aria-label="delete line" onClick={this.deleteInput(i)}>
                                                                        <RemoveCircleTwoToneIcon  />
                                                                    </IconButton>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                        <br/>
                                                        {row.lines != null && row.lines.length > 0 &&
                                                            <Grid container spacing={3} key={'ihead' + i}>
                                                                <Grid item sx={{width: 110}}>
                                                                    <FormLabel>{t('quantity') + '(' + row.uomSel.label + ')'}</FormLabel>
                                                                </Grid>
                                                                {row.itemSel != null && row.itemSel.hasCount === true &&
                                                                    <Grid item sx={{width: 100}}>
                                                                        <FormLabel>{t('count')}</FormLabel>
                                                                    </Grid>
                                                                }
                                                                {row.itemSel.lotTracked && this.props.auth.user.binFilter === true &&
                                                                    <Grid item style={{width: 170}}>
                                                                        <FormLabel>{t('bin')}</FormLabel>
                                                                    </Grid>
                                                                }
                                                                {row.itemSel.lotTracked &&
                                                                    <Aux>
                                                                        <Grid item sx={{width: 180}}>
                                                                            <FormLabel>{t('container')}</FormLabel>
                                                                        </Grid>
                                                                        {!fieldAccess &&
                                                                            <Grid item sx={{width: 140}}>
                                                                                <FormLabel>{t('lot')}</FormLabel>
                                                                            </Grid>
                                                                        }
                                                                    </Aux>
                                                                }
                                                                {fieldAccess &&
                                                                    <Grid item sx={{width: 50}}>
                                                                        <IconButton aria-label="add line" onClick={() => this.addInputLine(i)}>
                                                                            <AddBoxTwoToneIcon  />
                                                                        </IconButton>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        }
                                                        {row.lines != null && row.lines.length > 0 && row.lines.map((line, k) =>
                                                            <Aux key={'iline' + i + 'a' + k}>
                                                                {!fieldAccess &&
                                                                    <Grid container spacing={3} key={'in' + i + 'a' + k} sx={{pb: 2}}>
                                                                        <Grid item sx={{width: 110, textAlign: 'center'}} >
                                                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                                value={line.amount != null && line.amount !== '' ? line.amount : 0}
                                                                            />
                                                                        </Grid>
                                                                        {row.itemSel != null && row.itemSel.hasCount === true &&
                                                                            <Grid item sx={{width: 100, textAlign: 'center'}}>
                                                                                <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                                    value={line.count != null && line.count !== '' ? line.count : 0}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.lotTracked &&
                                                                            <Aux>
                                                                                <Grid item sx={{width: 180}}>
                                                                                    {line.containerSel != null &&
                                                                                        <Link to={'/container/' + line.container.value}>{line.containerSel.label}</Link>
                                                                                    }
                                                                                </Grid>
                                                                                <Grid item sx={{width: 140}}>
                                                                                    {line.lot!= null && row.lot !== '' &&
                                                                                        <Link to={'/lot/' + line.lot._id}>{line.lot.number}</Link>
                                                                                    }
                                                                                </Grid>
                                                                            </Aux>
                                                                        }
                                                                    </Grid>
                                                                }
                                                                {fieldAccess &&
                                                                    <Aux key={'iline' + i + 'a' + k}>
                                                                        <Grid container spacing={3} key={'ilinea' + i + 'a' + k}>
                                                                            <Grid item sx={{width: 110}}>
                                                                                <TextField type="number" value={line.amount} onChange={this.changeSubLineNumber('amount', i, k)} name={"amount" + i + 'a' + k}
                                                                                size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"inputAmount" + i +'a' + k}
                                                                                error={errors[`${t('inputAmount')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('inputAmount')} (${t('line')} ${i}, ${k})`]}/>
                                                                            </Grid>
                                                                            {row.itemSel != null && row.itemSel.hasCount &&
                                                                                <Grid item sx={{width: 100}}>
                                                                                    <TextField type="number" value={line.count} onChange={this.changeSubLineNumber('count', i, k)} name={"count" + i + 'a' + k}
                                                                                    size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"inputCount" + i +'a' + k} 
                                                                                    error={errors[`${t('inputCount')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('inputCount')} (${t('line')} ${i}, ${k})`]}/>
                                                                                </Grid>
                                                                            }
                                                                            {row.itemSel.lotTracked &&
                                                                                <Grid item sx={{width: 250}}>
                                                                                    <AutoCompleteField
                                                                                        value={line.containerSel}
                                                                                        options={row.containers}
                                                                                        onChange={this.changeSubLineAuto('containerSel', 'container', i, k)}
                                                                                        error={errors[`${t('inputContainer')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                        helperText={errors[`${t('inputContainer')} (${t('line')} ${i}, ${k})`]} 
                                                                                        disabled={!fieldAccess}
                                                                                        required
                                                                                        data-cy={"inputContainer" + i +'a' + k}
                                                                                    />
                                                                                </Grid>
                                                                            }
                                                                            <Grid item style={{sx: 50}}>
                                                                                <IconButton aria-label="delete line" onClick={this.deleteInputRow(i, k)} data-cy={"deleteInput" + i +'a' + k}>
                                                                                    <RemoveCircleTwoToneIcon  />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Aux>
                                                                }
                                                            </Aux>
                                                        )}
                                                    </Aux>
                                                }
                                                {row.lines.length < 2 &&
                                                    <Grid key={"inputs" + i} container spacing={3}>
                                                        <Grid item style={{sx: 350}}>
                                                            <FormControl>
                                                                <FormLabel>{t('item')}</FormLabel>
                                                                <Link to={'/item/' + row.item}>{row.itemSel != null ? row.itemSel.label : ''}</Link>
                                                            </FormControl>
                                                        </Grid>
                                                        {row.lines[0].lot != null && row.lines[0].lot._id != null &&
                                                            <Grid item sx={{width: 220}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('lot')}</FormLabel>
                                                                    <Link to={'/lot/' + row.lines[0].lot._id}>{row.lines[0].lot.number}</Link>
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {row.itemSel != null && row.itemSel.lotTracked === true && fieldAccess &&
                                                            <Grid item sx={{width: 250}}>
                                                                <AutoCompleteField
                                                                    value={row.lines[0].containerSel}
                                                                    options={row.containers}
                                                                    onChange={this.changeSubLineAuto('containerSel', 'container', i, 0)}
                                                                    error={errors[`${t('container')} (${t('line')} 0, 0)`] != null ? true : false}
                                                                    helperText={errors[`${t('container')} (${t('line')} 0, 0)`]}
                                                                    disabled={!fieldAccess}
                                                                    required
                                                                    label={t('container')}
                                                                    data-cy={"inputContainer" + i}
                                                                    groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                                    noneMessage={t('noLotAssigned')}
                                                                />
                                                            </Grid>
                                                        }
                                                        {row.lines[0].containerSel != null && !fieldAccess &&
                                                            <Grid item sx={{width: 250}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('container')}</FormLabel>
                                                                    <Link to={'/container/' + row.lines[0].container}>{row.lines[0].containerSel != null ? row.lines[0].containerSel.label : ''}</Link>
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        <Grid item sx={{width: 125}}>
                                                            {fieldAccess &&
                                                                <TextField type="number" value={row.lines[0].amount} onChange={this.changeSubLineNumber('amount', i, 0)} name={"inputAmount" + i + 'a' + 0}
                                                                size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"inputAmount" + i}
                                                                label={t('amount')+(row.uomSel != null && row.uomSel !== '' ? ' ('+row.uomSel.label+')' : '')} 
                                                                error={errors[`${t('inputAmount')} (${t('line')} 0)`] != null ? true : false} helperText={errors[`${t('inputAmount')} (${t('line')} 0)`]}/>
                                                            }
                                                            {!fieldAccess &&
                                                                <FormControl>
                                                                    <FormLabel>{t('amount')+(row.uomSel != null && row.uomSel !== '' ? ' ('+row.uomSel.label+')' : '')}</FormLabel>
                                                                    <NumericFormat displayType={'text'} value={row.amount} thousandSeparator={true} decimalScale={3}/>
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                        {row.itemSel != null && row.itemSel.hasCount &&
                                                            <Grid item sx={{width: 125}}>
                                                                {fieldAccess &&
                                                                    <TextField type="number" value={row.lines[0].count} onChange={this.changeSubLineNumber('count', i, 0)} name={"inputCount" + i + 'a' + 0}
                                                                    size="medium" fullWidth={true} disabled={!fieldAccess || !row.itemSel.hasCount}
                                                                    label={t('count')+' (ea)'} data-cy={"inputCount" + i} required
                                                                    error={errors[`${t('inputCount')} (${t('line')} 0)`] != null ? true : false} helperText={errors[`${t('inputCount')} (${t('line')} 0)`]}/>
                                                                }
                                                                {!fieldAccess &&
                                                                    <FormControl>
                                                                        <FormLabel>{t('count')+' (ea)'}</FormLabel>
                                                                        <NumericFormat displayType={'text'} value={row.lines[0].count} thousandSeparator={true} decimalScale={0}/>
                                                                    </FormControl>
                                                                }
                                                            </Grid>
                                                        }
                                                        {fieldAccess &&
                                                            <Grid item sx={{width: 50}}>
                                                                <IconButton aria-label="delete input" disabled={!fieldAccess} onClick={this.deleteInput(i)} data-cy={"deleteInput" + i}>
                                                                    <RemoveCircleTwoToneIcon  />
                                                                </IconButton>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                }
                                            </Box>
                                        )}
                                        {fieldAccess &&
                                            <Box borderColor="primary.main" {...defaultProps} component={'div'} key='addInput'>
                                            <Grid container spacing={3} key="addInputCont">
                                                <Grid item sx={{width: 350}}>
                                                    <AutoCompleteField
                                                        value={this.state.inputSel}
                                                        options={this.state.items}
                                                        onChange={this.changeAuto('inputSel', 'inputItem')}
                                                        error={errors[t('inputItem')] != null ? true : false}
                                                        helperText={errors[t('inputItem')]} 
                                                        disabled={!fieldAccess}
                                                        label={t('item')}
                                                        data-cy="inputItem"
                                                    />
                                                </Grid>
                                                {this.state.inputSel != null && this.state.inputSel.lotTracked === true &&
                                                    <Grid item sx={{width: 250}}>
                                                        <AutoCompleteField
                                                            value={this.state.inputContainerSel}
                                                            options={this.state.inputContainers}
                                                            onChange={this.changeAuto('inputContainerSel', 'inputContainer')}
                                                            error={errors[t('inputContainer')] != null ? true : false}
                                                            helperText={errors[t('inputContainer')]} 
                                                            disabled={!fieldAccess}
                                                            required
                                                            label={t('container')}
                                                            data-cy="inputContainer"
                                                            groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                            noneMessage={t('noLotAssigned')}
                                                        />
                                                    </Grid>
                                                }
                                                <Grid item sx={{width: 125}}>
                                                    <TextField type="number" value={this.state.inputAmount} onChange={this.changeNumber} name={"inputAmount"}
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} data-cy="inputAmount"
                                                    label={t('amount')+(this.state.inputUOMSel != null && this.state.inputUOMSel !== '' ? ' ('+this.state.inputUOMSel.label+')' : '')}
                                                    error={errors[t('inputAmount')] != null ? true : false} helperText={errors[t('inputAmount')]}/>
                                                </Grid>
                                                {this.state.inputHasCount &&
                                                    <Grid item xs={2} sm={1}>
                                                        <TextField type="number" value={this.state.inputCount} onChange={this.changeNumber} name={"inputCount"}
                                                        size="medium" fullWidth={true} disabled={!fieldAccess || this.state.inputSel == null || !this.state.inputSel.hasCount}
                                                        label={t('count') + ' (ea)'} data-cy="inputCount"
                                                        error={errors[t('inputCount')] != null ? true : false} helperText={errors[t('inputCount')]}/>
                                                    </Grid>
                                                }
                                                <Grid item xs={2} sm={1}>
                                                    <IconButton aria-label="add input" disabled={!fieldAccess} onClick={this.addInput} data-cy="addInput">
                                                        <AddBoxTwoToneIcon  />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                            </Box>
                                        }
                                    </Aux>
                                }
                                <br/>
                                {((!fieldAccess && this.state.outputLines != null && this.state.outputLines.length > 0) || fieldAccess) &&
                                    <Aux>
                                        <br/>
                                        <SectionTitle title={t('outputs')}/>
                                        {this.state.outputLines != null && this.state.outputLines.length > 0 && this.state.outputLines.map((row, i) =>
                                            <Box borderColor="primary.main" {...defaultProps} key={'outputLinex' + i} component={'div'}>
                                                {row.lines.length > 1 &&
                                                    <Aux key={'outAux' + i}>
                                                        <Grid container spacing={3} key={'out' + i}>
                                                            <Grid item sx={{width: 350}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('item')}</FormLabel>
                                                                    <Link to={'/item/' + row.item}>{row.itemSel != null ? row.itemSel.label : ''}</Link>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item sx={{width: 150}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('quantity')}</FormLabel>
                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                        value={row.amount != null && row.amount !== '' ? row.amount : 0}
                                                                        suffix={row.uomSel != null ? row.uomSel.label : ''}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            {row.count != null && row.count !== 0 &&
                                                                <Grid item sx={{width: 100}}>
                                                                    <FormControl>
                                                                        <FormLabel>{t('count')}</FormLabel>
                                                                        <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                            value={row.count != null && row.count !== '' ? row.count : 0}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            }
                                                            {row.totalWeight != null && row.totalWeight !== 0 && row.totalWeight !== '' &&
                                                                <Grid item sx={{width: 150}}>
                                                                    <FormControl>
                                                                        <FormLabel>{t('totalWeight')}</FormLabel>
                                                                        <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                            value={row.totalWeight != null && row.totalWeight !== '' ? row.totalWeight : 0}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            }
                                                            {fieldAccess &&
                                                                <Grid item style={{width: 50}}>
                                                                    <IconButton aria-label="delete line" onClick={this.deleteOutput(i)}>
                                                                        <RemoveCircleTwoToneIcon  />
                                                                    </IconButton>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                        <br/>
                                                        {row.lines != null && row.lines.length > 0 &&
                                                            <Grid container spacing={3} key={'ohead' + i}>
                                                                <Grid item sx={{width: 110}}>
                                                                    <FormLabel>{t('quantity') + '(' + row.uomSel.label + ')'}</FormLabel>
                                                                </Grid>
                                                                {(row.itemSel.hasCount === true || row.count != null) &&
                                                                    <Grid item sx={{width: 190}}>
                                                                        <FormLabel>{t('count')}</FormLabel>
                                                                    </Grid>
                                                                }
                                                                {this.state.isMultiplcation && row.itemSel != null && row.itemSel.category === this.props.auth.constants.itemCategories.plantlet &&
                                                                    <Grid item sx={{width: 60}}>
                                                                        <FormLabel>{t('addToBatch')}</FormLabel>
                                                                    </Grid>
                                                                }
                                                                {row.itemSel != null && row.itemSel.lotTracked &&
                                                                    <Grid item sx={{width: 60}}>
                                                                        <FormLabel>{t('newLot')}</FormLabel>
                                                                    </Grid>
                                                                }
                                                                {row.itemSel.lotTracked && row.itemSel.customNumber && fieldAccess && (row.lots == null || row.lots.length === 0) &&
                                                                    <Grid item sx={{width: 190}}>
                                                                        <FormLabel>{t('lot')}</FormLabel>
                                                                    </Grid>
                                                                }
                                                                {row.itemSel.lotTracked && !fieldAccess &&
                                                                    <Aux>
                                                                        <Grid item sx={{width: 210}}>
                                                                            <FormLabel>{t('container')}</FormLabel>
                                                                        </Grid>
                                                                        <Grid item sx={{width: 190}}>
                                                                            <FormLabel>{t('lot')}</FormLabel>
                                                                        </Grid>
                                                                    </Aux>
                                                                }
                                                                {row.itemSel.lotTracked && fieldAccess && row.lots != null && row.lots.length > 0 &&
                                                                    <Aux>
                                                                        <Grid item sx={{width: 190}}>
                                                                            <FormLabel>{t('lot')}</FormLabel>
                                                                        </Grid>
                                                                        <Grid item sx={{width: 210}}>
                                                                            <FormLabel>{t('container')}</FormLabel>
                                                                        </Grid>
                                                                    </Aux>
                                                                }
                                                                {this.state.cullTotal != null && this.state.cullTotal > 0 && 
                                                                (row.item === constants.items.seedWaste || row.item === constants.items.vegPlantWaste || 
                                                                row.item === constants.items.wholePlantWaste) &&
                                                                    <Grid item sx={{width: 100}}>
                                                                        <FormLabel>{t('plants')}</FormLabel>
                                                                    </Grid>
                                                                }
                                                                {row.itemSel.discreteWeight === true &&
                                                                    <Grid item sx={{width: 110}}>
                                                                        <FormLabel>{t('totalWeightG')}</FormLabel>
                                                                    </Grid>
                                                                }
                                                                {row.itemSel.tare === true &&
                                                                    <Grid item sx={{width: 120}}>
                                                                        <FormLabel>{t('tareWeight')}</FormLabel>
                                                                    </Grid>
                                                                }
                                                                {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                                    <Grid item sx={{width: 220}}>
                                                                        <FormLabel>{t('bin')}</FormLabel>
                                                                    </Grid>
                                                                }
                                                                {this.state.definition != null && this.state.definition.definitionId === this.props.auth.constants.batchActivityDefs.plantLot &&
                                                                    <Grid item sx={{width: 200}}>
                                                                        <FormLabel>{t('room')}</FormLabel>
                                                                    </Grid>
                                                                }
                                                                {row.itemSel != null && row.itemSel.lotTracked &&
                                                                    <Grid item sx={{width: 150}}>
                                                                        <FormLabel>{t('containerType')}</FormLabel>
                                                                    </Grid>
                                                                }
                                                                {fieldAccess &&
                                                                    <Grid item sx={{width: 50}}>
                                                                        <IconButton aria-label="delete line" onClick={() => this.addOutputLine(i)}>
                                                                            <AddBoxTwoToneIcon  />
                                                                        </IconButton>
                                                                    </Grid>
                                                                } 
                                                            </Grid>
                                                        }
                                                        {row.lines != null && row.lines.length > 0 && row.lines.map((line, k) =>
                                                            <Aux key={'ol' + i + 'a' + k}>
                                                                {!fieldAccess && 
                                                                    <Grid container spacing={3} key={'outline' + i + 'a' + k} sx={{pb: 2}}>
                                                                        <Grid item sx={{width: 110, textAlign: 'center'}} >
                                                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                                value={line.amount != null && line.amount !== '' ? line.amount : 0}
                                                                            />
                                                                        </Grid>
                                                                        {(row.itemSel.hasCount === true || row.count != null) &&
                                                                            <Grid item sx={{width: 100, textAlign: 'center'}}>
                                                                                <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                                    value={line.count != null && line.count !== '' ? line.count : 0}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {this.state.isMultiplcation && row.itemSel != null && row.itemSel.category === this.props.auth.constants.itemCategories.plantlet &&
                                                                            <Grid item sx={{width: 60}}>
                                                                                <CheckboxField
                                                                                checked={line.addToBatch} onChange={this.changeOutLineBool('addToBatch', i, k)}
                                                                                name="addToBatch" disabled={true}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.lotTracked &&
                                                                            <Grid item sx={{width: 60}}>
                                                                                <CheckboxField
                                                                                checked={line.newLot} onChange={this.changeOutLineBool('newLot', i, k)}
                                                                                name="newLot" disabled={true}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.lotTracked && !fieldAccess &&
                                                                            <Aux>
                                                                                <Grid item sx={{width: 210}}>
                                                                                    {line.containerSel != null &&
                                                                                        <Grid container>
                                                                                            <Grid item>
                                                                                                {hasDevices &&
                                                                                                    <LabelPrint 
                                                                                                        containerData={this.state.outputLabels != null && this.state.outputLabels.length > 0 ? 
                                                                                                                    this.state.outputLabels.find(x => x.id === line.container) : null}
                                                                                                        printAmount='single'
                                                                                                    />
                                                                                                }
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                {line.container != null &&
                                                                                                    <Link to={'/container/' + line.container}>{line.containerSel.label}</Link>
                                                                                                }
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    }
                                                                                </Grid>
                                                                                <Grid item sx={{width: 190}}>
                                                                                    {line.lotSel != null &&
                                                                                        <Link to={'/lot/' + line.lotSel.value}>{line.lotSel.label}</Link>
                                                                                    }
                                                                                </Grid>
                                                                            </Aux>
                                                                        }
                                                                        {this.state.cullTotal != null && this.state.cullTotal > 0 && 
                                                                        (row.item === constants.items.seedWaste || row.item === constants.items.vegPlantWaste || 
                                                                        row.item === constants.items.wholePlantWaste) &&
                                                                            <Grid item sx={{width: 100, textAlign: 'center'}}>
                                                                                <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                                    value={line.cullCount != null && line.cullCount !== '' ? line.cullCount : 0}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.discreteWeight &&
                                                                            <Grid item sx={{width: 110}}>
                                                                                <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                                    value={line.totalWeight != null && line.totalWeight !== '' ? line.totalWeight : 0}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.tare === true &&
                                                                            <Grid item sx={{width: 120}}>
                                                                                <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={2}
                                                                                    value={line.tareWeight != null && line.tareWeight !== '' ? line.tareWeight : 0}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                                            <Grid item sx={{width: 220}}>
                                                                                {line.binSel != null &&
                                                                                    <Link to={'/bin/' + line.binSel.value}>{line.binSel.label}</Link>
                                                                                }
                                                                            </Grid>
                                                                        }
                                                                        {this.state.definition != null && (this.state.definition.definitionId === this.props.auth.constants.batchActivityDefs.plantLot ||
                                                                        this.state.definition.definitionId === this.props.auth.constants.batchActivityDefs.tissueClone) &&
                                                                            <Grid item sx={{width: 200}}>
                                                                                {line.sourceRoomSel != null ? line.sourceRoomSel.label : ''}
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel != null && row.itemSel.lotTracked &&
                                                                            <Grid item sx={{width: 150}}>
                                                                                {line.containerTypeSel != null ? line.containerTypeSel.label : ''}
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                }
                                                                
                                                                {fieldAccess && 
                                                                    <Grid container spacing={3} key='newOutput'>
                                                                        <Grid item sx={{width: 110}}>
                                                                            <TextField type="number" value={line.amount} onChange={this.changeOutLineNumber('amount', i, k)} name={"outputAmount" + i + 'a' + k}
                                                                            size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"outputAmount" + i + 'a' + k}
                                                                            error={errors[`${t('outputAmount')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('outputAmount')} (${t('line')} ${i}, ${k})`]}/>
                                                                        </Grid>
                                                                        {(row.itemSel.hasCount || ((row.count != null && row.count !== '' && row.count !== 0) || (line.count != null && line.count !== '' && line.count !== 0))) &&
                                                                            <Grid item sx={{width: 100}}>
                                                                                {line.count != null &&
                                                                                    <NumericFormat displayType={'text'} value={line.count} thousandSeparator={true} />
                                                                                }
                                                                            </Grid>
                                                                        }
                                                                        {this.state.isMultiplcation && row.itemSel != null && row.itemSel.category === this.props.auth.constants.itemCategories.plantlet &&
                                                                            <Grid item sx={{width: 60}}>
                                                                                <CheckboxField
                                                                                checked={line.addToBatch} onChange={this.changeOutLineBool('addToBatch', i, k)}
                                                                                name="addToBatch" disabled={!fieldAccess}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel != null && row.itemSel.lotTracked &&
                                                                            <Grid item sx={{width: 60}}>
                                                                                <CheckboxField
                                                                                checked={line.newLot} onChange={this.changeOutLineBool('newLot', i, k)}
                                                                                name="newLot" disabled={!fieldAccess || line.addToBatch === true}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {row.lots != null && row.lots.length > 0 && line.newLot !== true && 
                                                                            <Grid item sx={{width: 190}}>
                                                                                <AutoCompleteField
                                                                                    value={line.lotSel}
                                                                                    options={row.lots}
                                                                                    onChange={this.changeOutLineAuto('lotSel', 'lot', i, k)}
                                                                                    error={errors[`${t('lot')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                    helperText={errors[`${t('lot')} (${t('line')} ${i}, ${k})`]}
                                                                                    disabled={!fieldAccess || line.addToBatch === true}
                                                                                    data-cy={"lot" + i + 'a' + k}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel != null && row.itemSel.lotTracked && row.itemSel.customNumber === true && 
                                                                        (line.newLot === true || row.lots == null || row.lots.length === 0) &&
                                                                            <Grid item sx={{width: 190}}>
                                                                                <TextField type="text" value={line.customLot} onChange={this.changeOutLineValue('customLot', i, k)} name={"customLot" + i + 'a' + k}
                                                                                size="medium" fullWidth={true} disabled={!fieldAccess || line.addToBatch === true} required data-cy={"outputCustomLot" + i + 'a' + k}
                                                                                placeholder={line.previousLot != null && line.previousLot !== '' && !line.newLot ? line.previousLot : ''}
                                                                                error={errors[`${t('customLot')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('customLot')} (${t('line')} ${i}, ${k})`]}/>
                                                                            </Grid>
                                                                        }
                                                                        {row.lots != null && row.lots.length > 0 && 
                                                                            <Grid item sx={{width: 210}}>
                                                                                {line.newLot !== true &&
                                                                                    <AutoCompleteField
                                                                                        value={line.containerSel}
                                                                                        options={line.containers}
                                                                                        onChange={this.changeOutLineAuto('containerSel', 'container', i, k)}
                                                                                        error={errors[`${t('customlot')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                        helperText={errors[`${t('customlot')} (${t('line')} ${i}, ${k})`]} 
                                                                                        disabled={!fieldAccess || line.addToBatch === true}
                                                                                        data-cy={"container" + i + 'a' + k}
                                                                                    />
                                                                                }
                                                                            </Grid>
                                                                        }
                                                                        {this.state.cullTotal != null && this.state.cullTotal > 0 && 
                                                                        (row.item === constants.items.seedWaste || row.item === constants.items.vegPlantWaste || 
                                                                        row.item === constants.items.wholePlantWaste) &&
                                                                            <Grid item sx={{width: 100, textAlign: 'center'}}>
                                                                                <TextField type="number" value={line.cullCount} onChange={this.changeOutLineNumber('cullCount', i, k)} name={"cullCount" + i + 'a' + k}
                                                                                    size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"cullCount" + i + 'a' + k} 
                                                                                    error={errors[`${t('cullCount')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('cullCount')} (${t('line')} ${i}, ${k})`]}/>
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.discreteWeight === true &&
                                                                            <Aux>
                                                                                <Grid item sx={{width: 100}}>
                                                                                    <TextField type="number" value={line.totalWeight} onChange={this.changeOutLineNumber('totalWeight', i, k)} name={"totalWeight" + i + 'a' + k}
                                                                                    size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"outputTotalWeight" + i + 'a' + k} 
                                                                                    error={errors[`${t('totalWeight')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('totalWeight')} (${t('line')} ${i}, ${k})`]}/>
                                                                                </Grid>
                                                                            </Aux>

                                                                        }
                                                                        {row.itemSel.tare === true &&
                                                                            <Grid item sx={{width: 120}}>
                                                                                <TextField type="number" value={line.tareWeight} onChange={this.changeOutLineNumber('tareWeight', i, k)} name={"tareWeight" + i + 'a' + k}
                                                                                size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"outputTareWeight" + i + 'a' + k}
                                                                                error={errors[`${t('tareWeight')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('tareWeight')} (${t('line')} ${i}, ${k})`]}/>
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                                            <Grid item sx={{width: 220}}>
                                                                                <AutoCompleteField
                                                                                    value={line.binSel}
                                                                                    options={this.state.bins}
                                                                                    onChange={this.changeOutLineAuto('binSel', 'bin', i, k)}
                                                                                    error={errors[`${t('outputBin')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                    helperText={errors[`${t('outputBin')} (${t('line')} ${i}, ${k})`]} 
                                                                                    disabled={!fieldAccess || line.addToBatch === true}
                                                                                    required
                                                                                    data-cy={"outputBin" + i + 'a' + k}
                                                                                    groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                                                    noneMessage={t('noRoomSelected')}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {this.state.definition != null && (this.state.definition.definitionId === this.props.auth.constants.batchActivityDefs.plantLot ||
                                                                        this.state.definition.definitionId === this.props.auth.constants.batchActivityDefs.tissueClone) &&
                                                                            <Grid item sx={{width: 200}}>
                                                                                <AutoCompleteField
                                                                                    value={line.sourceRoomSel}
                                                                                    options={this.state.batchRoomSelect}
                                                                                    onChange={this.changeOutLineAuto('sourceRoomSel', 'sourceRoom', i, k)}
                                                                                    error={errors[`${t('sourceRoom')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                    helperText={errors[`${t('sourceRoom')} (${t('line')} ${i}, ${k})`]} 
                                                                                    disabled={!fieldAccess }
                                                                                    required
                                                                                    data-cy={"sourceRoom" + i + 'a' + k}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel != null && row.itemSel.lotTracked &&
                                                                            <Grid item sx={{width: 150}}>
                                                                                <AutoCompleteField
                                                                                    value={line.containerTypeSel}
                                                                                    options={this.state.containerTypes}
                                                                                    onChange={this.changeOutLineAuto('containerTypeSel', 'containerType', i, k)}
                                                                                    error={errors[`${t('containerType')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                    helperText={errors[`${t('containerType')} (${t('line')} ${i}, ${k})`]} 
                                                                                    disabled={!fieldAccess || line.addToBatch === true}
                                                                                    required
                                                                                    data-cy={"containerType" + i + 'a' + k}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {fieldAccess &&
                                                                            <Grid item container sx={{width: 100}}>
                                                                                <Grid item>
                                                                                    <IconButton aria-label="delete line" onClick={this.deleteOutputRow(i, k)}>
                                                                                        <RemoveCircleTwoToneIcon  />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                                <Grid item sx={{ mt: 1 }}>
                                                                                    {hasDevices && preInventoryLabel && line.amount != null && line.amount > 0 &&
                                                                                        <LabelPrint 
                                                                                            containerData={{
                                                                                                container: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                                           row.itemSel.customNumber && line.customLot != null && line.customLot !== '' ? line.customLot + '-' + (k + 1) : 
                                                                                                           line.previousLot != null && line.previousLot !== '' ? line.previousLot + '-' + (k + 1) : '',
                                                                                                lot: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                                     row.itemSel.customNumber && line.customLot != null && line.customLot !== '' ? line.customLot : 
                                                                                                     line.previousLot != null && line.previousLot !== '' ? line.previousLot : '',
                                                                                                item: row.itemSel != null && row.itemSel.label != null ? row.itemSel.label : '',
                                                                                                strain: row.itemSel != null && row.itemSel.strain != null ? row.itemSel.strain : '',
                                                                                                quantity: ConversionHelper.round3(line.amount),
                                                                                                uom: row.uomSel.label,
                                                                                                category: line.containerTypeSel != null && line.containerTypeSel !== '' ? line.containerTypeSel.label : t('regular'),
                                                                                                itemNumber: row.itemSel != null && row.itemSel.number != null ? row.itemSel.number : '',
                                                                                                date: this.state.completedDate,
                                                                                                preInventory: true
                                                                                            }}
                                                                                            printAmount='single'
                                                                                        />
                                                                                    }
                                                                                </Grid>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                }
                                                            </Aux>
                                                        )}
                                                    </Aux>
                                                }
                                                {row.lines.length < 2 &&
                                                    <Grid container spacing={3} key={'output' + i}>
                                                        <Grid item sx={{width: 350}}>
                                                            <FormControl>
                                                                <FormLabel>{t('item')}</FormLabel>
                                                                <Link to={'/item/' + row.item}>{row.itemSel != null ? row.itemSel.label : ''}</Link>
                                                            </FormControl>
                                                        </Grid>
                                                        {this.state.isMultiplcation && row.itemSel != null && row.itemSel.category === this.props.auth.constants.itemCategories.plantlet && 
                                                            <Grid item sx={{width: 120}}>
                                                                <CheckboxField
                                                                checked={row.lines[0].addToBatch} onChange={this.changeOutLineBool('addToBatch', i, 0)} label={t('addToBatch')}
                                                                name="addToBatch" disabled={!fieldAccess}
                                                                />
                                                            </Grid>
                                                        }
                                                        {row.itemSel != null && row.itemSel.lotTracked && row.lines[0].addToBatch !== true &&
                                                            <Grid item sx={{width: 120}}>
                                                                <CheckboxField
                                                                checked={row.lines[0].newLot} onChange={this.changeOutLineBool('newLot', i, 0)} label={t('newLot')}
                                                                name="newLot" disabled={!fieldAccess}
                                                                />
                                                            </Grid>
                                                        }
                                                        {row.itemSel != null && row.itemSel.lotTracked && row.itemSel.customNumber && fieldAccess && row.lines[0].addToBatch !== true &&
                                                            <Grid item sx={{width: 150}}>
                                                                <TextField type="text" value={row.lines[0].customLot} onChange={this.changeOutLineValue('customLot', i, 0)} name={"customLot" + i + 'a' + 0}
                                                                size="medium" fullWidth={true} disabled={!fieldAccess}
                                                                label={t('lot')} data-cy={"outputCustomLot" + i}
                                                                error={errors[`${t('customLot')} (${t('line')} 0, 0)`] != null ? true : false} helperText={errors[`${t('customLot')} (${t('line')} 0, 0)`]}/>
                                                            </Grid>
                                                        }
                                                        {row.itemSel != null && row.itemSel.lotTracked && row.itemSel.customNumber && !fieldAccess && row.lines[0].addToBatch !== true && 
                                                        row.lines[0].newLot === true && row.lines[0].lotSel == null &&
                                                            <Grid item sx={{width: 150}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('lot')}</FormLabel>
                                                                    {row.lines[0].customLot}
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        <Grid item sx={{width: 125}}>
                                                            {fieldAccess &&
                                                                <TextField type="number" value={row.lines[0].amount} onChange={this.changeOutLineNumber('amount', i, 0)} name={"outputAmount" + i + 'a' + 0}
                                                                size="medium" fullWidth={true} disabled={!fieldAccess} data-cy={"outputAmount" + i}
                                                                label={t('amount')+(row.uomSel != null && row.uomSel !== '' ? ' ('+row.uomSel.label+')' : '')}
                                                                error={errors[`${t('outputAmount')} (${t('line')} 0, 0)`] != null ? true : false} helperText={errors[`${t('outputAmount')} (${t('line')} 0, 0)`]}/>
                                                            }
                                                            {!fieldAccess &&
                                                                <FormControl>
                                                                    <FormLabel>{t('amount')+(row.uomSel != null && row.uomSel !== '' ? ' ('+row.uomSel.label+')' : '')}</FormLabel>
                                                                    <NumericFormat displayType={'text'} value={row.lines[0].amount} thousandSeparator={true} decimalScale={3} />
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                        {this.state.outputCount &&
                                                            <Grid item sx={{width: 125}}>
                                                                {row.lines[0].count != null &&
                                                                    <FormControl>
                                                                        <FormLabel>{t('count') + ' (ea)'}</FormLabel>
                                                                        <NumericFormat displayType={'text'} value={row.lines[0].count} thousandSeparator={true} />
                                                                    </FormControl>
                                                                }
                                                            </Grid>
                                                        }
                                                        {row.lots != null && row.lots.length > 0 && fieldAccess && row.lines[0].newLot !== true && row.lines[0].addToBatch !== true &&
                                                            <Grid item sx={{width: 210}}>
                                                                <AutoCompleteField
                                                                    value={row.lines[0].lotSel}
                                                                    options={row.lines[0].lots}
                                                                    onChange={this.changeOutLineAuto('lotSel', 'lot', i, 0)}
                                                                    error={errors[`${t('lot')} (${t('line')} 0, 0)`] != null ? true : false}
                                                                    helperText={errors[`${t('lot')} (${t('line')} 0, 0)`]}
                                                                    disabled={!fieldAccess }
                                                                    label={t('lot')}
                                                                    data-cy={"outputLot" + i}
                                                                />
                                                            </Grid>
                                                        }
                                                        {row.lines[0].containers != null && row.lines[0].containers.length > 0 && row.lines[0].newLot !== true && fieldAccess && row.lines[0].addToBatch !== true &&
                                                            <Grid item sx={{width: 210}}>
                                                                <AutoCompleteField
                                                                    value={row.lines[0].containerSel}
                                                                    options={row.lines[0].containers}
                                                                    onChange={this.changeOutLineAuto('containerSel', 'container', i, 0)}
                                                                    error={errors[`${t('container')} (${t('line')} 0, 0)`] != null ? true : false}
                                                                    helperText={errors[`${t('container')} (${t('line')} 0, 0)`]}
                                                                    disabled={!fieldAccess }
                                                                    label={t('container')}
                                                                    data-cy={"outputContainer" + i}
                                                                />
                                                            </Grid>
                                                        }
                                                        {this.state.cullTotal != null && this.state.cullTotal > 0 && 
                                                        (row.item === constants.items.seedWaste || row.item === constants.items.vegPlantWaste || 
                                                        row.item === constants.items.wholePlantWaste) &&
                                                            <Grid item sx={{width: 100, textAlign: 'center'}}>
                                                                {fieldAccess &&
                                                                    <TextField type="number" value={row.lines[0].cullCount} onChange={this.changeOutLineNumber('cullCount', i, 0)} name={"cullCount" + i + 'a' + 0}
                                                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('plants')} data-cy={"cullCount" + i} 
                                                                    error={errors[`${t('container')} (${t('line')} 0, 0)`] != null ? true : false} helperText={errors[`${t('container')} (${t('line')} 0, 0)`]}/>
                                                                }
                                                                {!fieldAccess &&
                                                                    <FormControl>
                                                                        <FormLabel>{t('plants')}</FormLabel>
                                                                        <NumericFormat value={row.lines[0].cullCount} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                                    </FormControl>
                                                                }
                                                            </Grid>
                                                        }
                                                        {row.itemSel != null && row.itemSel.tare === true &&
                                                            <Grid item sx={{width: 120}} align="right">
                                                                {fieldAccess &&
                                                                    <TextField type="number" value={row.lines[0].tareWeight} onChange={this.changeOutLineNumber('tareWeight', i, 0)} name={"tareWeight" + i + 'a' + 0}
                                                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('plants')} data-cy={"outputTareWeight" + i} 
                                                                    error={errors[`${t('tareWeight')} (${t('line')} 0, 0)`] != null ? true : false} helperText={errors[`${t('tareWeight')} (${t('line')} 0, 0)`]}/>
                                                                }
                                                                {!fieldAccess &&
                                                                    <FormControl>
                                                                        <FormLabel>{t('tareWeight')}</FormLabel>
                                                                        <NumericFormat value={row.lines[0].tareWeight} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                                    </FormControl>
                                                                }
                                                            </Grid>
                                                        }
                                                        {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise && row.lines[0].addToBatch !== true &&
                                                            <Grid item sx={{width: 220}}>
                                                                {fieldAccess &&
                                                                    <AutoCompleteField
                                                                        value={row.lines[0].binSel}
                                                                        options={this.state.bins}
                                                                        onChange={this.changeOutLineAuto('binSel', 'bin', i, 0)}
                                                                        error={errors[`${t('outputBin')} (${t('line')} 0, 0)`] != null ? true : false}
                                                                        helperText={errors[`${t('outputBin')} (${t('line')} 0, 0)`]}
                                                                        disabled={!fieldAccess }
                                                                        required
                                                                        label={t('bin')}
                                                                        data-cy={"outputBin" + i}
                                                                        groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                                        noneMessage={t('noRoomSelected')}
                                                                    />
                                                                }
                                                                {!fieldAccess && row.lines[0].binSel != null &&
                                                                    <FormControl>
                                                                        <FormLabel>{t('bin')}</FormLabel>
                                                                        <Link to={'/bin/' + row.lines[0].binSel.value}>{row.lines[0].binSel.label}</Link>
                                                                    </FormControl>
                                                                }
                                                            </Grid>
                                                        }
                                                        {this.state.definition != null && (this.state.definition.definitionId === this.props.auth.constants.batchActivityDefs.plantLot ||
                                                        this.state.definition.definitionId === this.props.auth.constants.batchActivityDefs.tissueClone) &&
                                                            <Grid item sx={{width: 200}}>
                                                                {fieldAccess &&
                                                                    <AutoCompleteField
                                                                        value={row.lines[0].sourceRoomSel}
                                                                        options={this.state.batchRoomSelect}
                                                                        onChange={this.changeOutLineAuto('sourceRoomSel', 'sourceRoom', i, 0)}
                                                                        error={errors[`${t('sourceRoom')} (${t('line')} 0, 0)`] != null ? true : false}
                                                                        helperText={errors[`${t('sourceRoom')} (${t('line')} 0, 0)`]} 
                                                                        disabled={!fieldAccess }
                                                                        required
                                                                        label={t('room')}
                                                                        data-cy={"sourceRoom" + i}
                                                                    />
                                                                }
                                                                {!fieldAccess && row.lines[0].sourceRoomSel != null &&
                                                                    <FormControl>
                                                                        <FormLabel>{t('room')}</FormLabel>
                                                                        {row.lines[0].sourceRoomSel.label}
                                                                    </FormControl>
                                                                }
                                                            </Grid>
                                                        }
                                                        {row.itemSel != null && row.itemSel.discreteWeight &&
                                                            <Aux>
                                                                <Grid item sx={{width: 140}}>
                                                                    {fieldAccess &&
                                                                        <TextField type="number" value={row.lines[0].totalWeight}
                                                                        onChange={this.changeOutLineNumber('totalWeight', i, 0)}
                                                                        name={"totalWeight" + i + 'a' + 0}
                                                                        size="medium" fullWidth={true} disabled={!fieldAccess}
                                                                        label={t('totalWeightG')} required data-cy={"outputTotalWeight" + i}
                                                                        error={errors[`${t('totalWeight')} (${t('line')} 0, 0)`] != null ? true : false} helperText={errors[`${t('totalWeight')} (${t('line')} 0, 0)`]}/>
                                                                    }
                                                                    {!fieldAccess &&
                                                                        <FormControl>
                                                                            <FormLabel>{t('totalWeightG')}</FormLabel>
                                                                            <NumericFormat displayType={'text'} value={row.lines[0].totalWeight} thousandSeparator={true} decimalScale={3} />
                                                                        </FormControl>
                                                                    }
                                                                </Grid>
                                                                {row.lines[0].discreteWeight != null && row.lines[0].discreteWeight !== '' &&
                                                                    <Aux>
                                                                        <Grid item sx={{width: 150}}>
                                                                            <FormControl>
                                                                                <FormLabel>{t('discreteG')}</FormLabel>
                                                                                    <NumericFormat displayType={'text'} value={row.lines[0].discreteWeight} thousandSeparator={true} decimalScale={2} />
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Aux>
                                                                }
                                                            </Aux>
                                                        }
                                                        {!fieldAccess && row.lines[0].lotSel != null && row.lines[0].lot !== '' &&
                                                            <Grid item sx={{width: 200}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('lot')}</FormLabel>
                                                                    <Link to={'/lot/' + row.lines[0].lotSel.value}>{row.lines[0].lotSel != null ? row.lines[0].lotSel.label : ''}</Link>
                                                                </FormControl>
                                                                {row.lines[0].label != null && row.lines[0].label !== '' &&
                                                                    <IconButton aria-label="print lot label" 
                                                                                onClick={() => this.printDialog('lot', i, 0)} 
                                                                                disabled={!this.state.security.print} 
                                                                                sx={{ padding: 0,
                                                                                      marginLeft: 15,
                                                                                      marginBottom: 2,}} 
                                                                    >
                                                                        <LabelIcon />
                                                                    </IconButton>
                                                                }
                                                            </Grid>
                                                        }
                                                        {!fieldAccess && row.lines[0].container != null && row.lines[0].container !== '' && row.lines[0].containerSel != null && row.lines[0].containerSel !== '' &&
                                                            <Grid item sx={{width: 200}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('container')}</FormLabel>
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            {hasDevices &&
                                                                                <LabelPrint 
                                                                                    containerData={this.state.outputLabels != null && this.state.outputLabels.length > 0 ? 
                                                                                                this.state.outputLabels.find(x => x.id === row.lines[0].container) : null}
                                                                                    printAmount='single'
                                                                                />
                                                                            }
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Link to={'/container/' + row.lines[0].container}>{row.lines[0].containerSel != null ? row.lines[0].containerSel.label : ''}</Link>
                                                                        </Grid>
                                                                    </Grid>
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {row.itemSel != null && row.itemSel.lotTracked && row.lines[0].addToBatch !== true &&
                                                            <Grid item sx={{width: 150}}>
                                                                <AutoCompleteField
                                                                    value={row.lines[0].containerTypeSel}
                                                                    options={this.state.containerTypes}
                                                                    onChange={this.changeOutLineAuto('containerTypeSel', 'containerType', i, 0)}
                                                                    error={errors[`${t('containerType')} (${t('line')} 0, 0)`] != null ? true : false}
                                                                    helperText={errors[`${t('containerType')} (${t('line')} 0, 0)`]}
                                                                    disabled={!fieldAccess }
                                                                    required
                                                                    label={t('containerType')}
                                                                    data-cy={"containerType" + i}
                                                                />
                                                            </Grid>
                                                        }
                                                        {fieldAccess &&
                                                            <Grid container item sx={{width: 100}}>
                                                                <Grid item>
                                                                    <IconButton aria-label="delete output" onClick={this.deleteOutput(i)} disabled={!fieldAccess}>
                                                                        <RemoveCircleTwoToneIcon  />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item sx={{ mt: 1 }}>
                                                                    {hasDevices && preInventoryLabel && row.lines[0].amount != null && row.lines[0].amount > 0 && 
                                                                        <LabelPrint 
                                                                            containerData={{
                                                                                container: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                           row.lines[0].customLot != null && row.lines[0].customLot !== '' ? row.lines[0].customLot + '- 1' : '',
                                                                                lot: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                     row.lines[0].customLot != null && row.lines[0].customLot !== '' ? row.lines[0].customLot : '',
                                                                                item: row.itemSel != null && row.itemSel.label != null ? row.itemSel.label : '',
                                                                                strain: row.itemSel != null && row.itemSel.strain != null ? row.itemSel.strain : '',
                                                                                quantity: ConversionHelper.round3(row.lines[0].amount),
                                                                                uom: row.uomSel.label,
                                                                                category: row.lines[0].containerTypeSel != null && row.lines[0].containerTypeSel !== '' ? row.lines[0].containerTypeSel.label : t('regular'),
                                                                                itemNumber: row.itemSel != null && row.itemSel.number != null ? row.itemSel.number : '',
                                                                                date: this.state.completedDate,
                                                                                preInventory: true
                                                                            }}
                                                                            printAmount='single'
                                                                        />
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                }
                                            </Box>
                                        )}
                                    </Aux>
                                }
                                {fieldAccess && 
                                    <Box borderColor="primary.main" {...defaultProps} component={'div'} key="outNew">
                                    <Grid container spacing={3} key="newCont">
                                        <Grid item sx={{width: 350}}>
                                            <AutoCompleteField
                                                value={this.state.outputSel}
                                                options={this.state.allItems}
                                                onChange={this.changeAuto('outputSel', 'outputItem')}
                                                error={errors[t('outputItem')] != null ? true : false}
                                                helperText={errors[t('outputItem')]}
                                                disabled={!fieldAccess}
                                                label={t('item')}
                                                data-cy="outputItem"
                                            />
                                        </Grid>
                                        <Grid item sx={{width: 125}}>
                                            <TextField type="number" value={this.state.outputAmount} onChange={this.changeNumber} name={"outputAmount"}
                                            size="medium" fullWidth={true} disabled={!fieldAccess} data-cy="outputAmount"
                                            label={t('amount')+(this.state.outputUOMSel != null && this.state.outputUOMSel !== '' ? ' ('+this.state.outputUOMSel.label+')' : '')}
                                            error={errors[t('outputAmount')] != null ? true : false} helperText={errors[t('outputAmount')]}/>
                                        </Grid>
                                        {this.state.isMultiplcation && this.state.outputSel != null && this.state.outputSel.category === this.props.auth.constants.itemCategories.plantlet &&
                                            <Grid item sx={{width: 120}}>
                                                <CheckboxField
                                                checked={this.state.addToBatch} onChange={this.changeBool('addToBatch')} label={t('addToBatch')}
                                                name="addToBatch" disabled={!fieldAccess}
                                                />
                                            </Grid>
                                        }
                                        {this.state.addToBatch !== true &&
                                            <Grid item sx={{width: 120}}>
                                                <CheckboxField
                                                checked={this.state.newLot} onChange={this.changeBool('newLot')} label={t('newLot')}
                                                name="newLot" disabled={!fieldAccess}
                                                />
                                            </Grid>
                                        }
                                        {this.state.outputSel != null && this.state.outputSel.lotTracked === true && this.state.outputSel.customNumber && 
                                        (this.state.newLot === true || this.state.outputLots == null || this.state.outputLots.length === 0) && this.state.addToBatch !== true &&
                                            <Grid item sx={{width: 150}}>
                                                <TextField type="text" value={this.state.customLot} onChange={this.changeValue} name={"customLot"}
                                                size="medium" fullWidth={true} disabled={!fieldAccess}
                                                label={t('lot')} data-cy="outputCustomLot" 
                                                error={errors[t('customLot')] != null ? true : false} helperText={errors[t('customLot')]}/>
                                            </Grid>
                                        }
                                        {this.state.outputLots != null && this.state.outputLots.length > 0 && this.state.newLot !== true && this.state.addToBatch !== true &&
                                            <Grid item sx={{width: 210}}>
                                            <AutoCompleteField
                                                    value={this.state.outputLotSel}
                                                    options={this.state.outputLots}
                                                    onChange={this.changeAuto('outputLotSel', 'outputLot')}
                                                    error={errors[t('outputLot')] != null ? true : false}
                                                    helperText={errors[t('outputLot')]}
                                                    disabled={!fieldAccess}
                                                    label={t('lot')}
                                                    data-cy="outputLot"
                                                />
                                            </Grid>
                                        }
                                        {this.state.outputContainers != null && this.state.outputContainers.length > 0 && this.state.newLot !== true && this.state.addToBatch !== true &&
                                            <Grid item sx={{width: 210}}>
                                            <AutoCompleteField
                                                    value={this.state.outputContainerSel}
                                                    options={this.state.outputContainers}
                                                    onChange={this.changeAuto('outputContainerSel', 'outputContainer')}
                                                    error={errors[t('outputContainer')] != null ? true : false}
                                                    helperText={errors[t('outputContainer')]}
                                                    disabled={!fieldAccess}
                                                    label={t('container')}
                                                    data-cy="outputContainer"
                                                />
                                            </Grid>
                                        }
                                        {this.state.cullTotal != null && this.state.cullTotal > 0 && 
                                        (this.state.outputItem === constants.items.seedWaste || this.state.outputItem === constants.items.vegPlantWaste || 
                                        this.state.outputItem === constants.items.wholePlantWaste) &&
                                            <Grid item sx={{width: 100}}>
                                                <TextField type="number" value={this.state.cullPlants} onChange={this.changeNumber} name={"cullPlants"}
                                                size="medium" fullWidth={true} disabled={!fieldAccess} data-cy="cullPlants"
                                                label={t('plants')} 
                                                error={errors[t('cullPlants')] != null ? true : false} helperText={errors[t('cullPlants')]}/>
                                            </Grid>
                                        }
                                        {this.state.outputUOMSel != null && this.state.outputSel != null && this.state.outputSel.tare === true &&
                                            <Grid item sx={{width: 130}} align="right">
                                                <TextField type="number" value={this.state.tareWeight} onChange={this.changeNumber} name="tareWeight"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('tareWeight')} 
                                                error={errors[t('tareWeight')] != null ? true : false} helperText={errors[t('tareWeight')]}/>
                                            </Grid>
                                        }
                                        {this.state.outputSel != null && this.state.outputSel.category !== this.props.auth.constants.itemCategories.caExcise && this.state.addToBatch !== true &&
                                            <Grid item sx={{width: 220}}>
                                                <AutoCompleteField
                                                    value={this.state.outputBinSel}
                                                    options={this.state.bins}
                                                    onChange={this.changeAuto('outputBinSel', 'outputBin')}
                                                    error={errors[t('outputBin')] != null ? true : false}
                                                    helperText={errors[t('outputBin')]} 
                                                    disabled={!fieldAccess}
                                                    required
                                                    label={t('bin')}
                                                    data-cy="outputBin"
                                                    groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                    noneMessage={t('noRoomSelected')}
                                                />
                                            </Grid>
                                        }
                                        {this.state.definition != null && (this.state.definition.definitionId === this.props.auth.constants.batchActivityDefs.plantLot ||
                                        this.state.definition.definitionId === this.props.auth.constants.batchActivityDefs.tissueClone) &&
                                            <Grid item sx={{width: 200}}>
                                                <AutoCompleteField
                                                    value={this.state.sourceRoomSel}
                                                    options={this.state.batchRoomSelect}
                                                    onChange={this.changeAuto('sourceRoomSel', 'sourceRoom')}
                                                    error={errors[t('sourceRoom')] != null ? true : false}
                                                    helperText={errors[t('sourceRoom')]}
                                                    disabled={!fieldAccess}
                                                    required
                                                    label={t('room')}
                                                    data-cy="sourceRoom"
                                                />
                                            </Grid>
                                        }
                                        {this.state.outputSel != null && this.state.outputSel.discreteWeight &&
                                            <Grid item sx={{width: 125}}>
                                                <TextField type="number" value={this.state.outputTotalWeight} onChange={this.changeNumber} name={"outputTotalWeight"}
                                                size="medium" fullWidth={true} disabled={!fieldAccess}
                                                label={t('totalWeight')} data-cy="outputTotalWeight" 
                                                error={errors[t('outputTotalWeight')] != null ? true : false} helperText={errors[t('outputTotalWeight')]}/>
                                            </Grid>
                                        }
                                        {this.state.outputSel != null && this.state.outputSel.lotTracked && this.state.addToBatch !== true &&
                                            <Grid item sx={{width: 150}}>
                                                <AutoCompleteField
                                                        value={this.state.containerTypeSel}
                                                        options={this.state.containerTypes}
                                                        onChange={this.changeAuto('containerTypeSel', 'containerType')}
                                                        error={errors[t('containerType')] != null ? true : false}
                                                        helperText={errors[t('containerType')]} 
                                                        disabled={!fieldAccess}
                                                        required
                                                        label={t('containerType')}
                                                        data-cy="containerType"
                                                    />
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 50}}>
                                            <IconButton aria-label="Add Output" onClick={this.addOutput} disabled={!fieldAccess} data-cy="addOutput">
                                                <AddBoxTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    </Box>
                                }
                                {this.state.definition != null && (this.state.definition.changeRoom || this.state.isAddToBatch) &&
                                    <Aux>
                                        <br/>
                                        <SectionTitle title={t('rooms')}/>
                                        <Grid container spacing={2} key='roomHead'>
                                            {this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.plant && 
                                                this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.import && !this.state.isAddToBatch &&
                                                <Grid item xs={6} sm={3}>{t('startRoom')}</Grid>
                                            }
                                            <Grid item xs={6} sm={3}>{t('room')}</Grid>
                                            <Grid item xs={4} sm={2}>{t('count')}</Grid>
                                            {this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.plant && 
                                                this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.import && !this.state.isAddToBatch &&
                                                <Grid item xs={2} sm={1}>{t('advance')}</Grid>
                                            }
                                        </Grid>
                                        {this.state.rooms != null && this.state.rooms.length > 0 && this.state.rooms.map((room, i) =>
                                            <Grid container spacing={3} key={room._id + i}>
                                                {this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.plant && 
                                                this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.import && !this.state.isAddToBatch &&
                                                    <Grid item xs={6} sm={3}>
                                                        {fieldAccess &&
                                                            <AutoCompleteField
                                                                value={room.startSel}
                                                                options={this.state.batchRoomSelect}
                                                                onChange={this.changeRoomAuto('startSel', 'startRoom', i)}
                                                                error={errors[`${t('startRoom')} (Line ${i})`] != null ? true : false}
                                                                helperText={errors[`${t('startRoom')} (Line ${i})`]} 
                                                                disabled={!fieldAccess}
                                                            />
                                                        }
                                                        {!fieldAccess &&
                                                            room.startSel != null ? room.startSel.label : ''
                                                        }
                                                    </Grid>
                                                }
                                                <Grid item xs={6} sm={3}>
                                                    {fieldAccess &&
                                                        <AutoCompleteField
                                                            value={room.roomSel}
                                                            options={this.state.roomList}
                                                            onChange={this.changeRoomAuto('roomSel', 'room', i)}
                                                            error={errors[`${t('room')} (Line ${i})`] != null ? true : false}
                                                            helperText={errors[`${t('room')} (Line ${i})`]} 
                                                            disabled={!fieldAccess}
                                                            required={this.state.rooms == null || this.state.rooms.length === 0}
                                                        />
                                                    }
                                                    {!fieldAccess &&
                                                        room.roomSel != null ? room.roomSel.label : ''
                                                    }
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    {fieldAccess &&
                                                        <TextField type="number" value={room.count} onChange={this.changeRoomNumber('count', i)} name={"count" + i}
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} required={this.state.rooms == null || this.state.rooms.length === 0}
                                                        error={errors[`${t('count')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('count')} (${t('line')} ${i})`]}/>
                                                    }
                                                    {!fieldAccess &&
                                                        room.count
                                                    }
                                                </Grid>
                                                {this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.plant &&
                                                    this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.import && !this.state.isAddToBatch &&
                                                    <Grid item xs={2} sm={1}>
                                                        <CheckboxField
                                                        checked={room.advanceStage} onChange={this.changeRoomBool('advanceStage', i)}
                                                        disabled={true}
                                                        />
                                                    </Grid>
                                                }
                                                {fieldAccess &&
                                                    <Grid item xs={2} sm={1}>
                                                        <IconButton aria-label="add room" onClick={this.deleteRoom(i)}>
                                                            <RemoveCircleTwoToneIcon  />
                                                        </IconButton>
                                                    </Grid>
                                                }
                                            </Grid>
                                        )}
                                        {fieldAccess &&
                                            <Grid container spacing={3} key='newRoom'>
                                                {this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.plant && 
                                                    this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.import && !this.state.isAddToBatch &&
                                                    <Grid item xs={6} sm={3}>
                                                        <AutoCompleteField
                                                            value={this.state.startSel}
                                                            options={this.state.batchRoomSelect}
                                                            onChange={this.changeAuto('startSel', 'startRoom')}
                                                            error={errors[t('startRoom')] != null ? true : false}
                                                            helperText={errors[t('startRoom')]} 
                                                            disabled={!fieldAccess}
                                                        />
                                                    </Grid>
                                                }
                                                <Grid item xs={6} sm={3}>
                                                    <AutoCompleteField
                                                        value={this.state.roomToSel}
                                                        options={this.state.roomList}
                                                        onChange={this.changeAuto('roomToSel', 'roomTo')}
                                                        error={errors[t('room')] != null ? true : false}
                                                        helperText={errors[t('room')]} 
                                                        disabled={!fieldAccess}
                                                        required={ (this.state.definition != null && this.state.definition.changeRoom) &&
                                                                   (this.state.rooms == null || this.state.rooms.length === 0) }
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    <TextField type="number" value={this.state.count} onChange={this.changeNumber} name={"count"}
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} 
                                                    error={errors[t('count')] != null ? true : false} helperText={errors[t('count')]}/>
                                                </Grid>
                                                {this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.plant &&
                                                    this.state.definition.definitionId !== this.props.auth.constants.batchActivityDefs.import && !this.state.isAddToBatch &&
                                                    <Grid item xs={2} sm={1}>
                                                        <CheckboxField
                                                        checked={this.state.advanceStage} onChange={this.changeBool('advanceStage')}
                                                        disabled={true}
                                                        />
                                                    </Grid>
                                                }
                                                <Grid item xs={2} sm={1}>
                                                    <IconButton aria-label="add room" onClick={this.addRoom}>
                                                        <AddBoxTwoToneIcon  />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        }
                                        {errors[t('room')] &&
                                            <Grid container spacing={1}>
                                                <Grid item xs={6} sm={1}><Typography color='error' variant='subtitle'>{errors[t('room')]}</Typography></Grid>
                                            </Grid>
                                        }
                                    </Aux>
                                }
                                {!this.state.plantLot && !this.state.tissueExport && !harvest && !plant && !this.state.isMultiplcation && ((!fieldAccess && this.state.culls != null && this.state.culls.length > 0) || (
                                    fieldAccess && this.state.batchRoomList.length > 0)) &&
                                    <Aux>
                                        <br/>
                                        <SectionTitle title={t('culls')}/>
                                        <Grid container spacing={2} key='cullHead'>
                                            <Grid item xs={6} sm={3}>{t('room')}</Grid>
                                            <Grid item xs={4} sm={2}>{t('count')}</Grid>
                                            <Grid item xs={8} sm={4}>{t('reason')}</Grid>
                                        </Grid>
                                        {this.state.culls != null && this.state.culls.length > 0 && this.state.culls.map((row, i) =>
                                            <Grid container spacing={3} key={'cull' + i}>
                                                <Grid item xs={6} sm={3}>
                                                    {fieldAccess &&
                                                        <AutoCompleteField
                                                            value={row.roomSel}
                                                            options={this.state.batchRoomList}
                                                            onChange={this.changeCullAuto('roomSel', 'room', i)}
                                                            error={errors[`${t('cullRoom')} (${t('line')} ${i})`] != null ? true : false}
                                                            helperText={errors[`${t('cullRoom')} (${t('line')} ${i})`]} 
                                                            disabled={!fieldAccess}
                                                        />
                                                    }
                                                    {!fieldAccess &&
                                                        row.roomSel != null ? row.roomSel.label : ''
                                                    }
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    {fieldAccess &&
                                                        <TextField type="number" value={row.count} onChange={this.changeCullNumber('count', i)} name={"cullcount" + i}
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} 
                                                        error={errors[`${t('cullcount')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('cullcount')} (${t('line')} ${i})`]}/>
                                                    }
                                                    {!fieldAccess &&
                                                        row.count
                                                    }
                                                </Grid>
                                                <Grid item xs={8} sm={4}>
                                                    {fieldAccess &&
                                                        <AutoCompleteField
                                                            value={row.reasonSel}
                                                            options={this.state.cullReasons}
                                                            onChange={this.changeCullAuto('reasonSel', 'reason', i)}
                                                            error={errors[`${t('reason')} (${t('line')} ${i})`] != null ? true : false}
                                                            helperText={errors[`${t('reason')} (${t('line')} ${i})`]} 
                                                            disabled={!fieldAccess}
                                                        />
                                                    }
                                                    {!fieldAccess &&
                                                        row.reasonSel != null ? row.reasonSel.label : ''
                                                    }
                                                </Grid>
                                                {fieldAccess &&
                                                    <Grid item xs={2} sm={1}>
                                                        <IconButton aria-label="add room" onClick={this.deleteCull(i)}>
                                                            <RemoveCircleTwoToneIcon  />
                                                        </IconButton>
                                                    </Grid>
                                                }
                                            </Grid>
                                        )}
                                    </Aux>
                                }
                                {!this.state.plantLot && !this.state.tissueExport && !harvest && !plant && !this.state.isMultiplcation && fieldAccess && this.state.batchRoomList.length > 0 &&
                                    <Grid container spacing={3} key='cullRoomNew'>
                                        <Grid item xs={6} sm={3}>
                                            <AutoCompleteField
                                                value={this.state.cullRoomSel}
                                                options={this.state.batchRoomList}
                                                onChange={this.changeAuto('cullRoomSel', 'cullRoom')}
                                                error={errors[t('cullRoom')] != null ? true : false}
                                                helperText={errors[t('cullRoom')]} 
                                                disabled={!fieldAccess}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <TextField type="number" value={this.state.cullCount} onChange={this.changeNumber} name={"cullCount"}
                                            size="medium" fullWidth={true} disabled={!fieldAccess} 
                                            error={errors[t('cullCount')] != null ? true : false} helperText={errors[t('cullCount')]}/>
                                        </Grid>
                                        <Grid item xs={8} sm={4}>
                                            <AutoCompleteField
                                                value={this.state.cullReasonSel}
                                                options={this.state.cullReasons}
                                                onChange={this.changeAuto('cullReasonSel', 'cullReason')}
                                                error={errors[t('cullReason')] != null ? true : false}
                                                helperText={errors[t('cullReason')]}
                                                disabled={!fieldAccess}
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <IconButton aria-label="add room" onClick={this.addCull}>
                                                <AddBoxTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                }
                                {harvest &&
                                    <Aux>
                                        <br/>
                                        <SectionTitle title={t('harvestedRooms')}/>
                                        <Grid container spacing={2} key='harvestRoomHead'>
                                            <Grid item xs={6} sm={4}>{t('room')}</Grid>
                                            <Grid item xs={4} sm={2}>{t('count')}</Grid>
                                        </Grid>
                                        {this.state.harvestedRooms != null && this.state.harvestedRooms.length > 0 && this.state.harvestedRooms.map((row, i) =>
                                            <Grid container spacing={3} key={'harvestRoom' + i}>
                                                <Grid item xs={6} sm={4}>
                                                    {fieldAccess &&
                                                        <AutoCompleteField
                                                            value={row.roomSel}
                                                            options={this.state.flowerRoomList}
                                                            onChange={this.changeHarvestAuto('roomSel', 'room', i)}
                                                            error={errors[`${t('harvestRoom')} (${t('line')} ${i})`] != null ? true : false}
                                                            helperText={errors[`${t('harvestRoom')} (${t('line')} ${i})`]} 
                                                            disabled={!fieldAccess}
                                                        />
                                                    }
                                                    {!fieldAccess &&
                                                        row.roomSel != null ? row.roomSel.label : ''
                                                    }
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    {fieldAccess &&
                                                        <TextField type="number" value={row.count} onChange={this.changeHarvestNumber('count', i)} name={"harvestedRoomcount" + i}
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} 
                                                        error={errors[`${t('harvestedRoomcount')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('harvestedRoomcount')} (${t('line')} ${i})`]}/>
                                                    }
                                                    {!fieldAccess &&
                                                        row.count
                                                    }
                                                </Grid>
                                                {fieldAccess &&
                                                    <Grid item xs={2} sm={1}>
                                                        <IconButton aria-label="add room" onClick={this.deleteHarvestedRoom(i)}>
                                                            <RemoveCircleTwoToneIcon  />
                                                        </IconButton>
                                                    </Grid>
                                                }
                                            </Grid>
                                        )}
                                        {fieldAccess &&
                                            <Grid container spacing={3} key='harvestRoom'>
                                                <Grid item xs={6} sm={4}>
                                                    <AutoCompleteField
                                                        value={this.state.harvestedRoomSel}
                                                        options={this.state.flowerRoomList}
                                                        onChange={this.changeAuto('harvestedRoomSel', 'harvestedRoom')}
                                                        error={errors[t('harvestedRoom')] != null ? true : false}
                                                        helperText={errors[t('harvestedRoom')]} 
                                                        disabled={!fieldAccess}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    <TextField type="number" value={this.state.harvestedRoomCount} onChange={this.changeNumber} name={"harvestedRoomCount"}
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} 
                                                    error={errors[t('harvestedRoomCount')] != null ? true : false} helperText={errors[t('harvestedRoomCount')]}/>
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    <IconButton aria-label="add room" onClick={this.addHarvestedRoom}>
                                                        <AddBoxTwoToneIcon  />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        }
                                        <br/>
                                        {this.state.harvestedSaved &&
                                            <Aux>
                                                <SectionTitle title={t('totes')}/>
                                                <Grid container spacing={2} key='totesHead'>
                                                    <Grid item xs={6} sm={3}>{t('dateTime')}</Grid>
                                                    <Grid item xs={4} sm={2}>{t('tote')}</Grid>
                                                    <Grid item xs={4} sm={2}>{t('weight')}</Grid>
                                                    <Grid item xs={4} sm={2}>{t('room')}</Grid>
                                                </Grid>
                                            </Aux>
                                        }
                                    <br/>
                                    {this.state.harvestedSaved &&
                                        <Aux>
                                            <SectionTitle title={t('totes')}/>
                                            <Grid container spacing={2} key="totesHead">
                                                <Grid item xs={6} sm={3}>{t('dateTime')}</Grid>
                                                <Grid item xs={4} sm={2}>{t('tote')}</Grid>
                                                <Grid item xs={4} sm={2}>{t('weight')}</Grid>
                                                <Grid item xs={4} sm={2}>{t('room')}</Grid>
                                            </Grid>
                                            {this.state.harvestedTotes != null && this.state.harvestedTotes.length > 0 &&
                                                this.state.harvestedTotes.map((row, i) =>
                                                <Grid container spacing={3} key={'tote' + i}>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextOnlyClickButton enabled={true} click={() => this.editTote(i)}
                                                        name={format(Date.parse(row.dateTime), this.props.auth.user.dateFormat)}/>
                                                    </Grid>
                                                </Grid>
                                                )
                                            }
                                                {fieldAccess &&
                                                    <Button variant="outlined" color="primary" size="small" onClick={this.addTote}>{t('add')}</Button>
                                                }
                                            </Aux>
                                        }
                                        <br/>
                                        <br/>
                                    </Aux>
                                }
                                <br/>
                                <FormButtonStack
                                    id={this.state._id}
                                    objectType={this.props.auth.constants.objectIds.batchActivity}
                                    callback={this.loadRecord}
                                    saveClick={this.save}
                                    status={this.state.status}
                                    permissions={this.props.permission}
                                    common={this.props.auth.common}
                                    enableUnlockProp={this.state.enableUnlock}
                                    constants={this.props.auth.constants}
                                    saveEnabled={this.state.enableSave}
                                    activityDate={this.state.completedDate}
                                    lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                                    isSuper={this.props.auth.user.isSuper}
                                />
                            </TabPanel>
                            {this.state._id != null && this.state._id !== '' && this.state.sopsSel != null &&
                                this.state.sopsSel.length > 0 && this.state.sopsSel.map((sop, i) =>
                                <TabPanel value={this.state.tabKey} index={i + 1} key={'sop' + i}>
                                    <ActivityWorkRecord batch={this.state.batch} sop={sop.value} objectType={this.props.auth.constants.objectIds.batchActivity}
                                    locationId={this.state.batchObj.location} recordId={this.state._id} id={this.state.sops[i].workRecord} tab={this.state.tabKey}
                                    complete={this.loadRecord} renderNum={this.state.renderNum} users={this.state.users} date={this.state.completedDate}/>
                                </TabPanel>
                            )}
                            {this.state.hasItemLedgers &&
                                <TabCanvas value={this.state.tabKey} index={this.state.hasBatchLedgers ? this.state.tabLabels.length - 2 : this.state.tabLabels.length - 1}>
                                    <ItemLedgerComponent record={this.state._id} permission={this.props.permission} auth={this.props.auth} renderNum={this.state.renderNum}/>
                                </TabCanvas>
                            }
                            {this.state.hasBatchLedgers &&
                                <TabCanvas value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                                    <BatchLedgers batch={this.state.batch} activity={this.state._id} renderNum={this.state.renderNum}/>
                                </TabCanvas>
                            }
                        </Grid>
                    </Grid>
                <Dialog open={this.state.editTote} onClose={this.clearTote} maxWidth="md" fullWidth>
                    <DialogTitle>
                        <SectionTitle title={t('harvestTote')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <DateTimeSelect
                                onChange={this.dateTimeChange}
                                value={this.state.dateTime}
                                helperText={errors[t('dateTime')] }
                                error={errors[t('dateTime')]  != null ? true : false}
                                disabled={!fieldAccess}
                                label={t('time')}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.toteSel}
                                    options={this.state.totes}
                                    onChange={this.changeAuto('toteSel', 'tote')} 
                                    helperText={errors[t('tote')]} 
                                    disabled={!fieldAccess}
                                    label={t('tote')}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.weight} onChange={this.changeNumber} name={"weight"}
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('weight')} 
                                error={errors[t('weight')] != null ? true : false} helperText={errors[t('weight')]}/>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <AutoCompleteField
                                    value={this.state.weightUOMSel}
                                    options={this.state.uoms}
                                    onChange={this.changeAuto('weightUOMSel', 'weightUOM')}
                                    error={errors[t('weightUOM')]  != null ? true : false}
                                    helperText={errors[t('weightUOM')] } 
                                    disabled={true}
                                    label={t('uom')}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.toteUserSel}
                                    options={this.state.usersSel}
                                    onChange={this.changeAuto('toteUserSel', 'toteUser')}
                                    error={errors[t('toteUser')]  != null ? true : false}
                                    helperText={errors[t('toteUser')] } 
                                    disabled={!fieldAccess}
                                    label={t('user')}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.toteRoomSel}
                                    options={this.state.batchRoomList}
                                    onChange={this.changeAuto('toteRoomSel', 'toteRoom')}
                                    error={errors[t('toteRoom')]  != null ? true : false}
                                    helperText={errors[t('toteRoom')] } 
                                    disabled={!fieldAccess}
                                    label={t('room')}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={this.saveTote} disabled={!fieldAccess}>{t('save')}</Button>
                        <Button variant="contained" color="secondary" onClick={this.clearTote}>{t('close')}</Button>
                        <Button variant="contained" color="secondary" onClick={this.deleteTote} disabled={!fieldAccess}>{t('delete')}</Button>
                    </DialogActions>
                </Dialog>
                <BatchObservation
                    id={this.state.observation}
                    batch={this.state.batch}
                    date={this.state.completedDate}
                    activity={{_id: this.state._id, number: this.state.number}}
                    user={this.props.auth.user.userid}
                    open={this.state.showObservation}
                    onClose={this.editObservation}
                />
                {this.state.containerPrintOpen &&
                    <LabelDialog labelData={this.state.containerLabel} open={this.state.containerPrintOpen} toggleDialog={() => this.closeContainerPrintDialog} />
                }
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={fieldAccess}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
                {this.state.printViewer &&
                    <PrintView
                        id={this.state._id}
                        object={this.props.auth.constants.objectIds.batchActivity}
                        open={printViewer}
                        onClose={this.togglePrintViewer}
                        callBack={this.loadRecord}
                        printAccess={printAccess}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.batchActivity}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
                { this.state.showSequence &&
                    <ModalDialog buttonStack={[
                        <Button color="secondary" size="small" variant="text" onClick={() => this.toggleSequence()}>{t('close')}</Button>,
                        <Button color="primary" size="small" variant="contained" onClick={this.editLastSequence}>{t('save')}</Button>
                    ]} title={t('sequence')} toggleDialog={() => this.toggleSequence()}
                    dialogStatus={this.state.showSequence} fullWidth maxWidth='md'>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('lastSequence')}</FormLabel>
                                    <Typography>{this.state.lastSequence}</Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('sequence')}</FormLabel>
                                    <Typography>{this.state.sequence}</Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('newSequence')}</FormLabel>
                                    <TextField value={this.state.newSequence} onChange={this.changeValue} name="newSequence"
                                    size="medium" fullWidth={true} type="number" 
                                    error={errors[t('newSequence')] != null ? true : false} helperText={errors[t('newSequence')]}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    devices: state.devices,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(BatchActivity));
