import React, { Component } from 'react';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import TestResultLine from '../General/TestResultLine';
import ProductionScheduleGantt from '../General/ProductionScheduleGantt';
import SopByStatusChart from '../General/SopByStatusChart';
import SopByTypeChart from '../General/SopByTypeChart';
import Grid from '@mui/material/Grid';
import ScheduledActs from '../Widgets/ScheduledActs';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import ProdBatchUnitCost from '../General/ProdBatchUnitCost';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class ProcessingDashboard extends Component {
    componentDidMount = () => {
        const crumbs = [
            { path: 'active', label: this.props.t('productionDashboard'), screen: this.props.auth.screenDefs.processingDashboard}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
    };
    render(){
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        helpPath={this.props.auth.user.helpUrl + '/'+ this.props.security.currentPath.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <ProductionScheduleGantt/>
                    <br/>
                    <ProdBatchUnitCost/>
                    <ScheduledActs department={this.props.auth.constants.departments.production} title={t('scheduledActs')}/>
                    <Grid container>
                        <Grid item sm={6}>
                            <SopByStatusChart department={this.props.auth.constants.departments.production}/>
                        </Grid>
                        <Grid item sm={6}>
                            <SopByTypeChart department={this.props.auth.constants.departments.production}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <TestResultLine category="5df9481b43fb1600264ef329" items="5df9492843fb1600264ef332" test="5e064ed23cefd6001b44055a"/>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    security: state.security
  });
  
export default withTranslation() (connect(mapStateToProps)(ProcessingDashboard));