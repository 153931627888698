import React, { Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import DateSelect from '../UI/DateSelect/DateSelect';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link } from 'react-router-dom';
import ActionBar from '../UI/Buttons/ActionBar';
import Status from '../UI/Typography/Status';
import ProductionBatchEdit from './Forms/ProductionBatchEdit';
import { EvoraTabs, TabCanvas } from '../UI/Tabs/Tabs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { CompleteButton, ReleaseButton } from '../UI/Buttons/Buttons';
import { approveSigSecurity, destroyButtonSecurity, fieldSecurity, 
    printButtonSecurity, releaseButtonSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import SectionTitle from '../UI/Typography/SectionTitle';
import SubSubHeading from '../UI/Typography/SubSubHeading';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import { withTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import ConversionHelper from '../../helpers/conversionHelper';
import TextField from '../UI/TextField/TextField';
import Button from '@mui/material/Button';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Tooltip } from '@mui/material';
import ModalDialog from '../UI/Dialog/ModalDialog';
import isEmpty from '../../is-empty';
import ForwardIcon from '@mui/icons-material/Forward';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ConfirmDelete from '../General/ConfirmDelete';
import PrintView from '../Printing/PrintView';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

class ProductionBatch extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            _id: '',
            number: '',
            description: '',
            status: null,
            statuses: [],
            plannedStart: '',
            actualStart: '',
            productionSchedule: null,
            activities: [],
            outputItem: null,
            items: [],
            outputQuantity: '',
            actualOutput: '',
            uom: null,
            uoms: [],
            dueDate: '',
            completeDate: '',
            documents: null,
            reviewer: null,
            signer: null,
            startItem: '',
            startItemSel: null,
            startItems: [],
            security: {},
            totalLabour: 0,
            dialogOpen: false,
            tabLabels: [
                {name: t('plan'), disabled: false},
            ],
            tabKey: 0,
            fieldAccess: false,
            signed: false,
            printAccess: false,
            printClick: '',
            reviewSigSec: false,
            approveSigSec: false,
            inputs: [],
            outputs: [],
            deviations: [],
            ncrs: [],
            totalMinutes: 0,
            labourHours: 0,
            labourMinutes: 0,
            location: null,
            activitiesComplete: false,
            optionsMenu: [],
            flagForSignature: 0,
            reviewers: [],
            signers: [],
            roomCleanings: [],
            renderNum: Math.random(),
            finalDocument: null,
            sales: [],
            inputTotal: '',
            costs: {},
            reviewed: false,
            activeManagers: [],
            activeQas: [],
            form: '',
            hasForm: false,
            showUnlock: false,
            enableSave: true,
            showActivity: false,
            actItem: null,
            actRoute: null,
            actBom: null,
            expectedStart: '',
            expectedEnd: '',
            actQuantity: '',
            actUOM: null,
            sequence: -1,
            producedBy: '',
            producedBySel: null,
            producedBys: [],
            processingLoss: null,
            deleteOpen: false,
            printViewer: false,
            currentSeq: -1,
            showAddAct: false,
            addIndex: -1,
            completedItems: [],
            addItem: '',
            addItemSel: null,
            outputsTally: [],
            canDelete: false,
            categories: null,
            showItems: [],
            inventoryLots: [],
            showLots: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.processingLocation, common.cacheValues.quantityUOM, 
                        common.cacheValues.productionItem, common.cacheValues.productionBatchStatus, common.cacheValues.productionBatchForm], 
                        this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.productionBatch, this.props.auth.user.tenant);
        const fieldAccess = fieldSecurity(this.props.permission, this.props.auth.constants.productionBatchStatuses.inserted, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.productionBatch);
        const enableRelease = releaseButtonSecurity(this.props.permission, this.props.auth.constants.productionBatchStatuses.inserted, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.productionBatch);
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        })
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            locations: locations,
            items: cacheValues.productionItems,
            statuses: cacheValues.productionBatchStatuses,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            enableRelease: enableRelease,
            reviewers: userValues.review,
            signers: userValues.approve,
            activeManagers: activeManagers,
            activeQas: activeQas,
            hasForm: hasForm,
            uoms: cacheValues.quantityUOMs,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : ''
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/productionbatches', label: this.props.t('productionBatches'), screen: this.props.auth.screenDefs.ProductionBatch},
                    { path: 'active', label: this.props.t('productionBatch'), screen: this.props.auth.screenDefs.ProductionBatch}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const constants = this.props.auth.constants;
        const internal = this.props.auth.user.internal;
        let record = await axios.get('/api/productionbatch/' + this.state._id);
        let salesParms = {
            prodBatch: this.state._id
        }
        let sales = await axios.get('api/batchSales', {params: salesParms});
        let signed = record.data.status._id === this.props.auth.constants.productionBatchStatuses.complete ||
                    record.data.status._id === this.props.auth.constants.productionBatchStatuses.reviewed ||
                    record.data.status._id === this.props.auth.constants.productionBatchStatuses.signedOff;
        const fieldAccess = fieldSecurity(this.props.permission, record.data.status._id, this.props.auth.common, constants, this.props.auth.common.statusTypes.productionBatch);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, record.data.status._id, this.props.auth.common, constants,
            this.props.auth.common.statusTypes.productionBatch);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, record.data.status._id, this.props.auth.common, constants,
            this.props.auth.common.statusTypes.productionBatch);
        const destroyAccess = destroyButtonSecurity(this.props.permission, record.data.status._id, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.productionBatch);
        const printAccess = printButtonSecurity(this.props.permission, record.data.status._id, this.props.auth.common, constants, this.props.auth.common.statusTypes.productionBatch);
        let inputs = [];
        let outputs = [];
        let totalMinutes = 0;
        let activitiesComplete = true;
        let count = 0;
        let inputTotal = 0;
        let expectedLabour = 0;
        let expectedInputs = 0;
        let costs = record.data.costs;
        if(costs == null){
            costs = {};
        }
        let currentSeq = -1;
        let activities = record.data.activities;
        let completedItems = [];
        activities.forEach(row => {
            row.enabled = true;
            if(row.productionOrder != null && (row.productionOrder.status._id === constants.productionOrderStatuses.released ||
            row.productionOrder.status._id === constants.productionOrderStatuses.inserted || 
            row.productionOrder.status._id === constants.productionOrderStatuses.inprogress ||
            row.productionOrder.status._id === constants.productionOrderStatuses.complete)){
                if(currentSeq === -1){
                    currentSeq = row.sequence;
                    row.enabled = true;
                } else {
                    if(count > 0){
                        let prev = activities[count - 1];
                        if(prev.productionOrder != null && prev.productionOrder.status._id !== constants.productionOrderStatuses.inserted && 
                            prev.productionOrder.status._id !== constants.productionOrderStatuses.released){
                            currentSeq = row.sequence;
                            row.enabled = true;
                        }
                    }
                }
            }
            if(row.productionOrder != null && row.productionOrder.status._id !== constants.productionOrderStatuses.inserted && 
            row.productionOrder.status._id !== constants.productionOrderStatuses.released){
                row.enabled = true;
                row.productionOrder.inputs.forEach(input => {
                    let outputItem = record.data.activities.find(x => x.outputItem != null && x.outputItem._id === input.item._id);
                    if(!outputItem){
                        let cost = ConversionHelper.itemCost(input.item, input.amount);
                        inputs.push({
                            item: input.item,
                            amount: input.amount,
                            uom: input.uom,
                            count: input.count,
                            lot: input.lot,
                            date: row.startDate,
                            cost: cost
                        });
                        if(cost != null){
                            inputTotal += cost;
                        }
                    }
                });
                if(row.productionOrder != null && (row.productionOrder.status._id === constants.productionOrderStatuses.finished || 
                    row.productionOrder.status._id === constants.productionOrderStatuses.reviewed)){
                    row.canAdd = true;
                }
                row.productionOrder.outputs.forEach(output => {
                    let consumed = false;
                    if(count + 1 < record.data.activities.length){
                        let next = record.data.activities[count + 1];
                        if(next != null && next.productionOrder != null && next.productionOrder.inputs != null){
                            let found = next.productionOrder.inputs.find(x => x.item._id === output.item._id);
                            if(found){
                                if(next.productionOrder != null && next.productionOrder.status._id === constants.productionOrderStatuses.finished)
                                    consumed = true;
                            }
                        }
                    }
                    outputs.push({
                        item: output.item,
                        amount: output.amount,
                        uom: output.uom,
                        count: output.count,
                        lot: output.lot,
                        date: row.startDate,
                        status: consumed ? t('consumed') : null
                    });
                });
                row.productionOrder.lines.forEach(line => {
                    if(line.activity != null && line.activity.effort != null && line.activity.effort > 0){
                        totalMinutes += line.activity.users.length > 0 ? line.activity.users.length * line.activity.effort : line.activity.effort;
                    }
                });
            }
            if(row.roomToClean == null && (row.productionOrder == null || (row.productionOrder.status._id !== constants.productionOrderStatuses.finished  && 
                row.productionOrder.status._id !== constants.productionOrderStatuses.deleted))){
                activitiesComplete = false;
            }
            if(row.productionOrder != null && row.productionOrder.estimatedEffortCost != null){
                expectedLabour += row.productionOrder.estimatedEffortCost;
            }
            if(row.productionOrder != null && row.productionOrder.estimatedInputCost != null){
                expectedInputs += row.productionOrder.estimatedInputCost;
            }
            if(row.productionOrder != null && (row.productionOrder.status._id === constants.productionOrderStatuses.reviewed || 
                row.productionOrder.status._id === constants.productionOrderStatuses.finished)){
                let found = completedItems.find(x => x.value === row.productionOrder.outputItem._id);
                if(!found){
                    completedItems.push({value: row.outputItem._id, label: row.outputItem.number + '-' + row.outputItem.name});
                
                }
            }
            count++;
        });
        costs.inputItems = costs.inputItems == null ? '' : costs.inputItems;
        costs.labour = costs.labour == null ? '' : costs.labour;
        costs.water = costs.water == null ? '' : costs.water;
        costs.electricity = costs.electricity == null ? '' : costs.electricity;
        costs.gas = costs.gas == null ? '' : costs.gas;
        costs.overhead = costs.overhead == null ? '' : costs.overhead;
        costs.expectedLabour = expectedLabour;
        costs.expectedInputs = expectedInputs;
        let reviewed = record.data.status._id === this.props.auth.constants.productionBatchStatuses.reviewed || 
                        record.data.status._id === this.props.auth.constants.productionBatchStatuses.signedOff;
        let labourHours = Math.floor(totalMinutes / 60);
        let labourMinutes = totalMinutes - (labourHours * 60);
        let tabLabels = [
            {name: t('plan'), disabled: false}
        ];
        if(record.data.status._id !== this.props.auth.constants.productionBatchStatuses.inserted && 
        record.data.status._id !== this.props.auth.constants.productionBatchStatuses.released){
            tabLabels.push({name: t('inventory'), disabled: false});
            tabLabels.push({name: t('investigations'), disabled: false});
            tabLabels.push({name: t('cleaningRecords'), disabled: false});
            if(sales != null && sales.data.length > 0){
                tabLabels.push({name: t('sales'), disabled: false});
            }
        }
        let startItemSel = record.data.startItem != null ? record.data.startItems.find(x => x.value === record.data.startItem) : null;
        const optionsMenu = [
            { 
              security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.reviewers, 
              name: t('reviewer'), title: t('reviewed'), setId: () => this.loadRecord(), object: this.state._id, signature: record.data.reviewer, 
              objectType: this.props.auth.constants.objectIds.productionBatch, type: this.props.auth.constants.signatureTypes.reviewer,
              menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
              security: approveSigSec, user: this.props.auth.user.id, users: !internal && this.state.signer == null ? this.state.activeQas : this.state.signers, 
              name: t('signoff'), title: t('approved'), setId: () => this.loadRecord(), object: this.state._id, signature: record.data.signer, 
              objectType: this.props.auth.constants.objectIds.productionBatch, type: this.props.auth.constants.signatureTypes.signer,
              menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        const flagForSignature = record.data.status === this.props.auth.constants.productionOrderStatuses.complete ? 2 : 
                                 record.data.status === this.props.auth.constants.productionOrderStatuses.review ? 2 : 0;
        let showItems = [];
        if(record.data.categories != null && record.data.categories.length > 0){
            record.data.categories.forEach(row => {
                showItems.push(false);
            });
        }
        this.setState({
            number: record.data.number,
            description: record.data.description,
            location: record.data.location,
            status: record.data.status,
            plannedStart: record.data.plannedStart,
            actualStart: record.data.actualStart,
            activities: activities,
            outputItem: record.data.outputItem,
            outputQuantity: record.data.outputQuantity,
            uom: record.data.uom,
            dueDate: record.data.dueDate,
            completeDate: record.data.completeDate,
            documents: record.data.documents,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            destroyAccess: destroyAccess,
            printAccess: printAccess,
            fieldAccess: fieldAccess,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            signed: signed,
            inputs: inputs,
            outputs: outputs,
            tabLabels: tabLabels,
            deviations: record.data.deviations,
            ncrs: record.data.ncrs,
            totalMinutes: totalMinutes,
            labourHours: labourHours,
            labourMinutes: labourMinutes,
            productionSchedule: record.data.productionSchedule,
            startItem: record.data.startItem,
            startItemSel: startItemSel,
            startItems: record.data.startItems,
            activitiesComplete: activitiesComplete,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            actualOutput: record.data.actualOutput,
            roomCleanings: record.data.roomCleanings,
            printClick: '/#/productionbatchprint/' + record.data._id,
            renderNum: Math.random(),
            finalDocument: record.data.finalDocument,
            sales: sales.data,
            inputTotal: inputTotal,
            costs: costs,
            reviewed: reviewed,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            processingLoss: record.data.processingLoss,
            deleteOpen: false,
            currentSeq: currentSeq,
            completedItems: completedItems,
            outputsTally: record.data.outputsTally,
            categories: record.data.categories,
            showItems: showItems,
            showLots: false,
            inventoryLots: []
        }, () => {
            const crumbs = [
                { path: '/#/productionbatches', label: this.props.t('productionBatches'), screen: this.props.auth.screenDefs.ProductionBatch},
                { path: 'active', 
                  label: this.state.number != null && this.state.number !== '' ?
                         this.state.description + ' - ' + this.state.number : t('productionBatch'), 
                  screen: this.props.auth.screenDefs.ProductionBatch
                }
            ]
            this.determineDelete();
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });        
    };

    determineDelete(){
        const statuses = this.props.auth.constants.productionOrderStatuses;
        let canDelete = true;
        let activities = this.state.activities;
        if(activities != null && activities.length > 0){
            for(let row of activities){
                if(row.productionOrder != null){
                    let status = row.productionOrder.status._id;
                    if(status === statuses.complete || status === statuses.reviewed || status === statuses.finished){
                        canDelete = false;
                        break;
                    }
                }
            }
        }
        this.setState({canDelete: canDelete});
    }

    releaseClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._releaseClick(e));
    }

    _releaseClick = async (e) => {
        const t = this.props.t;
        try {
            let result = await axios.post('/api/productionbatchrelease', {id: this.state._id});
            this.setState({enableSave: true});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
        
    };

    completeBatch = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._completeBatch(e));
    }

    _completeBatch = async (e) => {
        const t = this.props.t;
        try {
            let result = await axios.post('/api/productionbatchcomplete', {_id: this.state._id});
            this.setState({enableSave: true});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    }

    _deleteClick = async (e) => {
        const t = this.props.t;
        try {
            let result = await axios.delete('/api/productionbatch/' + this.state._id);
            this.setState({enableSave: true});
            this.props.history.goBack();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    toggleDialog = () => {
        let dialogOpen = this.state.dialogOpen;
        this.setState({ dialogOpen: !dialogOpen });
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    cgsCalc = async () => {
        const t = this.props.t;
        let costs = this.state.costs;
        costs._id = this.state._id;
        try {
            let result = await axios.post('/api/productionbatchcgs', costs);
            this.setState({costs: result.data});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    changeCost = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            costs: {
                ...prevState.costs,
                [name]: value
            }
        }))
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value});
    }

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(newValue != null){
            if(data === 'addItem'){
                let activities = this.state.activities;
                let act = activities.find(x => x.outputItem != null && x.outputItem._id === newValue.value);
                let itemTally = this.state.outputsTally.find(x => x.item._id === newValue.value);
                this.setState({
                    actBom: act.bom,
                    actRoute: act.routing,
                    actUOM: act.outputUOM,
                    producedBy: act.producedBy,
                    producedBys: act.producedBys,
                    producedBySel: act.producedBys.find(x => x.value === act.producedBy),
                    itemTally: itemTally
                });
            } else {
                this.setState({
                    actBom: null,
                    actRoute: null,
                    actUOM: null,
                    sequence: -1,
                    producedBy: '',
                    producedBys: [],
                    produceBySel: null,
                    itemTally: null
                })
            }
        }
    };

    unlock = (e) => {
        this.toggleUnlock();
    }

    toggleUnlock = () => {
        this.setState({showUnlock: !this.state.showUnlock});
    }

    addActivity = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._addActivity());
    }

    _addActivity = async () => {
        const t = this.props.t;
        let errors = {};
        if(this.state.sequence === -1){
            return;
        }
        if(this.state.addItem == null){
            errors[t('additem')] = t('required');
        }
        if(this.state.producedBySel == null){
            errors[t('producedBy')] = t('required');
        }
        if(this.state.expectedStart === '' || this.state.expectedStart == null){
            errors[t('expectedStart')] = t('required');
        }
        if(this.state.expectedEnd === '' || this.state.expectedEnd == null){
            errors[t('expectedEnd')] = t('required');
        }
        let acts = this.state.activities;
        let base = acts.find(x => x.outputItem != null && x.outputItem._id === this.state.addItem);
        if(base == null){
            alert('base item null');
            return;
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            this.setState({enableSave: true});
            return;
        }
        let data = {
            id: this.state._id,
            index: this.state.sequence,
            outputItem: this.state.addItem,
            bom: this.state.producedBySel.bom,
            routing: this.state.producedBySel.routing,
            outputQuantity: base.outputQuantity,
            expectedStart: this.state.expectedStart,
            expectedEnd: this.state.expectedEnd
        }
        try {
            let result = await axios.post('/api/productionbatchact', data);
            this.setState({
                enableSave: true, 
                showAddAct: false,
                sequence: -1,
                addItem: '',
                addItemSel: null,
                producedBy: '',
                producedBySel: null,
                expectedStart: '',
                expectedEnd: '',
                itemTally: null
        
            }, () => {
                this.loadRecord();
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    removeActivity = (index) => {
        if(index < 0 || index >= this.state.activities.length) return;
        if(!this.state.fieldAccess) return;
        if(!this.state.enableSave) return;
        this.setState({enableSave: false}, () => this._removeActivity(index));
    }

    _removeActivity = async (index) => {
        const t = this.props.t;
        let activities = this.state.activities;
        let act = activities[index];
        if(act._id == null){
            activities.splice(index, 1);
            this.setState({activities: activities, enableSave: true});
        } else {
            try {
                let data = {
                    id: this.state._id,
                    activityId: act._id
                }
                let result = await axios.post('/api/productionbatchactdel/', data);
                activities.splice(index, 1);
                this.setState({activities: activities, enableSave: true});
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        }
    }

    releaseActivity = (index) => {
        if(index < 0) return;
        let act = this.state.activities[index];
        if(act.productionOrder != null) return;
        if(act.roomToClean != null){
            this.props.history.push('/roomcleaning?room=' + act.roomToClean._id + '&productionBatch=' + this.state._id + '&lineId=' + act._id);
            return;
        }
        if(this.state.status._id !== this.props.auth.constants.productionBatchStatuses.released && this.state.status._id !== this.props.auth.constants.productionBatchStatuses.inprogress) return;
        if(!this.state.enableSave) return;
        this.setState({enableSave: false}, () => this._releaseActivity(index));
    }

    _releaseActivity = async (index) => {
        const t = this.props.t;
        let act = this.state.activities[index];
        let data = {
            id: this.state._id,
            activityId: act._id
        }
        try {
            let result = await axios.post('/api/productionbatchactrelease', data);
            this.setState({enableSave: true}, () => {
                this.loadRecord();
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    toggleActivity = (index) => {
        this.setState({
            showAddAct: !this.state.showAddAct, sequence: index != null ? index + 1 : -1, itemTally: null});
    }

    togglePrintViewer = () => {
        this.setState({printViewer: !this.state.printViewer});
    }

    toggleCategoryItem = (i) => (e) => {
        let showItems = this.state.showItems;
        showItems[i] = !showItems[i];
        this.setState({showItems: showItems});
    }

    lotsPopup = (lots) => (e) => {
        this.setState({showLots: true, inventoryLots: lots});
    }
    closeLotsPopup = () => {
        this.setState({showLots: false, inventoryLots: []});
    }

    displayLotValue = (quantity, lots) => {
        if(lots != null && lots.length > 0){
            return <Button size="small" variant="outlined" color="primary" onClick={this.lotsPopup(lots)}>
                    <NumericFormat value={quantity} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                </Button>
        } else {
            return <NumericFormat value={quantity} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
        }
    }

    render(){
        const security = this.state.security;
        const fieldAccess = this.state.fieldAccess;
        const activitiesComplete = this.state.activitiesComplete;
        const constants = this.props.auth.constants;
        const t = this.props.t;
        const errors = this.props.errors;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        destroyClick={this.state.canDelete ? this.deleteToggle : null}
                        editClick={this.toggleDialog}
                        destroyAccess={this.state.destroyAccess}
                        status={this.state.status != null ? this.state.status._id : null}
                        fieldAccess={fieldAccess}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        objectId={this.props.auth.constants.objectIds.productionBatch}
                        unlockClick={this.unlock}
                        unlockAccess={security.unlock}
                        unlockCallback={this.loadRecord}
                        toggleUnlock={this.toggleUnlock}
                        showUnlock={this.state.showUnlock}
                        objectType={this.props.auth.constants.objectIds.productionBatch}
                        id={this.state._id}
                        printViewClick={this.togglePrintViewer}
                        printViewTitle={t('productionBatch')}
                        enablePrintView={this.state.status != null ? this.state.status._id === this.props.auth.constants.productionBatchStatuses.signedOff : false}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={2} md={2}>
                            <Status title={t('status')} name="status" text={this.state.status != null ? this.state.status.label : ''} />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Status title={t('output')} name="outputItem" text={this.state.outputItem != null ? 
                                this.state.outputItem.number + '-' + this.state.outputItem.name : ''} />
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Status title={t('planned')} name="outputQuantity" text={this.state.outputQuantity != null && this.state.uom != null ? 
                                this.state.outputQuantity + this.state.uom.label : ''} />
                        </Grid>
                        {this.state.actualOutput != null && this.state.actualOutput !== '' &&
                            <Grid item xs={4} md={2}>
                                <Status title={t("actual")} name="actualOutput" text={this.state.actualOutput != null  && this.state.uom != null ? 
                                    this.state.actualOutput + this.state.uom.label : ''} />
                            </Grid>
                        }
                        {this.state.totalMinutes != null && this.state.totalMinutes !== 0 &&
                            <Grid item xs={6} md={3}>
                                <Status title={t('labourInvestment')} name="totalLabour" text={this.state.labourHours > 0 ? 
                                this.state.labourHours + 'h ' + this.state.labourMinutes + 'm' : this.state.labourMinutes + 'm'} />
                            </Grid>
                        }
                    </Grid>
                    {this.state.hasForm === true &&
                        <Aux>
                            <br/>
                            {errors[t('form')] != null && errors[t('form')] !== '' &&
                                <div style={{color: 'red'}}>{errors[t('form')]}</div>
                            }
                            <Form renderer={renderer}
                                defaultFields={this.state.form}
                                onChange={this.formValueChanged}
                                disabled={true}/>
                        </Aux>
                    }
                    <br/>
                    <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                    <TabCanvas value={this.state.tabKey} index={0}>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.location != null ? this.state.location.name : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('plannedStart')}</FormLabel>
                                    <DateDisplay value={this.state.plannedStart} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                            {this.state.actualStart != null && this.state.actualStart !== '' &&
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('actualStart')}</FormLabel>
                                        <DateDisplay value={this.state.actualStart} format={this.props.auth.user.dateFormat}/>
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('dueDate')}</FormLabel>
                                    <DateDisplay value={this.state.dueDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                            {this.state.completeDate != null && this.state.completeDate !== '' &&
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('completedOn')}</FormLabel>
                                        <DateDisplay value={this.state.completeDate} format={this.props.auth.user.dateFormat}/>
                                    </FormControl>
                                </Grid>
                            }
                            {this.state.productionSchedule != null &&
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('productionSchedule')}</FormLabel>
                                        <Link to={'/productionschedule/' + this.state.productionSchedule._id}>{this.state.productionSchedule.number}</Link>
                                    </FormControl>
                                </Grid>
                            }
                            {this.state.startItemSel != null &&
                                <Grid item xs={8} sm={4}>
                                    <FormControl>
                                        <FormLabel>{t('startItem')}</FormLabel>
                                        <Link to={'/item/' + this.state.startItem}>{this.state.startItemSel.label}</Link>
                                    </FormControl>
                                </Grid>
                            }
                        </Grid>
                        {(this.state.status != null && this.state.status !== '' && (this.state.status._id === constants.productionBatchStatuses.complete || this.state.status._id === constants.productionBatchStatuses.reviewed ||
                        this.state.status._id === constants.productionBatchStatuses.signedOff)) &&
                            <Aux>
                                <br/>
                                <SectionTitle title={t('costs')}/>
                                <br/>
                                {this.state.costs.costPerUnit != null && this.state.costs.costsPerUnit !== '' &&
                                    <Grid container spacing={3}>
                                        <Grid item xs={8} sm={4}><SubSubHeading title={t('costPerUnit')}/></Grid>
                                        <Grid item xs={6} sm={3}>
                                            <NumericFormat displayType={'text'} value={this.state.costs.costPerUnit} thousandSeparator={true}
                                            decimalScale={2} prefix="$"/>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container spacing={3}>
                                    <Grid item xs={8} sm={4}></Grid>
                                    <Grid item xs={4} sm={2}><SubSubHeading title={t('actual')}/></Grid>
                                    <Grid item xs={4} sm={2}><SubSubHeading title={t('estimate')}/></Grid>
                                </Grid>
                                {this.state.costs.inputItems != null && this.state.costs.inputItems !== '' &&
                                    <Grid container spacing={3}>
                                        <Grid item xs={8} sm={4}>{t('costItems')}</Grid>
                                        <Grid item xs={4} sm={2}>
                                            <NumericFormat displayType={'text'} value={this.state.costs.inputItems} thousandSeparator={true} 
                                            decimalScale={2} prefix="$"/>
                                        </Grid>
                                        {this.state.costs.expectedInputs != null && this.state.costs.expectedInputs !== '' &&
                                            <Grid item xs={4} sm={2}>
                                                <NumericFormat displayType={'text'} value={this.state.costs.expectedInputs} thousandSeparator={true} 
                                                decimalScale={2} prefix="$"/>
                                            </Grid>
                                        }
                                    </Grid>
                                }
                                {this.state.costs.labour != null && this.state.costs.labour !== '' &&
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={4}>{t('labour')}</Grid>
                                        <Grid item xs={4} sm={2}>
                                            <NumericFormat displayType={'text'} value={this.state.costs.labour} thousandSeparator={true} 
                                            decimalScale={2} prefix="$"/>
                                        </Grid>
                                        {this.state.costs.expectedLabour != null && this.state.costs.expectedLabour !== '' &&
                                            <Grid item xs={4} sm={2}>
                                                <NumericFormat displayType={'text'} value={this.state.costs.expectedLabour} thousandSeparator={true} 
                                                decimalScale={2} prefix="$"/>
                                            </Grid>
                                        }
                                    </Grid>
                                }
                                <Grid container spacing={3}>
                                    <Grid item xs={8} sm={4}>{t('water')}</Grid>
                                    <Grid item xs={4} sm={2}>
                                        {this.state.security.update && !this.state.reviewed &&
                                            <TextField value={this.state.costs.water} onChange={this.changeCost} name="water" 
                                            size="medium" fullWidth={true} type="number"
                                            error={errors[t('water')] != null ? true : false} helperText={errors[t('water')]}/>
                                        }
                                        {this.state.reviewed && this.state.costs.water !== '' &&
                                            <NumericFormat displayType={'text'} value={this.state.costs.water} thousandSeparator={true} 
                                            decimalScale={2} prefix="$"/>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={8} sm={4}>{t('electricity')}</Grid>
                                    <Grid item xs={4} sm={2}>
                                        {this.state.security.update && !this.state.reviewed &&
                                            <TextField value={this.state.costs.electricity} onChange={this.changeCost} name="electricity" 
                                            size="medium" fullWidth={true} type="number"
                                            error={errors[t('electricity')] != null ? true : false} helperText={errors[t('electricity')]}/>
                                        }
                                        {this.state.reviewed && this.state.costs.electricity !== '' &&
                                            <NumericFormat displayType={'text'} value={this.state.costs.electricity} thousandSeparator={true} 
                                            decimalScale={2} prefix="$"/>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={8} sm={4}>{t('gas')}</Grid>
                                    <Grid item xs={4} sm={2}>
                                        {this.state.security.update && !this.state.reviewed &&
                                            <TextField value={this.state.costs.gas} onChange={this.changeCost} name="gas" 
                                            size="medium" fullWidth={true} type="number"
                                            error={errors[t('gas')] != null ? true : false} helperText={errors[t('gas')]}/>
                                        }
                                        {this.state.reviewed && this.state.costs.electricity !== '' &&
                                            <NumericFormat displayType={'text'} value={this.state.costs.gas} thousandSeparator={true} 
                                            decimalScale={2} prefix="$"/>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={8} sm={4}>{t('overhead')}</Grid>
                                    <Grid item xs={4} sm={2}>
                                        {this.state.security.update && !this.state.reviewed &&
                                            <TextField value={this.state.costs.overhead} onChange={this.changeCost} name="overhead" 
                                            size="medium" fullWidth={true} type="number"
                                            error={errors[t('overhead')] != null ? true : false} helperText={errors[t('overhead')]}/>
                                        }
                                        {this.state.reviewed && this.state.costs.overhead !== '' &&
                                            <NumericFormat displayType={'text'} value={this.state.costs.overhead} thousandSeparator={true} 
                                            decimalScale={2} prefix="$"/>
                                        }
                                    </Grid>
                                </Grid>
                                {this.state.security.update && !this.state.reviewed &&
                                    <Grid container spacing={3}>
                                            <Grid item sm={4}>
                                                <Button variant="outlined" color="primary" size="small" onClick={this.cgsCalc}>{t('calculate')}</Button>
                                            </Grid>
                                    </Grid>
                                }
                                <br/>
                            </Aux>
                        }
                        <br/>
                        {this.state.status != null && this.state.status._id === this.props.auth.constants.productionBatchStatuses.inserted &&
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <ReleaseButton fullWidth={true} releaseClick={this.releaseClick} enabled={true}/>
                                </Grid>
                            </Grid>
                        }
                        {activitiesComplete && this.state.status._id === this.props.auth.constants.productionBatchStatuses.inprogress &&
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <CompleteButton fullWidth={true} completeClick={this.completeBatch} enabled={security.complete} buttonName={t('completeBatch')}/>
                                </Grid>
                            </Grid>
                        }
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <Typography variant="h4" component="h5" color="primary">
                                    {t('plan')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer >
                                    <Table size="medium" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow key='planHeader'>
                                                <TableCell padding='none' align='left'>{t('number')}</TableCell>
                                                <TableCell padding='none' align='left'>{t('plannedStart')}</TableCell>
                                                <TableCell padding='none' align='left'>{t('actualStart')}</TableCell>
                                                <TableCell padding='none' align='left'>{t('description')}</TableCell>
                                                <TableCell padding='none' align='left'>{t('status')}</TableCell>
                                                <TableCell padding='none' align='left'>{t('output')}</TableCell>
                                                <TableCell padding='none' align='right'>{t('amount')}</TableCell>
                                                {this.state.activities[0] != null && this.state.activities[0].productionOrder != null && 
                                                this.state.activities[0].productionOrder.status._id === this.props.auth.constants.productionOrderStatuses.finished &&
                                                    <TableCell padding='none' align='right'>{t('actual')}</TableCell>
                                                }
                                                {this.state.fieldAccess && this.state.status !== this.props.auth.constants.productionBatchStatuses.deleted && this.state.status !==
                                                this.props.auth.constants.productionBatchStatuses.deleted && this.state.status !== this.props.auth.constants.productionBatchStatuses.reviewed &&
                                                this.state.status !== this.props.auth.constants.productionBatchStatuses.signedOff &&
                                                    <TableCell></TableCell>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.activities.map((row, i) =>
                                                <TableRow key={row._id}>
                                                    {row.productionOrder != null &&
                                                        <TableCell align='left'>
                                                            {row.enabled && 
                                                                <Link to={'/eproductionorder/' + row.productionOrder._id}>
                                                                    {row.productionOrder.number}
                                                                </Link>
                                                            }
                                                            {!row.enabled &&
                                                                row.productionOrder.number
                                                            }
                                                        </TableCell>
                                                    }
                                                    {row.productionOrder == null &&
                                                        <TableCell align='left'>
                                                            {row.roomCleaning != null &&
                                                                <Link to={'/roomcleaning/' + row.roomCleaning._id}>
                                                                    {row.roomCleaning.number}
                                                                </Link>
                                                            }
                                                        </TableCell>
                                                    }
                                                    <TableCell align='left'>
                                                        <DateDisplay value={row.expectedStart} format={this.props.auth.user.dateFormat}/>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        {row.productionOrder?.startDate != null && row.productionOrder?.startDate !== '' ?
                                                            <DateDisplay value={row.productionOrder.startDate} format={this.props.auth.user.dateFormat}/> : 
                                                         row.roomCleaning?.date != null && row.roomCleaning?.date !== '' ?
                                                            <DateDisplay value={row.roomCleaning.date} format={this.props.auth.user.dateFormat}/> : ''
                                                        }
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        {row.roomToClean != null ? t('roomCleaning') + '-' + row.roomToClean.name : row.productionOrder == null && row.bom != null ? 
                                                            row.bom.number + '-' + row.bom.description : row.productionOrder.description}
                                                    </TableCell>
                                                    <TableCell align='left'>{row.productionOrder != null ? row.productionOrder.status.label : row.roomCleaning != null ? row.roomCleaning.status : ''}</TableCell>
                                                    <TableCell align='left'>{row.outputItem != null ? row.outputItem.name : ''}</TableCell>
                                                    <TableCell align='right'>{row.outputQuantity != null ? row.outputQuantity + row.outputUOM.label : ''}</TableCell>
                                                    {row.productionOrder != null && row.productionOrder.status._id === this.props.auth.constants.productionOrderStatuses.finished &&
                                                        <TableCell padding='none' align='right'>
                                                            {row.productionOrder.outputs[0].amount + row.productionOrder.outputs[0].uom.label}
                                                        </TableCell>
                                                    }
                                                    {this.state.fieldAccess && this.state.status !== this.props.auth.constants.productionBatchStatuses.deleted && this.state.status !==
                                                    this.props.auth.constants.productionBatchStatuses.deleted && this.state.status !== this.props.auth.constants.productionBatchStatuses.reviewed &&
                                                    this.state.status !== this.props.auth.constants.productionBatchStatuses.signedOff &&
                                                        <TableCell>
                                                            {row.routing != null && row.canAdd &&
                                                                <Tooltip title={t('duplicate')}>
                                                                    <IconButton size="small" onClick={() => this.toggleActivity(i)}>
                                                                        <AddIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }
                                                            {(row.productionOrder == null || (row.productionOrder.status._id === this.props.auth.constants.productionOrderStatuses.inserted ||
                                                            row.productionOrder.status._id === this.props.auth.constants.productionOrderStatuses.released || 
                                                            row.productionOrder.status._id === this.props.auth.constants.productionOrderStatuses.inprogress)) && this.state.activities.length > 1 &&
                                                                <Tooltip title={t('delete')}>
                                                                    <IconButton size="small" onClick={() => this.removeActivity(i)}>
                                                                        <RemoveIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }
                                                            {(this.state.status._id === this.props.auth.constants.productionBatchStatuses.released ||
                                                             this.state.status._id === this.props.auth.constants.productionBatchStatuses.inprogress) && 
                                                             row.productionOrder == null && row._id != null && row.roomCleaning == null &&
                                                                <Tooltip title={t('release')}>
                                                                    <IconButton size="small" onClick={() => this.releaseActivity(i)}>
                                                                        <ForwardIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </TabCanvas>
                    <TabCanvas value={this.state.tabKey} index={1}>
                        {this.state.outputsTally != null && this.state.outputsTally.length > 0 &&
                            <Aux>
                                <br/>
                                <Grid container spacing={3} key="tallyHead">
                                    <Grid item xs={6} sm={3}>
                                        <strong>{t('item')}</strong>
                                    </Grid>
                                    <Grid item xs={2} sm={1} align="right">
                                        <strong>{t('expected')}</strong>
                                    </Grid>
                                    <Grid item xs={2} sm={1} align="right">
                                        <strong>{t('produced')}</strong>
                                    </Grid>
                                </Grid>
                                {this.state.outputsTally.map((row, index) => 
                                    <Grid container spacing={3} key={'tally' + index}>
                                        <Grid item xs={6} sm={3}>
                                            {row.item.number + '-' + row.item.name} 
                                        </Grid>
                                        <Grid item xs={2} sm={1} align="right">
                                            <NumericFormat displayType={'text'} value={row.expected} thousandSeparator={true} decimalScale={2}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1} align="right">
                                            <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true} decimalScale={2}/>
                                        </Grid>
                                    </Grid>
                                )}
                                <br/>
                            </Aux>
                        }
                        <Grid container spacing={3}>
                            {this.state.processingLoss != null && this.state.processingLoss !== '' &&
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('processingLoss') + ' (g)'}</FormLabel>
                                        <NumericFormat displayType={'text'} value={this.state.processingLoss} thousandSeparator={true}
                                            decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                            }
                        </Grid>
                        {this.state.categories != null && this.state.categories.length > 0 &&
                            <Aux>
                                <br/>
                                <SectionTitle title={t('inventoryChange')}/>
                                <Grid container spacing={3} key="catsHead">
                                    <Grid item sm="5"><strong>{t('category')}</strong></Grid>
                                    <Grid item sm="2" align="right"><strong>{t('inputs')}</strong></Grid>
                                    <Grid item sm="2" align="right"><strong>{t('outputs')}</strong></Grid>
                                    <Grid item sm="2" align="right"><strong>{t('waste')}</strong></Grid>
                                </Grid>
                                {this.state.categories.map((row, i) =>
                                    <Aux>
                                        <Grid container spacing={3} key={'category' + i}>
                                            <Grid item sm="5">
                                                <Aux>
                                                    <IconButton aria-label="expand row" size="small" onClick={this.toggleCategoryItem(i)}>
                                                        {this.state.showItems[i] ? <DownIcon /> : <UpIcon />}
                                                    </IconButton>
                                                    {row.category.description}
                                                </Aux>
                                            </Grid>
                                            <Grid item sm="2" align="right">
                                                <strong><NumericFormat value={row.inputs} displayType={'text'} thousandSeparator={true} decimalScale={2} /></strong>
                                            </Grid>
                                            <Grid item sm="2" align="right">
                                                <strong><NumericFormat value={row.outputs} displayType={'text'} thousandSeparator={true} decimalScale={2} /></strong>
                                            </Grid>
                                            <Grid item sm="2" align="right">
                                                <strong><NumericFormat value={row.waste} displayType={'text'} thousandSeparator={true} decimalScale={2} /></strong>
                                            </Grid>
                                        </Grid>
                                        {this.state.showItems[i] === true &&
                                            <Aux>
                                                <Grid container spacing={3} key={"itemsHead" + i}>
                                                    <Grid item sm="1"></Grid>
                                                </Grid>
                                                {row.items != null && row.items.length > 0 && row.items.map((item, k) =>
                                                    <Grid container spacing={3} key={"item" + i + 'a' + k} sx={{pb: 2}}>
                                                        <Grid item sm="1"></Grid>
                                                        <Grid item sm="4"><Link to={'/item/' + item.item._id}>{item.item.number + '-' + item.item.name}</Link></Grid>
                                                        <Grid item sm="2" align="right">
                                                                {this.displayLotValue(item.inputs, item.inputLots)}
                                                            </Grid>
                                                            <Grid item sm="2" align="right">
                                                            {this.displayLotValue(item.outputs, item.outputLots)}
                                                            </Grid>
                                                            <Grid item sm="2" align="right">
                                                                {this.displayLotValue(item.waste, item.wasteLots)}
                                                            </Grid>
                                                    </Grid>
                                                )}
                                            </Aux>
                                        }
                                    </Aux>
                                )}
                            </Aux>
                        }
                    </TabCanvas>
                    <TabCanvas value={this.state.tabKey} index={2}>
                        <Grid container spacing={2}>
                            {this.state.deviations != null && this.state.deviations.length > 0 &&
                                <Grid item xs={12} xl={5}>
                                    <Panel>
                                        <SubSectionHeading title={t('deviations')} />
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <TableContainer >
                                                    <Table stickyHeader sx={{ width: 'auto', tableLayout: 'auto'}}>
                                                        <TableHead>
                                                            <TableRow key='dev-header'>
                                                                <TableCell>{t('number')}</TableCell>
                                                                <TableCell>{t('title')}</TableCell>
                                                                <TableCell>{t('type')}</TableCell>
                                                                <TableCell>{t('date')}</TableCell>
                                                                <TableCell>{t('status')}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.deviations.map((row, i) =>
                                                                <TableRow key={row._id}>
                                                                    <TableCell><Link to={'/deviation/' + row._id}>{row.number}</Link></TableCell>
                                                                    <TableCell>{row.title}</TableCell>
                                                                    <TableCell>{row.type.label}</TableCell>
                                                                    <TableCell>
                                                                        <DateDisplay value={row.occurrenceDate} format={this.props.auth.user.dateFormat}/>
                                                                    </TableCell>
                                                                    <TableCell>{row.status != null ? row.status.label : ''}</TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </Panel>
                                </Grid>
                            }
                            {this.state.ncrs != null && this.state.ncrs.length > 0 &&
                                <Grid item xs={12} xl={5} >
                                    <Panel>
                                        <SubSectionHeading title={t('ncrs')} />
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <TableContainer >
                                                    <Table stickyHeader sx={{ width: 'auto', tableLayout: 'auto'}}>
                                                        <TableHead>
                                                            <TableRow key='ncr-header'>
                                                                <TableCell>{t('number')}</TableCell>
                                                                <TableCell>{t('title')}</TableCell>
                                                                <TableCell>{t('date')}</TableCell>
                                                                <TableCell>{t('status')}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.ncrs.map((row, i) =>
                                                                <TableRow key={row._id}>
                                                                    <TableCell><Link to={'/ncr/' + row._id}>{row.number}</Link></TableCell>
                                                                    <TableCell>{row.title}</TableCell>
                                                                    <TableCell>
                                                                        <DateDisplay value={row.occurrenceDate} format={this.props.auth.user.dateFormat}/>
                                                                    </TableCell>
                                                                    <TableCell>{row.status != null ? row.status.label : ''}</TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </Panel>
                                </Grid>
                            }
                        </Grid>
                    </TabCanvas>
                    <TabCanvas value={this.state.tabKey} index={3}>
                        <br/>
                        {this.state.roomCleanings != null && this.state.roomCleanings.length > 0 &&
                            <Aux>
                                <TableContainer>
                                    <Table stickyHeader sx={{ width: '100%', tableLayout: 'auto'}}>
                                        <TableHead>
                                            <TableRow key='cleanheader'>
                                                <TableCell>{t('number')}</TableCell>
                                                <TableCell>{t('type')}</TableCell>
                                                <TableCell>{t('room')}</TableCell>
                                                <TableCell>{t('completedOn')}</TableCell>
                                                <TableCell>{t('status')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {this.state.roomCleanings.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell><Link to={'/roomcleaning/' + row._id}>{row.number}</Link></TableCell>
                                                <TableCell>{row.type.label}</TableCell>
                                                <TableCell>{row.room.name}</TableCell>
                                                <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={row.date}/></TableCell>
                                                <TableCell>{row.status.label}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                            </Aux>
                        }
                    </TabCanvas>
                    {this.state.sales != null && this.state.sales.length > 0 &&
                        <TabCanvas value={this.state.tabKey} index={4}>
                            <TableContainer component={Paper}>
                                <Table aria-label="sales">
                                    <TableHead>
                                        <TableRow key="tableHeader">
                                            <TableCell>{t('number')}</TableCell>
                                            <TableCell>{t('date')}</TableCell>
                                            <TableCell>{t('customer')}</TableCell>
                                            <TableCell>{t('province')}</TableCell>
                                            <TableCell>{t('totalAmount')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.sales.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell><Link to={'/sale/' + row._id} name={'saleRow' + i}>{row.number}</Link></TableCell>
                                                <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                <TableCell>{row.customer.name}</TableCell>
                                                <TableCell>{row.prov != null ? row.prov.name : ''}</TableCell>
                                                <TableCell>{row.totalAmount}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabCanvas>
                    }
                </Panel>
                {this.state.dialogOpen &&
                    <ProductionBatchEdit 
                        id={this.state._id}
                        fieldAccess={fieldAccess}
                        reload={this.loadRecord}
                        permission={this.state.security} 
                        open={this.state.dialogOpen} 
                        onClose={() => this.toggleDialog()}
                    />
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }

                <ModalDialog buttonStack={(<Aux>
                        <Button color="secondary" size="small" variant='text' onClick={() => this.toggleActivity()}>Close</Button>
                        <Button color="primary" size="small" variant="contained" disabled={!fieldAccess} onClick={this.addActivity}>Save</Button>
                    </Aux>
                )} title={t('addActivity')} toggleDialog={() => this.toggleActivity()} fullWidth maxWidth='md' dialogStatus={this.state.showAddAct}>
                    <Grid container spacing={3}>
                        <Grid item xs={8} sm={8}>
                            <AutoCompleteField
                                value={this.state.addItemSel}
                                options={this.state.completedItems}
                                onChange={this.changeAuto('addItemSel', 'addItem')}
                                error={errors[t('addItem')] != null ? true : false}
                                helperText={errors[t('addItem')]}
                                label={t('item')}
                                disabled={!fieldAccess}
                                required
                                data-cy="addItem"
                            />
                        </Grid>
                        {this.state.itemTally != null && 
                            <Aux>
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('current')}</FormLabel>
                                        <NumericFormat displayType={'text'} value={this.state.itemTally.quantity} thousandSeparator={true} decimalScale={2}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('expected')}</FormLabel>
                                        <NumericFormat displayType={'text'} value={this.state.itemTally.expected} thousandSeparator={true} decimalScale={2}/>
                                    </FormControl>
                                </Grid>
                            </Aux>
                        }
                        {this.state.producedBys != null && this.state.producedBys.length > 0 &&
                            <Grid item xs={12} sm={11}>
                                <AutoCompleteField
                                    value={this.state.producedBySel}
                                    options={this.state.producedBys}
                                    onChange={this.changeAuto('producedBySel', 'producedBy')}
                                    error={errors[t('producedBy')] != null ? true : false}
                                    helperText={errors[t('producedBy')]}
                                    label={t('producedBy')}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy="producedBy"
                                />
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            <DateSelect
                                onChange={this.dateChange('expectedStart')}
                                value={this.state.expectedStart}
                                helperText={errors[t('expectedStart')]}
                                error={errors[t('expectedStart')] != null ? true : false}
                                label={t('expectedStart')}
                                format={this.props.auth.user.dateFormat}
                                disabled={!fieldAccess}
                                required
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <DateSelect
                                onChange={this.dateChange('expectedEnd')}
                                value={this.state.expectedEnd}
                                helperText={errors[t('expectedEnd')]}
                                error={errors[t('expectedEnd')] != null ? true : false}
                                label={t('expectedEnd')}
                                format={this.props.auth.user.dateFormat}
                                disabled={!fieldAccess}
                                required
                            />
                        </Grid>
                    </Grid>
                </ModalDialog>
                {this.state.printViewer &&
                    <PrintView
                        id={this.state._id}
                        object={this.props.auth.constants.objectIds.productionBatch}
                        open={this.state.printViewer}
                        onClose={this.togglePrintViewer}
                        callBack={this.loadRecord}
                        printAccess={this.state.security.print}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.productionBatch}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
                {this.state.showLots && this.state.inventoryLots != null && this.state.inventoryLots.length > 0 &&
                    <Dialog open={this.state.showLots} onClose={this.closeLotsPopup} center maxWidth="sm" fullWidth>
                        <DialogTitle>
                            <SectionTitle title={t('lots')}/>
                        </DialogTitle>
                        <DialogContent>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow key="lotdispHeader">
                                            <TableCell>{t('number')}</TableCell>
                                            <TableCell align="right">{t('quantity')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.inventoryLots.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell><Link to={"/lot/" + row._id}>{row.number}</Link></TableCell>
                                                <TableCell align="right"><NumericFormat value={row.amount} displayType={'text'} thousandSeparator={true} decimalScale={2}/></TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <DialogActions>
                                <Button variant="contained" color="secondary" size="small" onClick={this.closeLotsPopup}>{t('close')}</Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ProductionBatch));
